import { Link } from 'react-router-dom';
import SideBar from "./SideBar";
import { FaCashRegister, FaFileMedical } from "react-icons/fa";
import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from 'axios';
import ProfitLossDialog from './ProfitLossDialog';


function BalanceSheet() {
  const [ppne, setPPNE] = useState([])
  const [dfe, setDFE] = useState([])
  const [cnc, setCNC] = useState([])
  const [tnor, setTNOR] = useState([]);
  const [advance, setAdvance] = useState([]);
  const [tnop, setTNOP] = useState([])
  const [open, setOpen] = useState(false)
  const [noteID, setNoteID] = useState('')
  const [noteName, setNoteName] = useState('');
  const [clinicTotal, setClinicTotal] = useState(0);
  const [surgeryTotal, setSurgeryTotal] = useState(0);
  const [clinicTable, setClinicTabel] = useState([]);
  const [surgeryTabel, setSurgeryTabel] = useState([]);
  const [monthName, setMonthName] = useState([]);

  const token = localStorage.getItem('token');
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const handleBalanceSheetEntry = async () => {
    Swal.fire('Please wait')
    Swal.showLoading()
    await api.get('/account-balances/entry').then(() => {
      Swal.hideLoading()
      Swal.close()
      window.location.reload();
    })
      .catch(error => {
        console.log('ERROR!', error)
      })
  }

  const handleNoteModal = async (notesID, monthName) => {
    setNoteID(notesID)
    let date = new Date()
    if (monthName === undefined) monthName = date.toLocaleString('default', { month: 'short' });
    await api.get('/notes/get-notes', { params: { notesID: notesID, monthName: monthName } })
      .then(response => {
        console.log(response.data.data)
        setSurgeryTotal(response.data.data.surgeryTotal)
        setSurgeryTabel(response.data.data.surgeryTable)
        setNoteName(response.data.data.notesName)
        setClinicTotal(response.data.data.clinicTotal)
        setClinicTabel(response.data.data.clinicTable)
        setMonthName(response.data.data.month)
        console.log(monthName, 'here')
      })
    setOpen(true)
    // console.log(tableList, 'tableList')
    // console.log(noteName, 'noteName')
    // console.log(total, 'setTotal')
  }

  useEffect(() => {
    Swal.fire('Please wait')
    Swal.showLoading()
    const getIncomeStatement = async () => {
      await api.get('/transactions/balance-sheet')
        .then(res => {
          setPPNE(res.data.data.PPANDE)
          setDFE(res.data.data.DeferredExpenses)
          setCNC(res.data.data.CashAndCashEquivalent)
          setTNOR(res.data.data.TAOR)
          setAdvance(res.data.data.Advance)
          setTNOP(res.data.data.TAOP)
          Swal.hideLoading()
          Swal.close()
        })
        .catch(err => {
          console.log('ERROR!', err)
        })
    }
    getIncomeStatement()
  }, [])
  return (
    <div classNameName="App">
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className="wrapper">
        {/* <!-- Navbar --> */}
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* <!-- Left navbar links --> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#">
                <i className="fas fa-bars"></i>
              </a>
            </li>
          </ul>
          <div className="title">
            <h1>Finance & Admin Module</h1>
          </div>
          {/* <!-- end youtube test -->
    <!-- Right navbar links --> */}
          <ul className="navbar-nav ml-auto"></ul>
        </nav>
        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className="content-wrapper">
          {/* <!-- Content Header (Page header) --> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Balance Sheet List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className=''>
                        <h1 className="card-title font-weight-bold px-3 py-3">
                          Statement of Finiancial Position
                        </h1>
                      </div>
                      <div className=''>

                      </div>
                      <div className='mt-3'>
                        <button className='btn btn-sm btn-primary' onClick={() => { handleBalanceSheetEntry() }}>Balance Sheet Entry</button>
                      </div>

                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class="card-body">
                      <table id="example1" class="table">
                        <thead class="text-center bg-info">
                          <tr>

                            <th>Account Name</th>
                            <th>Note</th>
                            <th>January</th>
                            <th>February</th>
                            <th>March</th>
                            <th>April</th>
                            <th>May</th>
                            <th>June</th>
                            <th>July</th>
                            <th>August</th>
                            <th>September</th>
                            <th>October</th>
                            <th>November</th>
                            <th>December</th>
                          </tr>
                        </thead>

                        <tbody class="text-center">
                          <tr>
                            <td><b><u>Non Current Assets</u></b></td>
                            <td colSpan={13}></td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Property, Plant and Equipment</td>
                            <td><a role='button' onClick={e => handleNoteModal('64c72891150def8dbf9db25c')}>Note 1</a></td>
                            {ppne.map(item =>
                            (<>
                              <td>{item.clinic}</td>
                            </>)
                            )}


                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Deferred Expenses</td>
                            <td><a role='button' onClick={e => handleNoteModal('64c0d7133bc671d1b0740d39')}>Note 2</a></td>

                            {dfe.map(item =>
                            (<>
                              <td>{item.clinic}</td>
                            </>)
                            )}
                          </tr>

                          <tr>
                            <td><b><u>Current Assets</u></b></td>
                            <td colSpan={13}></td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Cash and Cash Equivalent</td>
                            <td><a role='button' onClick={e => handleNoteModal('64c0d8083bc671d1b0740ef3')}>Note 3</a></td>

                            {cnc.map(item =>
                            (<>
                              <td>{item.clinic}</td>
                            </>)
                            )}
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Trade and Other Receivable</td>
                            <td><a role='button' onClick={e => handleNoteModal('64c368bafb1f7de8f2355874')}>Note 4</a></td>

                            {tnor.map(item =>
                            (<>
                              <td>{item.clinic}</td>
                            </>)
                            )}
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Advance</td>
                            <td><a role='button' onClick={e => handleNoteModal('64c3693efb1f7de8f235590b')}>Note 5</a></td>
                            {advance.map(item =>
                            (<>
                              <td>{item.clinic}</td>
                            </>)
                            )}
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Closing Inventory</td>
                            <td></td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td colSpan={14}></td>
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td ><b><u>Total Assets</u></b></td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td><b><u>Equities</u></b></td>
                            <td colSpan={13}></td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Issued and Paid up Share Capital</td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Retained Earning-Previous Year</td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Retained Earning-For the month(Clinic)</td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Retained Earning-For the month(Surgery)</td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td colSpan={14}></td>
                          </tr>

                          <tr>
                            <td><b><u>Liabilities</u></b></td>
                            <td colSpan={13}></td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Non Current Liabilities</td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Current Liabilities</td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Trade and Other Payable</td>
                            <td><a role='button' onClick={e => handleNoteModal('64c36974fb1f7de8f23559b4')}>Note 6</a></td>
                            {tnop.map(item => (
                              <>
                                <td>{item.clinic}</td>
                              </>
                            ))}
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td colSpan={14}></td>
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td ><b><u>Total Equities and Liabilities</u></b></td>
                            <td> </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                            <td> -  </td>
                            <td> - </td>
                          </tr>


                        </tbody>
                      </table>
                      {/* <?php

              echo '<br /><a href="profit_loss_acc_list" class="float-left">Profit & Loss</a>
                          <a href="trial_balance" class="float-right">Trial Balance</a>
                           ';

                ?> */}
                      <a href="/profit_loss" class="float-left mt-3">
                        Profit & Loss
                      </a>
                      <a href="/balance_sheet" class="float-right mt-3">
                        Trial Balance
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <ProfitLossDialog
                open={open}
                close={() => setOpen(false)}
                noteName={noteName}
                surgeryTotal={surgeryTotal}
                surgeryTabel={surgeryTabel}
                clinicTotal={clinicTotal}
                clinicTable={clinicTable}
                handleNoteModal={handleNoteModal}
                noteID={noteID}
                monthName={monthName}
              />
            </div>{" "}
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className="main-footer">
        <strong>
          Copyright &copy; 2017-2020{" "}
          <a href="http://www.kwintechnologies.com">K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName="control-sidebar control-sidebar-dark">
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
      {/* <!-- jQuery --> */}
      <script src="{{asset('plugins/jquery/jquery.min.js')}}"></script>
      {/* <!-- jQuery UI 1.11.4 --> */}
      <script src="{{asset('plugins/jquery-ui/jquery-ui.min.js')}}"></script>
      {/* <!-- Select2 --> */}

      {/* <!-- Resolve conflict in jQuery UI tooltip with Bootstrap tooltip -->*/}
      <script>$.widget.bridge('uibutton', $.ui.button)</script>
      {/* <!-- Bootstrap 4 --> */}
      <script src="{{asset('plugins/bootstrap/js/bootstrap.bundle.min.js')}}"></script>
      {/* <!-- ChartJS --> */}
      <script src="{{asset('plugins/chart.js/Chart.min.js')}}"></script>
      {/* <!-- Sparkline --> */}
      <script src="{{asset('plugins/sparklines/sparkline.js')}}"></script>
      {/* <!-- jQuery Knob Chart --> */}
      <script src="{{asset('plugins/jquery-knob/jquery.knob.min.js')}}"></script>
      {/* <!-- daterangepicker --> */}
      <script src="{{asset('plugins/moment/moment.min.js')}}"></script>
      <script src="{{asset('plugins/daterangepicker/daterangepicker.js')}}"></script>

      {/* <!-- Tempusdominus Bootstrap 4 --> */}
      <script src="{{asset('plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js')}}"></script>
      {/* <!-- Summernote --> */}
      <script src="{{asset('plugins/summernote/summernote-bs4.min.js')}}"></script>
      {/* <!-- overlayScrollbars --> */}
      <script src="{{asset('plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js')}}"></script>
      {/* <!-- AdminLTE App --> */}
      <script src="{{asset('dist/js/adminlte.js')}}"></script>

      <script src="{{asset('dist/js/demo.js')}}"></script>

      <script src="{{asset('plugins/datatables/jquery.dataTables.js')}}"></script>
      <script src="{{asset('plugins/datatables/dataTables.bootstrap')}}"></script>
      <script src="{{asset('plugins/datatables-bs4/js/dataTables.bootstrap4.js')}}"></script>
      <script src="{{asset('plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js')}}"></script>
    </div>
  );
}
export default BalanceSheet;

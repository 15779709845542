import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [relatedBrandList, setRelatedBrandList] = useState([])
  const [procode, setProCode] = useState('')
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')

  const [categoryLists, setCategoryLists] = useState([])
  const [subCategoryLists, setSubCategoryLists] = useState([])

  const [relatedBrand, setRelatedBrand] = useState('')

  const [description, setDescription] = useState('')

  const ProMedicineCreate = () => {
    const data = {
      name: name,
      code: procode,
      relatedCategory: category,
      relatedSubCategory: subCategory,
      relatedBrand: relatedBrand,
      description: description
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.post('procedure-medicine', data, config).then(function (response) {
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })

      window.location.reload()

      props.setProMedicineLists([...props.proMedicineLists, response.data.data])
    })
    props.close()
  }

  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await apiInstance.get('categories')

        setCategoryLists(res.data.data)
      } catch (err) { }
    }
    const getSubCategory = async () => {
      try {
        const res = await apiInstance.get('sub-categories')

        setSubCategoryLists(res.data.data)
      } catch (err) { }
    }

    const getBrandLists = async () => {
      try {
        const res = await apiInstance.get('brands')
        setRelatedBrandList(res.data.data)
      } catch (err) {
        alert(err.message)
      }
    }
    getCategory()
    getSubCategory()

    getBrandLists()
  }, [])

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='md'>
        <DialogTitle>
          {' '}
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Create New Procedure Medicine</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          {/* @csrf */}
          <div class='modal-body'>
            <div class='form-group'>
              <label for='name'>Code</label>
              <input
                type='text'
                class='form-control border border-info'
                name='code'
                onChange={e => setProCode(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Name</label>
              <input
                type='text'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Category</label>
              <select
                class='custom-select border-info'
                name='account_type_id'
                id='cat'
                onChange={e => setCategory(e.target.value)}
              >
                <option hidden>Choose Category</option>
                {categoryLists.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div class='form-group'>
              <label for='name'>Sub Category</label>
              <select
                class='custom-select border-info'
                id='subCat'
                name='account_type_id'
                onChange={e => setSubCategory(e.target.value)}
              >
                <option hidden>Choose Sub Category</option>
                {subCategoryLists.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>

            <div class='form-group'>
              <label for='name'>Choose Brand</label>
              <select
                name='currency'
                id=''
                className='form-control mt-1'
                onchange='convert(this.value)'
                onChange={e => setRelatedBrand(e.target.value)}
              >
                <option hidden>Choose Brand</option>

                {relatedBrandList.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div class='form-group'>
              <label for='name'>Description</label>
              <textarea
                className='form-control'
                id='description'
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div class='modal-footer'>
            <Button
              class='btn btn-secondary'
              data-dismiss='modal'
              onClick={props.close}
            >
              Close
            </Button>
            <Button class='btn btn-primary' onClick={ProMedicineCreate}>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

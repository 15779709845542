import { createSlice } from '@reduxjs/toolkit'

const apiSlice = createSlice({
  name: 'api',
  initialState: {
    // serverurl: 'http://backendcherryk.kwintechnologykw11.com:4000/api/',
    url: 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  },
  reducers: {}
})

export const {} = apiSlice.actions
export default apiSlice.reducer

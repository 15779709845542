import * as React from 'react'
import Button from '@mui/material/Button'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useLocation, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import HeaderName from '../../HeaderName'
import apiInstance from '../../../../utils/api'

export default function BankInfoDialog() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [treatList, setTreatList] = useState([])
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [bodyParts, setBodyParts] = useState('')
  const [description, setDescription] = useState('')
  const [upCode, setUpCode] = useState('')
  const [upName, setUpName] = useState('')
  const [upBody, setUpBody] = useState('')
  const [upBodyId, setUpBodyID] = useState('')
  const [upDesc, setUpDesc] = useState('')

  const TreateId = useLocation().pathname.split('/')[2]

  const TreatmentUpdate = () => {
    const data = {
      id: TreateId,
      name: upName,
      code: upCode,
      bodyParts: upBody,
      description: upDesc
    }

    // console.log(data)
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.put('treatment-list', data, config).then(function (response) {
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })
      // window.location.reload()

      // setMedicineLists([...medicineLists, response.data.list[0]])
      // setMedicineLists(
      //   medicineLists.map(category => {
      //     if (category._id === response.data.list[0]._id) {
      //       return response.data.list[0]
      //     } else {
      //       return category
      //     }
      //   })
      // )

      console.log(response.data.list)
    })
  }

  useEffect(() => {
    const getTreat = async () => {
      try {
        const res = await apiInstance.get(
          //"http://backendcherryk.kwintechnologykw11.com:4000/apiInstance/procedure-items/" +
          'treatment-list/' + TreateId
        )

        console.log(res.data.data[0])
        setUpName(res.data.data[0].name)
        setUpCode(res.data.data[0].code)
        setUpBody(res.data.data[0].bodyParts)
        setUpBodyID(res.data.data[0])
        setUpDesc(res.data.data[0].description)
      } catch (err) { }
    }

    getTreat()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/treatC'>Back</Link>
                    </li>
                    <li className='breadcrumb-item active'>Treatment Update</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div class='modal-body'>
                <div class='form-group'>
                  <label for='name'>Code</label>
                  <input
                    type='text'
                    class='form-control border border-info'
                    name='code'
                    defaultValue={upCode}
                    onChange={e => setUpCode(e.target.value)}
                  />
                </div>
                <div class='form-group'>
                  <label for='name'>Name</label>
                  <input
                    type='text'
                    class='form-control border border-info'
                    name='name'
                    defaultValue={upName}
                    onChange={e => setUpName(e.target.value)}
                  />
                </div>
                <div class='form-group'>
                  <label for='name'>Choose Body Parts</label>
                  <select
                    name='currency'
                    id=''
                    className='form-control mt-1'
                    onchange='convert(this.value)'
                    onChange={e => setUpBody(e.target.value)}
                  >
                    <option value={upBody}>{upBody}</option>
                    <option value='Face'>Face</option>
                    <option value='Body'>Body</option>
                    <option value='Body Injection'>Body Injection</option>
                  </select>
                </div>
                <div class='form-group'>
                  <label for='name'>Description</label>
                  <textarea
                    className='form-control'
                    id='description'
                    defaultValue={upDesc}
                    onChange={e => setUpDesc(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div class='modal-footer'>
                <Button class='btn btn-secondary' data-dismiss='modal'>
                  Close
                </Button>
                <button onClick={TreatmentUpdate} class='btn btn-primary'>
                  Save
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
// phyo
//maymyat

import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import axios from 'axios'
import HeaderName from './HeaderName'
import ProfitLossDialog from './ProfitLossDialog';
import Swal from 'sweetalert2';

function IncomeStatement() {
  const [salesList, setSalesList] = useState([]);
  const [grossProfit, setGrossProfit] = useState([]);
  const [adminExpense, setAdminExpense] = useState([]);
  const [otherExpense, setOtherExpense] = useState([]);
  const [smExpense, setSmExpense] = useState([]);
  const [opExpense, setOpExpense] = useState([]);
  const [costOfSales, setCostOfSales] = useState([]);
  const [otherIncome, setOtherIncome] = useState([]);
  const [clinicTable, setClinicTabel] = useState([]);
  const [surgeryTabel, setSurgeryTabel] = useState([]);
  const [netProfit, setNetProfit] = useState([]);
  const [noteName, setNoteName] = useState('');
  const [clinicTotal, setClinicTotal] = useState(0);
  const [surgeryTotal, setSurgeryTotal] = useState(0);
  const [open, setOpen] = useState(false)
  const [monthName, setMonthName] = useState('');
  const token = localStorage.getItem('token')
  const [noteID, setNoteID] = useState('')
  //const url = 'http://localhost:9000/api'
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const handleNoteModal = async (notesID, monthName) => {
    setNoteID(notesID)
    let date = new Date()
    if (monthName === undefined) monthName = date.toLocaleString('default', { month: 'short' });
    await api.get('/notes/get-notes', { params: { notesID: notesID, monthName: monthName } })
      .then(response => {
        console.log(response.data.data)
        setSurgeryTotal(response.data.data.surgeryTotal)
        setSurgeryTabel(response.data.data.surgeryTable)
        setNoteName(response.data.data.notesName)
        setClinicTotal(response.data.data.clinicTotal)
        setClinicTabel(response.data.data.clinicTable)
        setMonthName(response.data.data.month)
        console.log(response.data.data.month)
      })
    setOpen(true)
    // console.log(tableList, 'tableList')
    // console.log(noteName, 'noteName')
    // console.log(total, 'setTotal')
  }
  useEffect(() => {
    Swal.fire('Please wait')
    Swal.showLoading()
    const getIncomeStatement = async () => {
      await api.get('/transactions/income-statement')

        .then(res => {
          setSalesList(res.data.data.Sales)
          setCostOfSales(res.data.data.CostOfSales)
          setOtherIncome(res.data.data.OtherIncome)
          setAdminExpense(res.data.data.AdministrationExpenses)
          setOpExpense(res.data.data.OperationExpenses)
          setSmExpense(res.data.data.SalesAndMarketingExpenses)
          setOtherExpense(res.data.data.OtherExpenses)
          setGrossProfit(res.data.data.GrossProfit)
          setNetProfit(res.data.data.NetProfit)

          Swal.hideLoading()
          Swal.close()
        })
        .catch(err => {
          console.log('ERROR!', err)
        })
    }
    getIncomeStatement()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper d-flex flex-row mb-3'>
          {/* <!-- Content Header (Page header) --> */}
          {/* <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <a href='/'>Home</a>
                    </li>
                    <li className='breadcrumb-item active'>
                      Income Statement
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div> */}

          {/* <!-- Main content --> */}

          <section className='content d-flex flex-row mb-3'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <h1 className='card-title font-weight-bold offset-4'>
                        Income Statement for the year 2023
                      </h1>


                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class='card-body'>
                      <table id='example1' class='table'>
                        <thead class='text-center bg-info'>
                          <tr>
                            {console.log(surgeryTabel, 'form front')}
                            <th>Account Name</th>
                            <th>Note</th>
                            <th colSpan={2}>January</th>
                            <th colSpan={2}>February</th>
                            <th colSpan={2}>March</th>
                            <th colSpan={2}>April</th>
                            <th colSpan={2}>May</th>
                            <th colSpan={2}>June</th>
                            <th colSpan={2}>July</th>
                            <th colSpan={2}>August</th>
                            <th colSpan={2}>September</th>
                            <th colSpan={2}>October</th>
                            <th colSpan={2}>November</th>
                            <th colSpan={2}>December</th>
                            <th>Total</th>
                          </tr>
                          <tr>

                            <th></th>
                            <th></th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th>Clinic</th>
                            <th>Surgery</th>
                            <th> </th>
                          </tr>
                        </thead>

                        <tbody class='text-center'>
                          <tr>
                            <td><b><u>Revenues</u></b></td>
                            <td colSpan={13}></td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Sales</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b2124656ec42e1490bece9')}>Note 7, 14</a></td>
                            {salesList.map((item, index) => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}

                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Cost of Sales</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b6483e593aa6c1c3027988')}>Note 8, 15</a></td>
                            {costOfSales.map((item) => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td colSpan={27}></td>
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td ><b><u>Gross Profit</u></b></td>
                            <td> </td>
                            {grossProfit.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}

                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Other Income</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b6530b1c1e52429aaf192b')}>Note 13</a></td>
                            {otherIncome.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}
                          </tr>

                          <tr>
                            <td><b><u>Administration and Operation</u></b></td>
                            <td colSpan={13}></td>
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Administration Expenses</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b657411c1e52429aaf6285')}>Note 9</a></td>
                            {adminExpense.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}

                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Operation Expenses</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b657a11c1e52429aaf62fd')}>Note 10, 16</a></td>
                            {opExpense.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}
                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Sales and Marketing Expenses</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b657b51c1e52429aaf6375')}>Note 11</a></td>
                            {smExpense.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}

                          </tr>

                          <tr>
                            <td style={{ tabSize: 4 }}>&nbsp;Other Expenses</td>
                            <td><a role='button' onClick={e => handleNoteModal('64b657c71c1e52429aaf63ed')}>Note 12</a></td>

                            {otherExpense.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}

                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td colSpan={27}></td>
                          </tr>

                          <tr style={{ borderBottom: "1pt solid black" }}>
                            <td ><b><u>Net Profit</u></b></td>
                            <td> </td>
                            {netProfit.map(item => (
                              <>
                                <td>{item.clinic}  </td>
                                <td>{item.surgery} </td>
                              </>
                            ))}

                          </tr>

                        </tbody>
                      </table>
                      {/* <?php

            session_start();

            $_SESSION['favcolor'] = $net ;

            echo '<br /><a href="balancesheet_acc_list" class="float-right">Balance Sheet</a>';

              ?> */}
                      <a href='/balance_sheet' class='mt-3 float-right'>
                        Balance Sheet
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <ProfitLossDialog
                open={open}
                close={() => setOpen(false)}
                noteName={noteName}
                surgeryTotal={surgeryTotal}
                surgeryTabel={surgeryTabel}
                clinicTotal={clinicTotal}
                clinicTable={clinicTable}
                handleNoteModal={handleNoteModal}
                noteID={noteID}
                monthName={monthName}
              />
            </div>{' '}
            {/*<!-- /.container-fluid --> */}
          </section>

          {/* <div className="modal fade" id="cost_center" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header bg-info">
            <h5 className="modal-title" id="exampleModalLabel">Add New Cost Center</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form action="{{route('store_cost_center')}}" method="post">
            @csrf
        <div className="modal-body">
            <div className="form-group">
                <label for="name">Cost Center Name</label>
                <input type="text" className="form-control border border-info" name="cc_name" placeholder="eg. mg mg"/>
            </div>

        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary">Save</button>
        </div>
        </form>
        </div>
    </div>
</div>  */}
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
      {/* <!-- jQuery --> */}
      <script src="{{asset('plugins/jquery/jquery.min.js')}}"></script>
      {/* <!-- jQuery UI 1.11.4 --> */}
      <script src="{{asset('plugins/jquery-ui/jquery-ui.min.js')}}"></script>
      {/* <!-- Select2 --> */}

      {/* <!-- Resolve conflict in jQuery UI tooltip with Bootstrap tooltip -->*/}
      <script>$.widget.bridge('uibutton', $.ui.button)</script>
      {/* <!-- Bootstrap 4 --> */}
      <script src="{{asset('plugins/bootstrap/js/bootstrap.bundle.min.js')}}"></script>
      {/* <!-- ChartJS --> */}
      <script src="{{asset('plugins/chart.js/Chart.min.js')}}"></script>
      {/* <!-- Sparkline --> */}
      <script src="{{asset('plugins/sparklines/sparkline.js')}}"></script>
      {/* <!-- jQuery Knob Chart --> */}
      <script src="{{asset('plugins/jquery-knob/jquery.knob.min.js')}}"></script>
      {/* <!-- daterangepicker --> */}
      <script src="{{asset('plugins/moment/moment.min.js')}}"></script>
      <script src="{{asset('plugins/daterangepicker/daterangepicker.js')}}"></script>

      {/* <!-- Tempusdominus Bootstrap 4 --> */}
      <script src="{{asset('plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js')}}"></script>
      {/* <!-- Summernote --> */}
      <script src="{{asset('plugins/summernote/summernote-bs4.min.js')}}"></script>
      {/* <!-- overlayScrollbars --> */}
      <script src="{{asset('plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js')}}"></script>
      {/* <!-- AdminLTE App --> */}
      <script src="{{asset('dist/js/adminlte.js')}}"></script>

      <script src="{{asset('dist/js/demo.js')}}"></script>

      <script src="{{asset('plugins/datatables/jquery.dataTables.js')}}"></script>
      <script src="{{asset('plugins/datatables/dataTables.bootstrap')}}"></script>
      <script src="{{asset('plugins/datatables-bs4/js/dataTables.bootstrap4.js')}}"></script>
      <script src="{{asset('plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js')}}"></script>
    </div>
  )
}
export default IncomeStatement

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Login from './components/views/Login'
import Dashboard from './components/views/Dashboard'
import ProfitAndLoss from './components/views/Master/Profit And Loss/ProfitAndLoss'
import AccountList from './components/views/AccountList'
import Bank from './components/views/Bank'
import BankUpdate from './components/views/AccountData/Bank/BankUpdate.jsx'
import Currency from './components/views/Currency'
import Income from './components/views/Income'
import IncomeUpdate from './components/views/AccountData/Income/IncomeUpdate.jsx'
import IncomeType from './components/views/IncomeType'
import Expense from './components/views/Expense'
import ExpenseUpdate from './components/views/AccountData/Expense/ExpenseUpdate.jsx'
import ExpenseType from './components/views/ExpenseType'
import FixAsset from './components/views/FixAsset'
import FixAssetUpdate from './components/views/Admin/FixAsset/fixupdate.jsx'
import ProfitLoss from './components/views/ProfitLoss'
import BalanceSheet from './components/views/BalanceSheet'
import TrialBabl from './components/views/TrailBabl'
import MedicineSale from './components/views/MedicineSale'
import Treatment from './components/views/Treatment'
import AccountType from './components/views/AccountType'
import Heading from './components/views/Heding'
import SubHeader from './components/views/SubHeader'
import Category from './components/views/Master/Category'
import SubCategory from './components/views/Master/SubCategory'
import Brand from './components/views/Master/Brand'
import AddAsset from './components/views/AddAsset'
import MedicineList from './components/views/Master/MedicineList/MedicineList'
import Unit from './components/views/Master/MedicineList/Unit'
import ProcedureMedicine from './components/views/Master/Procedure Medicine/ProcedureMedicine'
import ProUnit from './components/views/Master/Procedure Medicine/ProUnit'
import StockCount from './components/views/StockCount'
import StockUnit from './components/views/StockUnit'
import StockMedUnit from './components/views/StockMedUnit'
import Purchase from './components/views/Purchase'
import PurchaseDetail from './components/views/PurchaseDetail'
import PurchaseAdd from './components/views/PurchaseAdd'
import StockProAss from './components/views/StockProAssUnit'
import TreatmentCreate from './components/views/Master/Treatment/TreatmentCreate'
import CheckUnit from './components/views/Master/Treatment/CheckUnit'
import SellEndDialog from './components/views/SellEndDialog'
import Supplier from './components/views/Supplier'
import SupplierUpdate from './components/views/Admin/Supplier/SupplierUpdate.jsx'
import CreditDetail from './components/views/CreditDetail'
import MedicineUpdate from './components/views/Master/MedicineList/MedicineUpdate'
import TreatmentSale from './components/views/TreatmentSale'
import AccListUpdate from './components/views/AccListUpdate'
import ProUpdate from './components/views/Master/Procedure Medicine/ProUpdate.jsx'
import TreatUpdate from './components/views/Master/Treatment/Update.jsx'
import TCheckList from './components/views/Master/Treatment/UnitCreate.jsx'
import CreateNotes from './components/views/Master/Notes/CreateNotes'
import Notes from './components/views/Master/Notes/Notes.jsx'
// import UnitUpdate from './components/views/Master/Treatment/UnitUpdate.jsx'
import ProcedureAccessory from './components/views/Master/ProAccess/ProAccess'
import ProAccessUpdate from './components/views/Master/ProAccess/ProUpdate.jsx'
import ProAccessUnit from './components/views/Master/ProAccess/ProAccessUnit'
import TransferList from './components/views/Master/Transfer/TransferList'
// import TransactionList from './components/views/Master/Transaction/TransactionList';
import StockList from './components/views/Master/Log/LogList'
import Update2 from './components/views/Master/Treatment/UnitUpdate2'
import Doctor from './components/views/Admin/Doctor/DoctorList.jsx'
import DoctorReg from './components/views/Admin/Doctor/Register.jsx'
import DoctorUpdate from './components/views/Admin/Doctor/DoctorUpdate.jsx'
import AllTran from './components/views/Master/Transaction/AllTransaction.jsx'
import JournalEntry from './components/views/AccountData/JournalEntry/JournalEntryList.jsx'
import JournalUpdate from './components/views/AccountData/JournalEntry/JEUpdate.jsx'
import TrialBalance from './components/views/TrialBalance/TrialBalance.jsx'
import Machine from './components/views/Master/Machine/Machine.jsx'
import Discount from './components/views/Admin/Discount/DiscountList.jsx'
import DiscountUpdate from './components/views/Admin/Discount/DiscountUpdate.jsx'
import Member from './components/views/Admin/Member/MemberList.jsx'
import PackageList from './components/views/Admin/PackageList/PackageList.jsx'
import PackageReg from './components/views/Admin/PackageList/PackageReg.jsx'
import PackageUpdate from './components/views/Admin/PackageList/PackageUpdate.jsx'
import Comission from './components/views/Admin/Doctor/Commission.jsx'

import UserList from './components/views/Admin/User/UserList.jsx'
import UserDetail from './components/views/Admin/User/UserDetail.jsx'
import ReOrder from './components/views/Admin/Reorder/ReorderList.jsx'
import BCReport from './components/views/AccountData/BCReport/bcreport.jsx'
import Top10 from './components/views/Admin/Top10/Top10List.jsx'
import NurComission from './components/views/Admin/Doctor/NurCom.jsx'
import TheComission from './components/views/Admin/Doctor/TheCommission.jsx'
import Customer from './components/views/Admin/Customer/customerList.jsx'
import Bar from './components/views/SideBar'
//Damage
import Damage from './components/views/Admin/DamageList/damageCreate.jsx'
import DamageList from './components/views/Admin/DamageList/damageList.jsx'

import './App.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'

import './assets/dist/css/adminlte.min.css'
import './plugins/fontawesome-free/css/all.min.css'
import './plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css'
import './plugins/icheck-bootstrap/icheck-bootstrap.min.css'
import './plugins/jqvmap/jqvmap.min.css'
import './plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import './plugins/daterangepicker/daterangepicker.css'
import './plugins/summernote/summernote-bs4.css'
import './plugins/select2/css/select2.min.css'
import './file.css'
import './plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css'
import './assets/plugins/multiselect/css/multi-select.css'
import './plugins/datatables-bs4/css/dataTables.bootstrap4.css'
import './assets/plugins/dropify/dist/css/dropify.min.css'
import './assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.css'
import './assets/plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css'

function App () {
  return (
    <Router>
      <Routes>
        <Route path='/dash' element={<Dashboard />}></Route>
        <Route path='/create-notes' element={<CreateNotes />}></Route>
        <Route path='/' element={<Login />}></Route>
        <Route path='/trial-balance' element={<TrialBalance />}></Route>
        <Route path='/notes' element={<Notes />}></Route>
        {/* <Route path='/dashboard' element={<Dashboard/>}></Route> */}
        {/* <Route path='/transaction-list' element={<TransactionList />}></Route> */}
        <Route path='/profit-and-loss' element={<ProfitAndLoss />}></Route>
        <Route path='/stock-list' element={<StockList />}></Route>
        <Route path='/transfer-list' element={<TransferList />}></Route>
        <Route path='/fix_asset' element={<FixAsset />}></Route>
        <Route path='/update-fix/:id' element={<FixAssetUpdate />}></Route>
        <Route path='/sellEnd/:id' element={<SellEndDialog />}></Route>
        <Route path='/expense' element={<Expense />}></Route>
        <Route path='/expense_type' element={<ExpenseType />}></Route>
        <Route path='/income' element={<Income />}></Route>
        <Route path='/income_type' element={<IncomeType />}></Route>
        <Route path='/currency' element={<Currency />}></Route>
        <Route path='/bank' element={<Bank />}></Route>
        <Route path='/bank-update/:bankid' element={<BankUpdate />}></Route>
        <Route path='/account_list' element={<AccountList />}></Route>
        <Route path='/profit_loss' element={<ProfitLoss />}></Route>
        <Route path='/balance_sheet' element={<BalanceSheet />}></Route>
        <Route path='/trail_bal' element={<TrialBalance />}></Route>
        <Route path='/medicineSale' element={<MedicineSale />}></Route>
        <Route path='/treatment' element={<Treatment />}></Route>
        <Route path='/acc_type' element={<AccountType />}></Route>
        <Route path='/head' element={<Heading />}></Route>
        <Route path='/subHead' element={<SubHeader />}></Route>
        <Route path='/category' element={<Category />}></Route>
        <Route path='/sub_category' element={<SubCategory />}></Route>
        <Route path='/brand' element={<Brand />}></Route>
        <Route path='/add_asset' element={<AddAsset />}></Route>
        <Route path='/medicine_list' element={<MedicineList />}></Route>
        <Route path='/medUp/:id' element={<MedicineUpdate />}></Route>
        <Route path='/unit/:id' element={<Unit />}></Route>
        <Route path='/pro_medicine' element={<ProcedureMedicine />}></Route>
        <Route path='/prounit/:id' element={<ProUnit />}></Route>
        <Route path='/stock' element={<StockCount />}></Route>
        <Route path='/stockUnit/:id' element={<StockUnit />}></Route>
        <Route path='/stockMed/:id' element={<StockMedUnit />}></Route>
        <Route path='/stockProAss/:id' element={<StockProAss />}></Route>
        <Route path='/purchase' element={<Purchase />}></Route>
        <Route path='/purchaseDetail/:id' element={<PurchaseDetail />}></Route>
        <Route path='/add_purchase' element={<PurchaseAdd />}></Route>
        <Route path='/treatC' element={<TreatmentCreate />}></Route>
        <Route path='/checkUnit/:id' element={<CheckUnit />}></Route>
        <Route path='/supplier' element={<Supplier />}></Route>
        <Route path='/sup-update/:id' element={<SupplierUpdate />}></Route>
        <Route path='/creditDetail/:id' element={<CreditDetail />}></Route>
        <Route path='/treatSale' element={<TreatmentSale />}></Route>
        <Route path='/accUpdate/:id' element={<AccListUpdate />}></Route>
        <Route path='/proUpdate/:id' element={<ProUpdate />}></Route>
        <Route path='/treatUpdate/:id' element={<TreatUpdate />}></Route>
        <Route path='/TCL/:id' element={<TCheckList />}></Route>
        <Route path='/unitUpdate/:id1/:id2' element={<Update2 />}></Route>
        <Route path='/pro-access' element={<ProcedureAccessory />}></Route>
        <Route path='/proAcessUpdate/:id' element={<ProAccessUpdate />}></Route>
        <Route path='/proAccessUnit/:id' element={<ProAccessUnit />}></Route>
        <Route path='/doctor' element={<Doctor />}></Route>
        <Route path='/doctor-reg' element={<DoctorReg />}></Route>
        <Route path='/doctor-update/:id' element={<DoctorUpdate />}></Route>
        <Route path='/all-tran' element={<AllTran />}></Route>
        <Route path='/journal-list' element={<JournalEntry />}></Route>
        <Route path='/journal-update/:id' element={<JournalUpdate />}></Route>
        <Route path='/trial-bal' element={<TrialBalance />}></Route>;
        <Route path='/machine' element={<Machine />}></Route>
        <Route path='/discount' element={<Discount />}></Route>
        <Route path='/dis-update/:id' element={<DiscountUpdate />}></Route>
        <Route path='/member' element={<Member />}></Route>
        <Route path='/package' element={<PackageList />}></Route>;
        <Route path='/packageReg' element={<PackageReg />}></Route>
        <Route
          path='/package-update/:packageid'
          element={<PackageUpdate />}
        ></Route>
        <Route path='/expense-update/:id' element={<ExpenseUpdate />}></Route>
        <Route path='/income-update/:id' element={<IncomeUpdate />}></Route>
        <Route path='/refDoctor/:id' element={<Comission />}></Route>
        <Route path='/refthe/:id' element={<TheComission />}></Route>
        <Route path='/refnur/:id' element={<NurComission />}></Route>
        <Route path='/user' element={<UserList />}></Route>;
        <Route path='/user-detail/:id' element={<UserDetail />}></Route>
        <Route path='/reorder' element={<ReOrder />}></Route>
        <Route path='/bc-report' element={<BCReport />}></Route>
        <Route path='/top10' element={<Top10 />}></Route>
        <Route path='/customer' element={<Customer />}></Route>
        <Route path='/bar/:id' element={<Bar />}></Route>
        <Route path='/damage/:id' element={<Damage />}></Route>
         <Route path='/damage-list' element={<DamageList />}></Route>
      </Routes>
    </Router>
  )
}

export default App

import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import SideBar from '../../SideBar'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Button } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import Swal from 'sweetalert2'
import { Badge } from '@nextui-org/react'
import GoPurchase from '../PurchaseFormReOrder/AddPurchase'

import HeaderName from '../../HeaderName'
import apiInstance from '../../../../utils/api'
import { Link } from 'react-router-dom'
import {
  FaCashRegister,
  FaFileMedical,
  FaPenSquare,
  FaRegEdit,
  FaRegTrashAlt,
  FaFileExport,
  FaAngleDoubleRight
} from 'react-icons/fa'

import { useSelector } from 'react-redux'
function Stock() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [searchProResults, setSearchProResults] = useState([])
  const [showProSearch, setShowProSearch] = useState(false)
  const [hidePro, setHidePro] = useState(true)
  const [proName, setProName] = useState('')
  const [open, setOpen] = useState(false)

  const [searchMedResults, setSearchMedResults] = useState([])
  const [showMedSearch, setShowMedSearch] = useState(false)
  const [hideMed, setHideMed] = useState(true)
  const [medName, setMedName] = useState('')

  const [searchProAccResults, setSearchProAccResults] = useState([])
  const [showProAccSearch, setShowProAccSearch] = useState(false)
  const [hideProAcc, setHideProAcc] = useState(true)
  const [proAccName, setProAccName] = useState('')

  const [relatedProBranch, setRelatedProBranch] = useState([])
  const [showBranch, setShowBranch] = useState(false)
  const [showProBranch, setShowProBranch] = useState(false)
  const [showProAccBranch, setShowProAccBranch] = useState(false)
  const [showOrigin, setShowOrigin] = useState(true)

  const [proUnitLists, setProUnitLists] = useState([])
  const [medUnitLists, setMedUnitLists] = useState([])
  const [porAssLists, setProAssLists] = useState([])
  // const [showMedicine, setShowMedicine] = useState(true)
  const [showProUnit, setShowProUnit] = useState(false)
  const [showProAss, setShowProAss] = useState(false)

  const [branchesLists, setBranchesLists] = useState([])

  const [mQty, setMQty] = useState('')

  const [mTotal, setMTotal] = useState('')

  const [pmQty, setPMQty] = useState('')

  const [pmTotal, setPMTotal] = useState('')
  const [pmSell, setPMSell] = useState(0)
  const [pmPurchase, setPMPurchase] = useState(0)

  const [paQty, setPAQty] = useState('')

  const [paTotal, setPATotal] = useState('')
  const [paSell, setPASell] = useState(0)
  const [paPurchase, setPAPurchase] = useState(0)

  const [proId, setProId] = useState('')
  //Med
  const [medHoShow, setMedHoShow] = useState(true)
  // const [proName, setProName] = useState('')

  const ButtonStyle = { margin: '10px 10px' }
  const ErrorColor = { border: 'red' }
  const [branchID, setBranchID] = useState('')
  const [accBranchCount, setAccBranchCount] = useState('')
  const [medBranchCount, setMedBranchCount] = useState('')
  const [proBranchCount, setProBranchCount] = useState('')

  const [accBranchItemList, setAccBranchItemList] = useState([])
  const [medBranchItemList, setMedBranchItemList] = useState([])
  const [proBranchItemList, setProBranchItemList] = useState([])

  //For Ho
  const [accHOCount, setAccHOCount] = useState('')
  const [medHOCount, setMedHOCount] = useState('')
  const [proHOCount, setProHOCount] = useState('')

  const [accHOItemList, setAccHOItemList] = useState([])
  const [medHOItemList, setMedHOItemList] = useState([])
  const [proHOItemList, setProHOItemList] = useState([])

  const [showMed, setShowMed] = useState(false)
  const [showPro, setShowPro] = useState(false)
  const [showProAcc, setShowProAcc] = useState(false)

  //for CheckBox Med

  const [totalPrice, setTotalPrice] = useState('')
  const [medHOCheckList, setMedHOCheckList] = useState([])
  const [medID, setMedID] = useState([])
  const [medHOArr, setMedHOArr] = useState([])
  const [checked, setChecked] = useState(true)

  //for Checkbox Pro
  const [proHOCheckList, setProHOCheckList] = useState([])
  const [proID, setProID] = useState([])
  const [proHOArr, setProHOArr] = useState([])
  // const [checked, setChecked] = useState(true)

  //for Checkbox Pro-Acc
  const [proAccHOCheckList, setProAccHOCheckList] = useState([])
  const [proAccID, setProAccID] = useState([])
  const [proAccHOArr, setProAccHOArr] = useState([])
  // const [checked, setChecked] = useState(true)

  const handleMedCheck = (event, medUnit, type) => {
    console.log(medUnit._id, 'id')

    setMedID(medUnit._id)

    setMedHOCheckList([
      ...medHOCheckList,
      medHOItemList.filter(data => data._id === medUnit._id)[0]
    ])
  }
  const showMedPur = arr => {
    setOpen(true)
    setMedHOArr(arr)
  }

  const handleProCheck = (event, proUnit, type) => {
    console.log(proUnit._id, 'id')

    setProHOCheckList([
      ...proHOCheckList,
      proHOItemList.filter(data => data._id === proUnit._id)[0]
    ])
  }
  const showProPur = arr => {
    setOpen(true)
    setProHOArr(arr)
  }

  const handleProAccCheck = (event, proAccUnit, type) => {
    console.log(proAccUnit._id, 'id')

    setProAccHOCheckList([
      ...proAccHOCheckList,
      accHOItemList.filter(data => data._id === proAccUnit._id)[0]
    ])
    console.log(
      [
        ...proAccHOCheckList,
        accHOItemList.filter(data => data._id === proAccUnit._id)[0]
      ],
      'id list'
    )
  }
  const showProAccPur = arr => {
    setOpen(true)
    setProAccHOArr(arr)
  }

  const handleUpdateUnitCalculation = (event, proUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'currentQuantity') {
      let uQty = parseInt(event)
      let total = uQty * proUnit.toUnit
      console.log(uQty)
      console.log(total)
      setPMTotal(total)
      setPMQty(event)
      const newData = proUnitLists.map(data => {
        if (data._id === proUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setProUnitLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * proUnit.currentQuantity
      setPMTotal(total)
      console.log(total)
      const newData = proUnitLists.map(data => {
        if (data._id === proUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setProUnitLists(newData)
    }
  }

  const handleMUpdateUnitCalculation = (event, medUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'currentQuantity') {
      let uQty = parseInt(event)
      let total = uQty * medUnit.toUnit
      console.log(uQty)
      console.log(total)
      setMTotal(total)
      setMQty(event)
      const newData = medUnitLists.map(data => {
        if (data._id === medUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setMedUnitLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * medUnit.currentQuantity
      setMTotal(total)
      console.log(total)
      const newData = medUnitLists.map(data => {
        if (data._id === medUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setMedUnitLists(newData)
    }
  }

  const handleMedUnitChange = Id => {
    // setShowMedicine(false)
    setShowMed(true)
    setShowPro(false)
    setShowProAcc(false)
    setShowOrigin(true)
  }

  const handleProUnitChange = Id => {
    setShowMed(false)
    setShowPro(true)
    setShowProAcc(false)
    setShowOrigin(true)
  }

  const handleProAssUnitChange = Id => {
    setShowMed(false)
    setShowPro(false)
    setShowProAcc(true)
    setShowOrigin(true)
  }

  const _export1 = React.useRef(null)
  const _export2 = React.useRef(null)
  const _export3 = React.useRef(null)

  const excelExport1 = () => {
    if (_export1.current !== null) {
      console.log(_export1.current.props.data, 'excel')

      _export1.current.props.data.map(function (element, index) {
        element.code = element.code
      })

      _export1.current.save()
    }
  }

  const excelExport2 = () => {
    if (_export2.current !== null) {
      console.log(_export2.current.props.data, 'excel')
      if (_export2.current.props.data === { medUnitLists }) {
        _export2.current.props.data.map(function (element, index) {
          element.code = element.code
        })
      }
      _export2.current.save()
    }
  }

  const excelExport3 = () => {
    if (_export3.current !== null) {
      console.log(_export3.current.props.data, 'excel')
      _export3.current.props.data.map(function (element, index) {
        element.code = element.code
      })
      _export3.current.save()
    }
  }

  useEffect(() => {
    // const getProUnitLists = async () => {
    //   try {
    //     const res = await apiInstance.get('procedure-items?limit=30')
    //     setProUnitLists(res.data.list)
    //     console.log(res.data.list)
    //     console.log(setProUnitLists.reOrderQuantity)
    //     setProId(res.data.list._id)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }

    // const getMedUnitLists = async () => {
    //   try {
    //     const res = await apiInstance.get('medicine-items?limit=30')
    //     setMedUnitLists(res.data.list)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }

    // const getBranches = async () => {
    //   try {
    //     const res = await apiInstance.get('branches')
    //     setBranchesLists(res.data.list)
    //     console.log(res.data.list, 'branch')
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }

    // const getProAssLists = async () => {
    //   try {
    //     const res = await apiInstance.get('accessory-items?limit=30')
    //     setProAssLists(res.data.list)
    //     console.log(setProAssLists._id)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }

    // getProAssLists()

    //Reorder list

    const getProUnitLists = async () => {
      try {
        const res = await apiInstance.get('procedure-items?limit=30')
        setProHOItemList(
          res.data.list.filter(el => el.reOrderQuantity >= el.currentQuantity)
        )
        setProHOCount(res.data.list.length)
        console.log(
          res.data.list.filter(el => el.reOrderQuantity >= el.currentQuantity),
          'pro list'
        )
        // console.log(setProUnitLists.reOrderQuantity)
        setProId(res.data.list._id)
      } catch (err) {
        alert(err.message)
      }
    }

    const getMedUnitLists = async () => {
      try {
        const res = await apiInstance.get('medicine-items?limit=30')
        setMedHOItemList(
          res.data.list.filter(el => el.reOrderQuantity >= el.currentQuantity)
        )
        console.log(
          res.data.list.filter(el => el.reOrderQuantity >= el.currentQuantity),
          'ori med list'
        )
        setMedHOCount(res.data.list.length)
      } catch (err) {
        alert(err.message)
      }
    }

    const getProAssLists = async () => {
      try {
        const res = await apiInstance.get('accessory-items?limit=30')
        setAccHOItemList(
          res.data.list.filter(el => el.reOrderQuantity >= el.currentQuantity)
        )
        console.log(res.data.list, 'pro ass list')
        setAccHOCount(res.data.list.length)
      } catch (err) {
        alert(err.message)
      }
    }

    setShowMed(true)
    setShowPro(false)
    setShowProAcc(false)
    setShowOrigin(true)
    getProAssLists()

    getMedUnitLists()
    getProUnitLists()

    // getBranches()

    // getMedUnitLists()
    // getProUnitLists()
  }, [])
  const search = val => {
    console.log(val, 'val')
    if (val) {
      setProHOItemList(
        proHOItemList.filter(el =>
          el.procedureItemName.toLowerCase().includes(val.toLowerCase())
        )
      )
      setShowProSearch(true)
      setHidePro(false)
    } else {
      setShowProSearch(false)
      setHidePro(true)
    }
  }
  const medSearch = val => {
    if (val) {
      setMedHOItemList(
        medHOItemList.filter(el =>
          el.medicineItemName.toLowerCase().includes(val.toLowerCase())
        )
      )

      setShowMed(false)

      setShowMedSearch(true)
    } else {
      setShowMedSearch(false)
      setShowMed(true)
    }
  }

  const proAccSearch = val => {
    if (val) {
      setAccHOItemList(
        accHOItemList.filter(el =>
          el.accessoryItemName.toLowerCase().includes(val.toLowerCase())
        )
      )
      setShowProAccSearch(true)
      setHideProAcc(false)
    } else {
      setShowProAccSearch(false)
      setHideProAcc(true)
    }
  }

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />
        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active'>Reorder List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-1 col-md-4 ml-5'>
                  <div
                    className='input-group row ml-5'
                    style={{ marginBottom: '20px' }}
                  >
                    {/* <div className="col-md-6 mt-1">
                      <button
                        type="button"
                        className="btn  btn-primary ml-4"
                        style={{ borderRadius: "7px" }}
                        onClick={showDialog}>
                        Add Item
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={() => handleMedUnitChange()}
                              >
                                Medicine Items &nbsp;{' '}
                                {branchID && (
                                  <Badge color='error'>{medBranchCount}</Badge>
                                )}
                              </Button>
                            </div>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={handleProUnitChange}
                              >
                                Procedure Items &nbsp;{' '}
                                {branchID && (
                                  <Badge color='error'>{proBranchCount}</Badge>
                                )}
                              </Button>
                            </div>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={() => handleProAssUnitChange()}
                              >
                                Procedure Accessories &nbsp;{' '}
                                {branchID && (
                                  <Badge color='error'>{accBranchCount}</Badge>
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                        {/* <div className='offset-3 col-md-3 mt-1'>
                          <div className='form-group'>
                            <select
                              name='currency'
                              id=''
                              className='form-control mt-1'
                              onchange='convert(this.value)'
                              // placeholder='Filter By Branches'
                              onChange={e => handleProBranch(e.target.value)}
                            >
                              <option hidden>Filter By Branches</option>
                              <option value='HO'>HO</option>

                              {branchesLists.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {/* For Procedure Medicine Items */}
                      <ExcelExport data={proBranchItemList} ref={_export1}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems.code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems.procedureItemName'
                          title='Procedure Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems.currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='relatedProcedureItems.totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedProcedureItems.reOrderQuantity'
                          title='Reorder Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems.unit'
                          title='Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End        */}

                      {/* For Medicine Items */}
                      <ExcelExport data={medBranchItemList} ref={_export2}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='relatedMedicineItems.code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='relatedMedicineItems.medicineItemName'
                          title='Medicine Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedMedicineItems.currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='relatedMedicineItems.totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedMedicineItems.reOrderQuantity'
                          title='Reorder Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedMedicineItems.unit'
                          title='Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End     */}

                      {/* For Procedure Accessory FilterItems */}
                      <ExcelExport data={accBranchItemList} ref={_export3}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='relatedAccessoryItems.code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='relatedAccessoryItems.accessoryItemName'
                          title='Procedure Accessory Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedAccessoryItems.currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='relatedAccessoryItems.totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedAccessoryItems.reOrderQuantity'
                          title='Reorder Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedAccessoryItems.unit'
                          title='Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>

                      {/* End */}

                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='table-responsive'>
                            <table className='table table-hover'>
                              {showBranch && (
                                <div>
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-3'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-dark'
                                        onClick={excelExport2}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>
                                      &nbsp;
                                    </div>
                                    <div className='col-md-3 mr-2'>
                                      <div className='row'>
                                        <div className='col-md-8'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              setMedName(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>
                                        <div className='col-md-4 mt-2'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-dark'
                                            onClick={medSearch}
                                          >
                                            Search
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='table-responsive'>
                                    <table className='table table-hover'>
                                      <thead>
                                        <tr>
                                          <th style={{ fontWeight: '400px' }}>
                                            #
                                          </th>
                                          <th style={{ fontWeight: '400px' }}>
                                            Code
                                          </th>
                                          <th style={{ fontWeight: '400px' }}>
                                            Name
                                          </th>
                                          <th style={{ fontWeight: '400px' }}>
                                            Qty
                                          </th>

                                          <th style={{ fontWeight: '400px' }}>
                                            Total Unit
                                          </th>

                                          <th style={{ fontWeight: '400px' }}>
                                            Reorder Qty
                                          </th>
                                          <th style={{ fontWeight: '400px' }}>
                                            Unit
                                          </th>
                                          <th>Select</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {console.log(
                                          relatedProBranch,
                                          'relatedProBranch'
                                        )}
                                        {medBranchItemList.map((proUnit, i) => (
                                          <tr>
                                            <td>{++i}</td>
                                            <td>
                                              {proUnit.relatedProcedureItems
                                                ? proUnit.relatedProcedureItems
                                                  .code
                                                : proUnit.relatedMedicineItems
                                                  ? proUnit.relatedMedicineItems
                                                    .code
                                                  : proUnit.relatedAccessoryItems
                                                    ? proUnit.relatedAccessoryItems
                                                      .code
                                                    : ''}
                                            </td>
                                            <td>
                                              {proUnit.relatedProcedureItems
                                                ? proUnit.relatedProcedureItems
                                                  .procedureItemName
                                                : proUnit.relatedMedicineItems
                                                  ? proUnit.relatedMedicineItems
                                                    .medicineItemName
                                                  : proUnit.relatedAccessoryItems
                                                    ? proUnit.relatedAccessoryItems
                                                      .accessoryItemName
                                                    : ''}
                                            </td>
                                            <td>{proUnit.currentQty}</td>

                                            <td>
                                              {proUnit.relatedProcedureItems
                                                ? proUnit.relatedProcedureItems
                                                  .totalUnit
                                                : ''}
                                            </td>

                                            <td>{proUnit.reOrderQuantity}</td>
                                            <td>PCS</td>
                                            <td>
                                              <input type='checkbox' />
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}

                              {showOrigin && (
                                <>
                                  {showMed && (
                                    <div>
                                      <div className='row justify-content-between mb-3'>
                                        <div className='col-md-6'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-dark'
                                            onClick={excelExport2}
                                          >
                                            <FaFileExport />
                                            &nbsp; Export
                                          </button>
                                          &nbsp;
                                          <button
                                            onClick={() =>
                                              showMedPur(medHOCheckList)
                                            }
                                            className='btn btn-sm btn-dark'
                                          >
                                            {/* {console.log(medHOCheckList, 'arr')} */}
                                            &nbsp; Purchase
                                          </button>
                                          &nbsp;
                                        </div>
                                        <div className='col-md-3 mr-2'>
                                          <div className='row'>
                                            <div className='offset-3 col-md-8'>
                                              <input
                                                type='search'
                                                className='form-control rounded '
                                                style={{ borderRadius: '17px' }}
                                                onChange={e =>
                                                  medSearch(e.target.value)
                                                }
                                                id='search_code'
                                                placeholder='Search Items'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='table-responsive'>
                                        <table className='table table-hover'>
                                          <thead>
                                            <tr>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                #
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Code
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Name
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Qty
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Total Unit
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Reorder Qty
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Unit
                                              </th>
                                              <th>Desc</th>
                                              <th>Select</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {console.log(
                                              relatedProBranch,
                                              'relatedProBranch'
                                            )}
                                            {medHOItemList.map((medUnit, i) => (
                                              <tr>
                                                <td>{++i}</td>
                                                <td>{medUnit.code}</td>
                                                <td>
                                                  {medUnit.medicineItemName
                                                    ? medUnit.medicineItemName
                                                    : ''}
                                                </td>
                                                <td>
                                                  {medUnit.currentQuantity}
                                                </td>

                                                <td>{medUnit.totalUnit}</td>

                                                <td>
                                                  {medUnit.reOrderQuantity}
                                                </td>
                                                <td>PCS</td>
                                                <td>{medUnit.description}</td>
                                                <td>
                                                  <input
                                                    type='checkbox'
                                                    onClick={e =>
                                                      handleMedCheck(
                                                        e.target.value,
                                                        medUnit,
                                                        'checkBox'
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  )}
                                  {showMedSearch && (
                                    <div>
                                      <div className='row justify-content-between mb-3'>
                                        <div className='col-md-6'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-dark'
                                            onClick={excelExport2}
                                          >
                                            <FaFileExport />
                                            &nbsp; Export
                                          </button>
                                          &nbsp;
                                          <button
                                            onClick={() =>
                                              showMedPur(medHOCheckList)
                                            }
                                            className='btn btn-sm btn-dark'
                                          >
                                            {/* {console.log(medHOCheckList, 'arr')} */}
                                            &nbsp; Purchase
                                          </button>
                                          &nbsp;
                                        </div>
                                        <div className='col-md-3 mr-2'>
                                          <div className='row'>
                                            <div className='col-md-8'>
                                              <input
                                                type='search'
                                                className='form-control rounded '
                                                style={{ borderRadius: '17px' }}
                                                onChange={e =>
                                                  medSearch(e.target.value)
                                                }
                                                id='search_code'
                                                placeholder='Search Items'
                                              />
                                            </div>
                                            {/* <div className='col-md-2 mt-2'>
                                              <button
                                                type='button'
                                                className='btn btn-sm btn-dark'
                                                // onClick={medSearch}
                                              >
                                                Search
                                              </button>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='table-responsive'>
                                        <table className='table table-hover'>
                                          <thead>
                                            <tr>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                #
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Code
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Name
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Qty
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Total Unit
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Reorder Qty
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Unit
                                              </th>
                                              <th>Desc</th>
                                              <th>Select</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {console.log(
                                              relatedProBranch,
                                              'relatedProBranch'
                                            )}
                                            {medHOItemList.map((medUnit, i) => (
                                              <tr>
                                                <td>{++i}</td>
                                                <td>{medUnit.code}</td>
                                                <td>
                                                  {medUnit.medicineItemName
                                                    ? medUnit.medicineItemName
                                                    : ''}
                                                </td>
                                                <td>
                                                  {medUnit.currentQuantity}
                                                </td>

                                                <td>{medUnit.totalUnit}</td>

                                                <td>
                                                  {medUnit.reOrderQuantity}
                                                </td>
                                                <td>PCS</td>
                                                <td>{medUnit.description}</td>
                                                <td>
                                                  <input
                                                    type='checkbox'
                                                    key={medUnit}
                                                    // value={isChecked}
                                                    //   checked={medUnit.find(
                                                    //     item =>
                                                    //       item._id === medUnit._id
                                                    //   )}
                                                    // />
                                                    onClick={e =>
                                                      handleMedCheck(
                                                        e,
                                                        medUnit,
                                                        'checkBox'
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  )}

                                  {showPro && (
                                    <div>
                                      <div className='row justify-content-between mb-3'>
                                        <div className='col-md-3'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-dark'
                                            onClick={excelExport2}
                                          >
                                            <FaFileExport />
                                            &nbsp; Export
                                          </button>{' '}
                                          &nbsp;
                                          <button
                                            onClick={() =>
                                              showProPur(proHOCheckList)
                                            }
                                            className='btn btn-sm btn-dark'
                                          >
                                            {/* {console.log(medHOCheckList, 'arr')} */}
                                            &nbsp; Purchase
                                          </button>
                                          &nbsp;
                                        </div>
                                        <div className='col-md-3 mr-2'>
                                          <div className='row'>
                                            <div className='col-md-8'>
                                              <input
                                                type='search'
                                                className='form-control rounded '
                                                style={{ borderRadius: '17px' }}
                                                onChange={e =>
                                                  search(e.target.value)
                                                }
                                                id='search_code'
                                                placeholder='Search Items'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='table-responsive'>
                                        <table className='table table-hover'>
                                          <thead>
                                            <tr>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                #
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Code
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Name
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Qty
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Total Unit
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Reorder Qty
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Unit
                                              </th>
                                              <th>TotalUnit</th>
                                              <th>Select</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {console.log(
                                              relatedProBranch,
                                              'relatedProBranch'
                                            )}
                                            {proHOItemList.map((proUnit, i) => (
                                              <tr>
                                                <td>{++i}</td>
                                                <td>{proUnit.code}</td>
                                                <td>
                                                  {proUnit.procedureItemName
                                                    ? proUnit.procedureItemName
                                                    : ''}
                                                </td>
                                                <td>
                                                  {proUnit.currentQuantity}
                                                </td>

                                                <td>{proUnit.totalUnit}</td>

                                                <td>
                                                  {proUnit.reOrderQuantity}
                                                </td>
                                                <td>PCS</td>
                                                <td>{proUnit.totalUnit}</td>
                                                <td>
                                                  <input
                                                    type='checkbox'
                                                    onClick={e =>
                                                      handleProCheck(
                                                        e.target.value,
                                                        proUnit,
                                                        'checkBox'
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  )}
                                  {showProAcc && (
                                    <div>
                                      <div className='row justify-content-between mb-3'>
                                        <div className='col-md-3'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-dark'
                                            onClick={excelExport2}
                                          >
                                            <FaFileExport />
                                            &nbsp; Export
                                          </button>
                                          &nbsp;
                                          <button
                                            onClick={() =>
                                              showProAccPur(proAccHOCheckList)
                                            }
                                            className='btn btn-sm btn-dark'
                                          >
                                            {/* {console.log(medHOCheckList, 'arr')} */}
                                            &nbsp; Purchase
                                          </button>
                                          &nbsp;
                                        </div>
                                        <div className='col-md-3 mr-2'>
                                          <div className='row'>
                                            <div className='offset-3 col-md-8'>
                                              <input
                                                type='search'
                                                className='form-control rounded '
                                                style={{ borderRadius: '17px' }}
                                                onChange={e =>
                                                  proAccSearch(e.target.value)
                                                }
                                                id='search_code'
                                                placeholder='Search Items'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='table-responsive'>
                                        <table className='table table-hover'>
                                          <thead>
                                            <tr>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                #
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Code
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Name
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Qty
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Total Unit
                                              </th>

                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Reorder Qty
                                              </th>
                                              <th
                                                style={{ fontWeight: '400px' }}
                                              >
                                                Unit
                                              </th>
                                              <th>Select</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {console.log(
                                              relatedProBranch,
                                              'relatedProBranch'
                                            )}
                                            {accHOItemList.map((accUnit, i) => (
                                              <tr>
                                                <td>{++i}</td>
                                                <td>{accUnit.code}</td>
                                                <td>
                                                  {accUnit.accessoryItemName
                                                    ? accUnit.accessoryItemName
                                                    : ''}
                                                </td>
                                                <td>
                                                  {accUnit.currentQuantity}
                                                </td>

                                                <td>{accUnit.totalUnit}</td>

                                                <td>
                                                  {accUnit.reOrderQuantity}
                                                </td>
                                                <td>PCS</td>
                                                <td>{accUnit.totalUnit}</td>
                                                <td>
                                                  <input
                                                    type='checkbox'
                                                    onClick={e =>
                                                      handleProAccCheck(
                                                        e.target.value,
                                                        accUnit,
                                                        'checkBox'
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <StockCountModal
                open={open}
                close={() => setOpen(false)}
                branchesLists={branchesLists}
                setOpen={setOpen}
              /> */}

              <GoPurchase
                open={open}
                close={() => setOpen(false)}
                medHOArr={medHOArr}
                setMedHOArr={setMedHOArr}
                proHOArr={proHOArr}
                setProHOArr={setProHOArr}
                proAccHOArr={proAccHOArr}
                setProAccHOArr={setProAccHOArr}
              />
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '1rem' }}>
        <strong>
          Copyright &copy; 2017-2020
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Stock

import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    login: false,
    user: '',
    LoginURL: 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  },
  reducers: {
    loginSuccess: state => {
      state.login = true
    },
    addUser: (state, action) => {
      console.log(action.payload)
      state.user = action.payload
    },
    logoutSuccess: state => {
      state.login = false
      state.user = ''
    }
  }
})

export const { loginSuccess, addUser, logoutSuccess } = authSlice.actions
export default authSlice.reducer

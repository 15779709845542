import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import SideBar from './SideBar'
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaArrowCircleLeft,
  FaArrowLeft
} from 'react-icons/fa'
import HorizontalScroll from 'react-horizontal-scrolling'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'

function Unit () {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [proMedicineLists, setProMedicineLists] = useState([])
  const [prounitLists, setProUnitLists] = useState([])
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [currentQuantity, setCurrentQuantity] = useState('')
  const [reorder, setReorder] = useState('')
  const [fromUnit, setFromUnit] = useState('')
  const [toUnit, setToUnit] = useState('')
  const [purchasePrice, setPurchasePrice] = useState('')
  const [sellingPrice, setSellingPrice] = useState('')
  const [totalUnit, setTotalUnit] = useState('')
  const [description, setDescription] = useState('')
  const [perUnit, setPerUnit] = useState('')
  const ProUnitId = useLocation().pathname.split('/')[2]

  const handleUnitCalculation = event => {
    console.log(currentQuantity)
    console.log(event)

    if (currentQuantity) {
      let multi = currentQuantity * event
      let ans = multi / fromUnit

      console.log(ans)
      setTotalUnit(ans.toFixed(2))
    }

    setToUnit(event)
  }

  const AccountTypeCreate = () => {
    const data = {
      name: ProUnitId,
      code: code,
      perUnitQuantity: perUnit,
      procedureItemName: name,
      currentQuantity: currentQuantity,
      reOrderQuantity: reorder,
      purchasePrice: purchasePrice,
      sellingPrice: sellingPrice,
      fromUnit: fromUnit,
      toUnit: toUnit,
      description: description
    }

    // console.log(medicineListId);
    // alert(JSON.stringify(data))
    console.log(data)
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    api
      .post('procedure-item', data, config)
      .then(function (response) {
        // alert("success");
        setProUnitLists([...prounitLists, response.data.data])
      })
      .catch(function (err) {
        alert(err.message)
      })
    document.getElementById('description').value = ''
    document.getElementById('name').value = ''
    document.getElementById('code').value = ''
    document.getElementById('cur_qty').value = ''
    document.getElementById('reorder').value = ''
    document.getElementById('purchase').value = ''
    document.getElementById('per').value = ''
    document.getElementById('from').value = ''
    document.getElementById('to').value = ''
    document.getElementById('sellprice').value = ''
    document.getElementById('total').value = ''

    //      props.setOpen(false);
  }
  useEffect(() => {
    const getUnitLists = async () => {
      try {
        const res = await api.get('procedure-items/' + ProUnitId)
        console.log(res.data.data)
        setProUnitLists(res.data.data)
      } catch (err) {}
    }

    const getProMedicineLists = async () => {
      try {
        const res = await api.get('procedure-item/' + ProUnitId)
        console.log(ProUnitId)
        console.log(res.data.data[0].name)
        if (res.data.data[0].name != null) {
          let proName = res.data.data[0].name.name
          console.log(proName)
          setProMedicineLists(proName)
        } else {
          let proName = 'No name'
          setProMedicineLists(proName)
        }
      } catch (err) {
        alert(err.message)
      }
    }

    getProMedicineLists()
    getUnitLists()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/stock'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li
                      className='breadcrumb-item active'
                      style={{ marginTop: '0.2em' }}
                    >
                      <i>Procedure Medicine Unit List</i>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-header'>
                      <h3 className='card-title'>{proMedicineLists}'s Unit</h3>
                    </div>
                    <div className='card-body bg-light'>
                      <HorizontalScroll>
                        <table id='' className='table'>
                          <thead className='text-center bg-info'>
                            <tr>
                              <th>No</th>
                              <th>Code</th>
                              <th>Name</th>
                              <th>Current Qty</th>
                              <th>Reorder Qty</th>
                              <th>Purchase Price</th>
                              <th>Selling Price</th>
                              <th>Unit Convention</th>

                              <th>Description</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {prounitLists.map((unit, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>
                                <td>{unit.code}</td>
                                <td>
                                  {unit.procedureItemName
                                    ? unit.procedureItemName
                                    : 'No name'}
                                </td>
                                <td>
                                  {unit.currentQuantity
                                    ? unit.currentQuantity
                                    : '0'}
                                </td>
                                <td>{unit.reOrderQuantity}</td>
                                <td>{unit.purchasePrice}</td>
                                <td>{unit.sellingPrice}</td>

                                <td>{unit.toUnit ? unit.toUnit : '0'}</td>
                                <td>{unit.description}</td>
                                <td className='text-center'>
                                  <a href='' className='btn btn-warning'>
                                    <FaRegEdit />
                                  </a>
                                  &nbsp;
                                  <a href='' className='btn btn-danger'>
                                    <FaRegTrashAlt />
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </HorizontalScroll>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='card px-3 py-3'>
                    <h3 className='card-header mb-4 fw-5 text-secondary'>
                      Create Item
                    </h3>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Code
                      </label>
                      <input
                        type='text'
                        class='form-control border-info'
                        name='codename'
                        id='code'
                        //   ref={(el) => (this.name = el)}
                        onChange={e => setCode(e.target.value)}
                      />
                    </div>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Name
                      </label>
                      <input
                        type='text'
                        class='form-control border-info'
                        name='balance'
                        id='name'
                        //   ref={(el) => (this.name = el)}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Current Quantity
                      </label>
                      <input
                        type='number'
                        class='form-control border-info'
                        name='balance'
                        id='cur_qty'
                        //   ref={(el) => (this.name = el)}
                        onChange={e => setCurrentQuantity(e.target.value)}
                      />
                    </div>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Reorder Quantity (Optional)
                      </label>
                      <input
                        type='number'
                        class='form-control border-info'
                        name='balance'
                        id='reorder'
                        //   ref={(el) => (this.name = el)}
                        onChange={e => setReorder(e.target.value)}
                      />
                    </div>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Unit Convention (Optional)
                      </label>
                      <div className='row'>
                        <div className='col-md-6'>
                          <input
                            type='number'
                            class='form-control border-info'
                            name='balance'
                            id='from'
                            placeholder='Form Unit'
                            onChange={e => setFromUnit(e.target.value)}
                          />
                        </div>
                        <div className='col-md-6'>
                          <input
                            type='number'
                            class='form-control border-info'
                            name='balance'
                            id='to'
                            placeholder='To Unit'
                            onChange={e =>
                              handleUnitCalculation(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Per Unit Quantity (Optional)
                      </label>
                      <input
                        type='number'
                        class='form-control border-info'
                        name='balance'
                        id='per'
                        //   ref={(el) => (this.name = el)}
                        onChange={e => setPerUnit(e.target.value)}
                      />
                    </div>

                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Total Unit
                      </label>
                      <input
                        type='number'
                        class='form-control border-info'
                        name='balance'
                        id='total'
                        //   ref={(el) => (this.name = el)}
                        defaultValue={totalUnit}
                      />
                    </div>

                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Purchase Price
                      </label>
                      <input
                        type='number'
                        class='form-control border-info'
                        name='balance'
                        id='purchase'
                        //   ref={(el) => (this.name = el)}
                        onChange={e => setPurchasePrice(e.target.value)}
                      />
                    </div>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Selling Price
                      </label>
                      <div className='row'>
                        <div className='col-md-12'>
                          <input
                            type='number'
                            class='form-control border-info'
                            name='balance'
                            id='sellprice'
                            onChange={e => setSellingPrice(e.target.value)}
                          />
                        </div>
                        {/* <div className="col-md-4">
                          <input
                            type="number"
                            class="form-control border-info"
                            name="balance"
                            id="name"
                            placeholder="%"
                          />
                        </div> */}
                      </div>
                    </div>
                    <div class='form-group'>
                      <label for='name' className='text-secondary'>
                        Description
                      </label>
                      <textarea
                        className='form-control'
                        id='description'
                        //   ref={(el) => (this.description = el)}
                        onChange={e => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <button
                      className='btn btn-primary form-control text-center fw-5'
                      onClick={AccountTypeCreate}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Unit

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { FaCashRegister, FaFileMedical, FaArrowLeft } from 'react-icons/fa'
import Button from '@mui/material/Button'
import { useLocation } from 'react-router-dom'
import AddCreditDialog from '../views/AddCreditDialog'
import SideBar from './SideBar'
import { Link } from 'react-router-dom'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`

const Left = styled.div`
  font-weight: normal;
  flex: 1;
`

const Title = styled.h5`
  font-weight: bold;
  margin-top: 10px;
`

const Right = styled.div`
  font-weight: normal;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const Btn = styled.button`
  padding: 4px 8px;
  border-radius: 5px;
  margin-left: 13px;
`

const Div = styled.div``
const Input = styled.input`
  width: 165px;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 3px;
`
const Table = styled.table``
const Thead = styled.thead``
const Tbody = styled.tbody``
const Tr = styled.tr`
  text-align: center;
`
const Th = styled.th`
  font-size: 15px;
`
const Td = styled.td`
  font-size: 14px;
`
const Select = styled.select`
  padding: 0px 7px;
  border-radius: 5px;
`
const Option = styled.option``
const Badge = styled.span`
  background: rgb(0, 7, 51);
  padding: 0px 4px;
  color: white;
  border: none;
  border-radius: 4px;
`

const List = () => {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [suppliers, setSuppliers] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [supid, setSupId] = useState('')
  const [credit, setCredit] = useState('')

  const id = useLocation().pathname.split('/')[2]

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const res = await api.get('suppliers')
        setSuppliers(res.data.list.filter(el => el._id == id))
        console.log(
          res.data.list.filter(el => el._id == id),
          'list'
        )
      } catch (err) {}
    }
    getSuppliers()
  }, [])
  const getsup = (id, credit) => {
    setIsShow(true)
    setSupId(id)
    setCredit(credit)
  }

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/supplier'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active'></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Currency List</h3> */}

                      <h5 className=''>Supplier Credit Detail</h5>
                    </div>
                    <div className='card-body'>
                      <table className='table table-hover mt-4'>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Supplier Name</th>
                            <th>Phone</th>
                            <th>Purchase Amount</th>
                            <th>Credit Amount</th>
                            <th>Status</th>
                            <th>Pay Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {suppliers.map((supplier, i) => (
                            <tr>
                              <td>{++i}</td>
                              <td>{supplier.name}</td>
                              <td>{supplier.phone}</td>
                              <td>{supplier.purchaseAmount}</td>
                              <td>{supplier.creditAmount}</td>
                              <td>
                                {supplier.creditAmount == 0 ? (
                                  <b className='badge badge-sm badge-success'>
                                    Paid
                                  </b>
                                ) : (
                                  <b
                                    className='badge badge-sm badge-warning
'
                                  >
                                    Unpaid
                                  </b>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant='outlined'
                                  onClick={() =>
                                    getsup(supplier._id, supplier.creditAmount)
                                  }
                                >
                                  Pay Credit
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <AddCreditDialog
                open={isShow}
                close={() => setIsShow(false)}
                id={supid}
                credit={credit}
              />
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default List

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import Dialog from '@mui/material/Dialog'

import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { RxCross2 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import SideBar from '../../SideBar'
import { FaArrowLeft } from 'react-icons/fa'
import apiInstance from '../../../../utils/api'

import { useSelector } from 'react-redux'
const Button = styled.button`
  background: rgb(0, 7, 51);
  color: white;
  justify-content: center;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
`

const Create = props => {
  //   const state = props.location.state

  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  console.log(props.medHOArr, 'med arr list')
  console.log(props.proHOArr, 'pro arr list')
  console.log(props.proAccHOArr, 'pro acc arr list')
  // props.proHOArr.map((i)=>(i.))
  //   const location = useLocation()

  //   const ArrList = useLocation.pathname.split('/')[2]

  //   console.log(ArrList, 'Med reor list')
  const [bankList, setBankList] = useState([])
  const [cashList, setCashList] = useState([])

  const [showBank, setShowBank] = useState(false)
  const [showCash, setShowCash] = useState(false)
  const [relatedBankAcc, setRelatedBankAcc] = useState('')
  const [relatedCashAcc, setRelatedCashAcc] = useState('')
  const [relatedAccounting, setRelatedAccounting] = useState('')

  const [units, setUnits] = useState([])
  const [qty, setQty] = useState(0)
  const [price, setPrice] = useState('')
  const [medicines, setMedicines] = useState([])
  const [procedures, setProcedures] = useState([])
  const [proceduresAccess, setProceduresAccess] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [value, setValue] = useState('')
  const [id, setId] = useState('')
  const [supId, setSupId] = useState('')
  const [date, setDate] = useState('')
  const [remark, setRemark] = useState('')
  const [totqty, setTotQty] = useState(0)
  const [totprice, setTotPrice] = useState(0)
  const [branchLists, setBranchLists] = useState([])
  const [branch, setBranch] = useState('')
  const [medicineSale, setMedicineSale] = useState([])
  const [ho, setHO] = useState('HO')

  const [initialAmount, setInitialAmount] = useState('')

  const navigate = useNavigate()
  var totalprice = 0
  var totalqty = 0
  //med
  const [medQty, setMedQty] = useState('')
  const [medPrice, setMedPrice] = useState('')
  const [subTotal, setSubTotal] = useState('')
  const [showMedSubTotal, setShowMedSubTotal] = useState(false)

  const handleBankRadioChange = test => {
    setShowBank(true)
    setShowCash(false)
  }

  const handleCashRadioChange = () => {
    setShowBank(false)
    setShowCash(true)
  }

  useEffect(() => {
    const getBankLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')

        const bank = res.data.list.filter(
          el =>
            el.relatedHeader.name == 'Cash at Bank' &&
            el.relatedType.name === 'Assets'
        )
        setBankList(bank)
      } catch (err) { }
    }
    const getAccountingLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        const medicineSale = res.data.list.filter(
          e =>
            e.relatedHeader.name == 'Adminstrative exp' &&
            'Selling and distribution expense' &&
            'Personal expense' &&
            e.relatedType.name == 'Profit and Loss'
        )
        setInitialAmount(medicineSale[0].amount)
        // setFinalAmount(medicineSale[0].amount);
        setMedicineSale(medicineSale)
        // setAccountingList(res.data.list);
        // set(
        //   res.data.list.filter((e) => e.relatedHeader.name == "Current Assets")
        // );
      } catch (err) { }
    }

    const getBanks = async () => {
      try {
        const res = await apiInstance.get('suppliers')
        setSuppliers(res.data.list)
        console.log('supp')
        console.log(suppliers)
      } catch (err) { }
    }
    // const getBranches = async () => {
    //   try {
    //     const res = await apiInstance.get('branches')
    //     setBranchLists(res.data.list)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }
    const getCashLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')

        const cash = res.data.list.filter(
          el =>
            el.relatedHeader.name == 'Cash in Hand' &&
            el.relatedType.name === 'Assets'
        )
        setCashList(cash)
      } catch (err) { }
    }

    getCashLists()
    getBankLists()
    // getBranches()
    getAccountingLists()
    getBanks()
  }, [])

  const chgItem = val => {
    if (val == 1) {
      getMedicines()
    }
    if (val == 2) {
      getProcedures()
    }
    if (val == 3) {
      getProceduresAccess()
    }
  }

  const handleMed = (event, med, type) => {
    console.log(med, 'ety')
    if (type == 'currentQuantity') {
      const subTotal = event * med.purchasePrice

      setSubTotal(event * med.purchasePrice)
      console.log(event * med.purchasePrice, 'total')
      const newData = props.medHOArr.map(data => {
        if (data._id === med._id) {
          return { ...data, [type]: parseInt(event), totalUnit: subTotal }
        }
        return data
      })
      console.log(newData, 'new data')
      props.setMedHOArr(newData)
      // let tempPriceArr = [...unitArr, newData]

      newData.map((i, index) => {
        totalprice += i.currentQuantity * i.purchasePrice
        setTotPrice(totalprice)
        console.log(totalprice, 'price')
      })
      newData.map((i, index) => {
        totalqty += i.currentQuantity
        setTotQty(totalqty)
        console.log(totalqty, 'price')
      })

      // const res = props.medHOArr.filter(el => el._id == id)

      // const obj = {
      //   item_id: id,
      //   qty: med.currentQuantity,
      //   totalUnit: med.totalUnit,
      //   price: med.purchasePrice,
      //   subTotal: med.currentQuantity * med.purchasePrice
      // }
      // props.setMedHOArr(arr => [...arr, obj])
    } else if (type == 'purchasePrice') {
      let subTotal = parseInt(event) * med.currentQuantity

      setSubTotal(subTotal)
      console.log(subTotal)
      const newData = props.medHOArr.map(data => {
        if (data._id === med._id) {
          return { ...data, [type]: parseInt(event), totalUnit: subTotal }
        }
        return data
      })
      console.log(newData)
      props.setMedHOArr(newData)

      newData.map((i, index) => {
        totalprice += i.currentQuantity * i.purchasePrice
        setTotPrice(totalprice)
        console.log(totalprice, 'price')
      })
      newData.map((i, index) => {
        totalqty += i.currentQuantity
        setTotQty(totalqty)
        console.log(totalqty, 'price')
      })
    }
  }

  const handlePro = (event, pro, type) => {
    console.log(pro, 'ety')
    if (type == 'currentQuantity') {
      const subTotal = event * pro.purchasePrice

      setSubTotal(event * pro.purchasePrice)
      console.log(event * pro.purchasePrice, 'total')
      const newData = props.proHOArr.map(data => {
        if (data._id === pro._id) {
          return { ...data, [type]: parseInt(event), totalUnit: subTotal }
        }
        return data
      })
      console.log(newData, 'new data')
      props.setProHOArr(newData)
      // let tempPriceArr = [...unitArr, newData]

      newData.map((i, index) => {
        totalprice += i.currentQuantity * i.purchasePrice
        setTotPrice(totalprice)
        console.log(totalprice, 'price')
      })
      newData.map((i, index) => {
        totalqty += i.currentQuantity
        setTotQty(totalqty)
        console.log(totalqty, 'price')
      })
    } else if (type == 'purchasePrice') {
      let subTotal = parseInt(event) * pro.currentQuantity

      setSubTotal(subTotal)
      console.log(subTotal)
      const newData = props.proHOArr.map(data => {
        if (data._id === pro._id) {
          return { ...data, [type]: parseInt(event), totalUnit: subTotal }
        }
        return data
      })
      console.log(newData)
      props.setProHOArr(newData)

      newData.map((i, index) => {
        totalprice += i.currentQuantity * i.purchasePrice
        setTotPrice(totalprice)
        console.log(totalprice, 'price')
      })
      newData.map((i, index) => {
        totalqty += i.currentQuantity
        setTotQty(totalqty)
        console.log(totalqty, 'price')
      })
    }
  }
  const handleProAcc = (event, proAcc, type) => {
    console.log(proAcc, 'ety')
    if (type == 'currentQuantity') {
      const subTotal = event * proAcc.purchasePrice

      setSubTotal(event * proAcc.purchasePrice)
      console.log(event * proAcc.purchasePrice, 'total')
      const newData = props.proAccHOArr.map(data => {
        if (data._id === proAcc._id) {
          return { ...data, [type]: parseInt(event), totalUnit: subTotal }
        }
        return data
      })
      console.log(newData, 'new data')
      props.setProAccHOArr(newData)
      // let tempPriceArr = [...unitArr, newData]

      newData.map((i, index) => {
        totalprice += i.currentQuantity * i.purchasePrice
        setTotPrice(totalprice)
        console.log(totalprice, 'price')
      })
      newData.map((i, index) => {
        totalqty += i.currentQuantity
        setTotQty(totalqty)
        console.log(totalqty, 'price')
      })
    } else if (type == 'purchasePrice') {
      let subTotal = parseInt(event) * proAcc.currentQuantity

      setSubTotal(subTotal)
      console.log(subTotal)
      const newData = props.proAccHOArr.map(data => {
        if (data._id === proAcc._id) {
          return { ...data, [type]: parseInt(event), totalUnit: subTotal }
        }
        return data
      })
      console.log(newData)
      props.setProAccHOArr(newData)

      newData.map((i, index) => {
        totalprice += i.currentQuantity * i.purchasePrice
        setTotPrice(totalprice)
        console.log(totalprice, 'price')
      })
      newData.map((i, index) => {
        totalqty += i.currentQuantity
        setTotQty(totalqty)
        console.log(totalqty, 'price')
      })
    }
  }

  const getMedicines = async () => {
    const res = await apiInstance.get('medicine-items')
    // console.log('bro!');
    console.log(res.data.list, 'med')
    setUnits(res.data.list)
    setValue(1)
  }
  const getProcedures = async () => {
    const res = await apiInstance.get('procedure-items')
    setUnits(res.data.list)
    setValue(2)
  }

  const getProceduresAccess = async () => {
    const res = await apiInstance.get('accessory-items')
    setUnits(res.data.list)
    setValue(3)
  }

  const chgQty = async val => {
    const res = units.filter(el => el._id == val)
    console.log('hi')
    console.log(res[0])
    setPrice(res[0].purchasePrice)
    setId(val)
  }
  // const addPurchase = async () => {
  //   const res = units.filter(el => el._id == id)
  //   if (value == 1) {
  //     const obj = {
  //       item_id: id,
  //       name: res[0].medicineItemName,
  //       qty: qty,
  //       totalUnit: (res[0].toUnit * qty) / res[0].fromUnit,
  //       price: res[0].purchasePrice,
  //       subTotal: res[0].purchasePrice * qty
  //     }
  //     setMedicines(arr => [...arr, obj])
  //   }
  //   if (value == 2) {
  //     const obj = {
  //       item_id: id,
  //       name: res[0].procedureItemName,
  //       qty: qty,
  //       totalUnit: (res[0].toUnit * qty) / res[0].fromUnit,
  //       price: res[0].purchasePrice,
  //       subTotal: res[0].purchasePrice * qty
  //     }
  //     setProcedures(arr => [...arr, obj])
  //   }

  //   if (value == 3) {
  //     const obj = {
  //       item_id: id,
  //       name: res[0].accessoryItemName,
  //       qty: qty,
  //       totalUnit: (res[0].toUnit * qty) / res[0].fromUnit,
  //       price: res[0].purchasePrice,
  //       subTotal: res[0].purchasePrice * qty
  //     }
  //     setProceduresAccess(arr => [...arr, obj])
  //   }
  //   setTotPrice(totprice + res[0].purchasePrice * qty)
  //   setTotQty(totqty + parseInt(qty))
  // }
  const del = (id, val) => {
    if (val == 1) {
      const res = medicines.filter(el => el.item_id == id)
      setTotQty(totqty - res.qty)
      setTotPrice(totprice - res.purchasePrice * qty)
      setMedicines(medicines.filter(el => el.item_id != id))
    }
    if (val == 2) {
      const res = procedures.filter(el => el.item_id == id)
      setTotQty(totqty - res.qty)
      setTotPrice(totprice - res.purchasePrice * qty)
      setProcedures(procedures.filter(el => el.item_id != id))
    }
    if (val == 3) {
      const res = proceduresAccess.filter(el => el.item_id == id)
      setTotQty(totqty - res.qty)
      setTotPrice(totprice - res.purchasePrice * qty)
      setProceduresAccess(proceduresAccess.filter(el => el.item_id != id))
    }
  }
  const save = () => {
    const data = {
      purchaseDate: date,
      supplierName: supId,
      remark: remark,
      // relatedBranch: branch,
      medicineItems: props.medHOArr.map(i => ({
        item_id: i._id,
        qty: i.currentQuantity,
        totalUnit: i.totalUnit,
        price: i.purchasePrice,
        subTotal: i.currentQuantity * i.purchasePrice
      })),
      procedureItems: props.proHOArr.map(i => ({
        item_id: i._id,
        qty: i.currentQuantity,
        totalUnit: i.totalUnit,
        price: i.purchasePrice,
        subTotal: i.currentQuantity * i.purchasePrice
      })),
      accessoryItems: props.proAccHOArr.map(i => ({
        item_id: i._id,
        qty: i.currentQuantity,
        totalUnit: i.totalUnit,
        price: i.purchasePrice,
        subTotal: i.currentQuantity * i.purchasePrice
      })),
      totalQTY: totqty,
      totalPrice: totprice
    }

    if (ho !== 'HO') {
      data.relatedBranch = branch
    }
    if (relatedBankAcc) data.relatedBankAccount = relatedBankAcc
    if (relatedCashAcc) data.relatedCashAccount = relatedCashAcc
    // alert(JSON.stringify(data))
    apiInstance.post('purchase', data).then(function (response) {
      // alert("success");
      // props.setIncomeLists([...props.incomeLists, response.data.data[0]]);
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })
    })
    props.close()
  }
  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='xl'>
        <DialogTitle>
          {' '}
          <div className='modal-header ok'>
            <h4 className='modal-title'>Create Purchase With ReOrder Lists</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <div className='card-body'>
            <div className='p-2'>
              <div className='row'>
                <div className='offset-4 col-2'>
                  <div className='form-check form-check-inline mt-2 '>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='bank_cash'
                      id='bank'
                      value='1'
                      onClick={handleBankRadioChange}
                    />
                    &nbsp;
                    <label className='form-check-label text-success' for='bank'>
                      Bank
                    </label>
                  </div>
                </div>
                <div className='col-2'>
                  <div className='form-check form-check-inline mt-2 px-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='bank_cash'
                      id='cash'
                      value='2'
                      onClick={handleCashRadioChange}
                    />
                    <label className='form-check-label text-success' for='cash'>
                      Cash
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className='row'>
                <div className='form-group'>
                  <label>Branch</label>
                  <select
                    name='currency'
                    id=''
                    className='form-control mt-1'
                    onchange='convert(this.value)'
                    // placeholder='Filter By Branches'
                    onChange={e => {
                      setBranch(e.target.value)
                      setHO(e.target.value)
                    }}
                  >
                    <option hidden>Select Branch</option>
                    <option value={ho}>{ho}</option>

                    {branchLists.map(option => (
                      <option value={option._id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div> */}
              {showBank && (
                <div className='form-group mt-3' id='bankkk'>
                  <label className='control-label'>Bank Account</label>

                  <select
                    className='form-control'
                    name='bank_acc'
                    id='bank_acc'
                    onChange={e => setRelatedBankAcc(e.target.value)}
                  >
                    <option value=''>Select Bank Account</option>

                    {bankList.map(option => (
                      <option value={option._id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              )}
              {showCash && (
                <div className='form-group mt-3' id='cashhh'>
                  <label className='control-label'>Cash Account</label>

                  <select
                    className='form-control'
                    name='cash_acc'
                    id='cash_acc'
                    onChange={e => setRelatedCashAcc(e.target.value)}
                  >
                    <option value=''>Select Cash Account</option>
                    {/* @foreach ($cash_account as $acc) */}
                    {cashList.map(option => (
                      <option value={option._id}>{option.name}</option>
                    ))}

                    {/* @endforeach */}
                  </select>
                </div>
              )}
              <div className='row'>
                <div className='col-6'>
                  <label htmlFor=''>Purchase Date</label>
                  <input
                    type='date'
                    className='form-control'
                    onChange={e => setDate(e.target.value)}
                  />
                </div>
                <div className='col-6' style={{ marginTop: '0.5em' }}>
                  <label htmlFor=''>Supplier Name</label>
                  <select
                    name=''
                    id=''
                    className='form-control'
                    onChange={e => setSupId(e.target.value)}
                  >
                    <option value=''>Choose Supplier</option>
                    {suppliers.map((sup, i) => (
                      <option value={sup._id}>{sup.name}</option>
                    ))}
                  </select>
                </div>
                <div className='col-12 mt-3'>
                  <label htmlFor=''>Remark</label>
                  <textarea
                    className='form-control'
                    onChange={e => setRemark(e.target.value)}
                  />
                </div>
                <hr className='mt-3' />
                <div className='offset-1 col-10 mt-3'>
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th scope='col'>Name</th>
                        <th scope='col'>Qty</th>
                        <th scope='col'>Purchase Price</th>

                        <th scope='col'>Sub Total</th>
                        <th scope='col'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.medHOArr.map((med, index) => (
                        <tr>
                          {/* <th>{++index}</th> */}
                          <th>{med.medicineItemName}</th>
                          <th>
                            <input
                              type='number'
                              defaultValue={med.currentQuantity}
                              className='form-control'
                              style={{ width: '90px' }}
                              onChange={e =>
                                handleMed(
                                  e.target.value,
                                  med,
                                  'currentQuantity'
                                )
                              }
                            />
                          </th>
                          <th>
                            <input
                              type='number'
                              defaultValue={med.purchasePrice}
                              className='form-control'
                              style={{ width: '90px' }}
                              onChange={e =>
                                handleMed(e.target.value, med, 'purchasePrice')
                              }
                            />
                          </th>
                          <th>
                            <input
                              type='number'
                              value={
                                handleMed &&
                                med.currentQuantity * med.purchasePrice
                              }
                              className='form-control'
                              style={{ width: '90px' }}
                            // onChange={e => handleTotalPrice(e.target.value)}
                            />

                            {/* {showMedSubTotal
                              ? subTotal
                              : med.currentQuantity * med.purchasePrice} */}
                          </th>
                          <th>
                            <RxCross2 onClick={() => del(med.item_id, 1)} />
                          </th>
                        </tr>
                      ))}
                      {props.proHOArr.map((pro, index) => (
                        <tr>
                          {/* <th>{++index}</th> */}
                          <th>{pro.procedureItemName}</th>
                          <th>
                            <input
                              type='number'
                              defaultValue={pro.currentQuantity}
                              className='form-control'
                              style={{ width: '90px' }}
                              onChange={e =>
                                handlePro(
                                  e.target.value,
                                  pro,
                                  'currentQuantity'
                                )
                              }
                            />
                          </th>
                          <th>
                            <input
                              type='number'
                              defaultValue={pro.purchasePrice}
                              className='form-control'
                              style={{ width: '90px' }}
                              onChange={e =>
                                handlePro(e.target.value, pro, 'purchasePrice')
                              }
                            />
                          </th>
                          <th>
                            <input
                              type='number'
                              value={
                                handlePro &&
                                pro.currentQuantity * pro.purchasePrice
                              }
                              className='form-control'
                              style={{ width: '90px' }}
                            // onChange={e => handleTotalPrice(e.target.value)}
                            />

                            {/* {showMedSubTotal
                              ? subTotal
                              : med.currentQuantity * med.purchasePrice} */}
                          </th>
                          <th>
                            <RxCross2 onClick={() => del(pro._id)} />
                          </th>
                        </tr>
                      ))}

                      {props.proAccHOArr.map((proAcc, index) => (
                        <tr>
                          {/* <th>{++index}</th> */}
                          <th>{proAcc.accessoryItemName}</th>
                          <th>
                            <input
                              type='number'
                              defaultValue={proAcc.currentQuantity}
                              className='form-control'
                              style={{ width: '90px' }}
                              onChange={e =>
                                handleProAcc(
                                  e.target.value,
                                  proAcc,
                                  'currentQuantity'
                                )
                              }
                            />
                          </th>
                          <th>
                            <input
                              type='number'
                              defaultValue={proAcc.purchasePrice}
                              className='form-control'
                              style={{ width: '90px' }}
                              onChange={e =>
                                handleProAcc(
                                  e.target.value,
                                  proAcc,
                                  'purchasePrice'
                                )
                              }
                            />
                          </th>
                          <th>
                            <input
                              type='number'
                              value={
                                handleProAcc &&
                                proAcc.currentQuantity * proAcc.purchasePrice
                              }
                              className='form-control'
                              style={{ width: '90px' }}
                            // onChange={e => handleTotalPrice(e.target.value)}
                            />

                            {/* {showMedSubTotal
                              ? subTotal
                              : med.currentQuantity * med.purchasePrice} */}
                          </th>
                          <th>
                            <RxCross2 onClick={() => del(proAcc._id)} />
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className='col-6 mt-3'>
                  <label htmlFor=''>Total Quantity</label>
                  <input
                    type='number'
                    className='form-control'
                    value={totqty}
                  />
                </div>
                <div className='col-6 mt-3'>
                  <label htmlFor=''>Total Price</label>
                  <input
                    type='number'
                    className='form-control'
                    value={totprice}
                  />
                </div>
              </div>

              <button
                className='mt-4 rounded-3 btn btn-dark'
                style={{ marginLeft: '35em' }}
                onClick={save}
              >
                Save
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create

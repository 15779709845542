import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Swal from 'sweetalert2'

import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import { valueOf } from '../../../../assets/plugins/moment/src/lib/moment/to-type'

export default function BankInfoDialog (props) {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [unitLists, setUnitLists] = useState([])
  const [type, setType] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [treatLists, setTreatLists] = useState([])

  const [conValue, setConValue] = useState('')

  const [value, setValue] = useState('')
  const [itemUnitName, setItemUnitName] = useState('')
  const [name, setName] = useState('')
  const [relatedBrand, setRelatedBrand] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [description, setDescription] = useState('')
  const [showValue, setShowValue] = useState(false)
  const [showItem, setShowItem] = useState(false)
  const [faceList, setFaceList] = useState([])
  const [chooseFaceList, setChooseFaceList] = useState([])

  const [showFace, setShowFace] = useState(false)
  const [bodyList, setBodyList] = useState([])
  const [chooseBodyList, setChooseBodyList] = useState([])

  const [showBody, setShowBody] = useState(false)
  const [bodyInjList, setBodyInjList] = useState([])
  const [chooseBodyInjList, setChooseBodyInjList] = useState([])

  const [showBodyInj, setShowBodyInj] = useState(false)
  const [chooseUnitList, setChooseUnitList] = useState([])
  const [showUnit, setShowUnit] = useState(false)
  const [conName, setConName] = useState('')

  const [comPakList, setComPakList] = useState([])
  const [chooseComPakList, setChooseComPakList] = useState([])
  const [showComPak, setShowComPak] = useState(false)

  const [surgeryList, setSurgeryList] = useState([])
  const [chooseSurgeryList, setChooseSurgeryList] = useState([])
  const [showSurgery, setShowSurgery] = useState(false)

  //For Con Type
  const handleConType = name => {
    setConName(name)
  }

  //for Unit List
  const handleUnit = Id => {
    console.log(Id, 'Id')
    setChooseUnitList(
      unitLists.filter(
        el => (el.treatmentName ? el.treatmentName._id : '') == Id
      )
    )
    setShowUnit(true)
    console.log(
      unitLists.filter(
        el => (el.treatmentName ? el.treatmentName._id : '') == Id
      ),
      'Final Unit List'
    )
  }
  //If FOCItem , Item
  const handleBodyPart = event => {
    console.log(event, 'Body part')
    if (event == 'Treatment') {
      setShowFace(true)
      setShowBody(false)
      setShowBodyInj(false)
      setShowComPak(false)
      setShowSurgery(false)

      setChooseFaceList(faceList)
    }
    if (event == 'Hair,Combine Tre & Facial') {
      setShowBody(true)
      setShowFace(false)
      setShowBodyInj(false)
      setShowComPak(false)
      setShowSurgery(false)

      setChooseBodyList(bodyList)
    }

    if (event == 'Injection') {
      setShowBody(false)
      setShowFace(false)
      setShowBodyInj(true)
      setChooseBodyInjList(bodyInjList)
      setShowComPak(false)
      setShowSurgery(false)
    }
    if (event == 'Combination Package') {
      setShowBody(false)
      setShowFace(false)
      setShowBodyInj(false)
      setShowComPak(true)
      setShowSurgery(false)
      setChooseComPakList(comPakList)
    }
    if (event == 'Surgery Price List') {
      setShowBody(false)
      setShowFace(false)
      setShowBodyInj(false)
      setShowComPak(false)
      setShowSurgery(true)
      setChooseSurgeryList(surgeryList)
    }
  }

  //Type
  const handleType = event => {
    setType(event)
    console.log(event, 'Type')
    if (event == 'Amount') {
      setShowValue(true)
      setShowItem(false)
    } else if (event == 'Percentage') {
      setShowValue(true)
      setShowItem(false)
    } else {
      setShowItem(true)
      setShowValue(false)
    }
  }

  //Create
  const DiscountCreate = () => {
    const data = {
      name: name,
      startDate: startDate,
      endDate: endDate,
      type: type,
      description: description
    }

    if (type == 'FOCItem') {
      data.relatedFOCID = itemUnitName
    } else {
      data.value = value
    }
    if (conName == 'Amount') data.conditionAmount = conValue
    if (conName == 'Purchase Freq') data.conditionPurchaseFreq = conValue
    if (conName == 'Package Qty') data.conditionPackageQty = conValue

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    api
      .post('discount', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()
        // props.setDisLists([...props.disLists, response.data.data])
      })

      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Error',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    props.close()
  }

  useEffect(() => {
    const getTreatmentList = async () => {
      try {
        const res = await api.get('treatment-lists')

        setTreatLists(res.data.list)
        console.log(res.data.list, 'dialog treat list ')
        setFaceList(res.data.list.filter(el => el.bodyParts == 'Treatment'))
        setBodyList(
          res.data.list.filter(
            el => el.bodyParts == 'Hair,Combine Tre & Facial'
          )
        )
        setBodyInjList(res.data.list.filter(el => el.bodyParts == 'Injection'))
        setComPakList(
          res.data.list.filter(el => el.bodyParts == 'Combination Package')
        )
        setSurgeryList(
          res.data.list.filter(el => el.bodyParts == 'Surgery Price List')
        )

        // console.log(
        //   res.data.list.filter(el => el.bodyParts == 'Body Injection'),
        //   'body inj list'
        // )
      } catch (err) {}
    }
    const getUnitList = async () => {
      try {
        const res = await api.get('treatments')

        setUnitLists(res.data.list)
        console.log(res.data.list, 'unit list')
      } catch (err) {}
    }

    // const getBrandLists = async () => {
    //   try {
    //     const res = await api.get('brands')
    //     setRelatedBrandList(res.data.data)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }
    // getCategory()
    getUnitList()

    getTreatmentList()
  }, [])

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='sm'>
        <DialogTitle>
          <div className='modal-header ok'>
            <h4 className='modal-title'>Create Discount</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          {/* @csrf */}
          <div class='modal-body'>
            <div class='form-group'>
              <label for='name'>Name</label>
              <input
                type='text'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Type</label>
              <select
                class='custom-select border-info'
                name='account_type_id'
                id='cat'
                onChange={e => handleType(e.target.value)}
              >
                <option hidden>Choose Type</option>
                <option value='Amount'>Amount</option>
                <option value='Percentage'>Percent</option>
                <option value='FOCItem'>FOC Item</option>
              </select>
            </div>
            {showValue && (
              <div class='form-group'>
                <label for='name'>Value</label>
                <input
                  type='number'
                  class='form-control border border-info'
                  name='name'
                  placeholder='eg. mg mg'
                  onChange={e => setValue(e.target.value)}
                />
              </div>
            )}
            {showItem && (
              <div class='form-group'>
                <label for='name'>Item</label>
                <select
                  class='custom-select border-info'
                  id='subCat'
                  name='account_type_id'
                  onChange={e => handleBodyPart(e.target.value)}
                >
                  <option hidden>Choose Body Parts</option>

                  <option value='Treatment'>Treatment</option>
                  <option value='Hair,Combine Tre & Facial'>
                    Hair,Combine Tre & Facial
                  </option>
                  <option value='Injection'>Injection</option>
                  <option value='Combination Package'>
                    Combination Package
                  </option>

                  <option value='Surgery Price List'>Surgery Price List</option>
                </select>
              </div>
            )}
            {showFace && (
              <div class='form-group'>
                <label for='name'>Choose Treatment Name</label>
                <select
                  name='currency'
                  id=''
                  className='form-control mt-1'
                  onchange='convert(this.value)'
                  onChange={e => handleUnit(e.target.value)}
                >
                  <option hidden>Choose Name</option>

                  {chooseFaceList.map(option => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            )}
            {showBody && (
              <div class='form-group'>
                <label for='name'>Choose Treatment Name</label>
                <select
                  name='currency'
                  id=''
                  className='form-control mt-1'
                  onchange='convert(this.value)'
                  onChange={e => handleUnit(e.target.value)}
                >
                  <option hidden>Choose Name</option>

                  {chooseBodyList.map(option => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            )}
            {showBodyInj && (
              <div class='form-group'>
                <label for='name'>Choose Treatment Name</label>
                <select
                  name='currency'
                  id=''
                  className='form-control mt-1'
                  onchange='convert(this.value)'
                  onChange={e => handleUnit(e.target.value)}
                >
                  <option hidden>Choose Name</option>

                  {chooseBodyInjList.map(option => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            )}
            {showComPak && (
              <div class='form-group'>
                <label for='name'>Choose Treatment Name</label>
                <select
                  name='currency'
                  id=''
                  className='form-control mt-1'
                  onchange='convert(this.value)'
                  onChange={e => handleUnit(e.target.value)}
                >
                  <option hidden>Choose Name</option>

                  {chooseComPakList.map(option => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            )}
            {showSurgery && (
              <div class='form-group'>
                <label for='name'>Choose Treatment Name</label>
                <select
                  name='currency'
                  id=''
                  className='form-control mt-1'
                  onchange='convert(this.value)'
                  onChange={e => handleUnit(e.target.value)}
                >
                  <option hidden>Choose Name</option>

                  {chooseSurgeryList.map(option => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            )}

            {showUnit && (
              <div class='form-group'>
                <label for='name'>Choose Treatment Unit Name</label>
                <select
                  name='currency'
                  id=''
                  className='form-control mt-1'
                  onchange='convert(this.value)'
                  onChange={e => setItemUnitName(e.target.value)}
                >
                  <option hidden>Choose Name</option>

                  {chooseUnitList.map(option => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            )}
            <div class='form-group'>
              <label for='name'>Condition Type</label>
              <select
                name='currency'
                id=''
                className='form-control mt-1'
                onchange='convert(this.value)'
                onChange={e => handleConType(e.target.value)}
              >
                <option hidden>Choose Name</option>

                <option value='Amount'>Amount</option>
                <option value='Purchase Freq'>Purchase Freq</option>
                <option value='Package Qty'>Package Qty</option>
              </select>
            </div>
            <div class='form-group'>
              <label for='name'>Condition Value</label>
              <input
                type='number'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setConValue(e.target.value)}
              />
            </div>

            <div class='form-group'>
              <label for='name'>Start Date</label>
              <input
                type='date'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setStartDate(e.target.value)}
              />
            </div>

            <div class='form-group'>
              <label for='name'>End Date</label>
              <input
                type='date'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setEndDate(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Description</label>
              <textarea
                className='form-control'
                id='description'
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div class='modal-footer'>
            <Button
              class='btn btn-secondary'
              data-dismiss='modal'
              onClick={props.close}
            >
              Close
            </Button>
            <Button class='btn btn-primary' onClick={DiscountCreate}>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

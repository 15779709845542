import React from 'react'
import { createDate } from '../../assets/plugins/moment/src/lib/create/date-from-array'
import { Link } from 'react-router-dom'
import { FaCashRegister, FaFileMedical } from 'react-icons/fa'
import SideBar from './SideBar'
import HeaderName from './HeaderName'
function Dashboard () {
  return (
    <div classNameName='App'>
      {/* <!-- preloader --> */}
      {/* <!-- <div classNameName="preloader" id="preloaders" style="  position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../../image/loader2.gif') 50%50% no-repeat rgb(249, 249, 249);
    opacity: 0.9;"></div> -->

 */}

      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <a href='#'>Home</a>
                    </li>
                    <li className='breadcrumb-item active'>
                      {' '}
                      Company General Infomation{' '}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className='content'>
            <div className='container-fluid'>
              {/* <!-- Small boxes (Stat box) --> */}
              <div class='card'>
                <div class='card-body p-b-0'>
                  <h3 className='text-success text-center'>
                    Company General Infomation
                  </h3>
                  {/* @if($com == null) */}
                  <form
                    action="{{route('store_company')}}"
                    method='POST'
                    className='mt-5'
                  >
                    {/* @csrf */}
                    <div className='form-body'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Company Name
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='company_name'
                            />
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Company Address
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='company_address'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Company Contact
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder=''
                              name='company_contact'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Company Email
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder=''
                              name='company_email'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Company MD Name
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder=''
                              name='md_name'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Starting Capital
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder=''
                              name='capital'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Financial Year Start Date
                            </label>
                            <input
                              type='date'
                              className='form-control'
                              placeholder=''
                              name='start_date'
                              id='mdate'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Financial Year End Date
                            </label>
                            <input
                              type='date'
                              className='form-control'
                              placeholder=''
                              name='end_date'
                              id='mdate'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Net Profit for Previous Year
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder=''
                              name='pre_year'
                              id='mdate'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label className='control-label'>
                              Net Profit for Current Year
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder=''
                              name='curr_year'
                              id='mdate'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-actions'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='row'>
                              <div className=' col-md-9'>
                                <button
                                  type='submit'
                                  className='btn btn-primary'
                                >
                                  Create
                                </button>
                                &nbsp;
                                <button
                                  type='button'
                                  className='btn btn-danger'
                                  data-dismiss='modal'
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* @else */}
                  {/* <form action="{{route('update_company')}}" method="POST" className="mt-5">
                        {/* @csrf */}
                  {/* <div className="form-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Company Name</label>
                                    <input type="text" className="form-control" name="company_name" value="{{$com->company_name}}"/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Company Address</label>
                                    <input type="text" className="form-control" name="company_address" value="{{$com->company_address}}"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Company Contact</label>
                                    <input type="text" className="form-control" placeholder="" name="company_contact" value="{{$com->company_contact}}"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Company Email</label>
                                    <input type="text" className="form-control" placeholder="" name="company_email" value="{{$com->company_email}}"/>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Company MD Name</label>
                                    <input type="text" className="form-control" placeholder="" name="md_name" value="{{$com->company_md_name}}"/>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Starting Capital</label>
                                    <input type="text" className="form-control" placeholder="" name="capital" value="{{$com->starting_capital}}"/>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Financial Year Start Date</label>
                                    <input type="date" className="form-control" placeholder="" name="start_date" id="mdate" value="{{$com->financial_start_date}}"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Financial Year End Date</label>
                                    <input type="date" className="form-control" placeholder="" name="end_date" id="mdate" value="{{$com->financial_end_date}}"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Net Profit for Previous Year</label>
                                    <input type="text" className="form-control" placeholder="" name="pre_year" id="mdate" value="{{$com->netprofit_pre_year}}"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Net Profit for Current Year</label>
                                    <input type="text" className="form-control" placeholder="" name="curr_year" id="mdate" value="{{$com->netprofit_current_year}}"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-actions">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className=" col-md-9">
                                            <button type="submit" className="btn btn-primary">Update</button>
                                            <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div> */}
                  {/* </form>  */}
                  {/* @endif */}
                </div>
              </div>

              {/* <!-- /.row (main row) --> */}
            </div>{' '}
            {/*<!-- /.container-fluid --> */}
          </section>
          {/* <!-- /.content --> */}
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
      {/* <!-- jQuery --> */}
      <script src="{{asset('plugins/jquery/jquery.min.js')}}"></script>
      {/* <!-- jQuery UI 1.11.4 --> */}
      <script src="{{asset('plugins/jquery-ui/jquery-ui.min.js')}}"></script>
      {/* <!-- Select2 --> */}

      {/* <!-- Resolve conflict in jQuery UI tooltip with Bootstrap tooltip -->*/}
      <script>$.widget.bridge('uibutton', $.ui.button)</script>
      {/* <!-- Bootstrap 4 --> */}
      <script src="{{asset('plugins/bootstrap/js/bootstrap.bundle.min.js')}}"></script>
      {/* <!-- ChartJS --> */}
      <script src="{{asset('plugins/chart.js/Chart.min.js')}}"></script>
      {/* <!-- Sparkline --> */}
      <script src="{{asset('plugins/sparklines/sparkline.js')}}"></script>
      {/* <!-- jQuery Knob Chart --> */}
      <script src="{{asset('plugins/jquery-knob/jquery.knob.min.js')}}"></script>
      {/* <!-- daterangepicker --> */}
      <script src="{{asset('plugins/moment/moment.min.js')}}"></script>
      <script src="{{asset('plugins/daterangepicker/daterangepicker.js')}}"></script>

      {/* <!-- Tempusdominus Bootstrap 4 --> */}
      <script src="{{asset('plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js')}}"></script>
      {/* <!-- Summernote --> */}
      <script src="{{asset('plugins/summernote/summernote-bs4.min.js')}}"></script>
      {/* <!-- overlayScrollbars --> */}
      <script src="{{asset('plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js')}}"></script>
      {/* <!-- AdminLTE App --> */}
      <script src="{{asset('dist/js/adminlte.js')}}"></script>
      {/* <!-- AdminLTE dashboard demo (This is only for demo purposes) -->
<!-- <script src="{{asset('dist/js/pages/dashboard.js')}}"></script> -->
<!-- AdminLTE for demo purposes --> */}
      <script src="{{asset('dist/js/demo.js')}}"></script>
      {/* <!-- Bootstrap 4 -->
<!-- DataTable -->
{{-- <script src="{{asset('assets/plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js')}}"></script>

<script src="{{asset('assets/plugins/bootstrap-datepicker/bootstrap-datetimepicker.min.js')}}"></script>
<script src="{{asset('assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js')}}"></script>

<script src="{{asset('assets/plugins/datatables/jquery.dataTables.min.js')}}"></script> --}} */}

      <script src="{{asset('plugins/datatables/jquery.dataTables.js')}}"></script>
      <script src="{{asset('plugins/datatables/dataTables.bootstrap')}}"></script>
      <script src="{{asset('plugins/datatables-bs4/js/dataTables.bootstrap4.js')}}"></script>
      <script src="{{asset('plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js')}}"></script>

      {/* @yield('js')
<script>

// $(window).on('load', function(){
//                 $("#preloaders").fadeOut(100);
//             });
//             $(document).ajaxStart(function(){
//                 $("#preloaders").show();
//             });
//             $(document).ajaxComplete(function(){
//                 $("#preloaders").hide();
//             });
// $( document ).ready(function() {
//     // window.location.reload();
// });

</script> */}
    </div>
  )
}
export default Dashboard

import SideBar from '../../SideBar.js'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import apiInstance from '../../../../utils/api.js'
import HeaderName from '../../HeaderName'
function CatRegister() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [allLists, setAllLists] = useState([])
  const [topList, setTopList] = useState([])
  const [bottomList, setBottomList] = useState([])
  const [showTop, setShowTop] = useState(false)
  const [showBottom, setShowBottom] = useState(false)
  const [showOrigin, setShowOrigin] = useState(true)
  const [showSearchDate, setShowSearchDate] = useState(false)
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [searchByDateList, setSearchByDateList] = useState([])
  const [topTenList, setTopTenList] = useState([])
  const [type, setType] = useState('')

  const handleChoose = event => {
    if (event == 'Top') {
      setTopList(topTenList.sort((a, b) => b.qty - a.qty))
      setShowTop(true)
      setShowBottom(false)

      setShowOrigin(false)
      setShowSearchDate(false)
    }
    if (event == 'Bottom') {
      setBottomList(topTenList.sort((a, b) => a.qty - b.qty))
      setShowTop(false)
      setShowBottom(true)

      setShowOrigin(false)
      setShowSearchDate(false)
    }

    if (event == 'All') {
      setShowTop(false)
      setShowBottom(false)

      setShowSearchDate(true)
    }
  }

  const handleFilterDate = async () => {
    const nDate = new Date(to)
    const toDate = nDate.setDate(nDate.getDate() + 1)
    // }
    try {
      Swal.fire({
        title: 'Loading',
        text: 'Please wait...',
        allowOutsideClick: false
      })
      let data = {}

      if (type) {
        data.tsType = type
      } else {
      }

      if (from && to) {
        data.start = from
        data.end = toDate
      }

      await apiInstance
        .get(`treatment-selections/top-ten`, { params: data })
        .then(res => {
          console.log(res.data.data, 'main lis')
          setSearchByDateList(res.data.data)
          setTopTenList(res.data.data)

          setShowSearchDate(true)
          setShowOrigin(false)
          setShowTop(false)
          setShowBottom(false)

          // console.log(res.data.data.CashNames, 'c NAME')
        })
        .catch(error => {
          console.log('error', error)
        })
      Swal.close()

      // Process the response data
      //console.log(data);
    } catch (error) {
      Swal.close()
      console.error('Error occurred while fetching data.', error)
    }
  }

  // const handleFilterDate = () => {
  //   console.log(from, 'from')
  //   console.log(to, 'to')
  //   setSearchByDateList(
  //     allLists.filter(el => el.createdAt >= from && el.createdAt <= to)
  //   )
  //   console.log(
  //     allLists.filter(el => el.createdAt >= from && el.createdAt <= to),
  //     'filter date'
  //   )
  //   setShowSearchDate(true)
  //   setShowOrigin(false)
  //   setShowTop(false)
  //   setShowBottom(false)
  // }
  // useEffect(() => {
  //   const getPackage = async () => {
  //     try {
  //       const res = await apiInstance.get('treatment-selections/top-ten', {
  //         params: { start: from, end: to }
  //       })

  //       console.log(res.data.data, 'qty lis')
  //       setTopTenList(res.data.data)

  //       setAllLists(res.data.list)
  //     } catch (err) {}
  //   }
  //   getPackage()
  // }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        <HeaderName />
        <SideBar />
        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2 card-header py-3'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active'>Top 10 List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='form-group col-md-3'>
                        <label>From</label>
                        <input
                          type='date'
                          name='from'
                          id='from'
                          onChange={e => setFrom(e.target.value)}
                          className='form-control'
                        />
                      </div>
                      <div className='form-group col-md-3'>
                        <label>To</label>
                        <input
                          type='date'
                          name='to'
                          id='to'
                          onChange={e => setTo(e.target.value)}
                          className='form-control'
                        />
                      </div>
                      <div
                        className='form-group col-4'
                        style={{ marginTop: '2.5em' }}
                      >
                        <select
                          name='currency'
                          id=''
                          className='form-control '
                          // onchange='convert(this.value)'
                          // placeholder='Filter By Branches'
                          onChange={e => setType(e.target.value)}
                        >
                          <option hidden>Types</option>
                          <option value='All'>All</option>

                          <option value='TS'>Treatment Selection</option>
                          <option value='TSMulti'>
                            Multiple Treatment Selection
                          </option>
                        </select>
                      </div>

                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-sm btn-primary'
                          style={{ marginTop: '38px' }}
                          onClick={() => handleFilterDate()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='offset-2 col-4'>
                    <div
                      className='offset-5 col-md-6'
                      style={{ marginTop: '2.6em' }}
                    >
                      <div className='form-group'>
                        <select
                          name='currency'
                          id=''
                          className='form-control '
                          // onchange='convert(this.value)'
                          // placeholder='Filter By Branches'
                          onChange={e => handleChoose(e.target.value)}
                        >
                          <option hidden>Filter By Types</option>
                          <option value='All'>All</option>

                          <option value='Top'>Top 10</option>
                          <option value='Bottom'>Bottom</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-12 py-3 card'>
                    <div class='card-header'>
                      <div class='row' id='trial_balance'></div>
                    </div>
                    <div class='table-responsive text-black' id='slimtest2'>
                      <table class='table table-hover px-3' id='filter_date'>
                        <thead class='bg-info text-white'>
                          <tr>
                            <th>No.</th>

                            <th>Treatment</th>

                            <th>Treatment Unit</th>
                            <th>Qty</th>
                          </tr>
                        </thead>

                        {showSearchDate &&
                          searchByDateList.map((pack, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>

                                <td>{pack?.treatment}</td>
                                <td>{pack?.treatmentUnit}</td>

                                <td>{pack.qty}</td>
                              </tr>
                            </tbody>
                          ))}
                        {showOrigin &&
                          allLists.map((pack, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>

                                <td>{pack?.treatment}</td>
                                <td>{pack?.treatmentUnit}</td>

                                <td>{pack.qty}</td>
                              </tr>
                            </tbody>
                          ))}
                        {showTop &&
                          topList.map((pack, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>

                                <td>{pack.treatment ? pack.treatment : ''}</td>
                                <td>
                                  {pack.treatmentUnit ? pack.treatmentUnit : ''}
                                </td>

                                <td>{pack.qty}</td>
                              </tr>
                            </tbody>
                          ))}
                        {showBottom &&
                          bottomList.map((pack, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>

                                <td>{pack.treatment ? pack.treatment : ''}</td>
                                <td>
                                  {pack.treatmentUnit ? pack.treatmentUnit : ''}
                                </td>

                                <td>{pack.qty}</td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="card px-3 py-3">
                      <h5 className="card-header mb-4 text-secondary">
                        Create Reagent
                      </h5>

                      <div class="form-group">
                        <label for="code" className="text-secondary">
                          Code
                        </label>
                        <input
                          type="text"
                          id="code"
                          class="form-control border-info"
                          name="code"
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                      <div class="form-group">
                        <label for="name" className="text-secondary">
                          Name
                        </label>
                        <input
                          type="text"
                          class="form-control border-info"
                          name="name"
                          id="name"
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div class="form-group">
                        <label for="name" className="text-secondary">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="desc"
                          //   ref={(el) => (this.description = el)}
                          onChange={(e) =>
                            setDescription(e.target.value)
                          }></textarea>
                      </div>
                      <div class="form-group">
                        <label for="name" className="text-secondary">
                          Flag
                        </label>
                        <select
                          class="custom-select border-info"
                          name="account_type_id"
                          id="flag"
                          onChange={(e) => setFlag(e.target.value)}>
                          <option></option>
                          <option value="Service">Service</option>
                          <option value="Reagent">Reagent</option>
                        </select>
                      </div>
                      <button
                        className="btn btn-primary form-control text-center fw-5"
                        type="submit"
                        onClick={CategoryCreate}>
                        Save
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <ExpenseDialog
                open={open}
                close={() => setOpen(false)}
                expenseLists={expenseLists}
                setExpenseLists={setExpenseLists}
              /> */}
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default CatRegister

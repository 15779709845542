import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaCashRegister, FaFileMedical } from 'react-icons/fa'
import axios from 'axios'
import SideBar from '../../SideBar.js'
import SellEndDialog from '../../SellEndDialog'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'

function FixedAsset () {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [open, setOpen] = useState(false)
  const [fixedAssetLists, setFixedAssetLists] = useState([])
  const [id, setId] = useState('')
  const [isShow, setIsShow] = useState(false)
  const [relatedLists, setRelatedLists] = useState([])

  useEffect(() => {
    const getFixedAsset = async () => {
      try {
        const res = await api.get('fixed-assets')
        console.log(res.data.list, 'list')

        setFixedAssetLists(
          res.data.list.filter(
            el =>
              el.type == 'Medical Equipment' ||
              el.type == 'Surgery Equipment' ||
              el.type == 'Plant & Machinery'
          )
        )

        // console.log(
        //   res.data.list.filter(
        //     el =>
        //       el.relatedAccount.name == 'Medical Equipment' ||
        //       el.relatedAccount.name == 'Surgery Equipment' ||
        //       el.relatedAccount.name == 'Plant & Machinery'
        //   ),
        //   'Machine'
        // )
      } catch (err) {}
    }

    getFixedAsset()
  }, [])

  const handleTransactionShow = val => {
    console.log(val)
    const getRelated = async () => {
      try {
        console.log(val)
        const res = await api.get('transactions/related/' + val)

        console.log(res)
        setRelatedLists(res.data.data)
      } catch (err) {}
    }

    getRelated()

    if (isShow) {
      document.getElementById('toggle' + val).removeAttribute('hidden')
    } else {
      document.getElementById('toggle' + val).setAttribute('hidden', 'hidden')
    }
    setIsShow(!isShow)
  }

  const showDialog = val => {
    setOpen(true)
    setId(val)
  }

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'></div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='card'>
                <h4 className='ml-3 mt-3'>Machine List</h4>
                <div className='card-body'>
                  {/* <label className="">Fixed Asset List</label> */}

                  <table className='table table-hover text-black'>
                    <thead className='ok text-white'>
                      <tr>
                        <th>No</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>Initial Price</th>
                        <th>Salvage Value</th>
                        {/* {{-- <th>Current Value</th> --}} */}
                        <th>Depreciate Total</th>
                        <th>Used Life</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    {fixedAssetLists.map((fixedAsset, i) => (
                      <tbody>
                        <tr>
                          <td>{++i}</td>
                          <td>{fixedAsset.type}</td>
                          <td>{fixedAsset.name}</td>
                          <td>{fixedAsset.startDate.split('T')[0]}</td>
                          <td>{fixedAsset.initialPrice} </td>
                          <td>{fixedAsset.salvageValue}</td>
                          <td>
                            {fixedAsset.depriciationTotal
                              ? fixedAsset.depriciationTotal
                              : 'No Choose'}
                          </td>
                          <td>{fixedAsset.useLife}</td>
                          <td className='text-center'>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary ml-2'
                              // onClick={() =>
                              //   handleTransactionShow(
                              //     fixedAsset.relatedAccount._id
                              //   )
                              // }
                            >
                              Transaction
                            </button>
                          </td>
                        </tr>

                        {/* <tr
                          className='bg-light'
                          id={'toggle' + fixedAsset.relatedAccount._id}
                          hidden
                        >
                          <td colspan='12'>
                            <div>
                              <div class='row'>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    No
                                  </label>
                                </div>
                                <div class='col-md-3'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Account
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Type
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Date
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Amount
                                  </label>
                                </div>
                              </div>

                              {relatedLists
                                ? relatedLists.map((reList, i) => (
                                    <div class='row'>
                                      <div class='col-md-2'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {++i}
                                        </div>
                                      </div>
                                      <div class='col-md-3'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {reList.relatedAccounting.name}
                                        </div>
                                      </div>
                                      <div class='col-md-2'>{reList.type}</div>
                                      <div class='col-md-2'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {reList.date.split('T')[0]}
                                        </div>
                                      </div>
                                      <div class='col-md-2'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {reList.amount}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ''}
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}
    </div>
  )
}
export default FixedAsset

import * as React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import SideBar from '../../SideBar.js'
import { Link, useLocation } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt, FaArrowLeft } from 'react-icons/fa'
import apiInstance from '../../../../utils/api.js'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const IncomeID = useLocation().pathname.split('/')[2]

  const [branchLists, setBranchLists] = useState([])
  const [branch, setBranch] = useState('')
  const [branchID, setBranchID] = useState('')

  const [bankList, setBankList] = useState([])
  const [cashList, setCashList] = useState([])
  const [incomingAccount, setIncomingAccount] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [showBank, setShowBank] = useState(false)
  const [showCash, setShowCash] = useState(false)

  //setting up for data
  const [initialAmount, setInitialAmount] = useState('')
  const [relatedBankAcc, setRelatedBankAcc] = useState('')
  const [relatedCashAcc, setRelatedCashAcc] = useState('')
  const [relatedAccounting, setRelatedAccounting] = useState('')
  const [initialCurrency, setInitialCurrency] = useState('')
  const [date, setDate] = useState('')
  const [remark, setRemark] = useState('')
  const [finalAmount, setFinalAmount] = useState('')
  const [finalCurrency, setFinalCurrency] = useState('')
  const [branchName, setBranchName] = useState('')
  const [bankRadio, setBankRadio] = useState(false)
  const [cashRadio, setCashRadio] = useState(false)
  const [bankName, setBankName] = useState('')
  const [bankID, setBankID] = useState('')
  const [curCode, setCurCode] = useState([])

  const [cashName, setCashName] = useState('')
  const [cashID, setCashID] = useState('')

  const [relatedAccountingID, setRelatedAccountingID] = useState('')
  const [initialCurValue, setInitialCurValue] = useState('')
  const [finalCurValue, setFinalCurValue] = useState('')

  const IncomeCreate = event => {
    const data = {
      id: IncomeID,
      //   relatedAccounting: relatedAccounting,
      date: date,
      //   relatedBranch: branch,
      initialAmount: initialAmount,
      //   initialCurrency: initialCurrency,
      finalAmount: finalAmount,
      //   finalCurrency: finalCurrency,
      remark: remark
    }
    // console.log(jsonData);

    if (initialCurValue) {
      data.initialCurrency = initialCurValue
    } else {
      data.initialCurrency = initialCurrency
    }
    if (finalCurValue) {
      data.finalCurrency = finalCurValue
    } else {
      data.finalCurrency = finalCurrency
    }

    if (relatedAccountingID) {
      data.relatedAccounting = relatedAccountingID
    } else {
      data.relatedAccounting = relatedAccounting
    }
    if (showCash) {
      if (cashID) {
        data.relatedCashAccount = cashID
      } else {
        data.relatedCashAccount = relatedCashAcc
      }
    } else {
      if (bankID) {
        data.relatedBankAccount = bankID
      } else {
        data.relatedBankAccount = relatedBankAcc
      }
    }

    // alert(JSON.stringify(data))
    // console.log(jsonData);
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    // alert(JSON.stringify(jsonData));
    apiInstance.put('income', data, config).then(function (response) {
      setFinalAmount('')
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })
    })
  }

  const handleBankRadioChange = () => {
    setShowBank(true)
    setShowCash(false)
  }

  const handleCashRadioChange = () => {
    setShowBank(false)
    setShowCash(true)
  }

  const handleCalculation = event => {
    setFinalCurValue(event)
    if (initialCurrency === 'MMK') {
      console.log(finalCurrency)
      let current = currencyList.filter(currency => currency.code === event)[0]
        .exchangeRate
      console.log(current)
      let ans = initialAmount / current
      setFinalAmount(ans.toFixed(2))
      console.log(ans)
    } else if (initialAmount != 'MMK') {
      let current = currencyList.filter(
        currency => currency.code === initialCurrency
      )[0].exchangeRate
      // console.log(initialAmount);
      // console.log(current);
      let ans = initialAmount * current
      setFinalAmount(ans.toFixed(2))
      console.log(finalAmount)
    }
    setFinalCurrency(event)
    console.log(initialAmount, 'Amount')
    if (initialCurrency == event) setFinalAmount(initialAmount)
  }

  useEffect(() => {
    const getCashLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')

        const cash = res.data.list.filter(
          el =>
            el.relatedHeader.name === 'Cash in Hand' &&
            el.relatedType.name === 'Assets'
        )
        setCashList(cash)
      } catch (err) { }
    }

    //Bank List
    const getBankLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        console.log(res.data.list, 'bank')
        const bank = res.data.list.filter(
          el =>
            el.relatedHeader.name == 'Cash at Bank' &&
            el.relatedType.name === 'Assets'
        )
        setBankList(bank)
      } catch (err) { }
    }

    //Account List
    const getAccountingLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        console.log(res.data.list, 'acc list')
        const IncomingAcc = res.data.list.filter(
          e =>
            e.relatedType.name == 'Other Income' ||
            e.relatedType.name == 'Revenues'
        )

        setIncomingAccount(IncomingAcc)
        // setAccountingList(res.data.list)
      } catch (err) { }
    }

    //Currency list
    const getCurrencyLists = async () => {
      try {
        const res = await apiInstance.get('currencies')
        setCurrencyList(res.data.list)
        console.log(res.data.list, 'cur list')
        setInitialCurrency(currencyList.initialCurrency)
        setFinalCurrency(currencyList.finalCurrency)
        setCurCode(res.data.list.map(item => item.code))
        console.log(
          res.data.list.map(item => item.code),
          'code list'
        )
      } catch (err) { }
    }

    //Income List By ID
    const getIncome = async () => {
      try {
        const res = await apiInstance.get('income/' + IncomeID)
        console.log(res.data.data, 'income')

        setInitialCurValue(res.data.data[0].initialCurrency)

        setFinalCurValue(res.data.data[0].finalCurrency)

        if (res.data.data[0].relatedBankAccount) {
          setBankRadio(true)
          setCashRadio(false)
          setShowBank(true)
        } else {
          setBankRadio(false)
          setCashRadio(true)

          setShowCash(true)
        }
        setBankName(
          res.data.data[0].relatedBankAccount
            ? res.data.data[0].relatedBankAccount.name
            : ''
        )
        setCashName(
          res.data.data[0].relatedCashAccount
            ? res.data.data[0].relatedCashAccount.name
            : ''
        )
        setRelatedAccounting(
          res.data.data[0].relatedAccounting
            ? res.data.data[0].relatedAccounting.name
            : ''
        )

        setRelatedAccountingID(
          res.data.data[0].relatedAccounting
            ? res.data.data[0].relatedAccounting._id
            : ''
        )
        setBankID(
          res.data.data[0].relatedBankAccount
            ? res.data.data[0].relatedBankAccount._id
            : ''
        )
        setCashID(
          res.data.data[0].relatedCashAccount
            ? res.data.data[0].relatedCashAccount._id
            : ''
        )

        setInitialAmount(res.data.data[0].initialAmount)
        setFinalAmount(res.data.data[0].finalAmount)
        setDate(
          res.data.data[0].date ? res.data.data[0].date.split('T')[0] : ''
        )
        setRemark(res.data.data[0].remark)
      } catch (err) {
        alert(err.message)
      }
    }

    getIncome()
    getBankLists()
    getCashLists()
    // getHeaderLists();
    getAccountingLists()
    getCurrencyLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        {/* <HeaderName /> */}

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/income'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    {/* <li className='breadcrumb-item active'>
                      Stock Request Create
                    </li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='card row py-3'>
                <h5 className='card-header text-center py-3 fs-3 mb-3'>
                  Income Update
                </h5>
                <div className='form-body'>
                  <div className='row '>
                    {bankRadio ? (
                      <div className='offset-4 col-md-2'>
                        <div className='form-check form-check-inline mt-2 '>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='bank_cash'
                            id='bank'
                            value='1'
                            onClick={handleBankRadioChange}
                            checked
                          />
                          &nbsp;
                          <label
                            className='form-check-label text-success'
                            for='bank'
                          >
                            Bank
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className='offset-4 col-md-2'>
                        <div className='form-check form-check-inline mt-2 '>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='bank_cash'
                            id='bank'
                            value='1'
                            onClick={handleBankRadioChange}
                            disabled={true}
                          />
                          &nbsp;
                          <label
                            className='form-check-label text-success'
                            for='bank'
                          >
                            Bank
                          </label>
                        </div>
                      </div>
                    )}

                    {cashRadio ? (
                      <div className='col-md-2'>
                        <div className='form-check form-check-inline mt-2 px-4'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='bank_cash'
                            id='cash'
                            value='2'
                            onClick={handleCashRadioChange}
                            checked
                          />
                          <label
                            className='form-check-label text-success'
                            for='cash'
                          >
                            Cash
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className='col-md-2'>
                        <div className='form-check form-check-inline mt-2 px-4'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='bank_cash'
                            id='cash'
                            value='2'
                            onClick={handleCashRadioChange}
                            disabled={true}
                          />
                          <label
                            className='form-check-label text-success'
                            for='cash'
                          >
                            Cash
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  {showBank && (
                    <div className='form-group mt-3' id='bankkk'>
                      <label className='control-label'>Bank Account</label>

                      <select
                        className='form-control'
                        name='bank_acc'
                        id='bank_acc'
                        onChange={e => {
                          setRelatedBankAcc(e.target.value)
                          setBankID(e.target.value)
                        }}
                      >
                        <option value={bankID}>{bankName}</option>

                        {bankList.map(option => (
                          <option value={option._id}>{option.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {showCash && (
                    <div className='form-group mt-3' id='cash'>
                      <label className='control-label'>Cash Account</label>

                      <select
                        className='form-control'
                        name='cash_acc'
                        id='cash_acc'
                        onChange={e => {
                          setRelatedCashAcc(e.target.value)
                          setCashID(e.target.value)
                        }}
                      >
                        <option value={cashID} hidden>
                          {cashName}
                        </option>
                        {/* @foreach ($cash_account as $acc) */}
                        {cashList.map(option => (
                          <option value={option._id}>{option.name}</option>
                        ))}

                        {/* @endforeach */}
                      </select>
                    </div>
                  )}
                  <div className='form-group mt-3'>
                    <label className='control-label'>Incoming Account</label>
                    <select
                      className='form-control'
                      name='exp_acc'
                      onChange={e => {
                        setRelatedAccounting(e.target.value)
                        setRelatedAccountingID(e.target.value)
                      }}
                    >
                      <option value={relatedAccountingID} hidden>
                        {relatedAccounting}
                      </option>
                      {/* @foreach ($inc_account as $acc) */}

                      {incomingAccount.map(option => (
                        <option value={option._id}>{option.subHeader}</option>
                      ))}

                      {/* @endforeach */}
                    </select>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='control-label'>Initial Amount</label>

                        <input
                          type='number'
                          className='form-control'
                          name='amount'
                          id='convert_amount'
                          defaultValue={initialAmount}
                          onChange={event =>
                            setInitialAmount(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='control-label'>
                          Initial Currency
                        </label>

                        <select
                          name='currency'
                          id=''
                          className='form-control mt-1'
                          onChange={e => {
                            setInitialCurrency(e.target.value)
                            setInitialCurValue(e.target.value)
                          }}
                        >
                          <option value={initialCurValue} hidden>
                            {initialCurValue}
                          </option>
                          {currencyList.map(option => (
                            <option value={option.code}>{option.code}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='control-label'>Final Amount</label>

                        <input
                          type='number'
                          className='form-control'
                          name='amount'
                          id='convert_amount'
                          defaultValue={finalAmount}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='control-label'>Final Currency</label>

                        <select
                          name='currency'
                          id=''
                          className='form-control mt-1'
                          onchange='convert(this.value)'
                          onChange={e => handleCalculation(e.target.value)}
                        >
                          <option value={finalCurValue} hidden>
                            {finalCurValue}
                          </option>

                          {currencyList.map(option => (
                            <option value={option.code}>{option.code}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='control-label'>Date</label>
                    <input
                      type='date'
                      className='form-control'
                      name='date'
                      defaultValue={date}
                      onChange={e => setDate(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label className='control-label'>Remark</label>
                    <input
                      type='text'
                      className='form-control'
                      name='remark'
                      defaultValue={remark}
                      onChange={e => setRemark(e.target.value)}
                    />
                  </div>
                  <div className='row'>
                    <div className='col-md-9 mt-4'>
                      <button
                        type='submit'
                        className='btn btn-success'
                        onClick={IncomeCreate}
                      >
                        Submit
                      </button>
                      <button
                        type='button'
                        className='btn btn-inverse btn-dismiss'
                        data-dismiss='modal'
                        onClick={props.close}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
// phyo
//maymyat

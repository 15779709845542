import React, { useState } from 'react'
// import Stock from './StockCount.js'
export default function HeaderName() {
  const [hide, setHide] = useState(false)

  const divStyles1 = {
    textShadow: '1px 1px 2px grey',
    margin: 'auto 200px'
  }

  const handleHide = () => {
    setHide(!hide)
  }

  return (
    <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
      {/* <!-- Left navbar links --> */}
      <ul className='navbar-nav text-lg-end mr-2 mt-2'>
        <li className='nav-item' onClick={handleHide}>
          {/* <i className='fas fa-bars'></i> */}
        </li>
      </ul>
      {/* <Stock setHide={setHide} hide={hide} /> */}

      <div className='title py-3'>
        <h3 style={{ color: '#0b7874', fontFamily: 'serif', fontWeight: 'bolder', margin: 'auto 200px', letterSpacing: '1px' }}>Finance & Admin Module</h3>
      </div>

      <ul className='navbar-nav ml-auto'></ul>
    </nav>
  )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { RxCross2 } from 'react-icons/rx'
import SideBar from '../../SideBar'
import Swal from 'sweetalert2'

import { FaArrowLeft } from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import apiInstance from '../../../../utils/api'

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 30px;
`

const Center = styled.div`
  flex: 1;
  display: inline;
  text-align: center;
`

const Button = styled.button`
  background: rgb(0, 7, 51);
  color: white;
  justify-content: center;
  padding: 5px 30px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
`

const Title = styled.h5`
  font-weight: bold;
  margin-top: 10px;
`
const Div = styled.div``
const Label = styled.label``
const Input = styled.input`
  width: 100%;
`
const Span = styled.span`
  color: red;
`
const Textarea = styled.textarea``

const Select = styled.select`
  padding: 5px 7px;
  border-radius: 5px;
`
const Option = styled.option``

const Table = styled.table``
const Thead = styled.thead``
const Tbody = styled.tbody``
const Tr = styled.tr`
  text-align: center;
`
const Th = styled.th`
  font-size: 15px;
`
const Td = styled.td`
  font-size: 14px;
`

const Update2 = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [doctors, setDoctors] = useState([])
  const [tName, setTName] = useState([])
  const [therapiInstancests, setTherapiInstancests] = useState([])
  const [procedures, setProcedures] = useState([])
  const [machines, setMachines] = useState([])
  const [accessories, setAccessories] = useState([])
  const [medicineList, setMedicineList] = useState([])
  const [items, setItems] = useState([])
  const [isItem, setIsItem] = useState(false)
  const [id, setId] = useState('')
  const [accitems, setAccItems] = useState([])
  const [isAccItem, setIsAccItem] = useState(false)
  const [accid, setAccId] = useState('')
  const [macitems, setMacItems] = useState([])
  const [isMacItem, setIsMacItem] = useState(false)
  const [medItems, setMedItems] = useState([])
  const [isMedItem, setIsMedItem] = useState(false)
  const [qty, setQty] = useState('')

  const [macid, setMacId] = useState('')
  const [totalPrice, setTotalPrice] = useState('')
  const [percent, setPercent] = useState('')
  const [sellPrice, setSellPrice] = useState('')
  const [description, setDescription] = useState('')
  const [doctorname, setDoctorName] = useState('')
  const [therapiInstancestname, setTherapiInstancestName] = useState('')
  const [treatmenttime, setTreatmentTime] = useState('')
  const [treatmentCode, setTreatmentCode] = useState('')
  const [name, setName] = useState('')
  const [upProData, setUpProData] = useState([])
  const [upMedData, setUpMedData] = useState([])
  const [upProAcc, setUpProAcc] = useState([])
  const [upMachine, setUpMachine] = useState([])
  const [TCLtotalPrice, setTCLTotalPrice] = useState('')

  const [TCLsellPrice, setTCLSellPrice] = useState('')
  const [eightTotalPrice, setEightTotalPrice] = useState('')

  const [eightSellPrice, setEightSellPrice] = useState('')
  const [NptTotalPrice, setNptTotalPrice] = useState('')

  const [NptSellPrice, setNptSellPrice] = useState('')
  const [LSHTotalPrice, setLSHTotalPrice] = useState('')

  const [LSHSellPrice, setLSHSellPrice] = useState('')
  const [MDYTotalPrice, setMDYTotalPrice] = useState('')

  const [MDYSellPrice, setMDYSellPrice] = useState('')
  const [KTotalPrice, setKTotalPrice] = useState('')

  const [KSellPrice, setKSellPrice] = useState('')

  const [showPrice, setShowPrice] = useState(false)
  const [branchPrice, setBranchPrice] = useState([])
  const [showHO, setShowHO] = useState(true)
  const [branchName, setBranchName] = useState('')
  const [branchList, setBranchList] = useState([])
  const [branch, setBranch] = useState('')
  const [showEight, setShowEight] = useState(false)
  const [showNPT, setShowNPT] = useState(false)
  const [showMDY, setShowMDY] = useState(false)
  const [showLSH, setShowLSH] = useState(false)
  const [showK, setShowK] = useState(false)
  const [showTCLTotal, setShowTCLTotal] = useState(false)

  //Pro Medicine
  const [tableProData, setTableProData] = useState([])
  const [selectOption, setSelectOption] = useState({
    id: '',
    name: ''
  })

  //Medicine
  const [tableMedData, setTableMedData] = useState([])
  const [selectMedOption, setSelectMedOption] = useState({
    id: '',
    name: ''
  })

  //Medicine
  const [tableProAccData, setTableProAccData] = useState([])
  const [selectProAccOption, setSelectProAccOption] = useState({
    id: '',
    name: ''
  })

  //Machine
  const [tableMachineData, setTableMachineData] = useState([])
  const [selectMacOption, setSelectMacOption] = useState({
    id: '',
    name: ''
  })

  const [unitID, setUnitID] = useState('')

  //NOTE To get two ID
  const Id = useLocation().pathname.split('/')[2]
  const TreatId = useLocation().pathname.split('/')[3]
  // console.log(TreatId,'Hello')

  //End

  //med remove
  const [upMedRemoveData, setUpMedRemoveData] = useState([])

  const handleBranch = id => {
    setBranch(id)
    if (id == '6474394fdab649311f9fcc32') {
      setBranchName('SOK')
    } else {
      setBranchName(branchList.filter(el => el._id === id)[0].name)
    }

    if (id == '6474394fdab649311f9fcc32') {
      setShowEight(false)
      setShowHO(true)
      setShowPrice(false)
      setShowLSH(false)
      setShowNPT(false)
      setShowMDY(false)
      setShowK(false)
    }
    if (id == '64743985dab649311f9fcc33') {
      setShowEight(true)
      setShowHO(false)
      setShowPrice(false)
      setShowLSH(false)
      setShowNPT(false)
      setShowMDY(false)
      setShowK(false)
    }
    if (id == '64743991dab649311f9fcc34') {
      setShowEight(false)
      setShowHO(false)
      setShowPrice(false)
      setShowLSH(false)
      setShowNPT(true)
      setShowMDY(false)
      setShowK(false)
    }
    if (id == '6474399edab649311f9fcc35') {
      setShowEight(false)
      setShowHO(false)
      setShowPrice(false)
      setShowLSH(true)
      setShowNPT(false)
      setShowMDY(false)
      setShowK(false)
    }
    if (id == '647439aadab649311f9fcc36') {
      setShowEight(false)
      setShowHO(false)
      setShowPrice(false)
      setShowLSH(false)
      setShowNPT(false)
      setShowMDY(true)
      setShowK(false)
    }
    if (id == '649559535fc22f0e7f0884fe') {
      if (TCLtotalPrice) {
        setShowTCLTotal(false)
      }
      setShowEight(false)
      setShowHO(false)
      setShowPrice(true)
      setShowLSH(false)
      setShowNPT(false)
      setShowMDY(false)
      setShowK(false)
    }
    if (id == '64b6064a61cdd336e64f9300') {
      setShowEight(false)
      setShowHO(false)
      setShowPrice(false)
      setShowLSH(false)
      setShowNPT(false)
      setShowMDY(false)
      setShowK(true)
    }
  }

  useEffect(() => {
    const getMedicine = async () => {
      const res = await apiInstance.get('medicine-items')
      setMedicineList(res.data.list)
    }

    const getProcedures = async () => {
      const res = await apiInstance.get('procedure-items')
      setProcedures(res.data.list)
    }
    const getAccessories = async () => {
      const res = await apiInstance.get('accessory-items')
      console.log(res.data.list, 'acc')
      setAccessories(res.data.list)
    }
    // const getTreatment = async () => {
    //   const res = await apiInstance.get('treatment-list/' + Id)
    //   console.log(res.data, 'treatement')

    //   // setTName(res.data.data[0].name)
    //   // console.log(res.data.data[0].name)
    // }

    const getMachines = async () => {
      const res = await apiInstance.get('fixed-assets')
      setMachines(
        res.data.list.filter(
          el =>
            el.type == 'Medical Equipment' ||
            el.type == 'Surgery Equipment' ||
            el.type == 'Medical Machinery'
        )
      )
    }

    //  getTreatment()
    const getBranches = async () => {
      const res = await apiInstance.get('branches')
      setBranchList(res.data.list)
    }

    const getTreatment = async () => {
      try {
        const res = await apiInstance.get('treatment/' + Id)
        console.log(res.data.data, 'treat up')

        setPercent(res.data.data?.discount)

        setSellPrice(res.data.data?.sellingPrice)
        setTotalPrice(res.data.data?.estimateTotalPrice)

        setUnitID(res.data.data?._id)

        setName(res.data.data?.name)
        setTreatmentCode(res.data.data?.treatmentCode)
        setTreatmentTime(res.data.data?.treatmentTimes)


        setDescription(res.data.data?.description)

        //NOTE For Meducine
        if (
          res.data.data.medicineLists !== null &&
          res.data.data.medicineLists.length > 0
        ) {
          const newData = res.data.data.medicineLists.map((data, index) => {
            return {
              id: index,
              name: data.item_id ? data.item_id.medicineItemName : '',
              item_id: data.item_id._id,
              quantity: data.quantity,
              perUsageQTY: data.perUsageQTY,
              unit: data.unit
            }
          })
          console.log(newData, 'meeeeeeed')
          setUpMedData(newData)
          setIsMedItem(true)
        }
        //NOTE For Procedure Medicine
        if (
          res.data.data.procedureMedicine !== null &&
          res.data.data.procedureMedicine.length > 0
        ) {
          const newData = res.data.data.procedureMedicine.map(
            (data, index) => {
              return {
                id: index,
                name: data.item_id ? data.item_id.procedureItemName : '',
                item_id: data.item_id._id,
                quantity: data.quantity,
                perUsageQTY: data.perUsageQTY,
                unit: data.unit
              }
            }
          )
          setUpProData(newData)
          setIsItem(true)
        }
        //NOTE For Procedure Accessory
        if (
          res.data.data.procedureAccessory !== null &&
          res.data.data.procedureAccessory.length > 0
        ) {
          const newData = res.data.data.procedureAccessory.map(
            (data, index) => {
              return {
                id: index,
                name: data.item_id ? data.item_id.accessoryItemName : '',
                item_id: data.item_id._id,
                quantity: data.quantity,
                perUsageQTY: data.perUsageQTY,
                unit: data.unit
              }
            }
          )
          setUpProAcc(newData)
          console.log(newData, 'Pro Acc')
          setIsAccItem(true)
        }

        //NOTE For Machine
        if (
          res.data.data.machine !== null &&
          res.data.data.machine.length > 0
        ) {
          const newData = res.data.data.machine.map((data, index) => {
            return {
              id: index,
              name: data.item_id ? data.item_id.name : '',
              item_id: data.item_id._id,
              quantity: data.quantity,
              perUsageQTY: data.perUsageQTY
            }
          })
          setUpMachine(newData)
          console.log(newData, 'Mac Acc')
          setIsMacItem(true)
        }
      } catch (err) {
        alert(err.message)
      }
    }
    getTreatment()

    // getBranches()

    getMedicine()

    // getTherapiInstancests()
    getProcedures()
    getAccessories()
    getMachines()
  }, [])

  const removeUpMedicine = index => {
    // if (upMedData.index !== index)
    console.log(upMedData, 'UpMEDDATA')
    const temp = [...upMedData]
    console.log(temp.splice(index, 1), 'list')
    console.log(temp, 'TEMP')
    setUpMedData(temp)
    // }
  }
  // function findId(arr){
  //   return arr.id === id
  // }
  // const removeUpMedicine = id => {
  //   // make a separate copy of the array
  //   var index = upMedData.map(el => el.item_id === id).indexOf(id)
  //   // if (index !== -1) {
  //   //   upMedData.splice(index, 1)
  //   // }
  //   console.log(index, 'up med remove')
  // }
  // const removeUpMedicine = id => {
  //   setUpMedData({
  //     return upMedData.filter((_, i) => i !== id)
  // })
  //   setIsMedItem(true)
  // }

  const removeUpProcedure = id => {
    setUpProData(upProData.filter(el => el.item_id != id))
  }

  const removeUpProAcc = id => {
    setUpProAcc(upProAcc.filter(el => el.item_id != id))
  }

  const removeUpMac = id => {
    setUpMachine(upMachine.filter(el => el.item_id != id))
  }

  //Start procedure medicine add row

  //NOTE Input Changes when input qty and per usage qty
  const handleProInputChange = (event, id, field) => {
    const newData = tableProData.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData)
    setTableProData(newData)
  }
  const handleUpProInputChange = (event, id, field) => {
    const newData = upProData.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData)
    setUpProData(newData)
  }

  //NOTE to add row of Procedure Medicine
  const handleProAddRow = (id, name) => {
    // console.log(name, 'Pro name')
    setTableProData([
      ...tableProData,
      {
        id: tableProData.length + 1,
        name: name,
        item_id: id,
        quantity: '',
        perUsageQTY: ''
      }
    ])

    // console.log(proMedName, 'pro med')
    setIsItem(true)
  }

  //NOTE to get Id and Name of procedure medicine
  const handleProGet = event => {
    const { options, selectedIndex } = event.target
    const selectedOption = options[selectedIndex]
    const id = selectedOption.getAttribute('data-id')

    const proName = selectedOption.getAttribute('data-name')
    setSelectOption({
      id: id,
      name: proName
    })

    // const value = event.target.value
    // // Use the id, name, and value as needed
    // console.log('ID:', id)
    // console.log('Name:', proName)
    // console.log('Value:', value)
  }

  //NOTE to delete proecedure medicine
  const removeProcedure = id => {
    setTableProData(tableProData.filter(el => el.item_id != id))
  }
  //End procedure medicine add row

  //Start procedure medicine add row

  //NOTE Input Changes when input qty and per usage qty
  const handleMachineInputChange = (event, id, field) => {
    const newData = tableMachineData.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData, 'mac new data')
    setTableMachineData(newData)
  }

  //NOTE to add row of Procedure Medicine
  const handleMachineAddRow = (id, name) => {
    console.log(name, 'Mac name')
    setTableMachineData([
      ...tableMachineData,
      {
        id: tableMachineData.length + 1,
        name: name,
        item_id: id,
        quantity: '',
        perUsageQTY: ''
      }
    ])

    // console.log(proMedName, 'pro med')
    setIsMacItem(true)
  }

  //NOTE to get Id and Name of procedure medicine
  const handleMacGet = event => {
    const { options, selectedIndex } = event.target
    const selectedOption = options[selectedIndex]
    const id = selectedOption.getAttribute('data-id')

    const macName = selectedOption.getAttribute('data-name')
    setSelectMacOption({
      id: id,
      name: macName
    })

    // const value = event.target.value
    // // Use the id, name, and value as needed
    // console.log('ID:', id)
    // console.log('Name:', proName)
    // console.log('Value:', value)
  }

  //NOTE to delete proecedure medicine
  const removeMac = id => {
    console.log(id, 'Re Id')
    setTableMachineData(tableMachineData.filter(el => el.id != id))
  }
  //End procedure medicine add row

  //Start Medicine add row
  //NOTE Input Changes when input qty and per usage qty
  const handleMedInputChange = (event, id, field) => {
    const newData = tableMedData.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData)
    setTableMedData(newData)
  }

  const handleUpMedInputChange = (event, id, field) => {
    const newData = upMedData.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData)
    setUpMedData(newData)
  }

  //NOTE to add row of Procedure Medicine
  const handleMedAddRow = (id, name) => {
    // console.log(name, 'Med name')
    setTableMedData([
      ...tableMedData,
      {
        id: tableMedData.length + 1,
        name: name,
        item_id: id,
        quantity: '',
        perUsageQTY: ''
      }
    ])

    // console.log(proMedName, 'pro med')
    setIsMedItem(true)
  }

  //NOTE to get Id and Name of procedure medicine
  const handleMedGet = event => {
    const { options, selectedIndex } = event.target
    const selectedOption = options[selectedIndex]
    const id = selectedOption.getAttribute('data-id')

    const medName = selectedOption.getAttribute('data-name')
    setSelectMedOption({
      id: id,
      name: medName
    })

    // Use the id, name, and value as needed
    // console.log('ID:', id)
    // console.log('Name:', medName)
    // console.log('Value:', value)
  }

  //NOTE to delete proecedure medicine
  const removeMedicine = id => {
    setTableMedData(tableMedData.filter(el => el.item_id != id))
  }

  //End Medicine add row

  //Start ProAccessory add row
  //NOTE Input Changes when input qty and per usage qty
  const handleProAccInputChange = (event, id, field) => {
    const newData = tableProAccData.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData)
    setTableProAccData(newData)
  }

  const handleUpProAccInputChange = (event, id, field) => {
    const newData = upProAcc.map(data => {
      if (data.id === id) {
        return { ...data, [field]: event.target.value }
      }
      return data
    })
    console.log(newData)
    setUpProAcc(newData)
  }

  //NOTE to add row of Procedure Medicine
  const handleProAccAddRow = (id, name) => {
    console.log(name, 'proAcc name')
    setTableProAccData([
      ...tableProAccData,
      {
        id: tableProAccData.length + 1,
        name: name,
        item_id: id,
        quantity: '',
        perUsageQTY: ''
      }
    ])

    // console.log(proMedName, 'pro med')
    setIsAccItem(true)
  }

  //NOTE to get Id and Name of procedure medicine
  const handleProAccGet = event => {
    const { options, selectedIndex } = event.target
    const selectedOption = options[selectedIndex]
    const id = selectedOption.getAttribute('data-id')

    const proAccName = selectedOption.getAttribute('data-name')
    setSelectProAccOption({
      id: id,
      name: proAccName
    })

    const value = event.target.value
    // Use the id, name, and value as needed
    console.log('ID:', id)
    console.log('Name:', proAccName)
    console.log('Value:', value)
  }

  //NOTE to delete proecedure medicine
  const removeProAcc = id => {
    setTableProAccData(tableProAccData.filter(el => el.item_id != id))
  }

  const handlePercent = event => {
    setPercent(event)
    const res = (event / 100) * totalPrice
    const final = totalPrice - res

    setSellPrice(final)
    // console.log((event / 100) * totalPrice, 'per res')
  }

  //End Medicine add row

  const update = () => {
    // console.log(TreatId,'Treat ID')
    const data = {
      id: unitID,
      name: name,
      treatmentCode: treatmentCode,
      treatmentName: TreatId,
      treatmentTimes: treatmenttime,
      // relatedDoctor: doctorname,
      // medicineLists: tableMedData,
      // procedureMedicine: tableProData,
      // procedureAccessory: tableProAccData,
      // machine: macitems,
      estimateTotalPrice: totalPrice,
      discount: percent,
      sellingPrice: sellPrice,
      // TCLEstimateTotalPrice: TCLtotalPrice,
      // TCLSellingPrice: TCLsellPrice,
      description: description,
      status: true
    }

    if (upProData) {
      data.procedureMedicine = [...upProData, ...tableProData]
    } else {
      data.procedureMedicine = tableProData
    }

    if (upMedData) {
      data.medicineLists = [...upMedData, ...tableMedData]
    } else {
      data.medicineLists = tableMedData
    }

    if (upProAcc) {
      data.procedureAccessory = [...upProAcc, ...tableProAccData]
    } else {
      data.procedureAccessory = tableProAccData
    }

    if (upMachine) {
      data.machine = [...upMachine, ...tableMachineData]
    } else {
      data.machine = tableMachineData
    }

    if (branch == '6474394fdab649311f9fcc32' || totalPrice) {
      data.estimateTotalPrice = totalPrice
      data.sellingPrice = sellPrice
      data.discount = percent
    }
    if (branch == '64743985dab649311f9fcc33' || eightTotalPrice) {
      data.EightMileEstimateTotalPrice = eightTotalPrice
      data.EightMileSellingPrice = eightSellPrice
    }

    if (branch == '64743991dab649311f9fcc34' || NptTotalPrice) {
      data.NPTEstimateTotalPrice = NptTotalPrice
      data.NPTSellingPrice = NptSellPrice
    }
    if (branch == '6474399edab649311f9fcc35' || LSHTotalPrice) {
      data.LSHEstimateTotalPrice = LSHTotalPrice
      data.LSHSellingPrice = LSHSellPrice
    }
    if (branch == '647439aadab649311f9fcc36' || MDYTotalPrice) {
      data.MDYEstimateTotalPrice = MDYTotalPrice
      data.MDYSellingPrice = MDYSellPrice
    }

    if (branch == '649559535fc22f0e7f0884fe' || TCLtotalPrice) {
      data.TCLEstimateTotalPrice = TCLtotalPrice
      data.TCLSellingPrice = TCLsellPrice
    }
    if (branch == '64b6064a61cdd336e64f9300' || KTotalPrice) {
      data.KEstimateTotalPrice = KTotalPrice
      data.KSellingPrice = KSellPrice
    }

    // alert(JSON.stringify(data))
    apiInstance
      .put('treatment', data)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
  }

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to={'/checkUnit/' + TreatId}>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active'>Treatment Create</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='card-body row border border-dark'>
                <h5>Unit Update</h5>
                <div className='offset-2 col-8 py-5'>
                  <div className='row'>
                    <div className='col-4 form-group '>
                      <Label>
                        Treatment Unit Name<Span>*</Span>
                      </Label>
                      <Input
                        type='text'
                        className='form-control'
                        defaultValue={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                    <div className='col-4 form-group'>
                      <Label>
                        Treatment Unit Code<Span>*</Span>
                      </Label>
                      <Input
                        type='text'
                        className='form-control'
                        defaultValue={treatmentCode}
                        onChange={e => setTreatmentCode(e.target.value)}
                      />
                    </div>
                    <div className='col-4 form-group'>
                      <Label>
                        Treatment Times<Span>*</Span>
                      </Label>
                      <Input
                        type='text'
                        className='form-control'
                        defaultValue={treatmenttime}
                        onChange={e => setTreatmentTime(e.target.value)}
                      />
                    </div>

                    <div className='col-6 form-group mt-3'>
                      <Label>Medicine</Label>
                      <Select className='form-control' onChange={handleMedGet}>
                        <Option>Select Medicine</Option>
                        {medicineList.map((procedure, index) => (
                          <Option
                            value={procedure._id}
                            data-id={procedure._id}
                            data-name={procedure.medicineItemName}
                          >
                            {procedure.medicineItemName}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className='col-3 mt-4'>
                      <Button
                        onClick={e =>
                          handleMedAddRow(
                            selectMedOption.id,
                            selectMedOption.name
                          )
                        }
                      >
                        Add
                      </Button>
                    </div>
                    <div className='col-12 mt-3'>
                      <Table className='table table-striped'>
                        <Thead>
                          <Tr>
                            {/* <Th>No.</Th> */}
                            <Th>Item Name</Th>
                            <Th>Qty</Th>

                            <Th>Per Usage Qty</Th>

                            <Th>Unit</Th>
                          </Tr>
                        </Thead>

                        <Tbody>
                          {isMedItem &&
                            upMedData.map((item, index) => (
                              <Tr>
                                {/* {console.log(item, 'State Check')} */}
                                <Td>{item.name}</Td>
                                <Td>
                                  <input
                                    type='number'
                                    // defaultValue={procedure.qty}
                                    value={item.quantity}
                                    onChange={event =>
                                      handleUpMedInputChange(
                                        event,
                                        item.id,
                                        'quantity'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                {/* <Td>{procedure.purchasePrice}</Td> */}
                                <Td>
                                  <input
                                    type='number'
                                    value={item.perUsageQTY}
                                    onChange={event =>
                                      handleUpMedInputChange(
                                        event,
                                        item.id,
                                        'perUsageQTY'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                <Td>
                                  <input
                                    type='text'
                                    value={item.unit}
                                    onChange={event =>
                                      handleMedInputChange(
                                        event,
                                        item.id,
                                        'unit'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                <Td>
                                  <RxCross2
                                    onClick={() => removeUpMedicine(index)}
                                  />
                                </Td>
                              </Tr>
                            ))}

                          {isMedItem &&
                            tableMedData.map((item, index) => (
                              <Tr>
                                {/* <Td>{++index}</Td> */}
                                <Td>{item ? item.name : ''}</Td>
                                <Td>
                                  <input
                                    type='number'
                                    // defaultValue={procedure.qty}
                                    value={item.quantity}
                                    onChange={event =>
                                      handleMedInputChange(
                                        event,
                                        item.id,
                                        'quantity'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                {/* <Td>{procedure.purchasePrice}</Td> */}
                                <Td>
                                  <input
                                    type='number'
                                    value={item.perUsageQTY}
                                    onChange={event =>
                                      handleMedInputChange(
                                        event,
                                        item.id,
                                        'perUsageQTY'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                <Td>
                                  <input
                                    type='text'
                                    value={item.unit}
                                    onChange={event =>
                                      handleMedInputChange(
                                        event,
                                        item.id,
                                        'unit'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                <Td>
                                  <RxCross2
                                    onClick={() => removeMedicine(item.item_id)}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    {/* NOTE Procedure Medicine */}
                    <div className='col-6 form-group mt-3'>
                      <Label>Procedure Medicine</Label>
                      <Select className='form-control' onChange={handleProGet}>
                        <Option>Select Procedure Medicine</Option>
                        {procedures.map((procedure, index) => (
                          <Option
                            value={procedure._id}
                            data-id={procedure._id}
                            data-name={procedure.procedureItemName}
                          >
                            {procedure.procedureItemName}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className='col-3 mt-4'>
                      <Button
                        onClick={e =>
                          handleProAddRow(selectOption.id, selectOption.name)
                        }
                      >
                        Add
                      </Button>
                    </div>
                    <div className='col-12 mt-4'>
                      <Table className='table table-striped'>
                        <Thead>
                          <Tr>
                            {/* <Th>No.</Th> */}
                            <Th>Item Name</Th>
                            <Th>Qty</Th>
                            {/* <Th>Purchase Price</Th> */}
                            <Th>Per Usage Qty</Th>
                            {/* <Th>Amount</Th> */}
                            <Th>Unit</Th>
                          </Tr>
                        </Thead>
                        {isItem && (
                          <Tbody>
                            {upProData &&
                              upProData.map((item, index) => (
                                <Tr>
                                  {/* <Td>{++index}</Td> */}
                                  <Td>{item.name}</Td>
                                  <Td>
                                    <input
                                      type='number'
                                      // defaultValue={procedure.qty}
                                      value={item.quantity}
                                      onChange={event =>
                                        handleUpProInputChange(
                                          event,
                                          item.id,
                                          'quantity'
                                        )
                                      }
                                      className='size-10 form-control'
                                    />
                                  </Td>
                                  {/* <Td>{procedure.purchasePrice}</Td> */}
                                  <Td>
                                    <input
                                      type='number'
                                      value={item.perUsageQTY}
                                      onChange={event =>
                                        handleUpProInputChange(
                                          event,
                                          item.id,
                                          'perUsageQTY'
                                        )
                                      }
                                      className='size-10 form-control'
                                    />
                                  </Td>

                                  <Td>
                                    <input
                                      type='text'
                                      // defaultValue={procedure.qty}
                                      value={item.unit}
                                      onChange={event =>
                                        handleProInputChange(
                                          event,
                                          item.id,
                                          'unit'
                                        )
                                      }
                                      className='size-10 form-control'
                                    />
                                  </Td>

                                  {/* <Td>{procedure.purchasePrice}</Td> */}
                                  <Td>
                                    <RxCross2
                                      onClick={e =>
                                        removeUpProcedure(item.item_id)
                                      }
                                    />
                                  </Td>
                                </Tr>
                              ))}
                            {tableProData.map((item, index) => (
                              <Tr>
                                {/* <Td></Td> */}
                                <Td>{item ? item.name : ''}</Td>
                                <Td>
                                  <input
                                    type='number'
                                    // defaultValue={procedure.qty}
                                    value={item.quantity}
                                    onChange={event =>
                                      handleProInputChange(
                                        event,
                                        item.id,
                                        'quantity'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                {/* <Td>{procedure.purchasePrice}</Td> */}
                                <Td>
                                  <input
                                    type='number'
                                    value={item.perUsageQTY}
                                    onChange={event =>
                                      handleProInputChange(
                                        event,
                                        item.id,
                                        'perUsageQTY'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                <Td>
                                  <input
                                    type='text'
                                    // defaultValue={procedure.qty}
                                    value={item.unit}
                                    onChange={event =>
                                      handleProInputChange(
                                        event,
                                        item.id,
                                        'unit'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>
                                <Td>
                                  <RxCross2
                                    onClick={() =>
                                      removeProcedure(item.item_id)
                                    }
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        )}
                      </Table>
                    </div>
                    {/* NOTE End Procedure Medicine */}

                    <div className='col-6 form-group mt-3'>
                      <Label>Procedure Accessory</Label>
                      <Select
                        className='form-control'
                        onChange={handleProAccGet}
                      >
                        <Option>Select Procedure Accessory</Option>
                        {accessories.map((accessory, index) => (
                          <Option
                            value={accessory._id}
                            data-id={accessory._id}
                            data-name={accessory.accessoryItemName}
                          >
                            {accessory.accessoryItemName}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className='col-3 mt-4'>
                      <Button
                        onClick={e =>
                          handleProAccAddRow(
                            selectProAccOption.id,
                            selectProAccOption.name
                          )
                        }
                      >
                        Add
                      </Button>
                    </div>
                    <div className='col-12 mt-3'>
                      <Table className='table table-striped'>
                        <Thead>
                          <Tr>
                            {/* <Th>No.</Th> */}
                            <Th>Item Name</Th>
                            <Th>Qty</Th>
                            {/* <Th>Purchase Price</Th> */}
                            <Th>Per Usage Qty</Th>
                            <Th>Unit</Th>
                          </Tr>
                        </Thead>
                        {isAccItem && (
                          <Tbody>
                            {upProAcc &&
                              upProAcc.map((item, index) => (
                                <Tr>
                                  {/* <Td>{++index}</Td> */}
                                  <Td>{item ? item.name : ''}</Td>
                                  <Td>
                                    <input
                                      type='number'
                                      // defaultValue={procedure.qty}
                                      value={item.quantity}
                                      onChange={event =>
                                        handleUpProAccInputChange(
                                          event,
                                          item.id,
                                          'quantity'
                                        )
                                      }
                                      className='size-10 form-control'
                                    />
                                  </Td>
                                  {/* <Td>{procedure.purchasePrice}</Td> */}
                                  <Td>
                                    <input
                                      type='number'
                                      value={item.perUsageQTY}
                                      onChange={event =>
                                        handleUpProAccInputChange(
                                          event,
                                          item.id,
                                          'perUsageQTY'
                                        )
                                      }
                                      className='size-10 form-control'
                                    />
                                  </Td>
                                  <Td>
                                    <input
                                      type='text'
                                      // defaultValue={procedure.qty}
                                      value={item.unit}
                                      onChange={event =>
                                        handleUpProAccInputChange(
                                          event,
                                          item.id,
                                          'unit'
                                        )
                                      }
                                      className='size-10 form-control'
                                    />
                                  </Td>

                                  <Td>
                                    <RxCross2
                                      onClick={() =>
                                        removeUpProAcc(item.item_id)
                                      }
                                    />
                                  </Td>
                                </Tr>
                              ))}

                            {tableProAccData.map((item, index) => (
                              <Tr>
                                {/* <Td></Td> */}
                                <Td>{item ? item.name : ''}</Td>
                                <Td>
                                  <input
                                    type='number'
                                    // defaultValue={procedure.qty}
                                    value={item.quantity}
                                    onChange={event =>
                                      handleProAccInputChange(
                                        event,
                                        item.id,
                                        'quantity'
                                      )
                                    }
                                    className='size-10'
                                  />
                                </Td>
                                {/* <Td>{procedure.purchasePrice}</Td> */}
                                <Td>
                                  <input
                                    type='number'
                                    value={item.perUsageQTY}
                                    onChange={event =>
                                      handleProAccInputChange(
                                        event,
                                        item.id,
                                        'perUsageQTY'
                                      )
                                    }
                                    className='size-10'
                                  />
                                </Td>
                                <Td>
                                  <input
                                    type='text'
                                    // defaultValue={procedure.qty}
                                    value={item.unit}
                                    onChange={event =>
                                      handleUpProAccInputChange(
                                        event,
                                        item.id,
                                        'unit'
                                      )
                                    }
                                    className='size-10 form-control'
                                  />
                                </Td>

                                <Td>
                                  <RxCross2
                                    onClick={() => removeProAcc(item.item_id)}
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        )}
                      </Table>
                    </div>
                    {/* NOTE End Procedure Accessory */}

                    <div className='col-6 form-group mt-3'>
                      <Label>Machine</Label>
                      <Select className='form-control' onChange={handleMacGet}>
                        <Option>Select Machine</Option>
                        {machines.map((machine, i) => (
                          <Option
                            value={machine._id}
                            data-id={machine._id}
                            data-name={machine.name}
                          >
                            {machine.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className='col-3 mt-4'>
                      <Button
                        onClick={() =>
                          handleMachineAddRow(
                            selectMacOption.id,
                            selectMacOption.name
                          )
                        }
                      >
                        Add
                      </Button>
                    </div>
                    <div className='col-12 mt-3'>
                      <Table className='table table-striped'>
                        <Thead>
                          <Tr>
                            {/* <Th>No.</Th> */}
                            <Th>Item Name</Th>
                            <Th>Qty</Th>
                            {/* <Th>Purchase Price</Th> */}
                            <Th>Per Usage Qty</Th>
                            {/* <Th>Amount</Th> */}
                          </Tr>
                        </Thead>
                        {/* {isMacItem && (
                          <Tbody>
                            {upMachine &&
                              upMachine.map((macitem, index) =>
                                machines.map(
                                  (machine, i) =>
                                    macitem.item_id == machine._id && (
                                      <Tr>
                                        <Td>{machine.name}</Td>

                                        <Td>{machine.initialPrice}</Td>

                                        <Td>{machine.initialPrice}</Td>
                                        <Td>
                                          <RxCross2
                                            onClick={() =>
                                              removeMac(machine._id)
                                            }
                                          />
                                        </Td>
                                      </Tr>
                                    )
                                )
                              )}
                            {macitems.map((macitem, index) =>
                              machines.map(
                                (machine, i) =>
                                  macitem.item_id == machine._id && (
                                    <Tr>
                                      <Td>{machine.name}</Td>

                                      <Td>10</Td>

                                      <Td>1</Td>
                                      <Td>
                                        <RxCross2
                                          onClick={() => removeMac(machine._id)}
                                        />
                                      </Td>
                                    </Tr>
                                  )
                              )
                            )}
                          </Tbody>
                        )} */}
                        {isMacItem && (
                          <Tbody>
                            {upMachine &&
                              upMachine.map((item, index) => (
                                <Tr>
                                  {/* <Td>{++index}</Td> */}
                                  <Td>{item ? item.name : ''}</Td>
                                  <Td>
                                    <input
                                      type='number'
                                      // defaultValue={procedure.qty}
                                      value={item.quantity}
                                      onChange={event =>
                                        handleMachineInputChange(
                                          event,
                                          item.id,
                                          'quantity'
                                        )
                                      }
                                      className='size-10'
                                    />
                                  </Td>
                                  {/* <Td>{procedure.purchasePrice}</Td> */}
                                  <Td>
                                    <input
                                      type='number'
                                      value={item.perUsageQTY}
                                      onChange={event =>
                                        handleMachineInputChange(
                                          event,
                                          item.id,
                                          'perUsageQTY'
                                        )
                                      }
                                      className='size-10'
                                    />
                                  </Td>
                                  <Td>
                                    <RxCross2
                                      onClick={() => removeUpMac(item.item_id)}
                                    />
                                  </Td>
                                </Tr>
                              ))}

                            {tableMachineData.map((item, index) => (
                              <Tr>
                                {/* <Td></Td> */}
                                <Td>{item ? item.name : ''}</Td>
                                <Td>
                                  <input
                                    type='number'
                                    // defaultValue={procedure.qty}
                                    value={item.quantity}
                                    onChange={event =>
                                      handleMachineInputChange(
                                        event,
                                        item.id,
                                        'quantity'
                                      )
                                    }
                                    className='size-10'
                                  />
                                </Td>
                                {/* <Td>{procedure.purchasePrice}</Td> */}
                                <Td>
                                  <input
                                    type='number'
                                    value={item.perUsageQTY}
                                    onChange={event =>
                                      handleMachineInputChange(
                                        event,
                                        item.id,
                                        'perUsageQTY'
                                      )
                                    }
                                    className='size-10'
                                  />
                                </Td>
                                <Td>
                                  <RxCross2
                                    onClick={() => removeMac(item.id)}
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        )}
                      </Table>
                    </div>
                    {/* <div class='form-group'>
                      <label for='name'>Branch</label>
                      <select
                        class='custom-select border-info'
                        name='account_type_id'
                        onChange={e => handleBranch(e.target.value)}
                      >
                        <option hidden>Select Branch</option>

                        {branchList.map(option => (
                          <option value={option._id}>{option.name}</option>
                        ))}
                      </select>
                    </div> */}

                    {showHO && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={totalPrice}
                            onChange={e => setTotalPrice(e.target.value)}
                          />
                        </div>
                        <div className='col-2 form-group mt-3'>
                          <Label>Percent(%)</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={percent}
                            onChange={e => handlePercent(e.target.value)}
                          />
                        </div>
                        <div className='col-4 form-group mt-3'>
                          <Label>Selling Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={sellPrice}
                            onChange={e => setSellPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {/* 
                    {showPrice && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>TCL Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={TCLtotalPrice}
                            onChange={e => setTCLTotalPrice(e.target.value)}
                          />
                        </div>

                        <div className='col-4 form-group mt-3'>
                          <Label>TCL Selling Price</Label>
                          <div className='input-group'>
                            <Input
                              type='number'
                              className='form-control'
                              defaultValue={TCLsellPrice}
                              onChange={e => setTCLSellPrice(e.target.value)}
                            />
                            <div
                              className='input'
                              style={{ marginTop: '0.5em' }}
                            >
                              <span className='input-group-text'>THB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showEight && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>8Mile Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            value={eightTotalPrice}
                            onChange={e => setEightTotalPrice(e.target.value)}
                          />
                        </div>

                        <div className='col-4 form-group mt-3'>
                          <Label>8Mile Selling Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={eightSellPrice}
                            onChange={e => setEightSellPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    {showNPT && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>NPT Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={NptTotalPrice}
                            onChange={e => setNptTotalPrice(e.target.value)}
                          />
                        </div>

                        <div className='col-4 form-group mt-3'>
                          <Label>NPT Selling Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={NptSellPrice}
                            onChange={e => setNptSellPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {showMDY && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>MDY Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={MDYTotalPrice}
                            onChange={e => setMDYTotalPrice(e.target.value)}
                          />
                        </div>

                        <div className='col-4 form-group mt-3'>
                          <Label>MDY Selling Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={MDYSellPrice}
                            onChange={e => setMDYSellPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {showLSH && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>LSH Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={LSHTotalPrice}
                            onChange={e => setLSHTotalPrice(e.target.value)}
                          />
                        </div>

                        <div className='col-4 form-group mt-3'>
                          <Label>LSH Selling Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={LSHSellPrice}
                            onChange={e => setLSHSellPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {showK && (
                      <div className='row'>
                        <div className='col-4 form-group mt-3'>
                          <Label>K Total Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={KTotalPrice}
                            onChange={e => setKTotalPrice(e.target.value)}
                          />
                        </div>

                        <div className='col-4 form-group mt-3'>
                          <Label>K Selling Price</Label>
                          <Input
                            type='number'
                            className='form-control'
                            defaultValue={KSellPrice}
                            onChange={e => setKSellPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    )} */}

                    <div className='col-6 form-group mt-3'>
                      <Label>Description</Label>
                      <Textarea
                        className='form-control'
                        defaultValue={description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <Top>
                  <Center>
                    <Button onClick={update}>Update</Button>
                  </Center>
                </Top>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Update2

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { RxCross2 } from 'react-icons/rx'
import SideBar from '../../SideBar'
import Swal from 'sweetalert2'
import { FaArrowLeft } from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
const operator = [
    { value: 'Minus', label: 'Minus' },
    { value: 'Plus', label: 'Plus' }
]
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 30px;
`

const Center = styled.div`
  flex: 1;
  display: inline;
  text-align: center;
`

const Button = styled.button`
  background: rgb(0, 7, 51);
  color: white;
  justify-content: center;
  padding: 5px 30px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
`
const Label = styled.label``
const Input = styled.input`
  width: 100%;
`
const Span = styled.span`
  color: red;
`

const Table = styled.table``
const Thead = styled.thead``
const Tbody = styled.tbody``
const Tr = styled.tr`
  text-align: center;
`
const Th = styled.th`
  font-size: 15px;
`
const Td = styled.td`
  font-size: 14px;
`

const CreateNotes = () => {
    const token = localStorage.getItem('token')
    const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
    const api = axios.create({
        baseURL: url,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    const navigate = useNavigate()
    const [description, setDescription] = useState('')
    const [name, setName] = useState('')

    const [accountLists, setAccountLists] = useState('')
    const [fnoteAcc, setFNoteAcc] = useState(null)
    const [fOperator, setfOperator] = useState(null)
    const [fDataTable, setfDataTable] = useState([])
    const [typeValue, setTypeValue] = useState(null)
    const [snoteAcc, setSNoteAcc] = useState(null)
    const [sOperator, setsOperator] = useState(null)
    const [sDataTable, setsDataTable] = useState([])


    const animatedComponents = makeAnimated();

    //Medicine
    const [tableMedData, setTableMedData] = useState([])

    useEffect(() => {

        const getAccounts = async () => {
            const res = await api.get('accounting-lists')
            const filteredArray = res.data.list.map(item => { return ({ value: item._id, label: item.name }) })
            setAccountLists(filteredArray)
        }

        getAccounts()
    }, [])

    //First and Second Note 

    const handlesAccountSelect = async (data) => {
        setSNoteAcc(data)
    }

    const handlesOperatorSelect = async (data) => {
        setsOperator(data)
    }

    const handlesAddRow = async (relatedAccount, name, operator) => {
        console.log(relatedAccount, name, operator)
        setsDataTable([...sDataTable, { relatedAccount: relatedAccount, name: name, operator: operator }])
        console.log(sDataTable)
    }

    const removesRow = id => {
        setsDataTable(sDataTable.filter(el => el.relatedAccount != id))
    }


    const handlefAccountSelect = async (data) => {
        setFNoteAcc(data)
        console.log(fnoteAcc, 'fnoteAcc')
    }

    const handleFOperatorSelect = async (data) => {
        setfOperator(data)
    }

    const handleFAddRow = async (relatedAccount, name, operator) => {
        console.log(relatedAccount, name, operator)
        setfDataTable([...fDataTable, { relatedAccount: relatedAccount, name: name, operator: operator }])
        console.log(fDataTable)
    }

    const removeRow = id => {
        setfDataTable(fDataTable.filter(el => el.relatedAccount != id))
    }

    const create = () => {
        const data = {
            name: name,
            description: description,
            type: typeValue,
            // relatedDoctor: doctorname,
            item: fDataTable,
            secondaryItem: sDataTable,
        }
        // alert(JSON.stringify(data))

        api
            .post('note', data)
            .then(function (response) {
                Swal.fire({
                    title: 'Successful!',
                    text: 'Successfully Created',
                    icon: 'success',
                    // showCancelButton: true,

                    cancelButtonText: 'Close'
                }).then(() => {
                    navigate('/notes')
                })
            })
            .catch(function (err) {
                Swal.fire({
                    title: 'Something Wrong!',
                    text: 'Try again, Please.',
                    icon: 'warning',
                    // showCancelButton: true,

                    cancelButtonText: 'Close'
                })
            })
    }
    return (
        <div classNameName='App'>
            <div className='wrapper'>
                <HeaderName />

                {/* <!-- /.navbar --> */}

                {/* <!-- Main Sidebar Container --> */}
                <SideBar />

                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className='content-wrapper'>
                    {/* <!-- Content Header (Page header) --> */}
                    <div className='content-header'>
                        <div className='container-fluid'>
                            <div className='row mb-2'>
                                <div className='col-sm-12'>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'>
                                            <Link to={'/notes'}>
                                                <i>
                                                    <FaArrowLeft />
                                                </i>
                                            </Link>
                                        </li>
                                        <li className='breadcrumb-item active'>Create Notes</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Main content --> */}

                    <section className='content'>
                        <div className='container-fluid'>
                            <div className='card-body row border border-dark'>
                                <h5>Create Notes</h5>
                                <div className='offset-2 col-8 py-5'>
                                    <div className='row'>
                                        <div className='col-4 form-group '>
                                            <Label>
                                                Note Name<Span>*</Span>
                                            </Label>
                                            <Input
                                                type='text'
                                                className='form-control'
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-4 form-group'>
                                            <Label>
                                                Description<Span>*</Span>
                                            </Label>
                                            <Input
                                                type='text'
                                                className='form-control'
                                                onChange={e => setDescription(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-4 form-group'>
                                            <Label>
                                                Type<Span>*</Span>
                                            </Label>
                                            <select className='form-control form-select mt-2' onChange={e => setTypeValue(e.target.value)}>
                                                <option hidden  >Choose Type</option>
                                                <option value='income'>Income Statement</option>
                                                <option value='balance'>Balance Sheet</option>
                                            </select>
                                        </div>
                                        <div className='col-5 form-group mt-3'>
                                            <Label>First Note</Label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={accountLists}
                                                isSearchable
                                                isClearable
                                                name='Accounts'
                                                placeholder='Accounts'
                                                value={fnoteAcc}
                                                onChange={handlefAccountSelect}

                                            />

                                        </div>
                                        <div className='col-5 form-group mt-4'>
                                            <Label> </Label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={operator}
                                                isSearchable
                                                isClearable
                                                name='Operators'
                                                placeholder='Operators'
                                                value={fOperator}
                                                onChange={handleFOperatorSelect}
                                            />
                                        </div>
                                        <div className='col-2 mt-4'>
                                            <Button
                                                onClick={() => handleFAddRow(fnoteAcc.value, fnoteAcc.label, fOperator.value)}>
                                                Add
                                            </Button>
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <Table className='table table-striped'>
                                                <Thead>
                                                    <Tr>
                                                        <Th>No.</Th>
                                                        <Th>Account Name</Th>
                                                        <Th>Operator</Th>
                                                        <Th></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {console.log(tableMedData, 'TABLEMED')}
                                                    {fDataTable.map((item, index) => (
                                                        <Tr>
                                                            <Td>{++index}</Td>
                                                            <Td>{item.name ? item.name : item.relatedAccount ? item.relatedAccount.name : 'Not Set'}</Td>
                                                            <Td>
                                                                {item.operator ? item.operator : 'Not Set'}
                                                            </Td>
                                                            {/* <Td>{procedure.purchasePrice}</Td> */}
                                                            <Td>
                                                                <RxCross2
                                                                    onClick={() => removeRow(item.relatedAccount)}
                                                                />
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </div>

                                        {/* NOTE Procedure Medicine */}
                                        <div className='col-5 form-group mt-3'>
                                            <Label>Second Notes</Label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={accountLists}
                                                isSearchable
                                                isClearable
                                                name='Accounts'
                                                placeholder='Accounts'
                                                value={snoteAcc}
                                                onChange={handlesAccountSelect}
                                            />
                                        </div>

                                        <div className='col-5 form-group mt-4'>
                                            <Label></Label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={operator}
                                                isSearchable
                                                isClearable
                                                name='Operators'
                                                placeholder='Operators'
                                                value={sOperator}
                                                onChange={handlesOperatorSelect}
                                            />
                                        </div>
                                        <div className='col-2 mt-4'>
                                            <Button
                                                onClick={() => handlesAddRow(snoteAcc.value, snoteAcc.label, sOperator.value)}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                        <div className='col-12 mt-4'>
                                            <Table className='table table-striped'>
                                                <Thead>
                                                    <Tr>
                                                        <Th>No.</Th>
                                                        <Th>Account Name</Th>
                                                        <Th>Operator</Th>
                                                        <Th></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>

                                                    {sDataTable.map((item, index) => (
                                                        <Tr>
                                                            <Td>{++index}</Td>
                                                            <Td>{item.name ? item.name : item.relatedAccount ? item.relatedAccount.name : 'Not Set'}</Td>
                                                            <Td>
                                                                {item.operator ? item.operator : 'Not Set'}
                                                            </Td>
                                                            <Td>
                                                                <RxCross2
                                                                    onClick={() => removesRow(item.relatedAccount)}
                                                                />
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                                <Top>
                                    <Center>
                                        <Button onClick={create}>Save</Button>
                                    </Center>
                                </Top>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
            <footer className='main-footer' style={{ marginTop: '50px;' }}>
                <strong>
                    Copyright &copy; 2017-2020{' '}
                    <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
                </strong>
                All rights reserved.
            </footer>

            {/* <!-- Control Sidebar --> */}
            <aside className='control-sidebar control-sidebar-dark'>
                {/* <!-- Control sidebar content goes here --> */}
            </aside>

            {/* <!-- ./wrapper --> */}
        </div>
    )
}

export default CreateNotes

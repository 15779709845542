import MedicineSaleDialog from './MedicineSaleDialog'
import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import SideBar from './SideBar'
import Swal from 'sweetalert2'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { FaCashRegister, FaFileMedical, FaFileExcel } from 'react-icons/fa'
import ImageDialog from './Admin/Treatment Sale/ImageDialog'
// import { translateRect } from '../../pluginsfullcalendar/main.d';
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
import { Button } from '@mantine/core'
import { convertAndDisplayTZ } from '../../utils/momentTime.js'
import apiInstance from '../../utils/api.js'
function TreatementSale () {


  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt
          ? element.createdAt.split('T')[0]
          : ''
      })
      _export.current.save()
    }
  }

  const excelExport1 = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt
          ? element.createdAt.split('T')[0]
          : ''
      })
      _export.current.save()
    }
  }

  const timeElapsed = Date.now()
  const today = new Date(timeElapsed)
  const today_date = today.toISOString().split('T')[0]
  console.log(today_date)

  const [todayList, setTodayList] = useState([])
  const [startDate, setStartDate] = useState(today)
  const [endDate, setEndDate] = useState(today)
  const [payFilterList, setPayFilterList] = useState([])
  const [show, setShow] = useState(false)
  const [users, setUsers] = useState([])
  const [bankID, setBankID] = useState([])
  const [bankList, setBankList] = useState([])
  const [userID, setUserID] = useState('')
  const [open, setOpen] = useState('')
  const [close, setClose] = useState('')
  const [value, setValue] = useState('')
  const [imgList, setImgList] = useState([])
  const [purchaseType, setPurchaseType] = useState('')
  const [doctorID, setDoctorID] = useState('')
  const [type, setType] = useState('')
  const [bankTotal, setBankTotal] = useState(0)
  const [cashTotal, setCashTotal] = useState(0)

  const [bankIncomeList, setBankIncomeList] = useState([])
  const [cashIncomeList, setCashIncomeList] = useState([])
  const [bankNameList, setBankNameList] = useState([])
  const [cashNameList, setCashNameList] = useState([])
  const [bcshow, setBCShow] = useState('bankTable')
  const [cashDoctorList, setCashDoctorList] = useState([])
  const [filterCashDoctorList, setFilterCashDoctorList] = useState([])
  const [showDocCash, setShowDocCash] = useState(false)
  const [bankDoctorList, setBankDoctorList] = useState([])
  const [filterBankDoctorList, setFilterBankDoctorList] = useState([])
  const [showDocBank, setShowDocBank] = useState(false)
  const [doctors, setDoctors] = useState([])
  const [oriList, setOriList] = useState([])
  const [POSTotal, setPOSTotal] = useState('')
  const [normalTotal, setNormalTotal] = useState('')
  const [payTotal, setPayTotal] = useState('')
  const [bankType, setBankType] = useState('')

  // const paymentFilter = e => {
  //   const filteredArray = todayList.filter(
  //     obj => obj.paymentMethod === e.target.value
  //   )
  //   setPayFilterList(filteredArray)
  //   setShow(true)
  // }

  const showDialog = val => {
    const imgPath = imgList.filter(item => item._id === val)
    console.log(imgPath, 'imgPath')
    setValue(imgPath[0].payment ? imgPath[0].payment.imgUrl : '')
    setOpen(true)
  }

  const handleCashDoctor = event => {
    setFilterCashDoctorList(cashDoctorList.filter(el => el._id == event))
    console.log(
      cashDoctorList.filter(el => el._id == event),
      'doc fil l'
    )
    setShowDocCash(true)
    setShowDocBank(false)
  }
  const handleBankDoctor = event => {
    setFilterBankDoctorList(bankDoctorList.filter(el => el._id == event))
    console.log(
      cashDoctorList.filter(el => el._id == event),
      'doc fil l'
    )
    setShowDocBank(true)
    setShowDocCash(false)
  }

  const handleAllFilter = async () => {
    // if (fromDate) {
    console.log(userID, 'user id')
    console.log(userID, 'user id')

    const nDate = new Date(endDate)
    const toDate = nDate.setDate(nDate.getDate() + 1)

    try {
      Swal.fire({
        title: 'Loading',
        text: 'Please wait...',
        allowOutsideClick: false
      })
      let data = {
        tsType: 'MS'
      }

      if (bankID) {
        data.bankID = bankID
      }
      if (bankType) {
        data.bankType = bankType
      }
      if (startDate && toDate) {
        data.startDate = startDate
        data.endDate = toDate
      }
      if (userID) {
        data.createdBy = userID
      }
      if (doctorID) {
        data.relatedDoctor = doctorID
      }

      await apiInstance
        .get(`treatment-vouchers/TV-Filter`, { params: data })
        .then(res => {
          // setAccountLists(res.data.data)
          // setTransactionLists(res.data.transaction)
          // console.log(res.data.data, 'main lis')
          setOriList(res.data.data)
          if (res.data.data.CashList) {
            setCashTotal(res.data.data.CashTotal)

            setCashIncomeList(
              res.data.data.CashList.filter(
                el => el.tsType === 'MS' || el.tsType === 'Combined'
              )
            )
          }
          if (res.data.data.BankList) {
            setBankTotal(res.data.data.BankTotal)

            setBankIncomeList(
              res.data.data.BankList.filter(
                el => el.tsType === 'MS' || el.tsType === 'Combined'
              )
            )
          }

          setBankNameList(res.data.data.BankNames)
          setCashNameList(res.data.data.CashNames)

          // console.log(res.data.data.CashNames, 'c NAME')
        })
        .catch(error => {
          console.log('error', error)
        })
      Swal.close()

      // Process the response data
      //console.log(data);
    } catch (error) {
      Swal.close()
      console.error('Error occurred while fetching data.', error)
    }
  }
  const filterBankType = async value => {
    setBankType(value)
    const bankFiltered = bankIncomeList.filter(item => item.bankType === value)
    const cashFiltered = cashIncomeList.filter(item => item.bankType === value)
    setBankIncomeList(bankFiltered)
    setCashIncomeList(cashFiltered)
  }
  useEffect(() => {
    const getTreatmentVoucher = async () => {
      const res = await apiInstance.get('treatment-vouchers').then(res => {
        // setTodayList(res.data.list)
        console.log(res.data.list, 'data')
      })

      // .catch(error => {
      //   Swal.fire({
      //     title: 'Error',
      //     text: error.response.data.message,
      //     icon: 'error',
      //     confirmButtonText: 'CANCEL'
      //   })
      // })
    }

    const getUsers = async () => {
      apiInstance.get('users').then(res => {
        setUsers(res.data.list)
      })
    }

    const getDoctors = async () => {
      apiInstance.get('doctors').then(res => {
        setDoctors(res.data.data)
      })
    }

    const getImageList = async () => {
      await apiInstance.get('treatment-vouchers').then(res => {
        setImgList(res.data.list)
        // console.log(res.data.list, 'img List')
        // console.log(res.data.list, 'img url')
      })
    }
    const getBankList = async () => {
      await apiInstance.get('accounting-lists').then(res => {
        setBankList(
          res.data.list.filter(
            el =>
              el.relatedType.name == 'Assets' &&
              el.relatedHeader.name == 'Cash at Bank'
          )
        )
        console.log(
          res.data.list.filter(
            el =>
              el.relatedType.name == 'Assets' &&
              el.relatedHeader.name == 'Cash at Bank'
          ),
          'bank list'
        )
        // console.log(res.data.list, 'img url')
      })
    }
    getBankList()
    getDoctors()
    getUsers()
    getImageList()
    // getBranches()
    getUsers()
    getTreatmentVoucher()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='offset-4 col-5'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active text-bold fs-5'>
                      Medicine Sale List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className='row ml-2'>
            <div className='form-group col-md-2'>
              <label>From</label>
              <input
                type='date'
                name='from'
                id='from'
                className='form-control'
                //onChange={e => setStart(e.target.value)}
                onChange={e => setStartDate(e.target.value)}
              />
            </div>
            <div className='form-group col-md-2'>
              <label>To</label>
              <input
                type='date'
                name='to'
                id='to'
                className='form-control'
                // onChange={e => handleDateFilter(e.target.value)}
                onChange={e => setEndDate(e.target.value)}
              />
            </div>

            <div className='form-group col-md-2' style={{ marginTop: '2.6em' }}>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={e => setUserID(e.target.value)}
              >
                <option hidden>Issued By</option>
                {users.map((e, i) => (
                  <option value={e._id}>{e.givenName}</option>
                ))}
              </select>
            </div>

            {/* <div className='col-1'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={e => setDoctorID(e.target.value)}
                style={{ marginTop: '2.2em' }}
              >
                <option hidden>Doctor</option>
                {doctors.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div> */}
            <div className='col-2'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={e => setBankID(e.target.value)}
                style={{ marginTop: '2.2em' }}
              >
                <option hidden>Select Bank</option>
                {/* <option value='TS'>Treatment Selection</option>
                <option value='TSM'>Multiple Treatment Selection</option>
                <option value='MS'>Medicine Sale</option>
                <option value='Combined'>Combined</option> */}
                {bankList.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div className='col-md-2'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={e => setBankType(e.target.value)}
                style={{ marginTop: '2.2em' }}
              >
                <option hidden>Choose Bank </option>
                <option value='POS'>POS</option>
                <option value='Normal'>Normal</option>
                <option value='Pay'>Pay</option>
              </select>
            </div>

            <div className='form-group col-md-2'>
              <button
                className='btn btn-sm btn-primary'
                style={{ marginTop: '38px' }}
                onClick={handleAllFilter}
              >
                Filter
              </button>
            </div>
          </div>
          {/* <!-- Main content --> */}
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex justify-content-between'>
                    {/* <div className='row'>
                      {bcshow == 'bankTable' ? (
                        <div className='col-12'>
                          <select
                            class='custom-select border-info'
                            name='account_type_id'
                            onChange={e => handleBankDoctor(e.target.value)}
                          >
                            <option hidden>Filter By Doctor</option>
                            {bankDoctorList.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className='col-12'>
                          <select
                            class='custom-select border-info'
                            name='account_type_id'
                            onChange={e => handleCashDoctor(e.target.value)}
                          >
                            <option hidden>Filter By Doctor</option>
                            {cashDoctorList.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div> */}

                    <div className='d-flex col-4 offset-4 '>
                      <div className='' style={{ marginRight: '5px' }}>
                        <Button
                          color='cyan'
                          radius='sm'
                          size='sm'
                          onClick={() => setBCShow('bankTable')}
                        >
                          Bank({bankTotal} MMK)
                        </Button>
                      </div>

                      {bankType ? (
                        <div className='invisible'>
                          <Button
                            color='cyan'
                            radius='sm'
                            size='sm'
                            onClick={() => setBCShow('cashTable')}
                          >
                            Cash({cashTotal} MMK)
                          </Button>
                        </div>
                      ) : (
                        <div className=''>
                          <Button
                            color='cyan'
                            radius='sm'
                            size='sm'
                            onClick={() => setBCShow('cashTable')}
                          >
                            Cash({cashTotal} MMK)
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className='col-2'>
                      {bcshow == 'bankTable' ? (
                        <button
                          type='button'
                          className='btn btn-sm btn-success'
                          onClick={excelExport}
                        >
                          <FaFileExcel />
                          &nbsp;Export
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-sm btn-success'
                          onClick={excelExport1}
                        >
                          <FaFileExcel />
                          &nbsp;Export
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  {/* Export data in Excel */}
                  {bcshow == 'bankTable' ? (
                    <ExcelExport data={bankIncomeList} ref={_export}>
                      <ExcelExportColumn
                        field=''
                        title='No'
                        locked={true}
                        width={30}
                      />
                      <ExcelExportColumn
                        field='createdAt
'
                        title='Date'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={200}
                      />
                      <ExcelExportColumn
                        field='code
'
                        title='Voucher No'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={250}
                      />
                      <ExcelExportColumn
                        field='relatedPatient.name
'
                        title='Related Patient'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={250}
                      />
                      <ExcelExportColumn
                        field='relatedTreatmentSelection.relatedAppointments[0].relatedDoctor.name
'
                        title='Doctor'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={250}
                      />

                      <ExcelExportColumn
                        field='tsType
'
                        title='Type'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={150}
                      />

                      <ExcelExportColumn
                        field='paymentMethod'
                        title='Payment Method'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={150}
                      />
                      <ExcelExportColumn
                        field='msGrandTotal'
                        title='Grand Total'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={150}
                      />
                    </ExcelExport>
                  ) : (
                    <ExcelExport data={cashIncomeList} ref={_export}>
                      <ExcelExportColumn
                        field=''
                        title='No'
                        locked={true}
                        width={30}
                      />
                      <ExcelExportColumn
                        field='createdAt
'
                        title='Date'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={200}
                      />
                      <ExcelExportColumn
                        field='code
'
                        title='Voucher No'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={250}
                      />
                      <ExcelExportColumn
                        field='relatedPatient.name
'
                        title='Related Patient'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={250}
                      />
                      <ExcelExportColumn
                        field='relatedTreatmentSelection.relatedAppointments[0].relatedDoctor.name
'
                        title='Doctor'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={250}
                      />

                      <ExcelExportColumn
                        field='relatedTreatmentSelection.purchaseType

'
                        title='Type'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={150}
                      />

                      <ExcelExportColumn
                        field='paymentMethod'
                        title='Payment Method'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={150}
                      />
                      <ExcelExportColumn
                        field='msGrandTotal
'
                        title='Grand Total'
                        headerCellOptions={{
                          textAlign: 'center'
                        }}
                        width={150}
                      />
                    </ExcelExport>
                  )}

                  {/* Export Data in Excel end */}
                  <table id='example1' className='table'>
                    <thead className=' ok'>
                      <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Voucher No</th>
                        {/* <th>Related Treatment</th> */}
                        <th>Patient</th>
                        <th>Doctor</th>
                        <th>Type</th>

                        <th>Payment Method</th>
                        <th>Grand Total</th>
                        <th>Paid Amount</th>
                        <th>Balance</th>
                        <th>User</th>
                        <th>
                          {bcshow === 'bankTable' ? 'Bank Name' : 'Cash Name'}
                        </th>
                        <th>{bcshow === 'bankTable' && 'Bank Type'}</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>

                    {bcshow === 'bankTable'
                      ? bankIncomeList.map((tSale, i) => (
                          <tbody key={tSale._id}>
                            <tr>
                              <td>{++i}</td>
                              <td>
                                {tSale.createdAt
                                  ? convertAndDisplayTZ(tSale.createdAt)
                                  : ''}
                              </td>
                              <td>{tSale.code ? tSale.code : ''}</td>
                              <td>
                                {tSale.relatedPatient
                                  ? tSale.relatedPatient.name
                                  : ''}
                              </td>
                              <td>
                                {tSale.relatedTreatmentSelection
                                  .relatedAppointments
                                  ? tSale.relatedTreatmentSelection
                                      .relatedAppointments[0].relatedDoctor.name
                                  : '-'}
                              </td>
                              <td>
                                {tSale.tsType === 'MS' ? 'Medicine Sale' : ''}
                                {/* {tSale.msGrandTotal ? tSale.msGrandTotal : ''} */}
                              </td>
                              <td>
                                {tSale.paymentMethod
                                  ? tSale.paymentMethod
                                  : '-'}
                              </td>

                              <td>{tSale?.msTotalAmount}</td>
                              <td>
                                {!tSale.msPaidAmount ? 0 : tSale.msPaidAmount}
                              </td>
                              <td>
                                {tSale.msTotalAmount === tSale?.msPaidAmount
                                  ? 0
                                  : !tSale?.msPaidAmount
                                  ? 0
                                  : tSale.msTotalAmount - tSale?.msPaidAmount}
                              </td>

                              <td>
                                {tSale.createdBy
                                  ? tSale.createdBy.givenName
                                  : ''}
                              </td>
                              <td>
                                {tSale.relatedBank
                                  ? tSale.relatedBank.name
                                  : ''}
                              </td>
                              <td>{tSale.bankType}</td>
                              <td className='text-center'>
                                <button
                                  class='btn btn-primary btn-sm'
                                  onClick={() => showDialog(tSale._id)}
                                >
                                  Image
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))
                      : cashIncomeList.map((tSale, i) => (
                          <tbody key={tSale._id}>
                            <tr>
                              <td>{++i}</td>
                              <td>
                                {tSale.createdAt
                                  ? convertAndDisplayTZ(tSale.createdAt)
                                  : ''}
                              </td>
                              <td>{tSale.code ? tSale.code : ''}</td>
                              <td>
                                {tSale.relatedPatient
                                  ? tSale.relatedPatient.name
                                  : ''}
                              </td>
                              <td>
                                {tSale.relatedTreatmentSelection
                                  .relatedAppointments
                                  ? tSale.relatedTreatmentSelection
                                      .relatedAppointments[0].relatedDoctor.name
                                  : '-'}
                              </td>
                              <td>
                                {tSale.tsType === 'MS' ? 'Medicine Sale' : ''}
                                {/* {tSale.msGrandTotal ? tSale.msGrandTotal : ''} */}
                              </td>
                              <td>
                                {tSale.paymentMethod
                                  ? tSale.paymentMethod
                                  : '-'}
                              </td>

                              <td>{tSale?.msTotalAmount}</td>
                              <td>
                                {!tSale.msPaidAmount ? 0 : tSale.msPaidAmount}
                              </td>
                              <td>
                                {tSale.msTotalAmount === tSale?.msPaidAmount
                                  ? 0
                                  : tSale.msTotalAmount - tSale?.msPaidAmount}
                              </td>

                              <td>
                                {tSale.createdBy
                                  ? tSale.createdBy.givenName
                                  : ''}
                              </td>
                              <td>
                                {tSale.relatedCash
                                  ? tSale.relatedCash.name
                                  : ''}
                              </td>

                              <td></td>
                              <td className='text-center'>
                                <button
                                  class='btn btn-primary btn-sm'
                                  onClick={() => showDialog(tSale._id)}
                                >
                                  Image
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    {/* {oriList.map((tSale, i) => (
                      <tbody key={tSale._id}>
                        <tr>
                          <td>{++i}</td>
                          <td>
                            {tSale.createdAt
                              ? tSale.createdAt.split('T')[0]
                              : ''}
                          </td>
                          <td>{tSale.code ? tSale.code : ''}</td>
                          <td>
                            {tSale.relatedPatient
                              ? tSale.relatedPatient.name
                              : ''}
                          </td>
                          <td>
                            {tSale.relatedTreatmentSelection.relatedAppointments
                              ? tSale.relatedTreatmentSelection
                                  .relatedAppointments[0].relatedDoctor.name
                              : ''}
                          </td>
                          <td>
                            {tSale.relatedTreatmentSelection
                              ? tSale.relatedTreatmentSelection.purchaseType
                              : ''}
                          </td>
                          <td>
                            {tSale.paymentMethod ? tSale.paymentMethod : ''}
                          </td>

                          <td>{tSale.amount ? tSale.amount : ''}</td>

                          <td>
                            {tSale.createdBy ? tSale.createdBy.givenName : ''}
                          </td>
                          <td>{tSale.bankType}</td>
                          <td className='text-center'>
                            <button
                              class='btn btn-primary btn-sm'
                              onClick={() => showDialog(tSale._id)}
                            >
                              Image
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))} */}
                  </table>
                </div>

                <div className='col-8 d-flex justify-content-between'>
                  <div style={{ marginRight: '100px', marginLeft: '50px' }}>
                    <p>
                      <b>
                        <u>Bank List</u>
                      </b>
                    </p>
                    {/* {Object.entries(bankNameList).map(([k, v]) => (
                      <p>
                        {k} : {v}{' '}
                      </p>
                    ))} */}
                    {bankType && (
                      <>
                        <p>
                          {bankType == 'POS' ? (
                            <p>POS-MMK : {bankTotal}</p>
                          ) : (
                            <p>POS-MMK : 0</p>
                          )}
                        </p>
                        <p>
                          {bankType == 'Normal' ? (
                            <p>Normal-MMK : {bankTotal}</p>
                          ) : (
                            <p>Normal-MMK : 0</p>
                          )}
                        </p>
                        <p>
                          {bankType == 'Pay' ? (
                            <p>Pay-MMK : {bankTotal}</p>
                          ) : (
                            <p>Pay-MMK : 0</p>
                          )}
                        </p>
                      </>
                    )}
                  </div>

                  {bankType ? (
                    <div className='invisible'>
                      <p>
                        <b>
                          <u>Cash List</u>
                        </b>
                      </p>

                      {cashNameList
                        ? Object.entries(cashNameList).map(([k, v]) => (
                            <p>
                              {k} : {v}{' '}
                            </p>
                          ))
                        : ''}
                    </div>
                  ) : (
                    <div>
                      <p>
                        <b>
                          <u>Cash List</u>
                        </b>
                      </p>

                      {cashNameList
                        ? Object.entries(cashNameList).map(([k, v]) => (
                            <p>
                              {k} : {v}{' '}
                            </p>
                          ))
                        : ''}
                    </div>
                  )}

                  {/* <div>
                    <p>
                      <b>
                        <u>
                          {POSTotal ? 'POS Total' : ''}
                          {normalTotal ? 'Normal Total' : ''}
                          {payTotal ? 'Pay Total' : ''}
                        </u>
                      </b>
                    </p>

                   
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <ImageDialog open={open} close={() => setOpen(false)} value={value} />
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default TreatementSale

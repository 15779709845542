import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import {
    FaCashRegister,
    FaFileMedical,
    FaRegEdit,
    FaRegTrashAlt
} from 'react-icons/fa'

import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import { Input } from '@nextui-org/react'
import apiInstance from '../../../../utils/api'
export default function Damage() {
    // const token = localStorage.getItem('token')
    // const url = 'http://hlaasetheticbackend.kwintechnologies.com:4000/api/'
    // const api = axios.create({
    //     baseURL: url,
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //         'Content-Type': 'application/json'
    //     }
    // })

    const RelatedMedID = useLocation().pathname.split('/')[2]
    console.log(useLocation().state?.medData, 'med damre')
    const Data = useLocation().state?.data
    const MedData = useLocation().state?.medData
    console.log(Data, 'Data')
    const TotalUnit =
        (Data?.qty * Data.relatedMedicineItems?.toUnit) /
        Data.relatedMedicineItems?.fromUnit

    const ProTotal =
        (Data?.qty * Data.relatedProcedureItems?.toUnit) /
        Data.relatedProcedureItems?.fromUnit

    const AccTotal =
        (Data?.qty * Data.relatedAccessoryItems?.toUnit) /
        Data.relatedAccessoryItems?.fromUnit

    const [damageTotalUnit, setDamageTotalUnit] = useState('')
    const [damageQty, setDamageQty] = useState('')
    const [reason, setReason] = useState('')
    const [damageDate, setDamageDate] = useState('')

    const handleError = () => {
        Swal.fire({
            title: 'Please Check !',
            text: 'Your Damage Total Unit or Qty is greater than current!',
            icon: 'warning',
            // showCancelButton: true,

            showCancelButton: false,
            timer: 2000
        })
    }

    const handleUnit = val => {
        setDamageTotalUnit(val)
        if (MedData) {
            const CalProQty =
                (val * MedData?.fromUnit) /
                MedData?.toUnit
            setDamageQty(CalProQty)
        }
    }
    const CategoryCreate = () => {
        console.log(damageQty <= Data.qty, 'qqq')
        if (damageTotalUnit > TotalUnit || damageQty > Data.currentQuantity) {
            handleError()
        } else {
            const data = {
                purchaseHistoryId: Data._id,
                name: Data.name,
                damageDate: damageDate,
                // damageTotalUnit: damageTotalUnit,
                damageCurrentQty: damageQty,
                remark: reason,
            }
            if (MedData.medicineItemName) {
                data.relatedMedicineItem = MedData._id
            }

            if (MedData.procedureItemName) {
                data.relatedProcedureItem = MedData._id
            }

            if (MedData.accessoryItemName) {
                data.relatedAccessoryItem = MedData._id
            }

            //   if (damageQty <= data.qty) {
            //     data.damagedCurrentQty = damageQty
            //   }
            // alert(JSON.stringify(data))

            const config = {
                headers: { 'Content-Type': 'application/json' }
            }

            apiInstance
                .post('items/damage', data, config)
                .then(function (response) {
                    Swal.fire({
                        title: 'Successful!',
                        text: 'You Created Damage Data!',
                        icon: 'success',
                        // showCancelButton: true,

                        showCancelButton: false,
                        timer: 2000
                    })
                })
                .catch(function (err) {
                    alert(err.message)
                })
        }
    }
    return (
        <div classNameName='App'>
            {/* <!-- end preloader --> */}
            {/* @include('sweet::alert') */}

            <div className='wrapper'>
                {/* <!-- Navbar --> */}
                <HeaderName />

                {/* <!-- /.navbar --> */}

                {/* <!-- Main Sidebar Container --> */}
                <SideBar />

                {/* <!-- Content Wrapper. Contains page content --> */}

                <div className='content-wrapper'>
                    {/* <!-- Content Header (Page header) --> */}
                    <div className='content-header'>
                        <div className='container-fluid'>
                            <div className='row mb-2'>
                                <div className='col-sm-12'>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'>
                                            <Link to='/stock'>Back</Link>
                                        </li>
                                        <li className='breadcrumb-item active'>Damage</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Main content --> */}

                    <section className='content'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-10 offset-1'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h3 className='text-center py-3 '>Damage Items Create</h3>
                                        </div>
                                        <div className='card-body'>
                                            <div className='col-12'>
                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Purchase Date
                                                        </label>
                                                        <input
                                                            type='date'
                                                            id='code'
                                                            class='form-control border-info'
                                                            name='code'
                                                            value={Data.purchaseDate?.split('T')[0]}
                                                        //   ref={(el) => (this.name = el)}
                                                        />
                                                    </div>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Expired Date
                                                        </label>
                                                        <input
                                                            type='date'
                                                            id='code'
                                                            class='form-control border-info'
                                                            name='code'
                                                            //   ref={(el) => (this.name = el)}
                                                            value={Data.expiredDate?.split('T')[0]}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Name
                                                        </label>
                                                        <input
                                                            type='text'
                                                            id='code'
                                                            class='form-control border-info'
                                                            name='code'
                                                            //   ref={(el) => (this.name = el)}
                                                            value={
                                                                Data.name
                                                            }
                                                        />
                                                    </div>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Qty
                                                        </label>
                                                        <input
                                                            type='number'
                                                            id='code'
                                                            class='form-control border-info'
                                                            name='code'
                                                            //   ref={(el) => (this.name = el)}
                                                            value={Data?.currentQuantity}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            From Unit
                                                        </label>
                                                        <input
                                                            type='number'
                                                            class='form-control border-info'
                                                            //   ref={(el) => (this.name = el)}
                                                            value={
                                                                MedData?.fromUnit
                                                            }
                                                        />
                                                    </div>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            To Unit
                                                        </label>
                                                        <input
                                                            type='number'
                                                            value={
                                                                MedData?.toUnit
                                                            }
                                                            class='form-control border-info'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Total Unit
                                                        </label>
                                                        <input
                                                            type='number'
                                                            class='form-control border-info'
                                                            value={Data?.totalUnit}
                                                        />
                                                    </div>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Purchase Price
                                                        </label>
                                                        <input
                                                            type='number'
                                                            class='form-control border-info'
                                                            value={Data?.purchasePrice}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Selling Price
                                                        </label>
                                                        <input
                                                            type='number'
                                                            class='form-control border-info'
                                                            value={Data?.sellingPrice}
                                                        />
                                                    </div>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Unit
                                                        </label>
                                                        <input
                                                            type='text'
                                                            class='form-control border-info'
                                                            value={
                                                                Data.unit ? Data.unit
                                                                    : 'Not Set'
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Damage Total Unit
                                                        </label>
                                                        <input
                                                            type='number'
                                                            class='form-control border-info'
                                                            onChange={e => handleUnit(e.target.value)}
                                                        />
                                                    </div>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Damage Current Quantity
                                                        </label>
                                                        <input
                                                            type='number'
                                                            value={damageQty}
                                                            class='form-control border-info'
                                                            onChange={e => setDamageQty(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row px-3 py-1'>
                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Damage Date
                                                        </label>
                                                        <input
                                                            type='date'
                                                            class='form-control border-info'
                                                            onChange={e => setDamageDate(e.target.value)}
                                                        />
                                                    </div>

                                                    <div class='col-6 form-group'>
                                                        <label for='code' className='text-secondary'>
                                                            Reason
                                                        </label>
                                                        <input
                                                            type='text'
                                                            class='form-control border-info'
                                                            onChange={e => setReason(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-center p-2'>
                                                    <div>
                                                        <button
                                                            className='btn btn-success'
                                                            id='submit'
                                                            onClick={CategoryCreate}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                    &nbsp;
                                                    <div>
                                                        <Link to='/stock' className='btn btn-warning'>
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- /.container-fluid --> */}
                    </section>
                </div>
            </div>

            {/* <!-- /.content-wrapper --> */}
            <footer className='main-footer mt-5'>
                <strong>
                    Copyright &copy; 2017-2020{' '}
                    <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
                </strong>
                All rights reserved.
            </footer>

            {/* <!-- Control Sidebar --> */}
            {/* <aside classNameName="control-sidebar control-sidebar-dark">
       
      </aside> */}
            {/* <!-- /.control-sidebar --> */}

            {/* <!-- ./wrapper --> */}
        </div>
    )
}

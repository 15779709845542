/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import Doctor from "../../doctors.svg";
import ReJuVe from "../../assets/img/SkinLogo.png";
import apiInstance from "../../utils/api";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Provider } from "react-redux";

import { loginSuccess, addUser } from "../../redux/authRedux";
import Swal from "sweetalert2";
import { MouseEvent } from "react";

// const uri = 'http://localhost:9000/api/'

const Login = () => {
  // const url = 'http://backend.dr-rejuve.com:8080/api/'

  const [showPassword, setShowPassword] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowEmail = () => setShowEmail((show) => !show);

  const handleMouseDownEmail = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const Login = (e) => {
    e.preventDefault();
    console.log(password, "password");
    const data = {
      email: email,
      password: password,
    };

    // const api = axios.create({
    //   baseURL: apiInstance,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/json'
    //   }
    // })

    apiInstance
      .post("auth/login", data)
      .then(function (response) {
        console.log(response.data, "kkk");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("id", response.data.id);
        navigate("/category");
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome back!",
          showConfirmButton: false,
          timer: 3000,
        });

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Something Wrong!",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
      });
  };
  return (
    <div className='row' style={{ fontFamily: "serif" }}>
      <div className='col-7' style={{ marginTop: "80px" }}>
        <img src={Doctor} alt='' width='950px' height='600px' />
      </div>
      <div className='col-4 '>
        <div style={{ marginTop: "80px" }}>
          <img src={ReJuVe} alt='' width='600px' height='300px' />

          <span style={{ fontSize: "18px", marginLeft: "100px" }}>
            Hello, Greeting! Please Sign In to Your Account!
          </span>
          <form onSubmit={Login}>
            <div className='mt-3' style={{ marginLeft: "100px" }}>
              <FormControl sx={{ m: 1, width: "55ch" }} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-email'>
                  Email
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-email'
                  type={showEmail ? "text" : "email"}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle email visibility'
                        onClick={handleClickShowEmail}
                        onMouseDown={handleMouseDownEmail}
                        edge='end'
                      >
                        {showEmail ? <EmailIcon /> : <EmailIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Email'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </div>
            <div className='mt-2' style={{ marginLeft: "100px" }}>
              <FormControl sx={{ m: 1, width: "55ch" }} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  Password
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </div>
            <Button
              type='submit'
              variant='contained'
              sx={{
                m: 1,
                width: "45.5ch",
                marginLeft: "105px",
                marginTop: "20px",
              }}
              className='form-control'
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from 'react'

import { Link, useLocation } from 'react-router-dom'
import { FaArrowLeft, FaMinus } from 'react-icons/fa'
import Sidebar from '../../SideBar.js'

import axios from 'axios'
import { useEffect, useState } from 'react'

import Swal from 'sweetalert2'
import HeaderName from '../../HeaderName'

function LabServiceRegister () {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const PackageID = useLocation().pathname.split('/')[2]
  const [treatmentLists, setTreatmentLists] = useState([])
  const [unitArr, setUnitArr] = useState([])
  const [unitLists, setUnitLists] = useState([])
  const [showUnitLists, setShowUnitLists] = useState([])
  const [name, setName] = useState('')
  const [cost, setCost] = useState('')
  const [charges, setCharges] = useState('')
  const [status, setStatus] = useState('')
  const [packageLists, setPackageLists] = useState([])
  const [description, setDescription] = useState('')
  const [tempPackage, setTempPackage] = useState('')
  const [showUnit, setShowUnit] = useState(false)
  const [treatmentUnit, setTreatmentUnit] = useState('')
  const [upUnitLists, setUpUnitLists] = useState([])
  const [totalPrice, setTotalPrice] = useState('')
  const [totalCost, setTotalCost] = useState('')
  const [upTotalPrice, setUpTotalPrice] = useState('')
  const [upTotalCost, setUpTotalCost] = useState('')
  const [finalTotalPrice, setFinalTotalPrice] = useState('')

  var sumCost = 0
  var sumPrice = 0

  var sumUpCost = 0
  var sumUpPrice = 0

  const [price, setPrice] = useState('')
  const [Id, setId] = useState('')

  const handleAdd = Id => {
    setId(Id)
  }
  const handleBox = event => {
    let newPackage = {
      id: event,
      name: showUnitLists.filter(el => el._id == event)[0]
        ? showUnitLists.filter(el => el._id == event)[0].name
        : '',
      price: showUnitLists.filter(el => el._id == event)[0]
        ? showUnitLists.filter(el => el._id == event)[0].estimateTotalPrice
        : '',
      sellingPrice: showUnitLists.filter(el => el._id == event)[0]
        ? showUnitLists.filter(el => el._id == event)[0].sellingPrice
        : ''
    }

    setUnitArr([...unitArr, newPackage])

    //By Reduce
    // let tempMap = [...unitArr, newPackage]
    // const filteredTotal = tempMap.reduce(
    //   (accumulator, item) => accumulator + item.price,
    //   0
    // )
    // setTotalPrice(filteredTotal)

    //By Simple

    let tempCostArr = [...unitArr, newPackage]
    tempCostArr.map((i, index) => {
      sumCost += i.price
      setTotalCost(sumCost)
      console.log(sumCost, 'cost')
    })

    upUnitLists.map((i, index) => {
      sumUpCost += i.price
      setUpTotalCost(sumUpCost)
      console.log(sumUpCost, 'cost')
    })

    let tempPriceArr = [...unitArr, newPackage]

    tempPriceArr.map((i, index) => {
      sumPrice += i.sellingPrice
      setTotalPrice(sumPrice)
      console.log(sumPrice, 'price')
    })

    upUnitLists.map((i, index) => {
      sumUpPrice += i.sellingPrice
      setUpTotalPrice(sumUpPrice)
      console.log(sumUpPrice, 'up price')
    })
  }

  const TestPackageUpdate = event => {
    event.preventDefault()

    const data = {
      id: PackageID,
      name: name,
      totalPrice: upUnitLists ? totalPrice + upTotalPrice : totalPrice,
      totalCost: upUnitLists ? totalCost + upTotalCost : totalCost,
      //   relatedTreatments: unitArr.map(i => i.id),
      status: status,
      description: description
    }

    if (upUnitLists) {
      data.relatedTreatments = [
        ...upUnitLists.map(i => i.id),
        ...unitArr.map(i => i.id)
      ]
    } else {
      data.relatedTreatments = unitArr.map(i => i.id)
    }
    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    api
      .put('package', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })

    document.getElementById('name').value = ''
    document.getElementById('desc').value = ''
    document.getElementById('status').value = ''
    document.getElementById('flag').value = ''
    document.getElementById('charge').value = ''
    document.getElementById('cost').value = ''
  }

  const handleUnit = event => {
    setShowUnitLists(
      unitLists.filter(
        el => (el.treatmentName ? el.treatmentName._id : '') == event
      )
    )

    setShowUnit(true)

    console.log(
      unitLists.filter(
        el => (el.treatmentName ? el.treatmentName._id : '') == event
      )
    )
  }
  useEffect(() => {
    const getTreatment = async () => {
      try {
        const res = await api.get('treatment-lists')

        setTreatmentLists(res.data.list)
        console.log(res.data.list, 'treat list')
      } catch (err) {}
    }

    const getUnitLists = async () => {
      try {
        const res = await api.get('treatments')

        setUnitLists(res.data.list)
      } catch (err) {}
    }

    const getPackageLists = async () => {
      try {
        const res = await api.get('package/' + PackageID)
        console.log(res.data.data[0], 'pack up list')

        setName(res.data.data[0].name)
        setTotalCost(res.data.data[0].totalCost)
        setTotalPrice(res.data.data[0].totalPrice)
        setStatus(res.data.data[0].status)
        setDescription(res.data.data[0].description)
        setStatus(res.data.data[0].status)

        // setPackageLists(res.data.data)

        if (
          res.data.data[0].relatedTreatments !== null &&
          res.data.data[0].relatedTreatments.length > 0
        ) {
          const newData = res.data.data[0].relatedTreatments.map(
            (data, index) => {
              return {
                name: data.name ? data.name : '',
                id: data._id,
                price: data.estimateTotalPrice,
                sellingPrice: data.sellingPrice
              }
            }
          )
          setUpUnitLists(newData)
          setShowUnit(true)
        }
      } catch (err) {}
    }

    getPackageLists()
    getUnitLists()
    getTreatment()
  }, [])
  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <Sidebar />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/package'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active mt-1'>
                      Package Register
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className='content'>
            <div className='container-fluid'>
              {/* <!-- Small boxes (Stat box) --> */}
              <div class='card'>
                {/* <h3 className="text-center py-3">Test Package Register</h3> */}
                <div class='card-body p-b-0 py-5 px-5'>
                  {/* @if($com == null) */}
                  <form onSubmit={TestPackageUpdate}>
                    {/* @csrf */}
                    <div className='form-body'>
                      <div className='form-group'>
                        <label>Package Name</label>
                        <input
                          type='text'
                          id='name'
                          className='form-control'
                          defaultValue={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <label className='control-label'>
                            Add Treatments
                          </label>
                          <div className='row d-flex justify-content-between'>
                            <div className='col-md-5'>
                              <select
                                class='custom-select border-info'
                                name='account_type_id'
                                id='flag'
                                // placeHolder='Treatments'
                                onChange={e => {
                                  handleUnit(e.target.value)
                                }}
                              >
                                <option value=''>Choose Treatments</option>
                                {treatmentLists.map(option => (
                                  <option value={option._id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {showUnit && (
                              <div className='col-md-5'>
                                <select
                                  class='custom-select border-info'
                                  name='account_type_id'
                                  id='flag'
                                  onChange={e => {
                                    handleAdd(e.target.value)
                                  }}
                                >
                                  <option value=''>Choose Unit</option>
                                  {showUnitLists.map(option => (
                                    <option value={option._id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}

                            <div className='col-md-1'>
                              <button
                                type='button'
                                className='btn btn-primary'
                                onClick={() => handleBox(Id)}
                              >
                                <i class='fa fa-plus'></i>
                              </button>
                            </div>
                          </div>
                          {unitArr ? (
                            <div>
                              {unitArr.map(regArr => (
                                <div className='row mt-3'>
                                  <div className='col-md-3'>
                                    <label>Unit Name</label>
                                    <input
                                      type='text'
                                      defaultValue={regArr.name}
                                      className='form-control'
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <label>Price</label>
                                    <input
                                      type='text'
                                      defaultValue={regArr.price}
                                      className='form-control'
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <label>Selling Price</label>
                                    <input
                                      type='text'
                                      defaultValue={regArr.sellingPrice}
                                      className='form-control'
                                    />
                                  </div>

                                  <div className='col-md-2'>
                                    <button
                                      className='btn btn-sm btn-danger rounded-circle opacity-75'
                                      style={{ marginTop: '2.5em' }}
                                    >
                                      <FaMinus />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            ''
                          )}
                          {upUnitLists && (
                            <div>
                              {upUnitLists.map(regArr => (
                                <div className='row mt-3'>
                                  <div className='col-md-3'>
                                    <label>Unit Name</label>
                                    <input
                                      type='text'
                                      defaultValue={regArr.name}
                                      className='form-control'
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <label>Price</label>
                                    <input
                                      type='text'
                                      defaultValue={regArr.price}
                                      className='form-control'
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <label>Selling Price</label>
                                    <input
                                      type='text'
                                      defaultValue={regArr.sellingPrice}
                                      className='form-control'
                                    />
                                  </div>

                                  <div className='col-md-2'>
                                    <button
                                      className='btn btn-sm btn-danger rounded-circle opacity-75'
                                      style={{ marginTop: '2.5em' }}
                                    >
                                      <FaMinus />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='form-group mt-3'>
                        <label>Total Price</label>
                        <input
                          type='number'
                          id='charge'
                          className='form-control'
                          defaultValue={
                            upUnitLists ? totalPrice + upTotalPrice : totalPrice
                          }
                          onChange={e => setPrice(e.target.value)}
                        />
                      </div>

                      <div className='form-group'>
                        <label>Total Cost</label>
                        <input
                          type='number'
                          id='cost'
                          className='form-control'
                          defaultValue={
                            upUnitLists ? totalCost + upTotalCost : totalCost
                          }
                          onChange={e => setCost(e.target.value)}
                        />
                      </div>

                      <div className='form-group'>
                        <label>Status</label>
                        <select
                          class='custom-select border-info'
                          name='account_type_id'
                          id='status'
                          onChange={e => setStatus(e.target.value)}
                        >
                          <option hidden>{status}</option>
                          <option value='Active'>Active</option>
                          <option value='Pending'>Pending</option>
                        </select>
                      </div>

                      <div className='col-md-12'>
                        <label>Description</label>
                        <textarea
                          rows='8'
                          cols='40'
                          id='desc'
                          className='form-control'
                          defaultValue={description}
                          onChange={e => setDescription(e.target.value)}
                        ></textarea>
                      </div>

                      <div className='form-actions mt-3'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='row'>
                              <div className=' col-md-9'>
                                <button
                                  type='submit'
                                  className='btn btn-primary'
                                >
                                  Create
                                </button>
                                &nbsp;
                                <Link
                                  to='/package'
                                  className='btn btn-danger'
                                  data-dismiss='modal'
                                >
                                  Cancel
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* <!-- /.row (main row) --> */}
                </div>
                {/*<!-- /.container-fluid --> */}
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default LabServiceRegister

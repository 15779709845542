import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import NotesDetailModal from './NotesDetailModal'

import ReactPaginate from 'react-paginate'

import {
    FaRegMinusSquare,
    FaRegEdit,
    FaRegTrashAlt
} from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'

const NoteLists = ({ data, itemsPerPage }) => {
    const token = localStorage.getItem('token')
    const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
    const api = axios.create({
        baseURL: url,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    const [medicineLists, setMedicineLists] = useState([])
    const [detailModel, setDetailModel] = useState(false)
    const [detailData, setDetailData] = useState([])
    const [id, setID] = useState('')
    const [open, setOpen] = useState(false)
    const [close, setClose] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    // Logic for paginating the data
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    console.log(data)
    // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem)

    const nextPage = () => {
        setCurrentPage(currentPage + 1)
    }

    const prevPage = () => {
        setCurrentPage(currentPage - 1)
    }

    const showDialog = () => setOpen(true)

    const handleDetailModal = async (id) => {
        api.get('note/' + id)
            .then(response => {
                setDetailData(response.data.data)
                console.log(response.data.data, 'detailData')
                setID(id)
                setDetailModel(true)
            })
    }

    const handleDelete = event => {
        api
            .delete('note/' + event)
            .then(response => {
                Swal.fire({
                    title: 'Success',
                    text: 'Successfully Deleted!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
                const result = medicineLists.filter(item => item._id !== event)
                setMedicineLists(result)
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'CANCEL'
                })
            })


    }

    useEffect(() => {
        const getNotes = async () => {
            try {
                const res = await api.get('notes')
                setMedicineLists(res.data.list)

            } catch (err) {
                alert(err.message)
            }
        }
        getNotes()
    }, [])

    return (
        <div classNameName='App'>
            <div className='wrapper'>
                <HeaderName />

                {/* <!-- /.navbar --> */}

                {/* <!-- Main Sidebar Container --> */}
                <SideBar />

                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className='content-wrapper'>
                    {/* <!-- Content Header (Page header) --> */}
                    <div className='content-header'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'>
                                            <a href='/'>Home</a>
                                        </li>
                                        <li className='breadcrumb-item active'>Note Lists</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Main content --> */}

                    <section className='content'>
                        <div className='container-fluid'>
                            <div className='row d-flex justify-content-between'>
                                <div className='col-md-6'>
                                    {/* <h4>Medicine Item List</h4> */}
                                </div>
                                <div className='offset-2 col-md-4 ml-3'>
                                    <div
                                        className='input-group row ml-4'
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <div className='col-md-6'>
                                            <input
                                                type='search'
                                                className='form-control rounded ml-5'
                                                style={{ borderRadius: '12px' }}
                                                id='search_code'
                                                placeholder='Search'
                                            />
                                        </div>
                                        <div className='col-md-6 mt-1'>
                                            <Link to='/create-notes'>
                                                <button
                                                    type='button'
                                                    className='btn  btn-primary ml-5'
                                                    style={{ borderRadius: '7px' }}
                                                    onClick={showDialog}
                                                >
                                                    Add Item
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='card'>

                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='table-responsive' id='slimtest2'>
                                                        <table
                                                            className='table table-hover'
                                                            id='filter_date'
                                                        >
                                                            <thead className=''>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Date</th>
                                                                    <th>Name</th>
                                                                    <th>Description</th>
                                                                    <th>First Note</th>
                                                                    <th>Second Note</th>
                                                                    <th>Note Type</th>
                                                                    <th>Unit</th>
                                                                    <th className='text-center'>Action</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className=''>
                                                                {medicineLists.map((item, i) => (
                                                                    <tr>
                                                                        <td>{++i}</td>
                                                                        <td>{item.createdAt ? item.createdAt.split('T')[0] : '-'}</td>
                                                                        <td>{item.name ? item.name : '-'}</td>
                                                                        <td>{item.description ? item.description : '-'}</td>
                                                                        <td>{item.item ? item.item.length + ' Accounts' : '-'}</td>
                                                                        <td>{item.secondaryItem ? item.secondaryItem.length + ' Accounts' : '-'}</td>
                                                                        <td>{item.type ? item.type : '-'}</td>
                                                                        <td>
                                                                            <button
                                                                                className='btn btn-sm btn-primary'
                                                                                onClick={e =>
                                                                                    handleDetailModal(item._id)
                                                                                }
                                                                            >
                                                                                Details
                                                                            </button>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <button
                                                                                className='btn btn-sm btn-danger'
                                                                                onClick={e =>
                                                                                    handleDelete(item._id)
                                                                                }
                                                                            >
                                                                                <FaRegTrashAlt />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NotesDetailModal
                                open={detailModel}
                                setOpen={setDetailModel}
                                close={() => setDetailModel(false)}
                                detailData={detailData}
                                id={id}
                                type={detailData.length > 0 ? detailData[0].type : ''}
                                description={detailData.length > 0 ? detailData[0].description : ''}
                                name={detailData.length > 0 ? detailData[0].name : ''}
                                item={detailData.length > 0 ? detailData[0].item : []}
                                secondaryItem={detailData.length > 0 ? detailData[0].secondaryItem : []}
                            />
                        </div>
                    </section>
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
            <footer className='main-footer' style={{ marginTop: '50px;' }}>
                <strong>
                    Copyright &copy; 2017-2020{' '}
                    <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
                </strong>
                All rights reserved.
            </footer>

            {/* <!-- Control Sidebar --> */}
            <aside className='control-sidebar control-sidebar-dark'>
                {/* <!-- Control sidebar content goes here --> */}
            </aside>

            {/* <!-- ./wrapper --> */}
        </div>
    )
}

export default NoteLists

import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import SideBar from '../../SideBar'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Button } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import Swal from 'sweetalert2'

import HeaderName from '../../HeaderName'
import { Link } from 'react-router-dom'
import { FaFileExport, FaAngleDoubleRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
function Stock() {
  // const token = localStorage.getItem('token')
  // const url = 'http://hlaasetheticbackend.kwintechnologies.com:4000/api/'
  // const api = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const scroll = {
    width: '200px',
    height: "600px",
    overflowX: 'scroll'
  }
  const [searchProResults, setSearchProResults] = useState([])
  const [showProSearch, setShowProSearch] = useState(false)
  const [hidePro, setHidePro] = useState(true)
  const [proName, setProName] = useState('')

  const [searchMedResults, setSearchMedResults] = useState([])
  const [showMedSearch, setShowMedSearch] = useState(false)
  const [hideMed, setHideMed] = useState(true)
  const [medName, setMedName] = useState('')

  const [searchProAccResults, setSearchProAccResults] = useState([])
  const [showProAccSearch, setShowProAccSearch] = useState(false)
  const [hideProAcc, setHideProAcc] = useState(true)
  const [proAccName, setProAccName] = useState('')

  const [relatedProBranch, setRelatedProBranch] = useState([])
  const [showBranch, setShowBranch] = useState(false)

  const [proUnitLists, setProUnitLists] = useState([])
  const [medDamageLists, setMedDamageLists] = useState([])
  const [proDamageLists, setProDamageLists] = useState([])
  const [proAccDamageLists, setProAccDamageLists] = useState([])

  const [porAssLists, setProAssLists] = useState([])
  const [showMedicine, setShowMedicine] = useState(true)
  const [showProUnit, setShowProUnit] = useState(false)
  const [showProAss, setShowProAss] = useState(false)

  const [machineLists, setMachineLists] = useState([])

  const [mQty, setMQty] = useState('')

  const [mTotal, setMTotal] = useState('')
  const [mSell, setMSell] = useState(0)
  const [mPurchase, setMPurchase] = useState(0)

  const [pmQty, setPMQty] = useState('')

  const [pmTotal, setPMTotal] = useState('')
  const [pmSell, setPMSell] = useState(0)
  const [pmPurchase, setPMPurchase] = useState(0)

  const [paQty, setPAQty] = useState('')

  const [paTotal, setPATotal] = useState('')
  const [paSell, setPASell] = useState(0)
  const [paPurchase, setPAPurchase] = useState(0)

  const [proId, setProId] = useState('')
  // const [proName, setProName] = useState('')

  const ButtonStyle = { margin: '10px 10px' }
  const ErrorColor = { border: 'red' }

  //related med
  const [reMedSell, setReMedSell] = useState('')
  const [reMedPurchase, setReMedPurchase] = useState('')
  const [relatedLists, setRelatedLists] = useState([])
  const [medID, setMedID] = useState('')
  const [medPrice, setMedPrice] = useState('')
  const [relatedMedList, setRelatedMedList] = useState([])

  //related pro
  const [reProSell, setReProSell] = useState('')
  const [reProPurchase, setReProPurchase] = useState('')
  const [relatedProLists, setRelatedProLists] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [proMedPrice, setProMedPrice] = useState('')
  const [relatedProList, setRelatedProList] = useState([])

  //related proAcc
  const [reAccSell, setReAccSell] = useState('')
  const [reAccPurchase, setReAccPurchase] = useState('')
  const [relatedAccLists, setRelatedAccLists] = useState([])
  const [proAccPrice, setProAccPrice] = useState('')
  const [relatedProAccList, setRelatedProAccList] = useState([])

  console.log(relatedLists, 'list med')

  const handleMedUnitChange = () => {
    setShowMedicine(true)
    setShowProUnit(false)
    setShowProAss(false)
    setHideMed(true)
    setShowBranch(false)
  }

  const handleProUnitChange = () => {
    setShowMedicine(false)
    setShowProUnit(true)
    setShowProAss(false)
    setHidePro(true)
    setShowBranch(false)
  }

  const handleProAssUnitChange = () => {
    setShowProAss(true)
    setShowMedicine(false)
    setShowProUnit(false)
    setShowBranch(false)
    setHideProAcc(true)
  }

  const _export1 = React.useRef(null)
  const _export2 = React.useRef(null)
  const _export3 = React.useRef(null)
  const _exportre1 = React.useRef(null)
  const _exportre2 = React.useRef(null)
  const _exportre3 = React.useRef(null)

  const excelExport1 = () => {
    if (_export1.current !== null) {
      console.log(_export1.current.props.data, 'excel')

      _export1.current.props.data.map(function (element, index) {
        element.code = element.code
      })

      _export1.current.save()
    }
  }

  const excelExport2 = () => {
    if (_export2.current !== null) {
      console.log(_export2.current.props.data, 'excel')
      if (_export2.current.props.data === { medDamageLists }) {
        _export2.current.props.data.map(function (element, index) {
          element.code = element.code
        })
      }
      _export2.current.save()
    }
  }

  const excelExport3 = () => {
    if (_export3.current !== null) {
      console.log(_export3.current.props.data, 'excel')
      _export3.current.props.data.map(function (element, index) {
        element.code = element.code
      })
      _export3.current.save()
    }
  }

  const excelExportRe1 = () => {
    if (_export1.current !== null) {
      _export1.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt?.split('T')[0]
        element.totalUnit =
          (element.qty * element.relatedProcedureItems.toUnit) /
          element.relatedProcedureItems.fromUnit
        element.expiredDate = element.expiredDate?.split('T')[0]
      })

      _export1.current.save()
    }
  }

  const excelExportRe2 = () => {
    if (_export2.current !== null) {
      _export2.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt?.split('T')[0]
        element.totalUnit =
          (element.qty * element.relatedMedicineItems.toUnit) /
          element.relatedMedicineItems.fromUnit
        element.expiredDate = element.expiredDate?.split('T')[0]
      })
    }
    _export2.current.save()
  }

  const excelExportRe3 = () => {
    if (_export3.current !== null) {
      _export3.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt.split('T')[0]
        element.totalUnit =
          (element.qty * element.relatedAccessoryItems.toUnit) /
          element.relatedAccessoryItems.fromUnit
        element.expiredDate = element.expiredDate?.split('T')[0]
      })
      _export3.current.save()
    }
  }

  useEffect(() => {
    const getProUnitLists = async () => {
      try {
        const res = await apiInstance.get('procedure-items?limit=30')
        setProUnitLists(res.data.list)
        console.log(res.data.list)
        console.log(setProUnitLists.reOrderQuantity)
        setProId(res.data.list._id)
        // if (setProUnitLists.reOrderQuantity === 0) {
        // }
      } catch (err) {
        alert(err.message)
      }
    }

    const getMedDamageLists = async () => {
      try {
        const res = await apiInstance.get('items/damage')
        setMedDamageLists(
          res.data.data.filter(
            el => el?.relatedMedicineItem
          )
        )
        setProDamageLists(
          res.data.data.filter(
            el => el?.relatedProcedureItem
          )
        )

        setProAccDamageLists(
          res.data.data.filter(
            el => el?.relatedAccessoryItem
          )
        )

        // const arr = relatedLists.length - 1
        console.log(res.data.data, 'med')
      } catch (err) {
        alert(err.message)
      }
    }

    // const getBranches = async () => {
    //   try {
    //     const res = await axios.get(apiURL + 'branches')
    //     setBranchesLists(res.data.list)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }

    const getProAssLists = async () => {
      try {
        const res = await apiInstance.get('accessory-items?limit=30')
        setProAssLists(res.data.list)
        console.log(res.data.list, 'pro as')
      } catch (err) {
        alert(err.message)
      }
    }
    const getFixedAsset = async () => {
      try {
        const res = await apiInstance.get('fixed-assets')

        setMachineLists(
          res.data.list.filter(
            el =>
              el.relatedAccount.name == 'Medical Equipment' ||
              el.relatedAccount.name == 'Surgery Equipment' ||
              el.relatedAccount.name == 'Plant & Machinery'
          )
        )
      } catch (err) { }
    }
    const getPrice = async () => {
      try {
        const res = await apiInstance.get('stocks/inventory')
        console.log(res.data.data)
        setMedPrice(res.data.data.medicineItems)
        setProMedPrice(res.data.data.procedureItems)
        setProAccPrice(res.data.data.procedureItems)

        // setMachineLists(
        //   res.data.list.filter(
        //     el =>
        //       el.relatedAccount.name == 'Medical Equipment' ||
        //       el.relatedAccount.name == 'Surgery Equipment' ||
        //       el.relatedAccount.name == 'Plant & Machinery'
        //   )
        // )
      } catch (err) { }
    }

    const getRelatedStocks = async () => {
      try {
        const res = await apiInstance.get('stocks')
        console.log(
          res.data.list.filter(el => el.relatedMedicineItems),
          're st'
        )
        setRelatedMedList(res.data.list.filter(el => el.relatedMedicineItems))
        setRelatedProList(res.data.list.filter(el => el.relatedProcedureItems))
        setRelatedProAccList(res.data.list.filter(el => el.accessoryItems))
        // setMedPrice(res.data.data.medicineItems)
      } catch (err) { }
    }

    getRelatedStocks()

    getPrice()

    getFixedAsset()

    getProAssLists()
    getMedDamageLists()
    getProUnitLists()
  }, [])

  const search = () => {
    console.log(proName, 'name')
    const data = {
      search: proName
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.post('procedure-items-search', data, config).then(function (response) {
      console.log(response.data.data, 'data')

      setSearchProResults(response.data.data)

      setShowProSearch(true)
      setHidePro(false)
    })
  }
  const medSearch = val => {
    if (val) {
      setSearchMedResults(
        medDamageLists.filter(el =>
          el.relatedStockRecord?.relatedMedicineItems.medicineItemName
            .toLowerCase()
            .includes(val.toLowerCase())
        )
      )

      setShowMedSearch(true)
      setHideMed(false)
    } else {
      setShowMedSearch(false)
      setHideMed(true)
    }
  }

  const proAccSearch = () => {
    console.log(proAccName, 'name')
    const data = {
      search: proAccName
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.post('accessory-items-search', data, config).then(function (response) {
      console.log(response.data.data, 'data')

      setSearchProAccResults(response.data.data)

      setShowProAccSearch(true)
      setHideProAcc(false)
    })
  }

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />
        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Damage Lists
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-1 col-md-4 ml-5'>
                  <div
                    className='input-group row ml-5'
                    style={{ marginBottom: '20px' }}
                  >
                    {/* <div className="col-md-6 mt-1">
                      <button
                        type="button"
                        className="btn  btn-primary ml-4"
                        style={{ borderRadius: "7px" }}
                        onClick={showDialog}>
                        Add Item
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={e =>
                                  handleMedUnitChange(e.target.value)
                                }
                              >
                                Medicine Items
                              </Button>
                            </div>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={e =>
                                  handleProUnitChange(e.target.value)
                                }
                              >
                                Procedure Items
                              </Button>
                            </div>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={e =>
                                  handleProAssUnitChange(e.target.value)
                                }
                              >
                                Procedure Accessories
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-body'>
                      {/* Export data in Excel Simple*/}
                      {/* For Procedure Medicine Items */}
                      <ExcelExport data={proUnitLists} ref={_export1}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='procedureItemName'
                          title='Procedure Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End        */}
                      {/* For Medicine Items */}
                      <ExcelExport data={medDamageLists} ref={_export2}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='medicineItemName'
                          title='Medicine Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End     */}
                      {/* For Procedure Accessory FilterItems */}
                      <ExcelExport data={porAssLists} ref={_export3}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='accessoryItemName'
                          title='Procedure Accessory Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End */}
                      {/* Export Data in Excel end */}
                      {/* Export data in Excel Related*/}
                      {/* For Procedure Medicine Items */}
                      <ExcelExport data={relatedProList} ref={_export1}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt'
                          title='Purchase Date'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems.procedureItemName'
                          title='Medicine Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='qty'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems.fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='relatedProcedureItems
.toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='expiredDate'
                          title='Expired Date'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>

                      {/* End        */}
                      {/* For Medicine Items */}
                      <ExcelExport data={relatedMedList} ref={_export2}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt'
                          title='Purchase Date'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedMedicineItems.medicineItemName'
                          title='Medicine Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='qty'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedMedicineItems.fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='relatedMedicineItems.toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='expiredDate'
                          title='Expired Date'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End     */}
                      {/* For Procedure Accessory FilterItems */}
                      <ExcelExport data={relatedProAccList} ref={_export3}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt'
                          title='Purchase Date'
                          format={'MM/dd/yyyy'}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedAccessoryItems.accessoryItemName'
                          title='Medicine Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='qty'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedAccessoryItems.fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='relatedAccessoryItems.toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='expiredDate'
                          title='Expired Date'
                          format={'MM/dd/yyyy'}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>

                      {/* End */}
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-12'>
                          <div style={{ scroll }}>
                            <table className='table table-hover table-responsive'>

                              {showProUnit && (
                                <div >
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-3'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-success'
                                        onClick={excelExport1}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>{' '}
                                      &nbsp;
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-success'
                                        onClick={excelExportRe1}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export Related
                                      </button>
                                    </div>
                                    <div className='col-md-7 mr-2'>
                                      <div className='row offset-3'>
                                        {/* <div
                                          className='col-4'
                                          style={{ marginTop: '0.2em' }}
                                        >
                                          <Button className='btn btn-primary rounded-3 w-100'>
                                            {proMedPrice}
                                          </Button>
                                        </div> */}

                                        <div className='col-md-4'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              setProName(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>
                                        <div className='col-md-4 mt-1'>
                                          <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={search}
                                          >
                                            Search
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='' >
                                    <thead >
                                      <tr className='text-white' style={{ scroll }}>
                                        <th
                                          style={{ fontWeight: '400px', width: '200px' }}
                                          className='text-center'
                                        >
                                          No
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Start Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Expired Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Item Name
                                        </th>

                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Total Unit
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Qty
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Reason
                                        </th>

                                      </tr>
                                    </thead>

                                    <tbody>
                                      {hidePro &&
                                        proDamageLists.map((proUnit, i) => (

                                          <tr>
                                            <td className='text-center'>
                                              {++i}
                                            </td>
                                            <td>
                                              {' '}
                                              {proUnit.createdAt
                                                ? proUnit.createdAt.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {' '}
                                              {proUnit
                                                ? proUnit?.expiredDate?.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.damageDate
                                                ? proUnit.damageDate?.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit
                                                ? proUnit
                                                  ?.relatedProcedureItem
                                                  ?.procedureItemName
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.damageTotalUnit
                                                ? proUnit.damageTotalUnit
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.damageCurrentQty
                                                ? proUnit.damageCurrentQty
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.remark
                                                ? proUnit.remark
                                                : 'Not Set'}
                                            </td>
                                          </tr>

                                        ))}

                                      {showProSearch &&
                                        searchProResults.map((proUnit, i) => (
                                          <tr>
                                            <td className='text-center'>
                                              {++i}
                                            </td>
                                            <td>
                                              {' '}
                                              {proUnit.createdAt
                                                ? proUnit.createdAt.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {' '}
                                              {proUnit
                                                ? proUnit?.expiredDate?.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.damageDate
                                                ? proUnit.damageDate?.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit
                                                ? proUnit
                                                  ?.relatedProcedureItem
                                                  ?.procedureItemName
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.damageTotalUnit
                                                ? proUnit.damageTotalUnit
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.damageCurrentQty
                                                ? proUnit.damageCurrentQty
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {proUnit.remark
                                                ? proUnit.remark
                                                : 'Not Set'}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </div>

                                </div>
                              )}

                              {showMedicine && (
                                <div>
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-3'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-success'
                                        onClick={excelExport2}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>
                                      &nbsp;
                                      {/* <button
                                        type='button'
                                        className='btn btn-sm btn-success'
                                        onClick={excelExportRe2}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export Related
                                      </button> */}
                                    </div>
                                    <div className='col-12 mr-2'>
                                      <div className='row offset-3'>


                                        <div className='offset-5 col-md-4'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              medSearch(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <thead className='text-white'>
                                      <tr >
                                        <th

                                          style={{
                                            fontWeight: '400px', width: '200px'
                                          }}
                                          className='text-center'
                                        >
                                          No
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Start Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Expired Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Item Name
                                        </th>

                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Total Unit
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Qty
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Reason
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                      {showMedSearch &&
                                        searchMedResults.map((medUnit, i) => (
                                          <tr>
                                            <td className='text-center'>
                                              {++i}
                                            </td>
                                            <td>
                                              {' '}
                                              {medUnit.createdAt
                                                ? medUnit.createdAt.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {' '}
                                              {medUnit
                                                ? medUnit?.expiredDate?.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {medUnit.damageDate
                                                ? medUnit.damageDate?.split(
                                                  'T'
                                                )[0]
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {medUnit
                                                ? medUnit
                                                  ?.relatedMedicineItem
                                                  ?.medicineItemName
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {medUnit.damageTotalUnit
                                                ? medUnit.damageTotalUnit
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {medUnit.damageCurrentQty
                                                ? medUnit.damageCurrentQty
                                                : 'Not Set'}
                                            </td>
                                            <td>
                                              {medUnit.remark
                                                ? medUnit.remark
                                                : 'Not Set'}
                                            </td>
                                          </tr>
                                        ))}
                                      {/* {console.log(medDamageLists, 'med')} */}
                                      {hideMed &&
                                        medDamageLists.map((medUnit, i) => (
                                          <>
                                            <tr>
                                              <td className='text-center'>
                                                {++i}
                                              </td>
                                              <td>
                                                {' '}
                                                {medUnit.createdAt
                                                  ? medUnit.createdAt.split(
                                                    'T'
                                                  )[0]
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {' '}
                                                {medUnit
                                                  ? medUnit?.expiredDate?.split(
                                                    'T'
                                                  )[0]
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {medUnit.damageDate
                                                  ? medUnit.damageDate?.split(
                                                    'T'
                                                  )[0]
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {medUnit
                                                  ? medUnit
                                                    ?.relatedMedicineItem
                                                    ?.medicineItemName
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {medUnit.damageTotalUnit
                                                  ? medUnit.damageTotalUnit
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {medUnit.damageCurrentQty
                                                  ? medUnit.damageCurrentQty
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {medUnit.remark
                                                  ? medUnit.remark
                                                  : 'Not Set'}
                                              </td>
                                            </tr>
                                          </>
                                        ))}
                                    </tbody>
                                  </div>
                                </div>
                              )}

                              {showProAss && (
                                <div>
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-6'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-success'
                                        onClick={excelExport3}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>
                                      &nbsp;
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-success'
                                        onClick={excelExportRe3}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export Related
                                      </button>
                                    </div>
                                    <div className='col-md-5 mr-2'>
                                      <div className='row '>

                                        <div className='col-md-4'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              setProAccName(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>
                                        {/* <div className='col-md-4 mt-1'>
                                          <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={proAccSearch}
                                          >
                                            Search
                                          </button>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <thead className='position-overflow text-white'>
                                      <tr>
                                        <th
                                          style={{ fontWeight: '400px', width: '200px' }}
                                          className='text-center'
                                        >
                                          #
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Start Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Expired Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Date
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Item Name
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Total Unit
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Damage Qty
                                        </th>
                                        <th style={{ fontWeight: '400px', width: '200px' }}>
                                          Reason
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                      {hideProAcc &&
                                        proAccDamageLists.map((proAssUnit, i) => (
                                          <>
                                            <tr>
                                              <td className='text-center'>
                                                {++i}
                                              </td>
                                              <td>
                                                {' '}
                                                {proAssUnit.createdAt
                                                  ? proAssUnit.createdAt.split(
                                                    'T'
                                                  )[0]
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {' '}
                                                {proAssUnit
                                                  ? proAssUnit?.expiredDate?.split(
                                                    'T'
                                                  )[0]
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {proAssUnit.damageDate
                                                  ? proAssUnit.damageDate?.split(
                                                    'T'
                                                  )[0]
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {proAssUnit
                                                  ? proAssUnit
                                                    ?.relatedAccessoryItem
                                                    ?.accessoryItemName
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {proAssUnit.damageTotalUnit
                                                  ? proAssUnit.damageTotalUnit
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {proAssUnit.damageCurrentQty
                                                  ? proAssUnit.damageCurrentQty
                                                  : 'Not Set'}
                                              </td>
                                              <td>
                                                {proAssUnit.remark
                                                  ? proAssUnit.remark
                                                  : 'Not Set'}
                                              </td>
                                            </tr>
                                          </>
                                        ))}

                                      {showProAccSearch &&
                                        searchProAccResults.map(
                                          (proAssUnit, i) => i
                                        )}
                                    </tbody>
                                  </div>
                                </div>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <ProMedicine
                open={open}
                close={() => setOpen(false)}
                setProMedicineLists={setProMedicineLists}
                proMedicineLists={proMedicineLists}
              /> */}
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '1rem' }}>
        <strong>
          Copyright &copy; 2017-2020
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Stock

import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`
const Input = styled.input`
  flex: 1;
  min-width: 500px;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`
const Filter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

const FilterTitle = styled.span`
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 3px;
`
// hello world

const FilterSelect = styled.select`
  padding: 5px;
`

const FilterOption = styled.option``

const QRBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
`

export default function BankInfoDialog (props) {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [bankName, setBankName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [accountName, setAccountName] = useState('')

  const [accountHolderName, setAccountHolderName] = useState('')
  const [bankContact, setBankContact] = useState('')
  const [openingDate, setOpeningDate] = useState('')
  const [balance, setBalance] = useState('')
  const [bankAddress, setBankAddress] = useState('')
  //   const [relatedCurrency, setRelatedCurrency] = useState('');
  //   const [relatedAccounting, setRelatedAccounting] = useState('');

  const BankReg = () => {
    const data = {
      bankName: bankName,
      accountName: accountName,
      accountNumber: accountNumber,
      accountHolderName: accountHolderName,
      bankContact: bankContact,
      openingDate: openingDate,
      balance: balance,
      bankAddress: bankAddress
    }
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    const res = api.post('bank', data, config).then(function (response) {
      alert('success')
    })
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle>
          {' '}
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Add New Accounting</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <form action="{{route('store_accounting')}}" method='post'>
            {/* @csrf */}
            <div class='modal-body'>
              <div class='form-group'>
                <label for='name'>Account Code</label>
                <input
                  type='text'
                  class='form-control border border-info'
                  name='acc_code'
                  id='acc_code'
                  placeholder='eg. 123456'
                />
              </div>
              <div class='form-group'>
                <label for='name'>Account Name</label>
                <input
                  type='text'
                  class='form-control border-info'
                  name='acc_name'
                  id='acc_name'
                  placeholder='eg. Revenue Account'
                />
              </div>
              <div class='form-group'>
                <label for='name'>Account Type</label>
                <select
                  class='custom-select border-info'
                  name='account_type_id'
                >
                  <option>Choose Account Type</option>
                  <option value='6'>Revenue</option>
                  <option value='14'>Other Revenue</option>
                  <option value='7'>Receiable</option>
                  <option value='15'>Other Receiable</option>
                </select>
              </div>
              <div class='form-group'>
                <label for='name'>Cost Center</label>
                <select class='custom-select border-info' name='cost_center'>
                  <option>Choose Cost Center Name</option>
                  {/* @foreach($cost_centers as $cc) */}
                  <option value='Staff Loan'></option>
                  {/* @endforeach */}
                </select>
              </div>
              <div class='form-group'>
                <label for='name'>Balance</label>
                <input
                  type='text'
                  class='form-control border-info'
                  name='balance'
                />
              </div>
              <div class='form-group'>
                <label for='name'>Currency</label>
                <select class='custom-select border-info' name='currency'>
                  <option>Choose Currency</option>
                  {/* @foreach($currency as $cc) */}
                  <option value='{{$cc->id}}'>21007</option>
                  {/* @endforeach */}
                </select>
              </div>
              <div class='form-group'>
                <label for='name'>Projected Related</label>
                <div class='row'>
                  <div class='col-md-6'>
                    <div class='form-check form-check-inline'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='yes_no'
                        id='yes'
                        value='1'
                        onclick='show_project()'
                      />
                      <label class='form-check-label text-info' for='bank'>
                        Yes
                      </label>
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <div class='form-check form-check-inline'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='yes_no'
                        id='no'
                        value='2'
                        onclick='hide_project()'
                      />
                      <label class='form-check-label text-info' for='cash'>
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class='form-group' id='proj'>
                <label for='name'>Project</label>
                <select class='custom-select border-info' name='project_id'>
                  <option value='0'>Choose Project Name</option>
                  {/* @foreach($saleproject as $salepro) */}
                  <option value='{{$salepro->id}}'>MOC</option>
                  {/* @endforeach */}
                </select>
              </div>
            </div>
            <div class='modal-footer'>
              <Button
                class='btn btn-secondary'
                data-dismiss='modal'
                onClick={props.close}
              >
                Close
              </Button>
              <Button class='btn btn-primary'>Save</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

/* eslint-disable */

import SideBar from '../../SideBar.js'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiInstance from '../../../../utils/api.js'
function CatRegister() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [branch, setBranch] = useState('')
  const [dataIDArr, setDataIDArr] = useState([])
  const [collectAmount, setCollectAmount] = useState('')
  const [month, setMonth] = useState('')
  const [status, setStatus] = useState('')
  const [remark, setRemark] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [referDoctor, setReferDoctor] = useState([])
  const [doctorName, setDoctorName] = useState('')
  const DoctorID = useLocation().pathname.split('/')[2]
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [showOrigin, setShowOrigin] = useState(true)
  const [showDate, setShowDate] = useState(false)
  const [showMonth, setShowMonth] = useState(false)
  const [dateRadioValue, setDateRadioValue] = useState('')
  const [monthRadioValue, setMonthRadioValue] = useState('')

  const handlemonth = mon => {
    console.log(mon, 'mon')
    setMonth(mon)
  }

  const handleDateRadioChange = val => {
    // console.log(val, 'r val')
    setDateRadioValue(val)
    setShowDate(true)
    setShowMonth(false)
  }

  const handleMonthRadioChange = val => {
    setMonthRadioValue(val)
    setShowDate(false)
    setShowMonth(true)
  }

  const handleDetail = () => {
    setShowOrigin(false)
    setShowDetail(true)
  }
  const handleOrigin = () => {
    setShowOrigin(true)
    setShowDetail(false)
  }

  const handleDate = id => {
    const nDate = new Date(endDate)
    const toDate = nDate.setDate(nDate.getDate() + 1)
    apiInstance
      .get(`comissions/search`, {
        params: { start: startDate, end: toDate, doctor: id }
      })
      .then(function (res) {
        setSearch(res.data.data)
        console.log(res.data.data, 'date filter')
        setDataIDArr(
          ...dataIDArr,
          res.data.data.map(item => (item ? item._id : ''))
        )
        // console.log(res.data.data._id, 'data com ID')

        // console.log(
        //   res.data.data.map(item => (item ? item._id : '')),
        //   'data ID'
        // )

        setCollectAmount(res.data.collectAmount)
        // console.log(res.data.collectAmount, 'amount')
      })
      .catch(err => {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  const handleSearch = (mon, ID) => {
    apiInstance
      .get(`comissions/search?month=${mon}&doctor=${ID}`)
      .then(function (res) {
        setSearch(res.data.data)
        console.log(res.data.data, 'data')
        setDataIDArr(
          ...dataIDArr,
          res.data.data.map(item => (item ? item._id : ''))
        )
        // console.log(res.data.data._id, 'data com ID')

        // console.log(
        //   res.data.data.map(item => (item ? item._id : '')),
        //   'data ID'
        // )

        setCollectAmount(res.data.collectAmount)
        // console.log(res.data.collectAmount, 'amount')
      })
      .catch(err => {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const CollectDialog = () => {
    setOpen(true)
  }

  const Collect = () => {
    const data = {
      update: dataIDArr,
      relatedNurse: DoctorID,
      collectDate: date,
      remark: remark,
      collectAmount: collectAmount
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('comissions/collect', data, config)
      .then(function (response) {
        // setDoctorLists([...doctorLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  useEffect(() => {
    const getPackage = async () => {
      console.log(DoctorID, 'ID')
      try {
        const res = await apiInstance.get('nurse/' + DoctorID)

        console.log(res.data.data[0], 'd list one')
        setReferDoctor(res.data.data)
        setDoctorName(res.data.data[0].name)
        setBranch(res.data.data[0].relatedBranch.name)
      } catch (err) { }
    }

    // const search = async mon => {
    //   console.log(mon, 'month')
    //   try {
    //     const response = await apiInstance.get(
    //       'comissions/search?month=June&doctor=64807bb52b920a45cbc763b4'
    //     )
    //     console.log('night')
    //     console.log(response.data.data)
    //   } catch (err) {}
    // }
    // search()
    getPackage()
  }, [])

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        <SideBar />
        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2 card-header py-3'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/doctor'>Back</Link>
                    </li>
                    {/* <li className='breadcrumb-item active'>
                      Doctor {doctorName}'s Comissions
                    </li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div class='row'>
                  <div class='row'></div>
                  <div class='col-md-12 py-3'>
                    <div class='row' id='trial_balance'>
                      {showOrigin && (
                        <div className='col-12 card px-3 py-3'>
                          <div class='card-body'>
                            <div className='row card-header'>
                              <div className='col-6'>
                                <h5 className='fs-4'>
                                  {doctorName}'s Comissions
                                </h5>
                              </div>
                              <div className='offset-2 col-4'>
                                <div className='row'>
                                  <div className='col-6'>
                                    <div className='btn btn-sm btn-dark'>
                                      Amount Total : 0
                                    </div>
                                  </div>
                                  <div className='col-6'>
                                    <div className='btn btn-sm btn-dark'>
                                      Previous Amount :0
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row offset-md-4'>
                              <div className='col-md-2'>
                                <div className='form-check form-check-inline mt-2 '>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='bank_cash'
                                    id='bank'
                                    value='1'
                                    onClick={e =>
                                      handleDateRadioChange(e.target.value)
                                    }
                                  />
                                  &nbsp;
                                  <label
                                    className='form-check-label text-success'
                                    for='bank'
                                  >
                                    Date
                                  </label>
                                </div>
                              </div>
                              <div className='col-md-2'>
                                <div className='form-check form-check-inline mt-2 px-4'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='bank_cash'
                                    id='cash'
                                    value='2'
                                    onClick={e =>
                                      handleMonthRadioChange(e.target.value)
                                    }
                                  />
                                  <label
                                    className='form-check-label text-success'
                                    for='cash'
                                  >
                                    Month
                                  </label>
                                </div>
                              </div>
                            </div>
                            {showMonth && (
                              <div className='row form-group py-3'>
                                <div className='col-8 py-3'>
                                  <label htmlFor=''>Monthly Name</label>
                                  <select
                                    name=''
                                    id=''
                                    className='form-control'
                                    onChange={e => handlemonth(e.target.value)}
                                  >
                                    <option hidden>Choose Month</option>
                                    <option value='January'>January</option>
                                    <option
                                      value='Febuary
'
                                    >
                                      Febuary
                                    </option>
                                    <option
                                      value='March
'
                                    >
                                      March
                                    </option>
                                    <option
                                      value='April
'
                                    >
                                      April
                                    </option>
                                    <option value='May'>May</option>
                                    <option
                                      value='June
'
                                    >
                                      June
                                    </option>
                                    <option value='July'>July</option>
                                    <option
                                      value='August
'
                                    >
                                      August
                                    </option>
                                    <option
                                      value='September
'
                                    >
                                      September
                                    </option>
                                    <option
                                      value='October
'
                                    >
                                      October
                                    </option>
                                    <option
                                      value='November
'
                                    >
                                      November
                                    </option>
                                    <option
                                      value='December
'
                                    >
                                      December
                                    </option>
                                  </select>
                                </div>
                                <div className='col-3'>
                                  <button
                                    className='btn btn-m btn-info'
                                    style={{ marginTop: '44px' }}
                                    onClick={() =>
                                      handleSearch(month, DoctorID)
                                    }
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            )}

                            {showDate && (
                              <div className='row form-group py-3'>
                                <div className='col-4 py-2'>
                                  <label htmlFor=''>Start Date</label>
                                  <input
                                    type='date'
                                    className='form-control rounded-2'
                                    onChange={e => setStartDate(e.target.value)}
                                  />
                                </div>
                                <div className='col-4 py-2'>
                                  <label htmlFor=''>End Date</label>
                                  <input
                                    type='date'
                                    className='form-control rounded-2'
                                    onChange={e => setEndDate(e.target.value)}
                                  />
                                </div>

                                <div className='col-3'>
                                  <button
                                    className='btn btn-m btn-info'
                                    style={{ marginTop: '44px' }}
                                    onClick={() => handleDate(DoctorID)}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            )}
                            <div className='row'>
                              <div className='col-4'>
                                <div className='form-group'>
                                  <label>Previous Collect Amount</label>

                                  <input
                                    type='number'
                                    value={
                                      collectAmount
                                        ? collectAmount.toFixed(2)
                                        : ''
                                    }
                                    className='form-control'
                                  />
                                </div>
                              </div>

                              <div className='col-4'>
                                <div className='from-group'>
                                  <label>Collect Date</label>

                                  <input
                                    type='date'
                                    className='form-control'
                                    onChange={e => setDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className='col-4'>
                                <div className='form-group'>
                                  <label>Remark</label>

                                  <input
                                    type='text'
                                    name=''
                                    id=''
                                    cols='30'
                                    rows='3'
                                    className='form-control'
                                    onChange={e => setRemark(e.target.value)}
                                  ></input>
                                </div>
                              </div>
                            </div>

                            <div className='offset-5 mt-5'>
                              <button
                                className='btn btn-sm btn-success'
                                onClick={Collect}
                              >
                                Collect
                              </button>
                              <button
                                onClick={handleDetail}
                                className='btn btn-sm btn-info ml-3'
                              >
                                Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {showDetail && (
                        <div className='col-12 card px-3 py-3'>
                          <div class='card-body'>
                            <div className='row card-header'>
                              <div className='col-6'>
                                <h5 className='fs-4'>
                                  {doctorName}'s Comissions Detail
                                </h5>
                              </div>
                              <div className='offset-2 col-4'>
                                <div className='row'>
                                  <div className='col-6'>
                                    <div className='btn btn-sm btn-dark'>
                                      Amount Total : 0
                                    </div>
                                  </div>
                                  <div className='col-6'>
                                    <div className='btn btn-sm btn-dark'>
                                      Previous Amount :0
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class='col-md-12 py-3'>
                              <div class='row' id='trial_balance'>
                                <div
                                  class='table-responsive text-black'
                                  id='slimtest2'
                                >
                                  <table
                                    class='table table-hover'
                                    id='filter_date'
                                  >
                                    <thead class='bg-info text-white'>
                                      <tr>
                                        <th>#</th>

                                        {/* <th>Bank / Cash Account</th>  */}
                                        <th>Treatment Name</th>
                                        <th>Appointment Date</th>
                                        <th>Amount</th>
                                        <th>Speciality</th>

                                        <th>Commission</th>
                                      </tr>
                                    </thead>

                                    {search.map((doctor, i) => (
                                      <tbody className=''>
                                        <tr>
                                          <td>{++i}</td>

                                          <td>
                                            {
                                              doctor.relatedDoctor
                                                .treatmentUnitMain
                                            }
                                          </td>
                                          <td>
                                            {doctor.relatedAppointment.date
                                              ? doctor.relatedAppointment.date.split(
                                                'T'
                                              )[0]
                                              : ''}
                                          </td>
                                          <td>
                                            {doctor.commissionAmount
                                              ? doctor.commissionAmount.toFixed(
                                                2
                                              )
                                              : ''}
                                          </td>
                                          <td>
                                            {doctor.relatedDoctor.speciality}
                                          </td>

                                          <td>
                                            {doctor.relatedDoctor.commission}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </table>
                                </div>
                              </div>
                            </div>
                            <button
                              onClick={handleOrigin}
                              className='btn btn-sm btn-info ml-3'
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <CollectDialog open={open} close={() => setOpen(false)} /> */}
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default CatRegister

import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import apiInstance from '../../../../utils/api'
import { useState, useEffect } from 'react'
import {
  Redirect,
  useLocation,
  Link,
  Navigate,
  useNavigate
} from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import SideBar from '../../SideBar'
import { FaArrowLeft } from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'

export default function MedicineUpdate() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })
  const [category, setCategory] = useState('')

  const [subCategory, setSubCategory] = useState('')
  const [upCat, setUpCat] = useState('')
  const [upSubCat, setUpSubCat] = useState('')

  const [categoryID, setCategoryID] = useState('')
  const [subCategoryID, setSubCategoryID] = useState('')

  const [categoryLists, setCategoryLists] = useState([])
  const [subCategoryLists, setSubCategoryLists] = useState([])

  const [medicineLists, setMedicineLists] = useState([])
  const [relatedBrandList, setRelatedBrandList] = useState([])
  const [upRelatedBrand, setUpRelatedBrand] = useState('')
  const [upCode, setUpCode] = useState('')
  const [upName, setUpName] = useState('')
  const [upDesc, setUpDesc] = useState('')
  const [showUpdate, setShowUpdate] = useState(false)
  const [upRelatedBrandID, setUpRelatedBrandID] = useState('')

  const [description, setDescription] = useState('')
  const MedicineId = useLocation().pathname.split('/')[2]

  const MedicineUpdate = () => {
    const data = {
      id: MedicineId,
      name: upName,
      code: upCode,
      //   relatedBrand: upRelatedBrand,
      description: upDesc
    }
    //brand
    if (upRelatedBrandID) {
      data.relatedBrand = upRelatedBrandID
    } else {
      data.relatedBrand = upRelatedBrand
    }
    //category

    if (categoryID) {
      data.relatedCategory = categoryID
    } else {
      data.relatedCategory = category
    }

    //sub category
    if (subCategoryID) {
      data.relatedSubCategory = subCategoryID
    } else {
      data.relatedSubCategory = subCategory
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('medicine-list', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        // window.location.reload()

        // setMedicineLists([...medicineLists, response.data.list[0]])
        setMedicineLists(
          medicineLists.map(category => {
            if (category._id === response.data.list[0]._id) {
              return response.data.list[0]
            } else {
              return category
            }
          })
        )
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
  }

  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await apiInstance.get('categories')

        setCategoryLists(res.data.data)
      } catch (err) { }
    }
    const getSubCategory = async () => {
      try {
        const res = await apiInstance.get('sub-categories')

        setSubCategoryLists(res.data.data)
      } catch (err) { }
    }

    const getBrandLists = async () => {
      try {
        const res = await apiInstance.get('brands')
        setRelatedBrandList(res.data.data)
      } catch (err) {
        alert(err.message)
      }
    }

    const getMedicine = async () => {
      try {
        const res = await apiInstance.get('medicine-list/' + MedicineId)
        console.log(res.data.data[0])
        setUpCode(res.data.data[0].code)
        setUpName(res.data.data[0].name ? res.data.data[0].name : '')
        setUpDesc(res.data.data[0].description)
        setUpRelatedBrand(
          res.data.data[0].relatedBrand
            ? res.data.data[0].relatedBrand.name
            : ''
        )
        setUpRelatedBrandID(
          res.data.data[0].relatedBrand ? res.data.data[0].relatedBrand._id : ''
        )
        setUpCat(
          res.data.data[0].relatedCategory
            ? res.data.data[0].relatedCategory.name
            : ''
        )
        setCategoryID(
          res.data.data[0].relatedCategory
            ? res.data.data[0].relatedCategory._id
            : ''
        )
        setUpSubCat(
          res.data.data[0].relatedSubCategory
            ? res.data.data[0].relatedSubCategory.name
            : ''
        )
        setSubCategoryID(
          res.data.data[0].relatedSubCategory
            ? res.data.data[0].relatedSubCategory._id
            : ''
        )
        // console.log(res.data.data[0].relatedBrand._id)
      } catch (err) {
        alert(err.message)
      }
    }

    getCategory()
    getSubCategory()

    getMedicine()
    getBrandLists()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/medicine_list'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active'>Medicine Update</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='col-md-12'>
                        <h3 className='card-header mb-4 fw-5 text-secondary py-3'>
                          Update Medicine
                        </h3>
                        <div class='modal-body'>
                          <div class='form-group'>
                            <label for='code'>Code</label>
                            <input
                              type='text'
                              class='form-control border border-info'
                              name='code'
                              defaultValue={upCode}
                              onChange={e => setUpCode(e.target.value)}
                            />
                          </div>
                          <div class='form-group'>
                            <label for='name'>Name</label>
                            <input
                              type='text'
                              class='form-control border border-info'
                              name='name'
                              defaultValue={upName}
                              onChange={e => setUpName(e.target.value)}
                            />
                          </div>
                          <div class='form-group'>
                            <label for='name'>Category</label>
                            <select
                              class='custom-select border-info'
                              name='account_type_id'
                              id='cat'
                              onChange={e => {
                                setCategory(e.target.value)
                                setCategoryID(e.target.value)
                              }}
                            >
                              <option value={categoryID} hidden>
                                {upCat}
                              </option>
                              {categoryLists.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class='form-group'>
                            <label for='name'>Sub Category</label>
                            <select
                              class='custom-select border-info'
                              id='subCat'
                              name='account_type_id'
                              onChange={e => {
                                setSubCategory(e.target.value)
                                setSubCategoryID(e.target.value)
                              }}
                            >
                              <option value={subCategoryID} hidden>
                                {upSubCat}
                              </option>
                              {subCategoryLists.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class='form-group'>
                            <label for='name'>Choose Brand</label>
                            <select
                              name='currency'
                              id=''
                              className='form-control mt-1'
                              onchange='convert(this.value)'
                              onChange={e => {
                                setUpRelatedBrand(e.target.value)
                                setUpRelatedBrandID(e.target.value)
                              }}
                            >
                              <option value={upRelatedBrandID} hidden>
                                {upRelatedBrand}
                              </option>

                              {relatedBrandList.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class='form-group'>
                            <label for='name'>Description</label>
                            <textarea
                              className='form-control'
                              id='description'
                              defaultValue={upDesc}
                              onChange={e => setUpDesc(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div class='modal-footer'>
                          <Link
                            to='/medicine_list'
                            class='btn btn-secondary'
                            data-dismiss='modal'
                          >
                            Close
                          </Link>
                          <button
                            class='btn btn-primary'
                            onClick={MedicineUpdate}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer mt-5'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  )
}

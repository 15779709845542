import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import SideBar from './SideBar'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Button } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import Swal from 'sweetalert2'
import HeaderName from './HeaderName'
import BrokenIcon from '../views/Admin/DamageList/broken.png'
import { Link } from 'react-router-dom'
import {
  FaClipboardList,
FaCalculator,
  FaFileExport,
  FaAngleDoubleRight
} from 'react-icons/fa'
import { useSelector } from 'react-redux'
import apiInstance from '../../utils/api'
function Stock (props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

    const scroll = {
    height: '600px',
    overflowX: 'scroll'
  }
  // const scroll = {
  //   height: "600px",
  
  //   overflowX: "scroll",
  // };

  const [searchProResults, setSearchProResults] = useState([])
  const [showProSearch, setShowProSearch] = useState(false)
  const [hidePro, setHidePro] = useState(true)
  const [proName, setProName] = useState('')

  const [searchMedResults, setSearchMedResults] = useState([])
  const [showMedSearch, setShowMedSearch] = useState(false)
  const [hideMed, setHideMed] = useState(true)
  const [medName, setMedName] = useState('')

  const [searchProAccResults, setSearchProAccResults] = useState([])
  const [showProAccSearch, setShowProAccSearch] = useState(false)
  const [hideProAcc, setHideProAcc] = useState(true)
  const [proAccName, setProAccName] = useState('')

  const [relatedProBranch, setRelatedProBranch] = useState([])
  const [showBranch, setShowBranch] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [proUnitLists, setProUnitLists] = useState([])
  const [medUnitLists, setMedUnitLists] = useState([])
  const [proAssLists, setProAssLists] = useState([])
  const [showMedicine, setShowMedicine] = useState(true)
  const [showProUnit, setShowProUnit] = useState(false)
  const [showProAss, setShowProAss] = useState(false)
  const [type, setType] = useState('')

  const [branchesLists, setBranchesLists] = useState([])

  const [mQty, setMQty] = useState('')

  const [mTotal, setMTotal] = useState('')
  const [mSell, setMSell] = useState(0)
  const [mPurchase, setMPurchase] = useState(0)

  const [pmQty, setPMQty] = useState('')

  const [pmTotal, setPMTotal] = useState('')
  const [pmSell, setPMSell] = useState(0)
  const [pmPurchase, setPMPurchase] = useState(0)

  const [paQty, setPAQty] = useState('')

  const [paTotal, setPATotal] = useState('')
  const [paSell, setPASell] = useState(0)
  const [paPurchase, setPAPurchase] = useState(0)
  const [medTotal, setMedTotal] = useState('')
  const [proTotal, setProTotal] = useState('')
  const [proAssTotal, setProAssTotal] = useState('')
  const [typeFilterList, setTypeFilterList] = useState([])
  const [showType, setShowType] = useState(false)

//related med
    const [reMedSell, setReMedSell] = useState('')
  const [reMedPurchase, setReMedPurchase] = useState('')
  const [relatedLists, setRelatedLists] = useState([])
  const [medID, setMedID] = useState('')
  const [medPrice, setMedPrice] = useState('')
  const [relatedMedList, setRelatedMedList] = useState([])

    //related pro
  const [reProSell, setReProSell] = useState('')
  const [reProPurchase, setReProPurchase] = useState('')
  const [relatedProLists, setRelatedProLists] = useState([])
  // const [isShow, setIsShow] = useState(false)
  const [proMedPrice, setProMedPrice] = useState('')
  const [relatedProList, setRelatedProList] = useState([])

  //related proAcc
  const [reAccSell, setReAccSell] = useState('')
  const [reAccPurchase, setReAccPurchase] = useState('')
  const [relatedAccLists, setRelatedAccLists] = useState([])
  const [proAccPrice, setProAccPrice] = useState('')
  const [relatedProAccList, setRelatedProAccList] = useState([])
  const [proId, setProId] = useState('')
  // const [proName, setProName] = useState('')

  const ButtonStyle = { margin: '10px 10px' }
  const ErrorColor = { border: 'red' }

  const handleUpdateUnitCalculation = (event, proUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'currentQuantity') {
      let uQty = parseInt(event)
      let total = uQty * proUnit.toUnit
      console.log(uQty)
      console.log(total)
      setPMTotal(total)
      setPMQty(event)
      const newData = proUnitLists.map(data => {
        if (data._id === proUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setProUnitLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * proUnit.currentQuantity
      setPMTotal(total)
      console.log(total)
      const newData = proUnitLists.map(data => {
        if (data._id === proUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setProUnitLists(newData)
    }
  }

  const handleMUpdateUnitCalculation = (event, medUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'currentQuantity') {
      let uQty = parseInt(event)
      let total = uQty * medUnit.toUnit
      console.log(uQty)
      console.log(total)
      setMTotal(total)
      setMQty(event)
      const newData = medUnitLists.map(data => {
        if (data._id === medUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setMedUnitLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * medUnit.currentQuantity
      setMTotal(total)
      console.log(total)
      const newData = medUnitLists.map(data => {
        if (data._id === medUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setMedUnitLists(newData)
    }
  }

  const handlePAUpdateUnitCalculation = (event, paUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'currentQuantity') {
      let uQty = parseInt(event)
      let total = uQty * paUnit.toUnit
      console.log(uQty)
      console.log(total)
      setPATotal(total)
      setPAQty(event)
      const newData = proAssLists.map(data => {
        if (data._id === paUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setProAssLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * paUnit.currentQuantity
      setPATotal(total)
      console.log(total)
      const newData = proAssLists.map(data => {
        if (data._id === paUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData)
      setProAssLists(newData)
    }
  }

  const handlePMUpdate = proUnit => {
    //console.log(proId,'pro')
    const data = {
      id: proUnit._id,
      currentQuantity: proUnit.currentQuantity,
      fromUnit: proUnit.fromUnit,
      toUnit: proUnit.toUnit,
      totalUnit: proUnit.totalUnit
    }

    if (pmPurchase > 0) {
      data.purchasePrice = parseInt(pmPurchase)
    }
    if (pmSell > 0) {
      data.sellingPrice = parseInt(pmSell)
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('procedure-item', data, config)
      .then(function (response) {
        console.log(response.data)
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',

          cancelButtonText: 'Close'
        })
        //         // window.location.reload()

        //         // setUnitLists([...unitLists,response.data.list])

        //         // setUnitLists(
        //         //   unitLists.map(category => {
        //         //     if (category._id === response.data.list._id) {
        //         //       return response.data.list
        //         //     } else {
        //         //       return category
        //         //     }
        //         //   })
        //         // )
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  }

  const handleMUpdate = medUnit => {
    //console.log(proId,'pro')
    const data = {
      id: medUnit._id,
      currentQuantity: medUnit.currentQuantity,
      fromUnit: medUnit.fromUnit,
      toUnit: medUnit.toUnit,
      totalUnit: medUnit.totalUnit
    }

    if (mPurchase > 0) {
      data.purchasePrice = parseInt(mPurchase)
    }
    if (mSell > 0) {
      data.sellingPrice = parseInt(mSell)
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('medicine-item', data, config)
      .then(function (response) {
        console.log(response.data)
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',

          cancelButtonText: 'Close'
        })
        //         // window.location.reload()

        //         // setUnitLists([...unitLists,response.data.list])

        //         // setUnitLists(
        //         //   unitLists.map(category => {
        //         //     if (category._id === response.data.list._id) {
        //         //       return response.data.list
        //         //     } else {
        //         //       return category
        //         //     }
        //         //   })
        //         // )
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  }

  const handlePAUpdate = paUnit => {
    //console.log(proId,'pro')
    const data = {
      id: paUnit._id,
      currentQuantity: paUnit.currentQuantity,
      fromUnit: paUnit.fromUnit,
      toUnit: paUnit.toUnit,
      totalUnit: paUnit.totalUnit
    }

    if (paPurchase > 0) {
      data.purchasePrice = parseInt(paPurchase)
    }
    if (paSell > 0) {
      data.sellingPrice = parseInt(paSell)
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('accessory-item', data, config)
      .then(function (response) {
        console.log(response.data)
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',

          cancelButtonText: 'Close'
        })
        //         // window.location.reload()

        //         // setUnitLists([...unitLists,response.data.list])

        //         // setUnitLists(
        //         //   unitLists.map(category => {
        //         //     if (category._id === response.data.list._id) {
        //         //       return response.data.list
        //         //     } else {
        //         //       return category
        //         //     }
        //         //   })
        //         // )
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  }

  const handleMedUnitChange = () => {
    setShowMedicine(true)
    setShowProUnit(false)
    setShowProAss(false)
    setHideMed(true)
    setShowBranch(false)
    setShowType(false)
    if (type) {
      window.location.reload()
    }
  }

  const handleProUnitChange = () => {
    setShowMedicine(false)
    setShowProUnit(true)
    setShowProAss(false)
    setHidePro(true)
    setShowBranch(false)
  }

  const handleProAssUnitChange = () => {
    setShowProAss(true)
    setShowMedicine(false)
    setShowProUnit(false)
    setShowBranch(false)
    setHideProAcc(true)
  }

  const _export1 = React.useRef(null)
  const _export2 = React.useRef(null)
  const _export3 = React.useRef(null)

  const excelExport1 = () => {
    if (_export1.current !== null) {
      console.log(_export1.current.props.data, 'excel')

      _export1.current.props.data.map(function (element, index) {
        element.code = element.code
      })

      _export1.current.save()
    }
  }

  const excelExport2 = () => {
    if (_export2.current !== null) {
      console.log(_export2.current.props.data, 'excel')
      if (_export2.current.props.data === { medUnitLists }) {
        _export2.current.props.data.map(function (element, index) {
          element.code = element.code
        })
      }
      _export2.current.save()
    }
  }

  const excelExport3 = () => {
    if (_export3.current !== null) {
      console.log(_export3.current.props.data, 'excel')
      _export3.current.props.data.map(function (element, index) {
        element.code = element.code
      })
      _export3.current.save()
    }
  }

  useEffect(() => {
    const getProUnitLists = async () => {
      try {
        const res = await apiInstance.get('procedure-items?limit=30')
        setProUnitLists(res.data.list)

        const totalPrice = res.data.list.reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.purchasePrice * currentValue.currentQuantity,
          0
        )
        setProTotal(totalPrice)

        setProId(res.data.list._id)
        // if (setProUnitLists.reOrderQuantity === 0) {
        // }
      } catch (err) {
        alert(err.message)
      }
    }

    const getMedUnitLists = async () => {
      try {
        const res = await apiInstance.get('medicine-items?limit=30')

        setMedUnitLists(res.data.list)
        // console.log(res.data.list,'mmmmm')
        const totalPrice = res.data.list.reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.purchasePrice * currentValue.currentQuantity,
          0
        )
        setMedTotal(totalPrice)
      } catch (err) {
        alert(err.message)
      }
    }

    // const getBranches = async () => {
    //   try {
    //     const res = await axios.get(apiInstanceURL + 'branches')
    //     setBranchesLists(res.data.list)
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }

    const getProAssLists = async () => {
      try {
        const res = await apiInstance.get('accessory-items?limit=30')
        setProAssLists(res.data.list)
        const totalPrice = res.data.list.reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.purchasePrice * currentValue.currentQuantity,
          0
        )
        setProAssTotal(totalPrice)

        console.log(setProAssLists._id)
      } catch (err) {
        alert(err.message)
      }
    }

    getProAssLists()
    getMedUnitLists()
    getProUnitLists()
  }, [])

  const handleType = type => {
    setType(type)
    if (type === 'All') {
      setShowType(false)
      setShowMedSearch(false)
      setHideMed(true)
    } else {
      setTypeFilterList(medUnitLists.filter(el => el.itemType === type))
      setShowType(true)
      setShowMedSearch(false)
      setHideMed(false)
    }
    // if (handleProUnitChange() || handleProAssUnitChange()) {
    //   setHideMed(window.location.reload())
    // }
  }
  const search = event => {
    if (event) {
      setSearchProResults(
        proUnitLists.filter(
          el =>
            (el.procedureItemName ? el.procedureItemName : '')
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            (el.code ? el.code : '').toLowerCase().includes(event.toLowerCase())
        )
      )

      setShowProSearch(true)
      setShowMedSearch(false)
      setShowProAccSearch(false)

      setHidePro(false)
    } else {
      setShowProSearch(false)
      setHidePro(true)
    }
  }
  const medSearch = medName => {
    // console.log(medName, 'name')
    // console.log(medUnitLists, 'med list')

    if (medName) {
      setSearchMedResults(
        medUnitLists.filter(
          el =>
            el?.medicineItemName
              .toLowerCase()
              .includes(medName.toLowerCase()) ||
            (el?.code).toLowerCase().includes(medName.toLowerCase())
        )
      )

      setShowMedSearch(true)
      setShowProSearch(false)
      setShowProAccSearch(false)

      setHideMed(false)
    } else {
      setShowMedSearch(false)
      setShowProSearch(false)
      setShowProAccSearch(false)

      setHideMed(true)
    }
  }

  const proAccSearch = event => {
    // console.log(event, 'name')
    if (event) {
      setSearchProAccResults(
        proAssLists.filter(
          el =>
            (el?.accessoryItemName)
              .toLowerCase()
              .includes(event.toLowerCase()) ||
            el.code.toLowerCase().includes(event.toLowerCase())
        )
      )

      setShowProAccSearch(true)
      setHideProAcc(false)
    } else {
      setShowProAccSearch(false)
      setHideProAcc(true)
    }
  }

  const handleMedStockQtyCalculate = () => {
    const data = {
      medicineItems: medUnitLists.map((i)=>(i._id)),
      procedureItems: [],
      accessoryItems: []
    }
    const config= {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.put('items/confirm',data,config) .then(function (response) {
        console.log(response.data)
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Calculated',
          icon: 'success',

          cancelButtonText: 'Close'
        })
   
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
  }
  
  const handleProStockQtyCalculate = () => {
        const data = {
      medicineItems: [],
      procedureItems: proUnitLists.map((i)=>(i._id)),
      accessoryItems: []
    }
    const config= {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.put('items/confirm',data,config) .then(function (response) {
        console.log(response.data)
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Calculated',
          icon: 'success',

          cancelButtonText: 'Close'
        })
   
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
  }

  const handleProAssStockQtyCalculate = () => {
            const data = {
      medicineItems: [],
      procedureItems: [],
      accessoryItems: proAssLists.map((i)=>(i._id))
    }
    const config= {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.put('items/confirm',data,config) .then(function (response) {
        console.log(response.data)
        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Calculated',
          icon: 'success',

          cancelButtonText: 'Close'
        })
   
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
  }

  // related Med 
    const handleRelatedMedShow = val => {
  
      console.log(val,'val')
      console.log(medUnitLists.filter(el => el?._id === val)[0].purchaseHistory, 'rtgyuhio')
      setRelatedLists(medUnitLists.filter(el => el?._id === val)[0].purchaseHistory)
        if (isShow) {
          document.getElementById('toggle' + val).removeAttribute('hidden')
        } else {
          document
            .getElementById('toggle' + val)
            .setAttribute('hidden', 'hidden')
        }
        setIsShow(!isShow)
      
  }
    const handleReMedUpdate = (reList,medUnit) => {
    //console.log(proId,'pro')
    const data = {
      medicineItemId: medUnit._id,
      purchaseHistoryId: reList._id,
      unit: reList.unit,
      currentQuantity:parseInt(mQty ? mQty :reList.currentQuantity),
      totalUnit:mQty ? (mQty*medUnit?.toUnit / medUnit?.fromUnit) : reList?.totalUnit,
   
      // purchasePrice: reList.purchasePrice
    }

    if (reMedPurchase > 0) {
      data.purchasePrice = parseInt(reMedPurchase)
    } else {
       data.purchasePrice = reList.purchasePrice
    }
    if (reMedSell > 0) {
      data.sellingPrice = parseInt(reMedSell)
    } else {
      data.sellingPrice = reList.sellingPrice
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('purchase-history', data, config)
      .then(function (response) {
        console.log(response.data)

        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',

          showCancelButton: false,
          timer:2000
        })
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text:err.message,
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  }
   const handleReMedUpdateUnitCalculation = (event, medUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'qty') {
      let uQty = parseInt(event)
      let total = uQty * medUnit.toUnit

      setMTotal(total)
      setMQty(event)
      const newData = medUnit.purchaseHistory.map(data => {
        if (data._id === medUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      // console.log(newData, 'total')
      setRelatedLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * medUnit.qty
      setMTotal(total)
      console.log(total, 'total')
      const newData = medUnit.purchaseHistory.map(data => {
        if (data._id === medUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData, 'total')
      setRelatedLists(newData)
    }
  }

  // realted procedure
    const handleRelatedProShow = val => {
  
      console.log(val,'val')
      console.log(proUnitLists.filter(el => el?._id === val)[0].purchaseHistory, 'rtgyuhio')
      setRelatedLists(proUnitLists.filter(el => el?._id === val)[0].purchaseHistory)
        if (isShow) {
          document.getElementById('toggle' + val).removeAttribute('hidden')
        } else {
          document
            .getElementById('toggle' + val)
            .setAttribute('hidden', 'hidden')
        }
        setIsShow(!isShow)
      
  }
  
    const handleReProUpdateUnitCalculation = (event, proUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'qty') {
      let uQty = parseInt(event)
      let total = uQty * proUnit.toUnit

      setMTotal(total)
      setMQty(event)
      const newData = proUnit.purchaseHistory.map(data => {
        if (data._id === proUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      // console.log(newData, 'total')
      setRelatedLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * proUnit.qty
      setMTotal(total)
      console.log(total, 'total')
      const newData = proUnit.purchaseHistory.map(data => {
        if (data._id === proUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData, 'total')
      setRelatedLists(newData)
    }
  }
  
      const handleReProUpdate = (reList,proUnit) => {
    //console.log(proId,'pro')
    const data = {
      procedureItemId: proUnit._id,
      purchaseHistoryId: reList._id,
   unit:reList.unit,
      // purchasePrice: reList.purchasePrice
    }

    if (reProPurchase > 0) {
      data.purchasePrice = parseInt(reProPurchase)
    } else {
       data.purchasePrice = reList.purchasePrice
    }
    if (reProSell > 0) {
      data.sellingPrice = parseInt(reProSell)
    } else {
      data.sellingPrice = reList.sellingPrice
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('purchase-history', data, config)
      .then(function (response) {
        console.log(response.data)

        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',

          showCancelButton: false,
          timer:2000
        })
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text:err.message,
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  }

  // relate pro Acc
      const handleRelatedAccShow = val => {
  
      console.log(val,'val')
      console.log(proAssLists.filter(el => el?._id === val)[0].purchaseHistory, 'rtgyuhio')
      setRelatedLists(proAssLists.filter(el => el?._id === val)[0].purchaseHistory)
        if (isShow) {
          document.getElementById('toggle' + val).removeAttribute('hidden')
        } else {
          document
            .getElementById('toggle' + val)
            .setAttribute('hidden', 'hidden')
        }
        setIsShow(!isShow)
      
  }

    const handleReAccUpdateUnitCalculation = (event, proAssUnit, type) => {
    // console.log(qty, 'qty')
    // console.log(event, 'event')

    if (type === 'qty') {
      let uQty = parseInt(event)
      let total = uQty * proAssUnit.toUnit

      setMTotal(total)
      setMQty(event)
      const newData = proAssUnit.purchaseHistory.map(data => {
        if (data._id === proAssUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      // console.log(newData, 'total')
      setRelatedLists(newData)
    } else if (type === 'toUnit') {
      let total = parseInt(event) * proAssUnit.qty
      setMTotal(total)
      console.log(total, 'total')
      const newData = proAssUnit.purchaseHistory.map(data => {
        if (data._id === proAssUnit._id) {
          return { ...data, [type]: parseInt(event), totalUnit: total }
        }
        return data
      })
      console.log(newData, 'total')
      setRelatedLists(newData)
    }
  }

        const handleReAccUpdate = (reList,proAssUnit) => {
    //console.log(proId,'pro')
    const data = {
      accessoryItemId: proAssUnit._id,
      purchaseHistoryId: reList._id,
   unit:reList.unit,
      // purchasePrice: reList.purchasePrice
    }

    if (reAccPurchase > 0) {
      data.purchasePrice = parseInt(reAccPurchase)
    } else {
       data.purchasePrice = reList.purchasePrice
    }
    if (reAccSell > 0) {
      data.sellingPrice = parseInt(reAccSell)
    } else {
      data.sellingPrice = reList.sellingPrice
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('purchase-history', data, config)
      .then(function (response) {
        console.log(response.data)

        Swal.fire({
          title: 'Successful!',
          text: 'Successfully Updated',
          icon: 'success',

          showCancelButton: false,
          timer:2000
        })
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text:err.message,
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  }
  
  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />
        <SideBar />
        {/* {props.hide && <SideBar />} */}
        {/* <!-- /.navbar --> */}
        {/* <!-- Main Sidebar Container --> */}
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Stock Count & Price
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-1 col-md-4 ml-5'>
                  <div
                    className='input-group row ml-5'
                    style={{ marginBottom: '20px' }}
                  >
                    {/* <div className="col-md-6 mt-1">
                      <button
                        type="button"
                        className="btn  btn-primary ml-4"
                        style={{ borderRadius: "7px" }}
                        onClick={showDialog}>
                        Add Item
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={e =>
                                  handleMedUnitChange(e.target.value)
                                }
                              >
                                Medicine Items
                              </Button>
                            </div>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={e =>
                                  handleProUnitChange(e.target.value)
                                }
                              >
                                Procedure Items
                              </Button>
                            </div>
                            <div className='col-md-4'>
                              <Button
                                style={ButtonStyle}
                                onClick={e =>
                                  handleProAssUnitChange(e.target.value)
                                }
                              >
                                Procedure Accessories
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {/* For Procedure Medicine Items */}
                      <ExcelExport data={proUnitLists} ref={_export1}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='procedureItemName'
                          title='Procedure Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End        */}

                      {/* For Medicine Items */}
                      <ExcelExport data={medUnitLists} ref={_export2}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='medicineItemName'
                          title='Medicine Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      {/* End     */}

                      {/* For Procedure Accessory FilterItems */}
                      <ExcelExport data={proAssLists} ref={_export3}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='code'
                          title='Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='accessoryItemName'
                          title='Procedure Accessory Item Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='currentQuantity'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='fromUnit'
                          title='From Unit'
                          width={150}
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                        />
                        <ExcelExportColumn
                          field='toUnit'
                          title='To Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='totalUnit'
                          title='Total Unit'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='purchasePrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='actualStock'
                          title='Actual Stock'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='different'
                          title='Different'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={150}
                        />
                      </ExcelExport>

                      {/* End */}

                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12' >
                          <div className='scrollme' style={{scroll}} >
                            <table className='table table-hover table-responsive'  >
                              {showProUnit && (
                                <div>
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-2'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-dark'
                                        onClick={excelExport1}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                      <b className='btn btn-sm btn-dark'>
                                        Total Inventory Value :{' '}
                                        {proTotal ? proTotal.toFixed(2) : '0'}{' '}
                                        MMK
                                      </b>
                                    </div>

                                    <div className='col-md-3 mr-2'>
                                      <div className='row'>
                                        <div className='offset-3 col-md-8'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              search(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>
                                        
                                      </div>
                                    </div>
                                      <div className='col-2 mt-2'>
                                                <button
                                      onClick={handleProStockQtyCalculate}
                                            className='btn btn-success'
                                        
                                          >
                                         <FaCalculator />
                                          </button> &nbsp;
                                           {/* <button className='btn btn-md btn-success'><FaClipboardList /></button> */}
                                          
                                        </div>
                                  </div>
                                  <div >
                                    <thead className='sticky-top text-white' >
                                      <tr>
                                        <th
                                          style={{ fontWeight: '400px' }}
                                          className='text-center'
                                        >
                                          #
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Code
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Name
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Qty
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          From Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          To Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Total Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Selling( MMK )
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Purchase( MMK )
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Unit
                                        </th>
    <th>Opening Qty</th>
                                        <th>Purchase Qty</th>
                                        <th>Used Qty</th>
                                        <th>Closing Qty</th>
                                        <th
                                          className='text-center'
                                          style={{ fontWeight: '400px' }}
                                        >
                                          Related
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {hidePro &&
                                        proUnitLists.map((proUnit, i) => (
                                          <>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>{proUnit.code}</td>
                                            <td>{proUnit.procedureItemName}</td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proUnit.currentQuantity
                                                }
                                                onChange={e =>
                                                  handleUpdateUnitCalculation(
                                                    e.target.value,
                                                    proUnit,
                                                    'currentQuantity'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={proUnit.fromUnit}
                                                //  onChange={(e)=>setFrom(e.target.value)}
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={proUnit.toUnit}
                                                onChange={e =>
                                                  handleUpdateUnitCalculation(
                                                    e.target.value,
                                                    proUnit,
                                                    'toUnit'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                value={
                                                  (proUnit.toUnit *
                                                    proUnit.currentQuantity) /
                                                  proUnit.fromUnit
                                                }
                                                className='form-control'
                                                disabled
                                                //onChange={(e)=>setPMTotal(e.target.value)}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proUnit.sellingPrice
                                                }
                                                onChange={e =>
                                                  setPMSell(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proUnit.purchasePrice
                                                }
                                                onChange={e =>
                                                  setPMPurchase(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>PCS</td>
                                            <td>{ proUnit.openingQty ?  proUnit.openingQty : 0}</td>
                                            <td>{ proUnit.purchaseQty ?  proUnit.purchaseQty : 0}</td>
                                            <td>{ proUnit.useQty ?  proUnit.useQty : 0}</td>
                                            <td>{ proUnit.closingQty ?  proUnit.closingQty : 0}</td>
                                             <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedProShow(
                                                    proUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                          </tr>
                                                   <tr
                                            className='bg-light'
                                            id={'toggle' + proUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReProUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    proUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * proUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:proUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                              
                                                                  <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  />
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReProUpdate(
                                                                    reList,proUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          </>
                                        ))}

                                      {showProSearch &&
                                        searchProResults.map((proUnit, i) => (
                                          <>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>{proUnit.code}</td>
                                            <td>{proUnit.procedureItemName}</td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proUnit.currentQuantity
                                                }
                                                onChange={e =>
                                                  handlePAUpdateUnitCalculation(
                                                    e.target.value,
                                                    proUnit,
                                                    'currentQuantity'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={proUnit.fromUnit}
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={proUnit.toUnit}
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    proUnit,
                                                    'toUnit'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                value={
                                                  (proUnit.toUnit *
                                                    proUnit.currentQuantity) /
                                                  proUnit.fromUnit
                                                }
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proUnit.sellingPrice
                                                }
                                                onChange={e =>
                                                  setPASell(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proUnit.purchasePrice
                                                }
                                                onChange={e =>
                                                  setPAPurchase(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>PCS</td>
                                             <td>{ proUnit.openingQty ?  proUnit.openingQty : 0}</td>
                                            <td>{ proUnit.purchaseQty ?  proUnit.purchaseQty : 0}</td>
                                            <td>{ proUnit.useQty ?  proUnit.useQty : 0}</td>
                                            <td>{ proUnit.closingQty ?  proUnit.closingQty : 0}</td>
                                             <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedProShow(
                                                    proUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                          </tr>
                                                    <tr
                                            className='bg-light'
                                            id={'toggle' + proUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReProUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    proUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * proUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReProSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReProPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:proUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                              
                                                                  <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  />
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReProUpdate(
                                                                    reList,proUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          </>
                                        ))}
                                    </tbody>
                                  </div>
                                </div>
                              )}

                              {showMedicine && (
                                <div>
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-2'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-dark'
                                        onClick={excelExport2}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>
                                    </div>

                                    <div className='col-md-3 mt-1'>
                                      <b className='btn btn-sm btn-dark'>
                                        Total Inventory Value :{' '}
                                        {medTotal ? medTotal.toFixed(2) : '0'}{' '}
                                        MMK
                                      </b>
                                    </div>

                                    <div className='col-md-6 mr-2'>
                                      <div className='row'>
                                        <div
                                          className='col-3'
                                          style={{ marginTop: '0.5em' }}
                                        >
                                          <select
                                            className='form-control'
                                            onChange={e =>
                                              handleType(e.target.value)
                                            }
                                          >
                                            <option hidden>Select Type</option>
                                            <option value='All'>All</option>

                                            <option value='Oral'>Oral</option>
                                            <option value='Topical'>
                                              Topical
                                            </option>
                                            <option value='Comestic'>
                                              Comestic
                                            </option>
                                            <option value='Consignment'>
                                              Consignment
                                            </option>
                                            <option value='Accessories'>
                                              Accessories
                                            </option>
                                          </select>
                                        </div>

                                        <div className=' col-md-4'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              medSearch(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>
                                        <div className='col-2 mt-2'>
                                                <button
                                      onClick={handleMedStockQtyCalculate}
                                            className='btn btn-success'
                                        
                                          >
                                         <FaCalculator />
                                          </button> &nbsp;
                                           {/* <button className='btn btn-md btn-success'><FaClipboardList /></button> */}
                                          
                                        </div>
                                  
                                      </div>
                                    </div>
                                  </div>
                                  <div className='' >
                                    <thead className='sticky-top text-white '  >
                                      <tr colspan='16'>
                                        <th
                                          style={{ fontWeight: '400px' }}
                                          className='text-center'
                                        >
                                          #
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Code
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Name
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Qty
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          From Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          To Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Total Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Selling( MMK )
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Purchase( MMK )
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Unit
                                        </th>
                                     
                                        <th>Type</th>
                                        <th>Opening Qty</th>
                                        <th>Purchase Qty</th>
                                        <th>Used Qty</th>
                                        <th>Closing Qty</th>
                                        <th
                                          className='text-center'
                                          style={{ fontWeight: '400px' }}
                                        >
                                          Related
                                        </th>
                                   
                                      </tr>
                                    </thead>
                                    <tbody id='table_body'>
                                      {hideMed &&
                                        medUnitLists.map((medUnit, i) => (
                                          <>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>{medUnit.code}</td>
                                            <td>{medUnit.medicineItemName}</td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.currentQuantity
                                                }
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    medUnit,
                                                    'currentQuantity'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={medUnit.fromUnit}
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={medUnit.toUnit}
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    medUnit,
                                                    'toUnit'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                value={
                                                  (medUnit.toUnit *
                                                    medUnit.currentQuantity) /
                                                  medUnit.fromUnit
                                                }
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.sellingPrice
                                                }
                                                onChange={e =>
                                                  setMSell(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.purchasePrice
                                                }
                                                onChange={e =>
                                                  setMPurchase(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>PCS</td>
                                            <td>
                                              {medUnit.itemType
                                                ? medUnit.itemType
                                                : ''}
                                            </td>
                                            <td>{medUnit.openingQty ? medUnit.openingQty : 0}</td>
                                            <td>{medUnit.purchaseQty ? medUnit.purchaseQty : 0}</td>
                                            <td>{medUnit.useQty ? medUnit.useQty : 0}</td>
                                            <td>{medUnit.closingQty ? medUnit.closingQty : 0}</td>
                                           <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedMedShow(
                                                    medUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                            </tr>
                                                 <tr
                                            className='bg-light'
                                            id={'toggle' + medUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReMedUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    medUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  medUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  medUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * medUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:medUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                            
                                                                  <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  />
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReMedUpdate(
                                                                    reList,medUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          </>
                                        ))}
                                      {showMedSearch &&
                                        searchMedResults.map((medUnit, i) => (
                                          <>
                                        
                                          <tr>
                                            <td>{++i}</td>
                                            <td>{medUnit.code}</td>
                                            <td>{medUnit.medicineItemName}</td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.currentQuantity
                                                }
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    medUnit,
                                                    'currentQuantity'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={medUnit.fromUnit}
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={medUnit.toUnit}
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    medUnit,
                                                    'toUnit'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                value={
                                                  (medUnit.toUnit *
                                                    medUnit.currentQuantity) /
                                                  medUnit.fromUnit
                                                }
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.sellingPrice
                                                }
                                                onChange={e =>
                                                  setMSell(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.purchasePrice
                                                }
                                                onChange={e =>
                                                  setMPurchase(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>PCS</td>
                                            <td>
                                              {medUnit.itemType
                                                ? medUnit.itemType
                                                : ''}
                                            </td>
                                              <td>{medUnit.openingQty ? medUnit.openingQty : 0}</td>
                                            <td>{medUnit.purchaseQty ? medUnit.purchaseQty : 0}</td>
                                            <td>{medUnit.useQty ? medUnit.useQty : 0}</td>
                                            <td>{medUnit.closingQty ? medUnit.closingQty : 0}</td>
                                       <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedMedShow(
                                                    medUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                            </tr>
                                            
                                                     <tr
                                            className='bg-light'
                                            id={'toggle' + medUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReMedUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    medUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  medUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  medUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * medUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:medUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                DD
                                                                  {/* <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  /> */}
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReMedUpdate(
                                                                    reList,medUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                            </tr>
                                            </>
                                        ))}

                                      {showType &&
                                        typeFilterList.map((medUnit, i) => (
                                          <>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>{medUnit.code}</td>
                                            <td>{medUnit.medicineItemName}</td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.currentQuantity
                                                }
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    medUnit,
                                                    'currentQuantity'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={medUnit.fromUnit}
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={medUnit.toUnit}
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    medUnit,
                                                    'toUnit'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                value={
                                                  (medUnit.toUnit *
                                                    medUnit.currentQuantity) /
                                                  medUnit.fromUnit
                                                }
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.sellingPrice
                                                }
                                                onChange={e =>
                                                  setMSell(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  medUnit.purchasePrice
                                                }
                                                onChange={e =>
                                                  setMPurchase(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>PCS</td>
                                            <td>
                                              {medUnit.itemType
                                                ? medUnit.itemType
                                                : ''}
                                            </td>
                                              <td>{medUnit.openingQty ? medUnit.openingQty : 0}</td>
                                            <td>{medUnit.purchaseQty ? medUnit.purchaseQty : 0}</td>
                                            <td>{medUnit.useQty ? medUnit.useQty : 0}</td>
                                            <td>{medUnit.closingQty ? medUnit.closingQty : 0}</td>

                                          <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedMedShow(
                                                    medUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                            </tr>
                                                        <tr
                                            className='bg-light'
                                            id={'toggle' + medUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReMedUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    medUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  medUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  medUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * medUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReMedPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:medUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                              
                                                                  <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  />
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReMedUpdate(
                                                                    reList,medUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                            </tr>
                                            </>
                                        ))}
                                    </tbody>
                                  </div>
                                </div>
                              )}

                              {showProAss && (
                                <div>
                                  <div className='row justify-content-between mb-3'>
                                    <div className='col-md-2'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-dark'
                                        onClick={excelExport3}
                                      >
                                        <FaFileExport />
                                        &nbsp; Export
                                      </button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                      <b className='btn btn-sm btn-dark'>
                                        Total Inventory Value :{' '}
                                        {proAssTotal
                                          ? proAssTotal.toFixed(2)
                                          : '0'}{' '}
                                        MMK
                                      </b>
                                    </div>

                                    <div className='col-md-5 mr-2'>
                                      <div className='row'>
                                        <div className='col-md-6'>
                                          <input
                                            type='search'
                                            className='form-control rounded '
                                            style={{ borderRadius: '17px' }}
                                            onChange={e =>
                                              proAccSearch(e.target.value)
                                            }
                                            id='search_code'
                                            placeholder='Search Items'
                                          />
                                        </div>
                                          <div className='col-4 mt-2'>
                                                <button
                                      onClick={handleProAssStockQtyCalculate}
                                            className='btn btn-success'
                                        
                                          >
                                         <FaCalculator />
                                          </button> &nbsp;
                                           {/* <button className='btn btn-md btn-success'><FaClipboardList /></button> */}
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div >
                                    <thead
                                      style={{ width: '100%' }}
                                      className='sticky-top'
                                    >
                                      <tr className='text-white'>
                                        <th
                                          style={{ fontWeight: '400px' }}
                                          className='text-center'
                                        >
                                          #
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Code
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Name
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Qty
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          From Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          To Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Total Unit
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Selling( MMK )
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Purchase( MMK )
                                        </th>
                                        <th style={{ fontWeight: '400px' }}>
                                          Unit
                                        </th>
        <th>Opening Qty</th>
                                        <th>Purchase Qty</th>
                                        <th>Used Qty</th>
                                        <th>Closing Qty</th>
                                        <th
                                          className='text-center'
                                          style={{ fontWeight: '400px' }}
                                        >
                                          Related
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {hideProAcc &&
                                        proAssLists.map((proAssUnit, i) => (
<>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>{proAssUnit.code}</td>
                                            <td>
                                              {proAssUnit.accessoryItemName}
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proAssUnit.currentQuantity
                                                }
                                                onChange={e =>
                                                  handlePAUpdateUnitCalculation(
                                                    e.target.value,
                                                    proAssUnit,
                                                    'currentQuantity'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proAssUnit.fromUnit
                                                }
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={proAssUnit.toUnit}
                                                onChange={e =>
                                                  handleMUpdateUnitCalculation(
                                                    e.target.value,
                                                    proAssUnit,
                                                    'toUnit'
                                                  )
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                value={
                                                  (proAssUnit.toUnit *
                                                    proAssUnit.currentQuantity) /
                                                  proAssUnit.fromUnit
                                                }
                                                disabled
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proAssUnit.sellingPrice
                                                }
                                                onChange={e =>
                                                  setPASell(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type='number'
                                                defaultValue={
                                                  proAssUnit.purchasePrice
                                                }
                                                onChange={e =>
                                                  setPAPurchase(e.target.value)
                                                }
                                                className='form-control'
                                              />
                                            </td>
                                            <td>PCS</td>
 <td>{ proAssUnit.openingQty ?  proAssUnit.openingQty : 0}</td>
                                            <td>{ proAssUnit.purchaseQty ?  proAssUnit.purchaseQty : 0}</td>
                                            <td>{ proAssUnit.useQty ?  proAssUnit.useQty : 0}</td>
                                            <td>{ proAssUnit.closingQty ?  proAssUnit.closingQty : 0}</td>
                                              <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedAccShow(
                                                    proAssUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                            </tr>
                                                         <tr
                                            className='bg-light'
                                            id={'toggle' + proAssUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReAccUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    proAssUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proAssUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proAssUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * proAssUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReAccSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReAccPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:proAssUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                
                                                                  <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  />
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReAccUpdate(
                                                                    reList,proAssUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                            </tr>
                                            </>
                                        ))}

                                      {showProAccSearch &&
                                        searchProAccResults.map(
                                          (proAssUnit, i) => (
                                            <>
                                            <tr>
                                              <td>{++i}</td>
                                              <td>{proAssUnit.code}</td>
                                              <td>
                                                {proAssUnit.accessoryItemName}
                                              </td>
                                              <td>
                                                <input
                                                  type='number'
                                                  defaultValue={
                                                    proAssUnit.currentQuantity
                                                  }
                                                  onChange={e =>
                                                    handlePAUpdateUnitCalculation(
                                                      e.target.value,
                                                      proAssUnit,
                                                      'currentQuantity'
                                                    )
                                                  }
                                                  className='form-control'
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type='number'
                                                  defaultValue={
                                                    proAssUnit.fromUnit
                                                  }
                                                  disabled
                                                  className='form-control'
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type='number'
                                                  defaultValue={
                                                    proAssUnit.toUnit
                                                  }
                                                  onChange={e =>
                                                    handleMUpdateUnitCalculation(
                                                      e.target.value,
                                                      proAssUnit,
                                                      'toUnit'
                                                    )
                                                  }
                                                  className='form-control'
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type='number'
                                                  value={
                                                    (proAssUnit.toUnit *
                                                      proAssUnit.currentQuantity) /
                                                    proAssUnit.fromUnit
                                                  }
                                                  disabled
                                                  className='form-control'
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type='number'
                                                  defaultValue={
                                                    proAssUnit.sellingPrice
                                                  }
                                                  onChange={e =>
                                                    setPASell(e.target.value)
                                                  }
                                                  className='form-control'
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type='number'
                                                  defaultValue={
                                                    proAssUnit.purchasePrice
                                                  }
                                                  onChange={e =>
                                                    setPAPurchase(
                                                      e.target.value
                                                    )
                                                  }
                                                  className='form-control'
                                                />
                                              </td>
                                              <td>PCS</td>
 <td>{ proAssUnit.openingQty ?  proAssUnit.openingQty : 0}</td>
                                            <td>{ proAssUnit.purchaseQty ?  proAssUnit.purchaseQty : 0}</td>
                                            <td>{ proAssUnit.useQty ?  proAssUnit.useQty : 0}</td>
                                            <td>{ proAssUnit.closingQty ?  proAssUnit.closingQty : 0}</td>
                                              <td className='text-center'>
                                              <button
                                                onClick={() =>
                                                  handleRelatedAccShow(
                                                    proAssUnit._id
                                                  )
                                                }
                                                className='btn btn-sm btn-primary mt-3'
                                              >
                                                Related
                                              </button>
                                            </td>
                                              </tr>
                                                            <tr
                                            className='bg-light'
                                            id={'toggle' + proAssUnit._id}
                                            hidden
                                          >
                                            <td colspan='16' className='offset-1'>
                                              <table className='table table-hover table-primary'>
                                                <thead>
                                                  <th>No</th>
                                                  <th>Purchase Date</th>
                                                  <th>Name</th>
                                                  <th>Qty</th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>Total</th>
                                                  <th>Selling Price</th>

                                                  <th>Purchase Price</th>

                                                  <th>Total Price</th>
                                                  <th>Unit</th>
                                             
                                                  <th>Action</th>
                                                </thead>
                                                <tbody>
                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <tr>
                                                            <td>{++i}</td>
                                                            <td>
                                                              {
                                                                reList.purchaseDate?.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                reList?.name
                                                                  
                                                                  
                                                              }
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.currentQuantity
                                                                }
                                                                onChange={e =>
                                                                  handleReAccUpdateUnitCalculation(
                                                                    e.target
                                                                      .value,
                                                                    proAssUnit,
                                                                    'qty'
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proAssUnit?.fromUnit  
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  proAssUnit?.toUnit
                                                                        
                                                                }
                                                                disabled
                                                                // onChange={e =>
                                                                //   handleReMedUpdateUnitCalculation(
                                                                //     e.target
                                                                //       .value,
                                                                //     medUnit,
                                                                //     'toUnit'
                                                                //   )
                                                                // }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                value={
                                                                  mQty ? (mQty * proAssUnit.toUnit) : reList.totalUnit
                                                                }
                                                                disabled
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.sellingPrice
                                                                }
                                                                onChange={e =>
                                                                  setReAccSell(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>
                                                            <td>
                                                              <input
                                                                type='number'
                                                                defaultValue={
                                                                  reList.purchasePrice
                                                                }
                                                                onChange={e =>
                                                                  setReAccPurchase(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                className='form-control'
                                                              />
                                                            </td>

                                                            <td>
                                                              <input
                                                                value={
                                                                  reList.currentQuantity *
                                                                  reList.purchasePrice
                                                                }
                                                                disabled={true}
                                                                className='form-control'
                                                              />
                                                            </td>
                                                          <td>{reList?.unit}</td>
                                                       
                                                            <td className='text-center'>
                                                            <div className='flex flex-column   '>
                                                              <div className='col-4'>
                                                                <Link
                                                                to={
                                                                  '/damage/' +
                                                                  reList._id
                                                                }
                                                                state={{
                                                                  data: reList,medData:proAssUnit
                                                                }}
                                                              >
                                                                <button
                                                                  class='btn btn-sm btn-danger mt-3'

                                                                  //data-toggle='modal'
                                                                  // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                
                                                                  <img
                                                                    src={
                                                                      BrokenIcon
                                                                    }
                                                                    alt='ph'
                                                                    width='20px'
                                                                  />
                                                                </button>
                                                              </Link>
                                                              </div>
                                                             
                                                              <div className='col-4'>
                                                                     <button
                                                                onClick={e =>
                                                                  handleReAccUpdate(
                                                                    reList,proAssUnit
                                                                  )
                                                                }
                                                                class='btn btn-sm btn-primary mt-3'
                                                                //data-toggle='modal'
                                                                // data-target='#edit_stock{{$list->id}}'
                                                              >
                                                                <FaAngleDoubleRight />
                                                              </button>
                                                              </div>
                                                              </div>
                                                       
                                                         
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    : ''}
                                                </tbody>
                                              </table>
                                            </td>
                                              </tr>
                                              </>
                                          )
                                        )}
                                    </tbody>
                                  </div>
                                </div>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <ProMedicine
                open={open}
                close={() => setOpen(false)}
                setProMedicineLists={setProMedicineLists}
                proMedicineLists={proMedicineLists}
              /> */}
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '1rem' }}>
        <strong>
          Copyright &copy; 2017-2020
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Stock

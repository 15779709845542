import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [bodyParts, setBodyParts] = useState('')

  const [description, setDescription] = useState('')

  const TreatmentCreate = () => {
    const data = {
      name: name,
      code: code,
      bodyParts: bodyParts,
      description: description
    }

    //  alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.post('treatment-list', data, config).then(function (response) {
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })
      // window.location.reload()

      props.setTreatmentLists([...props.treatmentLists, response.data.data])
    })
    props.close()
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='md'>
        <DialogTitle>
          {' '}
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Create Treatment</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <div class='modal-body'>
            <div class='form-group'>
              <label for='name'>Code</label>
              <input
                type='text'
                class='form-control border border-info'
                name='code'
                onChange={e => setCode(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Name</label>
              <input
                type='text'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Choose Body Parts</label>
              <select
                name='currency'
                id=''
                className='form-control mt-1'
                onchange='convert(this.value)'
                onChange={e => setBodyParts(e.target.value)}
              >
                <option value=''>Choose Body Parts</option>

                <option value='Treatment'>Treatment</option>
                <option value='Hair,Combine Tre & Facial'>
                  Hair,Combine Tre & Facial
                </option>
                <option value='Injection'>Injection</option>
                <option value='Combination Package'>Combination Package</option>

                <option value='Surgery Price List'>Surgery Price List</option>
              </select>
            </div>
            <div class='form-group'>
              <label for='name'>Description</label>
              <textarea
                className='form-control'
                id='description'
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div class='modal-footer'>
            <Button
              class='btn btn-secondary'
              data-dismiss='modal'
              onClick={props.close}
            >
              Close
            </Button>
            <Button onClick={TreatmentCreate} class='btn btn-primary'>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Swal from 'sweetalert2'
import { RxCross2 } from 'react-icons/rx'

import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import { valueOf } from '../../../../assets/plugins/moment/src/lib/moment/to-type'

export default function BankInfoDialog (props) {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [unitLists, setUnitLists] = useState([])

  const [disList, setDisList] = useState([])
  const [treatLists, setTreatLists] = useState([])

  const [conValue, setConValue] = useState('')

  const [value, setValue] = useState('')
  const [showDisID, setShowDisID] = useState(false)
  const [name, setName] = useState('')
  const [relatedBrand, setRelatedBrand] = useState('')
  const [discountID, setDiscountID] = useState([])

  const [description, setDescription] = useState('')
  const [showValue, setShowValue] = useState(false)
  const [showItem, setShowItem] = useState(false)
  const [faceList, setFaceList] = useState([])
  const [chooseFaceList, setChooseFaceList] = useState([])
  const [showFace, setShowFace] = useState(false)
  const [bodyList, setBodyList] = useState([])
  const [conName, setConName] = useState('')

  const [showBody, setShowBody] = useState(false)
  const [bodyInjList, setBodyInjList] = useState([])
  const [disID, setDisID] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [tableDisList, setTableDisList] = useState([])
  const [disName, setDisName] = useState('')
  const [Id, setId] = useState([])

  const handleDelete = event => {
    console.log(event, 'ID')

    setTableDisList(tableDisList.filter(el => el.relatedDiscount != event))
  }

  const handleConType = name => {
    console.log(name, 'con Name')
    setConName(name)
  }
  //Type
  const handleDisName = event => {
    setDiscountID([...discountID, event])
    console.log(discountID, 'DIDIDID')

    setDisName(disList.filter(el => el._id == event)[0].name)
    console.log(
      disList.filter(el => el._id == event),
      'name'
    )
    setTableDisList([
      ...tableDisList,
      {
        id: tableDisList.length + 1,
        name: disList.filter(el => el._id == event)[0].name,
        relatedDiscount: event,
        startDate: disList.filter(el => el._id == event)[0].startDate,
        endDate: disList.filter(el => el._id == event)[0].endDate,
        type: disList.filter(el => el._id == event)[0].type,
        value: disList.filter(el => el._id == event)[0].value,
        item_name: disList.filter(el => el._id == event)[0].relatedFOCID
          ? disList.filter(el => el._id == event)[0].relatedFOCID.name
          : ''
      }
    ])

    // console.log(proMedName, 'pro med')

    setShowDisID(true)
  }

  //Create
  const DiscountCreate = () => {
    const data = {
      name: name,
      relatedDiscount: discountID,
      // type: disName,
      description: description
    }

    if (conName == 'Amount') data.conditionAmount = conValue
    if (conName == 'Purchase Freq') data.conditionPurchaseFreq = conValue
    if (conName == 'Package Qty') data.conditionPackageQty = conValue

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    api
      .post('member', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()

        // props.setMemLists([...props.memLists, response.data.data])
      })

      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Error',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    props.close()
  }

  useEffect(() => {
    const getDisLists = async () => {
      try {
        const res = await api.get('discounts')
        setDisList(res.data.list)
      } catch (err) {
        alert(err.message)
      }
    }
    // const getDisID = async () => {
    //   try {
    //     const res = await api.get('discount/'+)
    //     setDisList(res.data.list)
    //     console.log(res.data.list, 'dis list')
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }
    // getDisID()

    getDisLists()
  }, [])

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='sm'>
        <DialogTitle>
          <div className='modal-header ok'>
            <h4 className='modal-title'>Create Member Type</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          {/* @csrf */}
          <div class='modal-body'>
            <div class='form-group'>
              <label for='name'>Name</label>
              <input
                type='text'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Entitled Discount</label>
              <select
                class='custom-select border-info'
                name='account_type_id'
                id='cat'
                onChange={e => handleDisName(e.target.value)}
              >
                <option hidden>Choose Discount</option>
                {disList.map((option, i) => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
              {showDisID && (
                <div className='table-responsive text-black' id='slimtest2'>
                  <table className='table table-hover' id='filter_date'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Start Date</th>
                        <th>End Date</th>

                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Item</th>
                        {/* <th>Condition Type</th> */}
                        {/* <th>Condition Type</th> */}

                        {/* <th>Condition Value</th> */}
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableDisList.map((item, i) => (
                        <tr>
                          <td>{++i}</td>
                          <td>
                            {item.startDate ? item.startDate.split('T')[0] : ''}
                          </td>
                          <td>
                            {item.endDate ? item.endDate.split('T')[0] : ''}
                          </td>

                          <td>{item ? item.name : ''}</td>
                          <td>{item.type}</td>

                          <td>{item ? item.value : ''}</td>
                          {/* <td>
                            {item.conditionAmount ||
                              item.conditionPurchaseFreq ||
                              item.conditionPackageQty}
                          </td> */}
                          <td>{item ? item.item_name : ''}</td>
                          <td className='text-center'>
                            <RxCross2
                              onClick={() => handleDelete(item.relatedDiscount)}
                            />
                            &nbsp;
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div class='form-group'>
              <label for='name'>Membership Condition</label>
              <select
                name='currency'
                id=''
                className='form-control mt-1'
                onchange='convert(this.value)'
                onChange={e => handleConType(e.target.value)}
              >
                <option hidden>Choose Name</option>

                <option value='Amount'>Amount</option>
                <option value='Purchase Freq'>Purchase Freq</option>
                <option value='Package Qty'>Package Qty</option>
              </select>
            </div>
            <div class='form-group'>
              <label for='name'>Condition Value</label>
              <input
                type='number'
                class='form-control border border-info'
                name='name'
                placeholder='eg. mg mg'
                onChange={e => setConValue(e.target.value)}
              />
            </div>

            <div class='form-group'>
              <label for='name'>Description</label>
              <textarea
                className='form-control'
                id='description'
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div class='modal-footer'>
              <Button
                class='btn btn-secondary'
                data-dismiss='modal'
                onClick={props.close}
              >
                Close
              </Button>
              <Button class='btn btn-primary' onClick={DiscountCreate}>
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

import React from 'react'

export default function Footer() {
    return (
        <div> <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com' style={{ color: '#0b9490', fontSize: '16px', fontWeight: 'bold' }}>Dr Rejuve</a>.
        </strong>
            All rights reserved.</div>
    )
}

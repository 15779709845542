import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from './SideBar'
import { Link } from 'react-router-dom'
import { FaCashRegister, FaFileMedical } from 'react-icons/fa'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
function SubHeader () {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [subHeading, setSubHeading] = useState([])
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const SubHeadingCreate = () => {
    const data = {
      name: name,
      code: code,
      description: description
    }
    // alert(JSON.stringify(data));
    // console.log(data)
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    api
      .post('sub-header', data, config)
      .then(function (response) {
        // alert("success");
        setSubHeading([...subHeading, response.data.data])
      })
      .catch(function (err) {
        alert(err.message)
      })
    //      props.setOpen(false);
  }
  useEffect(() => {
    const getSubHeading = async () => {
      try {
        const res = await api.get('sub-headers')

        setSubHeading(res.data.list)
      } catch (err) {}
    }
    getSubHeading()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <a href='/'>Home</a>
                    </li>
                    <li className='breadcrumb-item active'>Sub Header</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Cost Center List</h3> */}
                    </div>
                    <div className='card-body'>
                      <table id='example1' className='table'>
                        <thead className='text-center bg-info'>
                          <tr>
                            <th>No</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {subHeading.map((subHead, i) => (
                          <tbody className=''>
                            <tr>
                              <td>{++i}</td>
                              <td>{subHead.code}</td>
                              <td>{subHead.name}</td>
                              <td>{subHead.description}</td>
                              <td>
                                <a href='' className='btn btn-sm btn-warning'>
                                  Update
                                </a>
                                &nbsp;
                                <a href='' className='btn btn-sm btn-danger'>
                                  Delete
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='card px-3 py-3'>
                    <h5 className='card-header mb-4 fw-5 text-secondary'>
                      Create Sub Header
                    </h5>
                    <form onSubmit={SubHeadingCreate}>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Code
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='balance'
                          // ref={(el) => (this.code = el)}
                          onChange={e => setCode(e.target.value)}
                        />
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Name
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='balance'
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Description
                          </label>
                          <textarea
                            className='form-control'
                            //   ref={(el) => (this.description = el)}
                            onChange={e => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                        <button
                          className='btn btn-primary form-control text-center fw-5'
                          type='submit'
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default SubHeader

import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import CurrencyDialog from '../views/CurrencyDialog'
import CurrencyUpdate from '../views/CurrencyUpdate'
import { Link, useLocation } from 'react-router-dom'
import Kwin from '../../assets/img/SkinHead.jpg'
import ReactShadowScroll from 'react-shadow-scroll'
import useCollapse from 'react-collapsed'
import {
  FaCashRegister,
  FaFileMedical,
  FaDatabase,
  FaArrowLeft,
  FaAngleDown,
  FaRegEdit,
  FaAngleUp,
  FaList,
  FaRegUser,
  FaRegTrashAlt,
  FaClipboardCheck,
  FaPowerOff
} from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutSuccess } from '../../../src/redux/authRedux'
import Swal from 'sweetalert2'

function SideBar () {
  const divStyles1 = {
    textShadow: '1px 2px 3px gray'
  }

  const [active, setActive] = useState('')
  const user = useSelector(state => state.auth.user)
  // console.log(user.user.role, 'role')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logout = () => {
    Swal.fire({
      title: 'Success',
      text: 'successfully Logout!',
      icon: 'success',
      showConfirmButton: false,
      timer:2000
    }).then(function () {
      dispatch(logoutSuccess())
      navigate('/')
    })
  }
  const divStyles = {
    boxShadow: '1px 1px 10px gray',
    maxHeight: '900px',
    overflowY:'scroll'
  }

  const handleActive = val => {
    setActive(val)
  }

  return (
    <aside className='main-sidebar bg-gradient-light ' style={divStyles}>
      {/* <!-- Brand Logo --> */}
      {/* {{-- <a href="index3.html" classNameName="brand-link">
              <img src="{{asset('image/logo.jpg')}}" alt="K-win Technology" classNameName="brand-image img-circle"
                   style="opacity: .8;margin-top:10px;">
                </a> --}} */}
      <div >  
        <img src={Kwin} alt="The Skin Studio" 
                  width='230px' height='150px' />
      </div>
             
      {/* <h3 className='text-center mt-3 mr-4 fs-2' style={{color:'#0b7874' , fontFamily:'serif',fontWeight:'bolder'}}>
K-Win Technologies
      </h3> */}

      {/* 
            <!-- Sidebar --> */}
      <div className='sidebar'>
      
        <nav className='mt-1'>
          <ul className='nav sidebar-nav nav-pills nav-sidebar flex-column'>
            {/* //admin */}
            <li className='ml-3'>
              <p
                data-bs-toggle='collapse'
                href='#collapseExample'
                role='button'
                aria-expanded='false'
                aria-controls='collapseExample'
                className='d-flex justify-content-between'
              >
                <div>
                  <FaRegUser /> &nbsp; Admin &nbsp;
                </div>
                <b className='mr-5'>
                  <FaAngleDown />
                </b>
              </p>
            </li>
            <div className='collapse' id='collapseExample'>
              <ul style={{ listStyle: 'none' }}>
                <li className='nav-item' onClick={() => handleActive('1')}>
                  <Link to='/stock' className='nav-link'>
                    <p>Stock Count & Price</p>
                  </Link>
                </li>

                {/* <li className='nav-item'>
                  <Link to='/stock-req' className='nav-link'>
                    <p>Stock Request</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link to='/stock-tran' className='nav-link'>
                    <p>Stock Transfer</p>
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <Link to='/purchase' className='nav-link '>
                    <p>Purchase List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/supplier' className='nav-link '>
                    <p>Supplier List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/stock-list' className='nav-link '>
                    <p>Log List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/fix_asset' className='nav-link '>
                    <p>Fixed Asset List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/medicineSale' className='nav-link '>
                    <p>Medicine Sale List</p>
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to='/treatment' className='nav-link '>
                    <p>Receiveable Treatment</p>
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <Link to='/treatSale' className='nav-link '>
                    <p>Treatment Voucher List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/doctor' className='nav-link '>
                    <p>Doctor List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/discount' className='nav-link '>
                    <p>Discount List</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link to='/member' className='nav-link '>
                    <p>Member List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/package' className='nav-link '>
                    <p>Package List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/user' className='nav-link '>
                    <p>Employee List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/reorder' className='nav-link '>
                    <p>ReOrder List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/top10' className='nav-link '>
                    <p>Top 10 List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/customer' className='nav-link '>
                    <p>Customer List</p>
                  </Link>
                </li>
                  <li className='nav-item'>
                  <Link to='/damage-list' className='nav-link '>
                    <p>Damage Items List</p>
                  </Link>
                </li>
              </ul>
            </div>

            <li className='nav-item ml-3'>
              <p
                data-bs-toggle='collapse'
                href='#accountData'
                role='button'
                aria-expanded='false'
                aria-controls='accountData'
                className='d-flex justify-content-between'
              >
                <div>
                  <FaDatabase /> &nbsp; Account Data &nbsp;
                </div>
                <b className='mr-5'>
                  <FaAngleDown />
                </b>
              </p>
            </li>

            <div className='collapse' id='accountData'>
              <ul style={{ listStyle: 'none' }}>
                <li className='nav-item'>
                  <Link to='/account_list' className='nav-link'>
                    <p>Account Lists</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/profit-and-loss' className='nav-link'>
                    <p>Profit And Loss</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/trial-bal' className='nav-link'>
                    <p>Trial Balance</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link to='/acc_type' className='nav-link'>
                    <p>Account Type</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/head' className='nav-link'>
                    <p>Heading Type</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/expense' className='nav-link '>
                    <p>Expense List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/income' className='nav-link '>
                    <p>Income List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/currency' className='nav-link '>
                    <p>Currency List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/bank' className='nav-link '>
                    <p>Bank List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/transfer-list' className='nav-link '>
                    <p>Transfer List</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link to='/all-tran' className='nav-link '>
                    <p>All Transaction List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/journal-list' className='nav-link'>
                    <p>Journal Entry</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/bc-report' className='nav-link'>
                    <p>Bank / Cash Report</p>
                  </Link>
                </li>
              </ul>
            </div>

            <li className='nav-item ml-3'>
              <p
                data-bs-toggle='collapse'
                href='#reportData'
                role='button'
                aria-expanded='false'
                aria-controls='reportData'
                className='d-flex justify-content-between'
              >
                <div>
                  <FaClipboardCheck /> &nbsp; Report
                </div>
                <b className='mr-5'>
                  <FaAngleDown />
                </b>
              </p>
            </li>
            <div className='collapse' id='reportData'>
              <ul style={{ listStyle: 'none' }}>
                <li className='nav-item'>
                  <Link to='/profit_loss' className='nav-link'>
                    <p>Profit & Loss</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/balance_sheet' className='nav-link'>
                    <p>Balance Sheet</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/trail_bal' className='nav-link'>
                    <p>Trail Balance</p>
                  </Link>
                </li>
              </ul>
            </div>
            {/* Master Data */}
            {/* {user.user.role === 'Admin' && (
              <> */}
            <li className='nav-item ml-3'>
              <p
                data-bs-toggle='collapse'
                href='#masterData'
                role='button'
                aria-expanded='false'
                aria-controls='masterData'
                className='d-flex justify-content-between'
              >
                <div>
                  <FaFileMedical /> &nbsp; Master Data
                </div>
                <b className='mr-5'>
                  <FaAngleDown />
                </b>
              </p>
            </li>
            <div className='collapse' id='masterData'>
              <ul style={{ listStyle: 'none' }}>
                <li className='nav-item'>
                  <Link to='/category' className='nav-link'>
                    <p>Category</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/notes' className='nav-link'>
                    <p>Notes</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/sub_category' className='nav-link'>
                    <p>Sub Category</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/brand' className='nav-link'>
                    <p>Brand List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/medicine_list' className='nav-link'>
                    <p>Medicine List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/pro_medicine' className='nav-link'>
                    <p>Procedure Medicine</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link to='/pro-access' className='nav-link'>
                    <p>Procedure Accessory</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/machine' className='nav-link'>
                    <p>Machine</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/treatC' className='nav-link'>
                    <p>Treatment List</p>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/pro_medicine" className="nav-link">
                    <p>Treatment List</p>
                  </Link>
                </li> */}
              </ul>
            </div>
            {/* </>
            )} */}

            <li className='nav-item'>
              <a className='nav-link' onClick={logout}>
                <FaPowerOff />

                <span className='ml-1'>Logout</span>
              </a>
            </li>

            <li className='nav-item'>
              {/* <Link
                to="http://clinicdenovopos.kwintechnologies.com/dashboard"
                className="nav-link ">
                <p>
                  <FaCashRegister /> &nbsp; POS
                </p>
              </Link> */}
            </li>
            {/* <li className='nav-item'>
              <a href='' className='nav-link'>
                <p>
                  &nbsp;Logout
                </p>
              </a>
            </li> */}
          </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  //       <aside className='main-sidebar bg-gradient-light ' style={divStyles}>
  //     {/* <!-- Brand Logo --> */}
  //     {/* {{-- <a href="index3.html" classNameName="brand-link">
  //             <img src="{{asset('image/logo.jpg')}}" alt="K-win Technology" classNameName="brand-image img-circle"
  //                  style="opacity: .8;margin-top:10px;">
  //               </a> --}} */}
  //     <h3 className='text-center mt-3 mr-4 fs-2' style={{color:'#0b7874' , fontFamily:'serif',fontWeight:'bolder'}}>
  // Dr Rejuve
  //     </h3>

  //     {/* 
  //           <!-- Sidebar --> */}
  //     <div className='sidebar mt-2'>
  //       <nav className='mt-3'>
  //         <ul className='nav sidebar-nav nav-pills nav-sidebar flex-column'>
  //           {/* //admin */}
  //           <li className='ml-3'>
  //             <p
                
  //               className='d-flex justify-content-between'
  //               onClick={()=>setShow(!show)}
  //             >
  //               <div >
  //                 <FaRegUser /> &nbsp; Admin &nbsp;
  //               </div>
  //               {show ? (
  //                <b className='mr-5'>
  //                 <FaAngleDown />
  //               </b>
  //               ): (
  //                     <b className='mr-5'>
  //                   <FaAngleUp />
  //                         </b>
  //               )}
            
            
               
  //             </p>
  //           </li>
  //           {location.pathname && !show ? (
  //           <div >
  //             <ul style={{ listStyle: 'none' }}>
  //                 <li className={location.pathname === '/stock' ? 'new-style d-flex justify-content-start ' : 'nav-link d-flex justify-content-start'} onClick={() => handleActive('1')}>
  //                   <Link to='/stock' className={location.pathname === '/stock' ? 'text-white mt-2 ml-2' : 'text-black'}>
  //                   <p>Stock Count & Price</p>
  //                 </Link>
  //                 </li>
                  
  //               <li className={location.pathname === '/purchase' ? 'new-style d-flex justify-content-start ' : 'nav-link d-flex justify-content-start'}>
  //                 <Link to='/purchase' className={location.pathname === '/purchase' ? 'text-white mt-2 ml-2' : 'text-black'}>
  //                   <p>Purchase List</p>
  //                 </Link>
  //               </li>
  //               <li className={location.pathname === '/supplier' ? 'new-style d-flex justify-content-start' : 'nav-link d-flex justify-content-start'}>
  //                 <Link to='/supplier' className={location.pathname === '/supplier' ? 'text-white mt-2 ml-2' : 'text-black'}>
  //                   <p>Supplier List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/stock-list' className='nav-link '>
  //                   <p>Log List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/fix_asset' className='nav-link '>
  //                   <p>Fixed Asset List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/medicineSale' className='nav-link '>
  //                   <p>Medicine Sale List</p>
  //                 </Link>
  //               </li>
  //               {/* <li className='nav-item'>
  //                 <Link to='/treatment' className='nav-link '>
  //                   <p>Receiveable Treatment</p>
  //                 </Link>
  //               </li> */}
  //               <li className='nav-item'>
  //                 <Link to='/treatSale' className='nav-link '>
  //                   <p>Treatment Voucher List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/doctor' className='nav-link '>
  //                   <p>Doctor List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/discount' className='nav-link '>
  //                   <p>Discount List</p>
  //                 </Link>
  //               </li>

  //               <li className='nav-item'>
  //                 <Link to='/member' className='nav-link '>
  //                   <p>Member List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/package' className='nav-link '>
  //                   <p>Package List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/user' className='nav-link '>
  //                   <p>Employee List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/reorder' className='nav-link '>
  //                   <p>ReOrder List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/top10' className='nav-link '>
  //                   <p>Top 10 List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/customer' className='nav-link '>
  //                   <p>Customer List</p>
  //                 </Link>
  //               </li>
  //             </ul>
  //           </div>
  //           ): ''}
            

  //           <li className='nav-item ml-3'>
  //             <p
  //               data-bs-toggle='collapse'
  //               href='#accountData'
  //               role='button'
  //               aria-expanded='false'
  //               aria-controls='accountData'
  //               className='d-flex justify-content-between'
  //             >
  //               <div>
  //                 <FaDatabase /> &nbsp; Account Data &nbsp;
  //               </div>
  //               <b className='mr-5'>
  //                 <FaAngleDown />
  //               </b>
  //             </p>
  //           </li>

  //           <div className='collapse' id='accountData'>
  //             <ul style={{ listStyle: 'none' }}>
  //               <li className='nav-item'>
  //                 <Link to='/account_list' className='nav-link'>
  //                   <p>Account Lists</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/profit-and-loss' className='nav-link'>
  //                   <p>Profit And Loss</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/trial-bal' className='nav-link'>
  //                   <p>Trial Balance</p>
  //                 </Link>
  //               </li>

  //               <li className='nav-item'>
  //                 <Link to='/acc_type' className='nav-link'>
  //                   <p>Account Type</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/head' className='nav-link'>
  //                   <p>Heading Type</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/expense' className='nav-link '>
  //                   <p>Expense List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/income' className='nav-link '>
  //                   <p>Income List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/currency' className='nav-link '>
  //                   <p>Currency List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/bank' className='nav-link '>
  //                   <p>Bank List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/transfer-list' className='nav-link '>
  //                   <p>Transfer List</p>
  //                 </Link>
  //               </li>

  //               <li className='nav-item'>
  //                 <Link to='/all-tran' className='nav-link '>
  //                   <p>All Transaction List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/journal-list' className='nav-link'>
  //                   <p>Journal Entry</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/bc-report' className='nav-link'>
  //                   <p>Bank / Cash Report</p>
  //                 </Link>
  //               </li>
  //             </ul>
  //           </div>

  //           <li className='nav-item ml-3'>
  //             <p
  //               data-bs-toggle='collapse'
  //               href='#reportData'
  //               role='button'
  //               aria-expanded='false'
  //               aria-controls='reportData'
  //               className='d-flex justify-content-between'
  //             >
  //               <div>
  //                 <FaClipboardCheck /> &nbsp; Report
  //               </div>
  //               <b className='mr-5'>
  //                 <FaAngleDown />
  //               </b>
  //             </p>
  //           </li>
  //           <div className='collapse' id='reportData'>
  //             <ul style={{ listStyle: 'none' }}>
  //               <li className='nav-item'>
  //                 <Link to='/profit_loss' className='nav-link'>
  //                   <p>Profit & Loss</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/balance_sheet' className='nav-link'>
  //                   <p>Balance Sheet</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/trail_bal' className='nav-link'>
  //                   <p>Trail Balance</p>
  //                 </Link>
  //               </li>
  //             </ul>
  //           </div>
  //           {/* Master Data */}
  //           {/* {user.user.role === 'Admin' && (
  //             <> */}
  //           <li className='nav-item ml-3'>
  //             <p
  //               data-bs-toggle='collapse'
  //               href='#masterData'
  //               role='button'
  //               aria-expanded='false'
  //               aria-controls='masterData'
  //               className='d-flex justify-content-between'
  //             >
  //               <div>
  //                 <FaFileMedical /> &nbsp; Master Data
  //               </div>
  //               <b className='mr-5'>
  //                 <FaAngleDown />
  //               </b>
  //             </p>
  //           </li>
  //           <div className='collapse' id='masterData'>
  //             <ul style={{ listStyle: 'none' }}>
  //               <li className='nav-item'>
  //                 <Link to='/category' className='nav-link'>
  //                   <p>Category</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/notes' className='nav-link'>
  //                   <p>Notes</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/sub_category' className='nav-link'>
  //                   <p>Sub Category</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/brand' className='nav-link'>
  //                   <p>Brand List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/medicine_list' className='nav-link'>
  //                   <p>Medicine List</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/pro_medicine' className='nav-link'>
  //                   <p>Procedure Medicine</p>
  //                 </Link>
  //               </li>

  //               <li className='nav-item'>
  //                 <Link to='/pro-access' className='nav-link'>
  //                   <p>Procedure Accessory</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/machine' className='nav-link'>
  //                   <p>Machine</p>
  //                 </Link>
  //               </li>
  //               <li className='nav-item'>
  //                 <Link to='/treatC' className='nav-link'>
  //                   <p>Treatment List</p>
  //                 </Link>
  //               </li>
  //               {/* <li className="nav-item">
  //                 <Link to="/pro_medicine" className="nav-link">
  //                   <p>Treatment List</p>
  //                 </Link>
  //               </li> */}
  //             </ul>
  //           </div>
  //           {/* </>
  //           )} */}

  //           <li className='nav-item'>
  //             <a className='nav-link' onClick={logout}>
  //               <FaPowerOff />

  //               <span className='ml-1'>Logout</span>
  //             </a>
  //           </li>

  //           <li className='nav-item'>
  //             {/* <Link
  //               to="http://clinicdenovopos.kwintechnologies.com/dashboard"
  //               className="nav-link ">
  //               <p>
  //                 <FaCashRegister /> &nbsp; POS
  //               </p>
  //             </Link> */}
  //           </li>
  //           {/* <li className='nav-item'>
  //             <a href='' className='nav-link'>
  //               <p>
  //                 &nbsp;Logout
  //               </p>
  //             </a>
  //           </li> */}
  //         </ul>
  //       </nav>
  //       <Outlet/>
  //       {/* <!-- /.sidebar-menu --> */}
  //     </div>
  //     {/* <!-- /.sidebar --> */}
  //   </aside>
  )
}
export default SideBar

import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from '../SideBar'
import { useNavigate } from 'react-router-dom'

import Swal from 'sweetalert2'
import { FaCashRegister, FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import HeaderName from '../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../utils/api'
function SubCategory () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [categoryLists, setCategoryLists] = useState([])
  const [subCategoryLists, setSubCategoryLists] = useState([])
  const [code, setCode] = useState('')
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState(null)
  const [showUpdate, setShowUpdate] = useState(false)
  const [updateDataList, setUpdateDataList] = useState([])
  const [upCode, setUpCode] = useState('')
  const [upName, setUpName] = useState('')
  const [upDesc, setUpDesc] = useState('')
  const [relatedCat, setRelatedCat] = useState('')
  const [relatedCatID, setRelatedCatID] = useState('')
  const [Id, setId] = useState('')
  const navigate = useNavigate()

  // const [categoryLists, setCategoryLists] = useState([]);

  const handleDelete = event => {
    apiInstance
      .delete('sub-category/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = subCategoryLists.filter(item => item._id !== event)
        setSubCategoryLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleUpdate = event => {
    // console.log(event, 'event')
    const getCatUpdate = async () => {
      try {
        const res = await apiInstance.get('sub-category/' + event)
        console.log(res.data.data[0])
        setUpCode(res.data.data[0].code)
        setUpName(res.data.data[0].name)
        setRelatedCat(res.data.data[0].relatedCategory.name)
        console.log(res.data.data[0].relatedCategory.name, 'related cat')

        setRelatedCatID(res.data.data[0].relatedCategory._id)

        console.log(res.data.data[0].relatedCategory._id, 'recat')

        setUpDesc(res.data.data[0].description)
      } catch (err) {}
    }
    getCatUpdate()
    setShowUpdate(true)
    setId(event)
  }

  const SubCategoryUpdate = () => {
    const data = {
      id: Id,
      code: upCode,
      // relatedCategory: relatedCat,
      name: upName,
      description: upDesc
    }

    if (relatedCatID) {
      data.relatedCategory = relatedCatID
    } else {
      data.relatedCategory = relatedCat
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('sub-category', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()

        //  setSubCategoryLists([...subCategoryLists, response.data.data]);
        setSubCategoryLists(
          subCategoryLists.map(category => {
            if (category._id === response.data.data._id) {
              return response.data.data
            } else {
              return category
            }
          })
        )
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    document.getElementById('desc').value = ''
    document.getElementById('name').value = ''
    document.getElementById('code').value = ''
    document.getElementById('cat').value = ''
  }

  const SubCategoryCreate = () => {
    const data = {
      code: code,
      name: name,
      relatedCategory: category,
      description: description
    }
    // if(description){
    //   data.description=description
    // }
    // alert(JSON.stringify(data));
    console.log(data)
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('sub-category', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()

        setSubCategoryLists([...subCategoryLists, response.data.data])
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    document.getElementById('desc').value = ''
    document.getElementById('name').value = ''
    document.getElementById('code').value = ''
    document.getElementById('cat').value = ''
  }
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await apiInstance.get('categories')

        console.log(res.data.data, 'cat')
        setCategoryLists(res.data.data)
      } catch (err) {}
    }
    const getSubCategory = async () => {
      try {
        const res = await apiInstance.get('sub-categories')

        console.log(res.data.data, 'sub cat')
        setSubCategoryLists(res.data.data)
      } catch (err) {}
    }
    getCategory()
    getSubCategory()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Sub Category List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Cost Center List</h3> */}
                    </div>
                    <div className='card-body'>
                      <table id='example1' className='table'>
                        <thead className=' bg-info'>
                          <tr>
                            <th>No</th>
                            <th>Code</th>
                            <th>Category</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {subCategoryLists.map((cat, i) => (
                          <tbody className=''>
                            <tr>
                              <td>{++i}</td>
                              <td>{cat.code}</td>
                              <td>
                                {cat.relatedCategory
                                  ? cat.relatedCategory.name
                                  : ''}
                              </td>
                              <td>{cat.name}</td>
                              <td>{cat.description}</td>
                              <td>
                                <button
                                  className='btn btn-sm btn-warning'
                                  onClick={e => handleUpdate(cat._id)}
                                >
                                  <FaRegEdit />
                                </button>
&nbsp;
                                <button
                                  className='btn btn-sm btn-danger'
                                  onClick={e => handleDelete(cat._id)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                {showUpdate ? (
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='card-header mb-4 fw-5 text-secondary py-3'>
                        Update Sub Category
                      </h5>
                      <div className='px-3 py-3'>
                        <div class='form-group'>
                          <label for='code' className='text-secondary'>
                            Code
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='code'
                            id='code'
                            value={upCode}
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setUpCode(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name'>Category</label>
                          <select
                            class='custom-select border-info'
                            name='account_type_id'
                            id='cat'
                            onChange={e => {
                              setRelatedCat(e.target.value)
                              setRelatedCatID(e.target.value)
                            }}
                          >
                            <option value={relatedCatID}>{relatedCat}</option>
                            {categoryLists.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Name
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='balance'
                            id='name'
                            value={upName}
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setUpName(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Description
                          </label>
                          <textarea
                            className='form-control'
                            id='desc'
                            value={upDesc}
                            //   ref={(el) => (this.description = el)}
                            onChange={e => setUpDesc(e.target.value)}
                          ></textarea>
                        </div>
                        <button
                          className='btn  form-control text-center fw-5'
                          style={{backgroundColor:'#0b9490',color:'white'}}
                          onClick={SubCategoryUpdate}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='card-header mb-4 fw-5 text-secondary py-3'>
                        Create Sub Category
                      </h5>
                      <div className='px-3 py-3'>
                        <div class='form-group'>
                          <label for='code' className='text-secondary'>
                            Code
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='code'
                            id='code'
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setCode(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name'>Category</label>
                          <select
                            class='custom-select border-info'
                            name='account_type_id'
                            id='cat'
                            onChange={e => setCategory(e.target.value)}
                          >
                            <option>Choose Category</option>
                            {categoryLists.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Name
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='balance'
                            id='name'
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Description
                          </label>
                          <textarea
                            className='form-control'
                            id='desc'
                            //   ref={(el) => (this.description = el)}
                            onChange={e => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                          <button
                               style={{backgroundColor:'#0b9490',color:'white'}}
                          className='btn  form-control text-center fw-5'
                          onClick={SubCategoryCreate}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer mt-5'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  )
}
export default SubCategory

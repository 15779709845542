import { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import SideBar from '../../SideBar'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
//const uri = 'http://localhost:9000/apiInstanceInstance/';

export default function StockList() {
  const [stockList, setStockList] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [itemList, setItemList] = useState([])
  const [items, setItems] = useState([])
  const [uri, setUri] = useState('')
  const [show, setShow] = useState(true)
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const finalFilter = event => {
    let data = {
      id: event.target.value
    }
    apiInstance
      .get('logs/filter', { params: data })
      .then(response => {
        // setTodayList([])
        setStockList(response.data.data)
      })
      .catch(error => {
        Swal.fire({
          title: '404',
          text: error.response.data.message,
          icon: 'warning',
          confirmButtonText: 'CANCEL'
        })
      })
    // const filteredArray = stockList.filter(obj => [obj.relatedProcedureItems?._id, obj.relatedAccessoryItems?._id, obj.relatedMachine?._id].includes(event.target.value));
    // console.log(filteredArray,'filterdarray')
    // setStockList(filteredArray)
  }

  const handleItems = event => {
    if (uri === 'false') {
      setShow(false)
      let data = {
        id: event
      }
      apiInstance
        .get('logs/filter', { params: data })
        .then(response => {
          // setTodayList([])
          setStockList(response.data.data)
        })
        .catch(error => {
          Swal.fire({
            title: '404',
            text: error.response.data.message,
            icon: 'warning',
            confirmButtonText: 'CANCEL'
          })
        })
    } else {
      setShow(true)
      apiInstance
        .get(uri + '/' + event)
        .then(response => {
          // setTodayList([])
          setItems(response.data.data)
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'CANCEL'
          })
        })
    }
  }

  const handleCategory = event => {
    switch (event) {
      case 'Machine': {
        handleMachine()
        setUri('false')
        setShow(true)
        break
      }
      case 'Procedure Medicine': {
        handleProcedureMedicine()
        setUri('procedure-items')
        break
      }
      case 'Procedure Accessory': {
        handleProcedureAccessory()
        setUri('accessory-items')
        break
      }
      default:
        return event
    }
  }
  const handleMachine = event => {
    apiInstance
      .get('fixed-assets')
      .then(response => {
        // setTodayList([])
        setItemList(response.data.list)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  const handleProcedureMedicine = event => {
    apiInstance
      .get('procedure-medicines')
      .then(response => {
        // setTodayList([])
        setItemList(response.data.list)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  const handleProcedureAccessory = event => {
    apiInstance
      .get('procedure-accessories')
      .then(response => {
        // setTodayList([])
        setItemList(response.data.list)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const filter = event => {
    const nDate = new Date(endDate)
    const toDate = nDate.setDate(nDate.getDate() + 1)

    let data = {
      start: startDate,
      end: toDate
    }
    apiInstance
      .get('logs/filter', { params: data })
      .then(response => {
        // setTodayList([])
        setStockList(response.data.data)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  useEffect(() => {
    const getStockLogList = async () => {
      apiInstance
        .get('logs')
        .then(response => {
          setStockList(response.data.data)
          console.log(response.data.data)
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'CANCEL'
          })
        })
    }

    getStockLogList()
  }, [])

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'></div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <div className='row ml-2'>
                <div className='form-group col-md-3'>
                  <label>From</label>
                  <input
                    type='date'
                    name='from'
                    id='from'
                    className='form-control'
                    onChange={e => setStartDate(e.target.value)}
                  />
                </div>
                <div className='form-group col-md-3'>
                  <label>To</label>
                  <input
                    type='date'
                    name='to'
                    id='to'
                    className='form-control'
                    onChange={e => setEndDate(e.target.value)}
                  />
                </div>
                <div className='form-group col-md-2'>
                  <button
                    className='btn btn-sm btn-primary form-control'
                    style={{ marginTop: '33px' }}
                    onClick={filter}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Main content --> */}
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row '>
                    <div className='form-group col-md-1'>
                      <h2 className='card-title mt-3'>
                        <b>Log List</b>
                      </h2>
                    </div>
                    <div className='form-group col-md-2 mt-2'>
                      <select
                        class='custom-select border-info'
                        onChange={e => {
                          handleCategory(e.target.value)
                        }}
                      >
                        <option>Choose Category</option>
                        <option value='Machine'>Machine</option>
                        <option value='Procedure Medicine'>
                          Procedure Medicine
                        </option>
                        <option value='Procedure Accessory'>
                          Procedure Accessory
                        </option>
                      </select>
                    </div>

                    <div className='form-group col-md-2 mt-2'>
                      <select
                        class='custom-select border-info'
                        onChange={e => handleItems(e.target.value)}
                      >
                        <option>Choose Item List</option>
                        {itemList.map((element, index) => (
                          <option value={element._id}>{element.name}</option>
                        ))}
                      </select>
                    </div>
                    {show ? (
                      <div className='form-group col-md-2 mt-2'>
                        <select
                          class='custom-select border-info'
                          onChange={e => finalFilter(e)}
                        >
                          <option>Choose Items</option>
                          {items.map((element, index) => (
                            <option value={element._id}>
                              {element.procedureItemName
                                ? element.procedureItemName
                                : element.accessoryItemName}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='card-body'>
                  <table id='example1' className='table'>
                    <thead className=' bg-info'>
                      <tr>
                        <th>No</th>
                        <th>Vou Code</th>
                        <th>Date</th>
                        <th>Item</th>
                        {/* <th>Related Treatment</th> */}
                        <th>Treatment Selection</th>
                        <th>Appointment</th>

                        <th>Current QTY</th>
                        <th>Actual QTY</th>
                        <th>Final QTY</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <>
                      {stockList.map((e, i) => (
                        <tbody key={e._id}>
                          <tr>
                            <td>{++i}</td>
                            <td>
                              {
                                e.relatedTreatmentSelection?.relatedTreatment
                                  .treatmentCode
                              }
                            </td>
                            <td>{e.date?.split('T')[0]}</td>
                            <td>
                              {e.relatedProcedureItems?.procedureItemName ||
                                e.relatedAccessoryItems?.accessoryItemName ||
                                e.relatedMachine?.name}
                            </td>
                            <td>
                              {
                                e.relatedTreatmentSelection?.relatedTreatment
                                  ?.name
                              }
                            </td>
                            <td>
                              {
                                e.relatedAppointment?.originalDate?.split(
                                  'T'
                                )[0]
                              }
                            </td>
                            <td>{e.currentQty ? e.currentQty : ''}</td>
                            <td>{e.actualQty ? e.actualQty : ''}</td>
                            <td>{e.finalQty ? e.finalQty : ''}</td>
                            <td className='text-center'>
                              <button class='btn btn-primary btn-sm'>
                                Related
                              </button>
                            </td>
                          </tr>

                          <tr
                            className='bg-gradient-light'
                            id={'toggle' + e._id}
                            hidden
                          >
                            <td colspan='12'>
                              <div>
                                <div class='row'>
                                  <div class='col-md-2'>
                                    <label
                                      style={{ fontSize: '15px' }}
                                      class='text-dark'
                                    >
                                      No
                                    </label>
                                  </div>
                                  <div class='col-md-3'>
                                    <label
                                      style={{ fontSize: '15px' }}
                                      class='text-dark'
                                    >
                                      Account
                                    </label>
                                  </div>
                                  <div class='col-md-2'>
                                    <label
                                      style={{ fontSize: '15px' }}
                                      class='text-dark'
                                    >
                                      Type
                                    </label>
                                  </div>
                                  <div class='col-md-2'>
                                    <label
                                      style={{ fontSize: '15px' }}
                                      class='text-dark'
                                    >
                                      Date
                                    </label>
                                  </div>
                                  <div class='col-md-2'>
                                    <label
                                      style={{ fontSize: '15px' }}
                                      class='text-dark'
                                    >
                                      Amount
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <MedicineSaleDialog
            open={open}
            close={() => setOpen(false)}
            setOpen={setOpen}
            grand={grand}
          /> */}
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}

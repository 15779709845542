import React, { useState, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import SideBar from '../../SideBar'
import { FaArrowLeft, FaMinus } from 'react-icons/fa'

const AddSupplierDialog = () => {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const Id = useLocation().pathname.split('/')[2]
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [isShow, setIsShow] = useState(true)
  const [suppliers, setSuppliers] = useState([])
  const [email, setEmail] = useState('')

  const save = () => {
    const data = {
      id: Id,
      phone: phone,
      name: name,
      address: address,
      email: email
    }
    api
      .put('supplier', data)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        setSuppliers([...suppliers, response.data.data])
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
  }
  useEffect(() => {
    const getSupplier = async () => {
      try {
        const res = await api.get('supplier/' + Id)
        console.log(res.data.data, 'list')
        setName(res.data.data[0].name ? res.data.data[0].name : '')
        setEmail(res.data.data[0].email ? res.data.data[0].email : '')
        setPhone(res.data.data[0].phone ? res.data.data[0].phone : '')
        setAddress(res.data.data[0].address ? res.data.data[0].address : '')
      } catch (err) {}
    }
    getSupplier()
  }, [])

  return (
    <div>
      <div className='wrapper'>
        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row '>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/supplier'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active'>Account List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container '>
              <div
                className='card px-3 py-3
'
              >
                <h3 className='text-center'>Supplier Update</h3>
                <div className='row form-group d-flex justify-content-center py-3'>
                  <div className='col-8 mt-2'>
                    <label htmlFor=''>Name:</label>
                    <input
                      type='text'
                      className='form-control'
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div className='col-8 mt-2'>
                    <label htmlFor=''>Phone:</label>
                    <input
                      type='number'
                      className='form-control'
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </div>
                  <div className='col-8 mt-2'>
                    <label htmlFor=''>Email:</label>
                    <input
                      type='email'
                      value={email}
                      className='form-control'
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>

                  <div className='col-8 mt-2'>
                    <label htmlFor=''>Address:</label>
                    <textarea
                      className='form-control'
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className='row d-flex justify-content-center'>
                  <div className='col-3'>
                    <Link to='/supplier' className='btn btn-sm btn-dark'>
                      Cancel
                    </Link>
                  </div>
                  <div className='col-3'>
                    <button onClick={save} className='btn btn-sm btn-dark'>
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}
    </div>
  )
}
// phyo
//maymyat

export default AddSupplierDialog

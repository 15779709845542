import { useEffect, useState } from 'react'
import TransferModal from './TransferModal'
import axios from 'axios'

import Swal from 'sweetalert2'
import SideBar from '../../SideBar'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
export default function TransferList() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [relatedLists, setRelatedLists] = useState([])

  const [transferList, setTransferList] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [open, setOpen] = useState(false)
  const [bankList, setBankList] = useState([])
  const [cashList, setCashList] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [fromAcc, setFromAcc] = useState('')
  const [fromType, setFromType] = useState('')
  const [fromAmount, setFromAmount] = useState('')
  const [toAcc, setToAcc] = useState('')
  const [toType, setToType] = useState('')
  const [toAmount, setToAmount] = useState('')
  const [date, setDate] = useState('')
  const [searchLists, setSearchLists] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [showOrigin, setShowOrigin] = useState(true)

  console.log(bankList, 'bank')

  const handleModalBox = event => {
    setOpen(true)
  }
  const filter = event => {

    setSearchLists(
      transferList.filter(el => el.date?.split('T')[0] >= startDate && el.date?.split('T')[0] <= endDate)
    )
    setShowSearch(true)
    setShowOrigin(false)
  }

  const handleRelated = id => {
    const res = transferList.filter(el => el._id == id)
    setFromAcc(res[0].fromAcc ? res[0].fromAcc.subHeader : '')
    setFromAmount(res[0].fromAcc ? res[0].fromAcc.amount : '')
    setFromType(res[0].fromAcc ? res[0].fromAcc.relatedType.name : '')
    setToAcc(res[0].toAcc ? res[0].toAcc.subHeader : '')
    setToAmount(res[0].toAcc ? res[0].toAcc.amount : '')
    setToType(res[0].toAcc ? res[0].toAcc.relatedType.name : '')
    setDate(res[0].date)
    console.log(res, 'f name')
    if (isShow) {
      document.getElementById('toggle' + id).removeAttribute('hidden')
    } else {
      document.getElementById('toggle' + id).setAttribute('hidden', 'hidden')
    }
    setIsShow(!isShow)
  }

  useEffect(() => {
    const getBankList = async () => {
      apiInstance
        .get('accounting-lists')
        .then(response => {
          // console.log('response', response.data.list)
          const filteredArray = response.data.list.filter(
            obj => obj.relatedHeader.name === 'Cash at Bank'
          )
          setBankList(filteredArray)
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'CANCEL'
          })
        })
    }

    const getCashList = async () => {
      apiInstance
        .get('accounting-lists')
        .then(response => {
          const filteredArray = response.data.list.filter(
            obj => obj.relatedHeader.name === 'Cash in Hand'
          )
          // console.log(filteredArray, 'cash')
          setCashList(filteredArray)
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'CANCEL'
          })
        })
    }

    const getTransferList = async () => {
      apiInstance
        .get('transfers')
        .then(response => {
          console.log(response.data.data, 'tran list')
          setTransferList(response.data.data)
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'CANCEL'
          })
        })
    }

    getBankList()
    getCashList()
    getTransferList()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'></div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <div className='row ml-3'>
                <div className='form-group col-md-3 ml-1'>
                  <label>From</label>
                  <input
                    type='date'
                    name='from'
                    id='from'
                    className='form-control'
                    onChange={e => setStartDate(e.target.value)}
                  />
                </div>
                <div className='form-group col-md-3'>
                  <label>To</label>
                  <input
                    type='date'
                    name='to'
                    id='to'
                    className='form-control'
                    onChange={e => setEndDate(e.target.value)}
                  />
                </div>
                <div className='form-group col-md-2'>
                  <button
                    className='btn btn-sm btn-primary '
                    style={{ marginTop: '38px' }}
                    onClick={filter}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Main content --> */}
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row d-flex justify-content-between'>
                    <div className='form-group col-md-2'>
                      <h2 className='card-title mt-3'>
                        <b>Transfer List</b>
                      </h2>
                    </div>
                    <div className='form-group col-md-1 mt-2'>
                      <button
                        className='btn btn-sm btn-success'
                        onClick={handleModalBox}
                      >
                        Transfer
                      </button>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <table id='example1' className='table'>
                    <thead className=' bg-info'>
                      <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>From Acc</th>
                        {/* <th>Related Treatment</th> */}
                        <th>To Acc</th>
                        <th>Remark</th>

                        <th>Amount</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <>
                      {showOrigin &&
                        transferList
                          .slice(0)
                          .reverse()
                          .map((e, i) => (
                            <tbody key={e._id}>
                              <>
                                <tr>
                                  <td>{++i}</td>
                                  <td>{e.date ? e.date.split('T')[0] : ''}</td>
                                  <td>{e.fromAcc?.subHeader}</td>
                                  <td>{e.toAcc?.subHeader}</td>
                                  <td>{e.remark ? e.remark : ''}</td>
                                  <td>{e.amount ? e.amount : ''}</td>
                                  <td className='text-center'>
                                    <button
                                      onClick={() => handleRelated(e._id)}
                                      class='btn btn-primary btn-sm'
                                    >
                                      Related
                                    </button>
                                  </td>
                                </tr>

                                <tr
                                  className='bg-light'
                                  id={'toggle' + e._id}
                                  hidden
                                >
                                  <td colspan='10'>
                                    <div style={{ marginLeft: '15em' }}>
                                      <div class='row'>
                                        <div className='col-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            No
                                          </label>
                                        </div>
                                        <div class='col-md-3'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Account
                                          </label>
                                        </div>
                                        <div class='col-md-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Type
                                          </label>
                                        </div>
                                        <div class='col-md-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Date
                                          </label>
                                        </div>
                                        <div class='col-md-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Amount
                                          </label>
                                        </div>
                                      </div>

                                      <div class='row'>
                                        <div className='col-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            1
                                          </label>
                                        </div>

                                        <div class='col-md-3'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {fromAcc}(From)
                                          </div>
                                        </div>
                                        <div class='col-md-2'>{fromType}</div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {date.split('T')[0]}
                                          </div>
                                        </div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {fromAmount}
                                          </div>
                                        </div>
                                      </div>
                                      <div class='row'>
                                        <div className='col-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            2
                                          </label>
                                        </div>

                                        <div class='col-md-3'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {toAcc}(To)
                                          </div>
                                        </div>
                                        <div class='col-md-2'>{toType}</div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {date.split('T')[0]}
                                          </div>
                                        </div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {toAmount}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          ))}
                      {showSearch &&
                        searchLists
                          .slice(0)
                          .reverse()
                          .map((e, i) => (
                            <tbody key={e._id}>
                              <>
                                <tr>
                                  <td>{++i}</td>
                                  <td>{e.date ? e.date.split('T')[0] : ''}</td>
                                  <td>{e.fromAcc?.subHeader}</td>
                                  <td>{e.toAcc?.subHeader}</td>
                                  <td>{e.remark ? e.remark : ''}</td>
                                  <td>{e.amount ? e.amount : ''}</td>
                                  <td className='text-center'>
                                    <button
                                      onClick={() => handleRelated(e._id)}
                                      class='btn btn-primary btn-sm'
                                    >
                                      Related
                                    </button>
                                  </td>
                                </tr>

                                <tr
                                  className='bg-light'
                                  id={'toggle' + e._id}
                                  hidden
                                >
                                  <td colspan='10'>
                                    <div style={{ marginLeft: '15em' }}>
                                      <div class='row'>
                                        <div className='col-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            No
                                          </label>
                                        </div>
                                        <div class='col-md-3'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Account
                                          </label>
                                        </div>
                                        <div class='col-md-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Type
                                          </label>
                                        </div>
                                        <div class='col-md-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Date
                                          </label>
                                        </div>
                                        <div class='col-md-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            Amount
                                          </label>
                                        </div>
                                      </div>

                                      <div class='row'>
                                        <div className='col-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            1
                                          </label>
                                        </div>

                                        <div class='col-md-3'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {fromAcc}(From)
                                          </div>
                                        </div>
                                        <div class='col-md-2'>{fromType}</div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {date.split('T')[0]}
                                          </div>
                                        </div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {fromAmount}
                                          </div>
                                        </div>
                                      </div>
                                      <div class='row'>
                                        <div className='col-2'>
                                          <label
                                            style={{ fontSize: '15px' }}
                                            class='text-dark'
                                          >
                                            2
                                          </label>
                                        </div>

                                        <div class='col-md-3'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {toAcc}(To)
                                          </div>
                                        </div>
                                        <div class='col-md-2'>{toType}</div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {date.split('T')[0]}
                                          </div>
                                        </div>
                                        <div class='col-md-2'>
                                          <div
                                            style={{
                                              fontSize: '15px'
                                            }}
                                          >
                                            {toAmount}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          ))}
                    </>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <TransferModal
            open={open}
            close={() => setOpen(false)}
            setOpen={setOpen}
            bankList={bankList}
            cashList={cashList}
          />
          {/* <MedicineSaleDialog
            open={open}
            close={() => setOpen(false)}
            setOpen={setOpen}
            grand={grand}
          /> */}
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}

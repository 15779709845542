import React, { useState } from 'react'
import ExpenseDialog from '../views/ExpenseDialog'
import { useEffect } from 'react'
import SideBar from './SideBar'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Button } from '@mantine/core'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'

import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Link } from 'react-router-dom'
import apiInstance from '../../utils/api'
import HeaderName from './HeaderName'

const Expense = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // //const url = "http://localhost:9000/apiInstance/"
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [hide, setHide] = useState(true)
  const [open, setOpen] = useState(false)
  const [expenseLists, setExpenseLists] = useState([])
  const [relatedLists, setRelatedLists] = useState([])
  const [searchList, setSearchList] = useState([])
  const [showSearch, setShowSearch] = useState(false)

  const [isShow, setIsShow] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  const [bankTotal, setBankTotal] = useState(0)
  const [cashTotal, setCashTotal] = useState(0)

  const [bankExpenseList, setBankExpenseList] = useState([])
  const [cashExpenseList, setCashExpenseList] = useState([])
  const [bankNameList, setBankNameList] = useState([])
  const [cashNameList, setCashNameList] = useState([])

  const [show, setShow] = useState('bankTable')

  const handleDelete = event => {
    apiInstance
      .delete('expense/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = expenseLists.filter(item => item._id !== event)
        setExpenseLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleFilterDate = async () => {
    // if (fromDate) {
    // console.log(fromDate, 'from')
    // }
    const nDate = new Date(toDate)
    const endDate = nDate.setDate(nDate.getDate() + 1)
    // console.log(endDate, 'ex date')

    try {
      Swal.fire({
        title: 'Loading',
        text: 'Please wait...',
        allowOutsideClick: false
      })
      await apiInstance
        .get(`expenses/filter`, {
          params: { start: fromDate, end: endDate }
        })
        .then(res => {
          // setAccountLists(res.data.data)
          //setTransactionLists(res.data.transaction)
          console.log(res.data.data)
          setBankTotal(res.data.data.BankTotal)
          setCashTotal(res.data.data.CashTotal)
          setBankExpenseList(res.data.data.BankList)
          setCashExpenseList(res.data.data.CashList)
          setBankNameList(res.data.data.BankNames)
          setCashNameList(res.data.data.CashNames)
          //console.log(res.data.transaction)
        })
        .catch(error => {
          console.log('error', error)
        })
      Swal.close()

      // Process the response data
      //console.log(data);
    } catch (error) {
      Swal.close()
      console.error('Error occurred while fetching data.', error)
    }
  }

  const showDialog = () => setOpen(true)
  const _export = React.useRef(null)

  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.date = element.date ? element.date.split('T')[0] : ''
      })
      _export.current.save()
    }
  }

  const excelExport1 = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.date = element.date ? element.date.split('T')[0] : ''
      })
      _export.current.save()
    }
  }

  const handleRelatedShow = async id => {
    const res = await apiInstance.get('transactions/relatedExpense/' + id)

    console.log(res.data.data, 'list')
    setRelatedLists(res.data.data)

    if (isShow) {
      document.getElementById('toggle' + id).removeAttribute('hidden')
    } else {
      document.getElementById('toggle' + id).setAttribute('hidden', 'hidden')
    }
    setIsShow(!isShow)
  }

  const search = val => {
    // console.log(expenseLists, 'e val')
    if (val) {
      setSearchList(
        expenseLists.filter(
          el =>
            el.relatedAccounting?.name
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            (el.relatedBankAccount
              ? el.relatedBankAccount.name
                  .toLowerCase()
                  .includes(val.toLowerCase())
              : el.relatedCashAccount?.name
                  .toLowerCase()
                  .includes(val.toLowerCase()))
        )
      )

      setShowSearch(true)
      setHide(false)
    } else {
      setShowSearch(false)
      setHide(true)
    }
  }
  useEffect(() => {
    const getExpenseLists = async () => {
      const res = await apiInstance.get('expenses')
      // alert("success");
      // console.log(res.data.list);
      setExpenseLists(res.data.list)
      console.log(res.data.list, 'list')
    }

    getExpenseLists()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Expense List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-5'>
                  <div className='row'>
                    <div className='form-group col-md-4'>
                      <label>From</label>
                      <input
                        type='date'
                        name='from'
                        id='from'
                        onChange={e => setFromDate(e.target.value)}
                        className='form-control'
                      />
                    </div>
                    <div className='form-group col-md-4'>
                      <label>To</label>
                      <input
                        type='date'
                        name='to'
                        id='to'
                        onChange={e => setToDate(e.target.value)}
                        className='form-control'
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <button
                        className='btn btn-sm btn-primary form-control'
                        style={{ marginTop: '33px' }}
                        onClick={() => handleFilterDate()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className=' col-md-4'>
                  <div className='input-group' style={{ marginTop: '28px' }}>
                    <input
                      type='search'
                      className='form-control rounded'
                      id='search_code'
                      placeholder='Search'
                      onChange={e => search(e.target.value)}
                    />
                    {/* <button
                      type='button'
                      className='btn btn-outline-primary'
                      style={{ height: '0.97cm', marginTop: '0.2cm' }}
                      onclick='acc_code_search()'
                    >
                      search
                    </button> */}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <div class='card'>
                    <div class='card-header'>
                      <div class='d-flex justify-content-between'>
                        <div className=''>
                          <label className=''>Expense Transaction List</label>
                        </div>

                        <div className='d-flex float-center'>
                          <div className='' style={{ marginRight: '5px' }}>
                            <Button
                              color='cyan'
                              radius='sm'
                              size='sm'
                              onClick={() => setShow('bankTable')}
                            >
                              Bank({bankTotal} MMK)
                            </Button>
                          </div>

                          <div className=''>
                            <Button
                              color='cyan'
                              radius='sm'
                              size='sm'
                              onClick={() => setShow('cashTable')}
                            >
                              Cash({cashTotal} MMK)
                            </Button>
                          </div>
                        </div>

                        <span class='float-right'>
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target='#add_incomes'
                            class='btn btn-sm btn-dark rounded-1'
                            onclick='hide_bank_acc()'
                            onClick={showDialog}
                          >
                            <i class='fas fa-plus'></i> Add
                          </button>
                          &nbsp;
                          {/* <a href="/expense_type" class="btn btn-primary">
                              Expense Type
                            </a> */}
                          {show == 'bankTable' ? (
                            <button
                              type='button'
                              className='btn btn-sm btn-success'
                              onClick={excelExport}
                            >
                              Export
                            </button>
                          ) : (
                            <button
                              type='button'
                              className='btn btn-sm btn-success'
                              onClick={excelExport1}
                            >
                              Export
                            </button>
                          )}
                        </span>
                      </div>
                      <div class='row' id='trial_balance'></div>
                    </div>

                    <div class='card-body'>
                      {/* Export data in Excel */}

                      {show == 'bankTable' ? (
                        <ExcelExport data={bankExpenseList} ref={_export}>
                          <ExcelExportColumn
                            field=''
                            title='No'
                            locked={true}
                            width={30}
                          />
                          <ExcelExportColumn
                            field='date'
                            title='Date'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={200}
                          />

                          <ExcelExportColumn
                            field='relatedBankAccount.name'
                            title='Bank Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedCashAccount.name'
                            title='Cash Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedAccounting.name'
                            title='Account'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalAmount'
                            title='Balance'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalCurrency'
                            title='Currency'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />

                          <ExcelExportColumn
                            field='relatedBranch.name'
                            title='Branch'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='remark'
                            title='Remark'
                            width={150}
                          />
                        </ExcelExport>
                      ) : (
                        <ExcelExport data={cashExpenseList} ref={_export}>
                          <ExcelExportColumn
                            field=''
                            title='No'
                            locked={true}
                            width={30}
                          />
                          <ExcelExportColumn
                            field='date'
                            title='Date'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={200}
                          />

                          <ExcelExportColumn
                            field='relatedBankAccount.name'
                            title='Bank Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedCashAccount.name'
                            title='Cash Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedAccounting.name'
                            title='Account'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalAmount'
                            title='Balance'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalCurrency'
                            title='Currency'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />

                          <ExcelExportColumn
                            field='relatedBranch.name'
                            title='Branch'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='remark'
                            title='Remark'
                            width={150}
                          />
                        </ExcelExport>
                      )}
                      {/* 
                      {showBranch && (
                        <ExcelExport data={relatedBranch} ref={_export}>
                          <ExcelExportColumn
                            field=''
                            title='No'
                            locked={true}
                            width={30}
                          />
                          <ExcelExportColumn
                            field='date'
                            title='Date'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={200}
                          />

                          <ExcelExportColumn
                            field='relatedBankAccount.name'
                            title='Bank Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedCashAccount.name'
                            title='Cash Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedAccounting.name'
                            title='Account'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalAmount'
                            title='Balance'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalCurrency'
                            title='Currency'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />

                          <ExcelExportColumn
                            field='relatedBranch.name'
                            title='Branch'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='remark'
                            title='Remark'
                            width={150}
                          />
                        </ExcelExport>
                      )} */}

                      {/* Export Data in Excel end */}
                      <div class='row'>
                        <div class='col-md-12'>
                          <div
                            class='table-responsive text-black'
                            id='slimtest2'
                          >
                            <table class='table table-hover' id='filter_date'>
                              <thead class='ok'>
                                <tr>
                                  <th>#</th>
                                  <th class='text-center'>Date</th>
                                  <th class='text-center'>
                                    Bank / Cash Account
                                  </th>
                                  <th class='text-center'>Account</th>

                                  <th class='text-center'>Balance</th>
                                  <th class='text-center'>Currency</th>

                                  <th class='text-center'>Remark</th>
                                  <th className='text-center'>Transaction</th>
                                  <th class='text-center'>Action</th>
                                </tr>
                              </thead>

                              {showSearch && (
                                <tbody className=''>
                                  {show === 'bankTable'
                                    ? searchList
                                        .filter(el => el.relatedBankAccount)
                                        .map((expenseList, i) => (
                                          <>
                                            <tr>
                                              <td>{++i}</td>
                                              <td>
                                                {expenseList.date
                                                  ? expenseList.date.split(
                                                      'T'
                                                    )[0]
                                                  : ''}
                                              </td>
                                              <td>
                                                {expenseList.relatedBankAccount
                                                  ? expenseList
                                                      .relatedBankAccount.name
                                                  : expenseList
                                                      .relatedCashAccount.name}
                                              </td>
                                              <td>
                                                {expenseList.relatedAccounting
                                                  ? expenseList
                                                      .relatedAccounting.name
                                                  : 'No'}
                                              </td>

                                              <td>
                                                {expenseList.finalAmount
                                                  ? expenseList.finalAmount.toLocaleString(
                                                      undefined,
                                                      {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2
                                                      }
                                                    )
                                                  : ''}
                                              </td>
                                              <td>
                                                {expenseList.finalCurrency}
                                              </td>

                                              <td>{expenseList.remark}</td>

                                              <td className='text-center'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-primary ml-2'
                                                  onClick={() =>
                                                    handleRelatedShow(
                                                      expenseList._id
                                                    )
                                                  }
                                                >
                                                  Transaction
                                                </button>
                                              </td>
                                              <td>
                                                <Link
                                                  to={
                                                    '/expense-update/' +
                                                    expenseList._id
                                                  }
                                                  className='btn btn-sm btn-warning'
                                                >
                                                  <FaRegEdit />
                                                </Link>
                                                &nbsp;
                                                <button
                                                  className='btn btn-sm btn-danger'
                                                  onClick={e =>
                                                    handleDelete(
                                                      expenseList._id
                                                    )
                                                  }
                                                >
                                                  <FaRegTrashAlt />
                                                </button>
                                                &nbsp;
                                              </td>
                                            </tr>
                                            <tr
                                              className='bg-light'
                                              id={'toggle' + expenseList._id}
                                              hidden
                                            >
                                              <td colspan='12'>
                                                <div>
                                                  <div class='row'>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        No
                                                      </label>
                                                    </div>
                                                    <div class='col-md-3'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Account
                                                      </label>
                                                    </div>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Type
                                                      </label>
                                                    </div>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Date
                                                      </label>
                                                    </div>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Amount
                                                      </label>
                                                    </div>
                                                  </div>

                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <div class='row'>
                                                            <div class='col-md-2'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {++i}
                                                              </div>
                                                            </div>
                                                            <div class='col-md-3'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {
                                                                  reList
                                                                    .relatedAccounting
                                                                    .name
                                                                }
                                                              </div>
                                                            </div>
                                                            <div class='col-md-2'>
                                                              {reList.type}
                                                            </div>
                                                            <div class='col-md-2'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {
                                                                  reList.date.split(
                                                                    'T'
                                                                  )[0]
                                                                }
                                                              </div>
                                                            </div>
                                                            <div class='col-md-2'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {reList.amount}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                    : ''}
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        ))
                                    : searchList
                                        .filter(el => el.relatedCashAccount)
                                        .map((expenseList, i) => (
                                          <>
                                            <tr>
                                              <td>{++i}</td>
                                              <td>
                                                {expenseList.date
                                                  ? expenseList.date.split(
                                                      'T'
                                                    )[0]
                                                  : ''}
                                              </td>
                                              <td>
                                                {expenseList.relatedBankAccount
                                                  ? expenseList
                                                      .relatedBankAccount.name
                                                  : expenseList
                                                      .relatedCashAccount.name}
                                              </td>
                                              <td>
                                                {expenseList.relatedAccounting
                                                  ? expenseList
                                                      .relatedAccounting.name
                                                  : 'No'}
                                              </td>

                                              <td>
                                                {expenseList.finalAmount
                                                  ? expenseList.finalAmount.toLocaleString(
                                                      undefined,
                                                      {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2
                                                      }
                                                    )
                                                  : ''}
                                              </td>
                                              <td>
                                                {expenseList.finalCurrency}
                                              </td>

                                              <td>{expenseList.remark}</td>

                                              <td className='text-center'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-primary ml-2'
                                                  onClick={() =>
                                                    handleRelatedShow(
                                                      expenseList._id
                                                    )
                                                  }
                                                >
                                                  Transaction
                                                </button>
                                              </td>
                                              <td>
                                                <Link
                                                  to={
                                                    '/expense-update/' +
                                                    expenseList._id
                                                  }
                                                  className='btn btn-sm btn-warning'
                                                >
                                                  <FaRegEdit />
                                                </Link>
                                                &nbsp;
                                                <button
                                                  className='btn btn-sm btn-danger'
                                                  onClick={e =>
                                                    handleDelete(
                                                      expenseList._id
                                                    )
                                                  }
                                                >
                                                  <FaRegTrashAlt />
                                                </button>
                                                &nbsp;
                                              </td>
                                            </tr>
                                            <tr
                                              className='bg-light'
                                              id={'toggle' + expenseList._id}
                                              hidden
                                            >
                                              <td colspan='12'>
                                                <div>
                                                  <div class='row'>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        No
                                                      </label>
                                                    </div>
                                                    <div class='col-md-3'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Account
                                                      </label>
                                                    </div>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Type
                                                      </label>
                                                    </div>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Date
                                                      </label>
                                                    </div>
                                                    <div class='col-md-2'>
                                                      <label
                                                        style={{
                                                          fontSize: '15px'
                                                        }}
                                                        class='text-dark'
                                                      >
                                                        Amount
                                                      </label>
                                                    </div>
                                                  </div>

                                                  {relatedLists
                                                    ? relatedLists.map(
                                                        (reList, i) => (
                                                          <div class='row'>
                                                            <div class='col-md-2'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {++i}
                                                              </div>
                                                            </div>
                                                            <div class='col-md-3'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {
                                                                  reList
                                                                    .relatedAccounting
                                                                    .name
                                                                }
                                                              </div>
                                                            </div>
                                                            <div class='col-md-2'>
                                                              {reList.type}
                                                            </div>
                                                            <div class='col-md-2'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {
                                                                  reList.date.split(
                                                                    'T'
                                                                  )[0]
                                                                }
                                                              </div>
                                                            </div>
                                                            <div class='col-md-2'>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    '15px'
                                                                }}
                                                              >
                                                                {reList.amount}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                    : ''}
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        ))}
                                </tbody>
                              )}

                              {hide && (
                                <tbody className=''>
                                  {show === 'bankTable'
                                    ? bankExpenseList.map((expenseList, i) => (
                                        <>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>
                                              {expenseList.date
                                                ? expenseList.date.split('T')[0]
                                                : ''}
                                            </td>
                                            <td>
                                              {expenseList.relatedBankAccount
                                                ? expenseList.relatedBankAccount
                                                    .name
                                                : expenseList.relatedCashAccount
                                                    .name}
                                            </td>
                                            <td>
                                              {expenseList.relatedAccounting
                                                ? expenseList.relatedAccounting
                                                    .name
                                                : 'No'}
                                            </td>

                                            <td>
                                              {expenseList.finalAmount
                                                ? expenseList.finalAmount.toLocaleString(
                                                    undefined,
                                                    {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    }
                                                  )
                                                : ''}
                                            </td>
                                            <td>{expenseList.finalCurrency}</td>

                                            <td>{expenseList.remark}</td>

                                            <td className='text-center'>
                                              <button
                                                type='button'
                                                className='btn btn-sm btn-primary ml-2'
                                                onClick={() =>
                                                  handleRelatedShow(
                                                    expenseList._id
                                                  )
                                                }
                                              >
                                                Transaction
                                              </button>
                                            </td>
                                            <td>
                                              <Link
                                                to={
                                                  '/expense-update/' +
                                                  expenseList._id
                                                }
                                                className='btn btn-sm btn-warning'
                                              >
                                                <FaRegEdit />
                                              </Link>
                                              &nbsp;
                                              <button
                                                className='btn btn-sm btn-danger'
                                                onClick={e =>
                                                  handleDelete(expenseList._id)
                                                }
                                              >
                                                <FaRegTrashAlt />
                                              </button>
                                              &nbsp;
                                            </td>
                                          </tr>
                                          <tr
                                            className='bg-light'
                                            id={'toggle' + expenseList._id}
                                            hidden
                                          >
                                            <td colspan='12'>
                                              <div>
                                                <div class='row'>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      No
                                                    </label>
                                                  </div>
                                                  <div class='col-md-3'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Account
                                                    </label>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Type
                                                    </label>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Date
                                                    </label>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Amount
                                                    </label>
                                                  </div>
                                                </div>

                                                {relatedLists
                                                  ? relatedLists.map(
                                                      (reList, i) => (
                                                        <div class='row'>
                                                          <div class='col-md-2'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {++i}
                                                            </div>
                                                          </div>
                                                          <div class='col-md-3'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {
                                                                reList
                                                                  .relatedAccounting
                                                                  .name
                                                              }
                                                            </div>
                                                          </div>
                                                          <div class='col-md-2'>
                                                            {reList.type}
                                                          </div>
                                                          <div class='col-md-2'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {
                                                                reList.date.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </div>
                                                          </div>
                                                          <div class='col-md-2'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {reList.amount}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  : ''}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))
                                    : cashExpenseList.map((expenseList, i) => (
                                        <>
                                          <tr>
                                            <td>{++i}</td>
                                            <td>
                                              {expenseList.date
                                                ? expenseList.date.split('T')[0]
                                                : ''}
                                            </td>
                                            <td>
                                              {expenseList.relatedBankAccount
                                                ? expenseList.relatedBankAccount
                                                    .name
                                                : expenseList.relatedCashAccount
                                                    .name}
                                            </td>
                                            <td>
                                              {expenseList.relatedAccounting
                                                ? expenseList.relatedAccounting
                                                    .name
                                                : 'No'}
                                            </td>

                                            <td>
                                              {expenseList.finalAmount
                                                ? expenseList.finalAmount.toLocaleString(
                                                    undefined,
                                                    {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2
                                                    }
                                                  )
                                                : ''}
                                            </td>
                                            <td>{expenseList.finalCurrency}</td>

                                            <td>{expenseList.remark}</td>

                                            <td className='text-center'>
                                              <button
                                                type='button'
                                                className='btn btn-sm btn-primary ml-2'
                                                onClick={() =>
                                                  handleRelatedShow(
                                                    expenseList._id
                                                  )
                                                }
                                              >
                                                Transaction
                                              </button>
                                            </td>
                                            <td>
                                              <Link
                                                to={
                                                  '/expense-update/' +
                                                  expenseList._id
                                                }
                                                className='btn btn-sm btn-warning'
                                              >
                                                <FaRegEdit />
                                              </Link>
                                              &nbsp;
                                              <button
                                                className='btn btn-sm btn-danger'
                                                onClick={e =>
                                                  handleDelete(expenseList._id)
                                                }
                                              >
                                                <FaRegTrashAlt />
                                              </button>
                                              &nbsp;
                                            </td>
                                          </tr>
                                          <tr
                                            className='bg-light'
                                            id={'toggle' + expenseList._id}
                                            hidden
                                          >
                                            <td colspan='12'>
                                              <div>
                                                <div class='row'>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      No
                                                    </label>
                                                  </div>
                                                  <div class='col-md-3'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Account
                                                    </label>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Type
                                                    </label>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Date
                                                    </label>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <label
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                      class='text-dark'
                                                    >
                                                      Amount
                                                    </label>
                                                  </div>
                                                </div>

                                                {relatedLists
                                                  ? relatedLists.map(
                                                      (reList, i) => (
                                                        <div class='row'>
                                                          <div class='col-md-2'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {++i}
                                                            </div>
                                                          </div>
                                                          <div class='col-md-3'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {
                                                                reList
                                                                  .relatedAccounting
                                                                  .name
                                                              }
                                                            </div>
                                                          </div>
                                                          <div class='col-md-2'>
                                                            {reList.type}
                                                          </div>
                                                          <div class='col-md-2'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {
                                                                reList.date.split(
                                                                  'T'
                                                                )[0]
                                                              }
                                                            </div>
                                                          </div>
                                                          <div class='col-md-2'>
                                                            <div
                                                              style={{
                                                                fontSize: '15px'
                                                              }}
                                                            >
                                                              {reList.amount}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  : ''}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                </tbody>
                              )}
                            </table>
                          </div>

                          <div className='d-flex '>
                            <div style={{ marginRight: '100px' }}>
                              <p>
                                <b>
                                  <u>Bank List</u>
                                </b>
                              </p>
                              {Object.entries(bankNameList).map(([k, v]) => (
                                <p>
                                  {k} : {v}{' '}
                                </p>
                              ))}
                            </div>

                            <div>
                              <p>
                                <b>
                                  <u>Cash List</u>
                                </b>
                              </p>
                              {Object.entries(cashNameList).map(([k, v]) => (
                                <p>
                                  {k} : {v}{' '}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ExpenseDialog
                open={open}
                close={() => setOpen(false)}
                expenseLists={expenseLists}
                setExpenseLists={setExpenseLists}
              />
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Expense

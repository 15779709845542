import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import CurrencyDialog from '../views/CurrencyDialog'
import CurrencyUpdate from '../views/CurrencyUpdate'
import { Link } from 'react-router-dom'
import SideBar from './SideBar'
import useCollapse from 'react-collapsed'
import HeaderName from './HeaderName'
import apiInstance from '../../utils/api'
function Dashboard () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [isExpanded, setExpanded] = useState(false)
  const [isAccData, setAccData] = useState(false)

  const { getCollapseProps, getToggleProps } = useCollapse()

  const handleAdminChange = () => {
    setExpanded(!isExpanded)
  }

  const handleAccChange = () => {
    setAccData(!isAccData)
  }
  const [purchaseLists, setPurchaseLists] = useState([])
  const [allcurrencylist, setAllCurrencyLists] = useState([])
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [code, setCode] = useState('')
  const [currencyName, setCurrencyName] = useState('')
  const [showUpdate, setShowUpdate] = useState(false)
  const [id, setId] = useState('')
  const showDialog = () => setOpen(true)
  const showCurrencyUpdate = val => {
    setId(val)
    setShowUpdate(true)
  }

  useEffect(() => {
    const getPurchaseLists = async () => {
      try {
        const res = await apiInstance.get('purchases')

        setPurchaseLists(res.data.list)
        console.log(res.data.list,'hi')
      } catch (err) {}
    }
    getPurchaseLists()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Purchase List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Currency List</h3> */}

                      <Link
                        to='/add_purchase'
                        className='btn btn-dark float-right'
                        data-toggle='modal'
                        data-target='#currency'
                      >
                        <i className='fa fa-plus'></i> &nbsp;Add
                      </Link>
                    </div>
                    <div className='card-body'>
                      <table id='example1' className='table'>
                        <thead className=' bg-info'>
                          <tr>
                            <th>No</th>
                            <th>Purchase Date</th>
                            <th>Supplier Name</th>
                            <th>Total Qty</th>
                            <th>Total Price</th>

                            <th className='text-center'>Action</th>
                          </tr>
                        </thead>

                        <tbody className=''>
                          {purchaseLists.map((purchaseList, i) => (
                            <tr>
                              <td>{++i}</td>
                              <td>{purchaseList.purchaseDate?.split('T')[0]}</td>

                              <td>
                                {purchaseList.supplierName
                                  ? purchaseList.supplierName.name
                                  : ''}
                              </td>
                              <td>{purchaseList.totalQTY}</td>
                              <td>{purchaseList.totalPrice}</td>

                              <td className='text-center'>
                                <Link
                                  to={'/purchaseDetail/' + purchaseList._id}
                                  class='btn btn-dark btn-sm'
                                >
                                  Detail
                                </Link>
                              </td>
                            </tr>
                          ))}

                          {/* @endforeach */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <CurrencyDialog
                open={open}
                close={() => setOpen(false)}
                setOpen={setOpen}
                setPurchaseLists={setPurchaseLists}
                purchaseLists={purchaseLists}
              />
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Dashboard

import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import apiInstance from '../../utils/api'
export default function CurrencyUpdate(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [exchangeRate, setExchangeRate] = useState('')
  const [name, setName] = useState('')

  const CurrencyUpdate = () => {
    const data = {
      id: props.id,
      exchangeRate: exchangeRate,
      lastUpdate: Date.now()
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.put('currency', data, config).then(function (response) {
      // alert("success");
      const index = props.currencyLists.findIndex(item => item._id === props.id)
      let arr = [...props.currencyLists]
      arr[index] = {
        ...arr[index],
        ...response.data.data
      }
      props.setCurrencyLists(arr)
    })
    props.setShowUpdate(false)
  }

  return (
    <div>
      <Dialog open={props.showUpdate} onClose={props.close}>
        <DialogTitle>
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Update New Currency</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <form action='' method='post'>
            <div class='modal-body'>
              <div class='form-group'>
                <label for='name'>Exchange Rate</label>
                <input
                  type='text'
                  class='form-control border border-info'
                  name='rate'
                  onChange={e => setExchangeRate(e.target.value)}
                />
              </div>
            </div>
            <div class='modal-footer'>
              <Button
                class='btn btn-secondary'
                data-dismiss='modal'
                onClick={props.close}
              >
                Close
              </Button>
              <Button class='btn btn-primary' onClick={CurrencyUpdate}>
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaArrowLeft, FaMinus } from 'react-icons/fa'
import Sidebar from '../../SideBar'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import HeaderName from '../../HeaderName'
// import { useSelector } from 'react-redux'
import { MultiSelect } from "react-multi-select-component";
import apiInstance from '../../../../utils/api'
function LabServiceRegister() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const ID = useLocation().pathname.split('/')[2]
  console.log(ID, 'id')

  //Doctor
  const [Doctor, setDoctor] = useState([])
  const [branch, setBranch] = useState('')
  const [name, setName] = useState('')
  const [special, setSpecial] = useState('')
  const [treatmentUnitMain, setTreatmentUnitMain] = useState('')
  const [commission, setCommission] = useState('')
  const [schedule, setSchedule] = useState('')
  const [selected, setSelected] = useState([]);

  //TherapiInstancest
  const [Thera, setTheRa] = useState([])
  const [tBranch, setTBranch] = useState('')
  const [tName, setTName] = useState('')
  const [tSpecial, setTSpecial] = useState('')
  const [tTreatmentUnitMain, setTTreatmentUnitMain] = useState('')
  const [tCommission, setTCommission] = useState('')
  const [tSchedule, setTSchedule] = useState('')
  const [headTreatmentList, setHeadTreatmentList] = useState([])
  const [treatmentArr, setTreatmentArr] = useState([])
  const [treatmentName, setTreatmentName] = useState('')
  const [oriTreatmentArr, setOriTreatmentArr] = useState([])

  //Nurse
  const [Nurse, setNurse] = useState([])
  const [nBranch, setNBranch] = useState('')
  const [nName, setNName] = useState('')
  const [nSpecial, setNSpecial] = useState('')
  const [nTreatmentUnitMain, setNTreatmentUnitMain] = useState('')
  const [nCommission, setNCommission] = useState('')
  const [nSchedule, setNSchedule] = useState('')

  const [showRelatedDoc, setShowRelatedDoc] = useState(true)
  const [showTherapiInstancest, setShowTherapiInstancest] = useState(false)
  const [showNurse, setShowNurse] = useState(false)

  const [doctorLists, setDoctorLists] = useState([])
  const [allTreatmentList, setAllTreatmentList] = useState([])
  const [nurseLists, setNurseLists] = useState([])
  // console.log(treatmentArr, 'adsfsddsf')
  const [branchLists, setBranchLists] = useState([])
  const [Id, setId] = useState('')

  // const url = 'http://backendcherryk.cherrykmyanmar.com:4000/apiInstance'
  const options = headTreatmentList.map((i) => {
    return { label: i.name, value: i._id }
  })

  // console.log(selected.map((e) => {
  //   return {
  //     relatedTreatmentList: e.value,
  //     commissionPercent: parseInt(commission),
  //   }
  // }), 'tre')
  const handleAdd = Id => {
    setId(Id)
    // console.log(headTreatmentList.filter(el => el._id === Id)[0]?.name, 'name')
    setTreatmentName(headTreatmentList.filter(el => el._id === Id)[0]?.name)

  }

  const handleBox = event => {

    let newPackage = {
      relatedTreatmentList: Id,
      commissionPercent: commission ? parseInt(commission) : 0,

    }
    setTreatmentArr([...treatmentArr, newPackage])
    document.getElementById('com').value = 0
    document.getElementById('treatname').value = 'Choose Treatment'

  }
  const handleListDelete = (index) => {
    // console.log(treatmentArr.filter((el, ind) => ind !== index), 'ind')
    setTreatmentArr(treatmentArr.filter((el, ind) => ind !== index))
    setCommission(0)
    // console.log(treatmentArr.filter((el, ind) => ind !== index))
  }

  const handleListOldDelete = (inde) => {
    setTreatmentArr(treatmentArr.filter((el, ind) => ind !== inde))
  }


  //Doctor create
  const DoctorCreate = event => {
    const data = {
      id: ID

    }
    if (oriTreatmentArr) {
      data.commission = [
        ...oriTreatmentArr,
        ...treatmentArr
      ]
    } else {
      data.commission = [
        ...oriTreatmentArr,
        ...treatmentArr
      ]

    }
    if (schedule) {
      data.schedule = schedule
    } else {
      data.schedule = Doctor[0]?.schedule
    }

    if (name) {
      data.name = name
    } else {
      data.name = Doctor[0]?.name
    }

    if (treatmentUnitMain) {
      data.treatmentUnitMain = treatmentUnitMain
    } else {
      data.treatmentUnitMain = Doctor[0]?.treatmentUnitMain
    }
    if (special) {
      data.speciality = special
    } else {
      data.speciality = Doctor[0]?.speciality
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('doctor', data, config)
      .then(function (response) {
        // setDoctorLists([...doctorLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        document.getElementById('name').value = ''
        document.getElementById('com').value = ''
        document.getElementById('spe').value = ''
        document.getElementById('unit').value = ''
        document.getElementById('sche').value = ''
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  //TherapiInstancest create
  const TherapiInstancestCreate = event => {
    const data = {
      id: ID
      // name: tName,
      // relatedBranch: tBranch,
      // treatmentUnitMain: tTreatmentUnitMain,
      // commission: tCommission,
      // schedule: tSchedule
      // speciality: tSpecial
    }
    if (tCommission) {
      data.commission = tCommission
    } else {
      data.commission = Thera[0]?.commission
    }
    if (tSchedule) {
      data.schedule = tSchedule
    } else {
      data.schedule = Thera[0]?.schedule
    }

    if (tName) {
      data.name = tName
    } else {
      data.name = Thera[0]?.name
    }

    if (tTreatmentUnitMain) {
      data.treatmentUnitMain = tTreatmentUnitMain
    } else {
      data.treatmentUnitMain = Thera[0]?.treatmentUnitMain
    }
    if (tSpecial) {
      data.speciality = tSpecial
    } else {
      data.speciality = Thera[0]?.speciality
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('therapiInstancest', data, config)
      .then(function (response) {
        // setTherapiInstancestLists([...therapiInstancestLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          confirmButtonText: 'OK'
        })

        // document.getElementById('name').value = ''
        // document.getElementById('com').value = ''
        // document.getElementById('spe').value = ''
        // document.getElementById('unit').value = ''
        // document.getElementById('sche').value = ''
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  //Nurse
  const NurseCreate = event => {
    const data = {
      id: ID
      // name: nName,
      // relatedBranch: nBranch,
      // treatmentUnitMain: nTreatmentUnitMain,
      // commission: nCommission,
      // schedule: nSchedule
      // speciality: nSpecial
    }
    if (nCommission) {
      data.commission = nCommission
    } else {
      data.commission = Nurse[0]?.commission
    }
    if (nSchedule) {
      data.schedule = nSchedule
    } else {
      data.schedule = Nurse[0]?.schedule
    }
    if (nName) {
      data.name = nName
    } else {
      data.name = Nurse[0]?.name
    }

    if (nTreatmentUnitMain) {
      data.treatmentUnitMain = nTreatmentUnitMain
    } else {
      data.treatmentUnitMain = Nurse[0]?.treatmentUnitMain
    }
    if (nSpecial) {
      data.speciality = nSpecial
    } else {
      data.speciality = Nurse[0]?.speciality
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('nurse', data, config)
      .then(function (response) {
        // setNurseLists([...nurseLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'Successfully Nurse Registered!',
          icon: 'success',
          confirmButtonText: 'OK'
        })

        // document.getElementById('name').value = ''
        // document.getElementById('com').value = ''
        // document.getElementById('spe').value = ''
        // document.getElementById('unit').value = ''
        // document.getElementById('sche').value = ''
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  useEffect(() => {
    const getBranches = async () => {
      try {
        const res = await apiInstance.get('branches')
        setBranchLists(res.data.list)
      } catch (err) {
        alert(err.message)
      }
    }
    const getDoctorLists = async () => {
      const res = await apiInstance.get('doctors')

      setDoctorLists(res.data.data)

      if (res.data.data.filter(el => el._id === ID)) {
        setDoctor(res.data.data.filter(el => el._id === ID))
        // console.log(res.data.data.filter(el => el._id === ID))
        setShowNurse(false)
        setShowRelatedDoc(true)
        setShowTherapiInstancest(false)
        setTreatmentArr([...treatmentArr, ...res.data.data.filter(el => el._id === ID)[0].commission])
        // setOriTreatmentArr([..., treatmentArr.map((i) => {
        //   return {
        //     relatedTreatmentList: i.relatedTreatmentList,
        //     commissionPercent: i.commission
        //   }
        // })])
      }
    }
    getDoctorLists()

    // const getTherapiInstancestLists = async () => {
    //   const res = await apiInstance.get('therapiInstancests')

    //   setTherapiInstancestLists(res.data.data)

    //   if (res.data.data.filter(el => el._id === ID)) {
    //     setTheRa(res.data.data.filter(el => el._id === ID))
    //     setShowNurse(false)
    //     setShowRelatedDoc(false)
    //     setShowTherapiInstancest(true)
    //   }
    // }
    // getTherapiInstancestLists()

    const getNurseLists = async () => {
      const res = await apiInstance.get('nurses')
      console.log(res.data.list, 'nur')

      setNurseLists(res.data.list)

      if (res.data.list.filter(el => el._id === ID)) {
        setNurse(res.data.list.filter(el => el._id === ID))
        setShowNurse(true)
        setShowRelatedDoc(false)
        setShowTherapiInstancest(false)
      }
    }
    const getHeadTreatment = async () => {

      try {
        const res = await apiInstance.get('treatment-lists')

        // console.log(res.data.list, 'd list one')
        // const doctorCom = doctorLists.filter((i) => (i.commission[0] && i.commission.map((p) => (p.relatedTreatmentList)))).map((list) => (list.commission))[0]
        // const hee = res.data.list.filter(el => doctorCom.map((e) => (e.relatedTreatmentList)) === el._id)
        // console.log(doctorCom, 'com')
        // console.log(hee, 'hee')
        setHeadTreatmentList(res.data.list)
      } catch (err) { }
    }

    getHeadTreatment()
    getDoctorLists()
    getNurseLists()

    // getBranches()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />
        <Sidebar />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/doctor'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active mt-1'>
                      Doctor / Nurse Aid Update
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className='content'>
            <div className='container-fluid'>
              {/* <!-- Small boxes (Stat box) --> */}
              <div class='card'>
                <div class='card-body p-b-0'>
                  {/* @if($com == null) */}

                  {/* @csrf */}

                  <div className='form-group'>
                    {Doctor[0] && (
                      <div className='row'>
                        <div className='col-md-4'>
                          <label>ReferDoctor</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            checked={Doctor[0]}
                          />
                        </div>

                        <div className='col-md-4'>
                          <label>Nurse Aid</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            checked={Nurse[0]}
                            disabled
                          />
                        </div>
                        <div className='col-md-4'>
                          <label>TherapiInstancest</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            value='Pathologist'
                            checked={Thera[0]}
                            disabled
                          />
                        </div>
                      </div>
                    )}

                    {Nurse[0] && (
                      <div className='row'>
                        <div className='col-md-4'>
                          <label>ReferDoctor</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            checked={Doctor[0]}
                            disabled
                          />
                        </div>

                        <div className='col-md-4'>
                          <label>Nurse Aid</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            checked={Nurse[0]}
                          />
                        </div>
                        <div className='col-md-4'>
                          <label>TherapiInstancest</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            value='Pathologist'
                            checked={Thera[0]}
                            disabled
                          />
                        </div>
                      </div>
                    )}

                    {Thera[0] && (
                      <div className='row'>
                        <div className='col-md-4'>
                          <label>ReferDoctor</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            checked={Doctor[0]}
                            disabled
                          />
                        </div>

                        <div className='col-md-4'>
                          <label>Nurse Aid</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            checked={Nurse[0]}
                            disabled
                          />
                        </div>
                        <div className='col-md-4'>
                          <label>TherapiInstancest</label>&nbsp;
                          <input
                            type='radio'
                            id='doccl'
                            name='doccl'
                            value='Pathologist'
                            checked={Thera[0]}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {Nurse[0] && (
                    <>
                      <div className='form-group'>
                        <label className='control-label'>Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='company_address'
                          id='name'
                          defaultValue={Nurse[0]?.name}
                          onChange={e => setNName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Position</label>
                        <input
                          type='text'
                          defaultValue={Nurse[0]?.speciality}
                          className='form-control'
                          id='spe'
                          name='company_contact'
                          onChange={e => setNSpecial(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Treatment Type</label>
                        <input
                          type='email'
                          className='form-control'
                          id='unit'
                          defaultValue={Nurse[0]?.treatmentUnitMain}
                          name='company_email'
                          onChange={e => setNTreatmentUnitMain(e.target.value)}
                        />
                      </div>

                      <div className='form-group'>
                        <label className='control-label'>Commission</label>

                        <input
                          type='number'
                          value={2}
                          className='form-control'
                          id='com'
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Joined Date</label>

                        <input
                          type='date'
                          className='form-control'
                          defaultValue={Nurse[0]?.createdAt?.split('T')[0]}
                          id='sche'
                          onChange={e => setNSchedule(e.target.value)}
                        />
                      </div>
                      <div></div>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className=' col-md-9'>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={NurseCreate}
                              >
                                Nurse Update
                              </button>
                              &nbsp;
                              <Link
                                to='/doctor'
                                className='btn btn-danger'
                                data-dismiss='modal'
                              >
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {Doctor[0] && (
                    <>
                      {/* <div className='form-group'>
                        <label>Branch</label>
                        <select
                          name='currency'
                          id=''
                          className='form-control mt-1'
                          onchange='convert(this.value)'
                          // placeholder='Filter By Branches'
                          onChange={e => setBranch(e.target.value)}
                        >
                          <option hidden value={Doctor[0].relatedBranch?._id}>
                            {Doctor[0].relatedBranch?.name}
                          </option>

                          {branchLists.map(option => (
                            <option value={option._id}>{option.name}</option>
                          ))}
                        </select>
                      </div> */}

                      <div className='form-group'>
                        <label className='control-label'>Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='company_address'
                          defaultValue={Doctor[0]?.name}
                          id='name'
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Position</label>
                        <input
                          type='text'
                          className='form-control'
                          id='spe'
                          name='company_contact'
                          defaultValue={Doctor[0]?.speciality}
                          onChange={e => setSpecial(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Treatment Type</label>
                        <input
                          type='email'
                          className='form-control'
                          id='unit'
                          defaultValue={Doctor[0]?.treatmentUnitMain}
                          name='company_email'
                          onChange={e => setTreatmentUnitMain(e.target.value)}
                        />
                      </div>
                      {/* <div className='form-group'>
                        <label>Treatment</label>&nbsp;

                        <MultiSelect
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"
                        />


                      </div> */}

                      <div className='form-group'>
                        <label className='control-label'>Joined Date</label>

                        <input
                          type='date'
                          className='form-control'
                          defaultValue={Doctor[0]?.createdAt.split('T')[0]}
                          id='sche'
                          onChange={e => setSchedule(e.target.value)}
                        />
                      </div>
                      <div className=' row '>
                        <div className='col-5' style={{ marginTop: '0.8rem' }}>
                          <label>Treatment</label>&nbsp;
                          <select

                            class='custom-select border-info'
                            name='account_type_id'

                            id='treatname'
                            onChange={e => {
                              handleAdd(e.target.value)
                            }}
                          >
                            <option hidden>Choose Treatment</option>
                            {headTreatmentList.map(option => (
                              <option value={option._id} >
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-5' style={{ marginTop: '0.5rem' }}>
                          <label>Comission</label>
                          <input
                            type='number'
                            defaultValue={commission}
                            className='form-control'
                            id='com'
                            onChange={e => setCommission(e.target.value)}
                          />
                        </div>
                        <div className='col-1' style={{ marginTop: '2.3rem' }}>
                          <button
                            type='button'
                            className='btn btn-lg btn-primary'
                            onClick={() => handleBox(Id)}
                          >
                            <i class='fa fa-plus'></i>
                          </button>
                        </div>
                      </div>
                      {(treatmentArr) && (
                        <div>
                          {treatmentArr.map((regArr, index) => (
                            <div className='row mt-3'>
                              <div className='col-md-5'>
                                <label>Treatment Name</label>
                                <input
                                  type='text'
                                  disabled
                                  defaultValue={treatmentName ? treatmentName : headTreatmentList.filter(el => el._id === regArr?.relatedTreatmentList)[0]?.name}
                                  className='form-control'
                                />
                              </div>
                              <div className='col-md-5'>
                                <label>Comission</label>
                                <input
                                  type='text'
                                  disabled
                                  value={regArr.commissionPercent}
                                  className='form-control'

                                />
                              </div>


                              <div className='col-md-2'>
                                <button
                                  className='btn btn-sm btn-danger rounded-circle opacity-75'
                                  style={{ marginTop: '2.5em' }}
                                  onClick={() => handleListOldDelete(index)}
                                >
                                  <FaMinus />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* {treatmentArr ? (
                        <div>
                          {treatmentArr.map((regArr, index) => (
                            <div className='row mt-3'>
                              <div className='col-md-5'>
                                <label>Treatment Name</label>
                                <input
                                  type='text'
                                  disabled

                                  defaultValue={treatmentName ? treatmentName : headTreatmentList.filter(el => el._id === regArr?.relatedTreatmentList)[0]?.name}
                                  className='form-control'
                                />
                              </div>
                              <div className='col-md-5'>
                                <label>Comission</label>
                                <input
                                  type='text'
                                  disabled
                                  defaultValue={regArr.commissionPercent}
                                  className='form-control'

                                />
                              </div>


                              <div className='col-md-2'>
                                <button
                                  className='btn btn-sm btn-danger rounded-circle opacity-75'
                                  style={{ marginTop: '2.5em' }}
                                  onClick={() => handleListDelete(index)}
                                >
                                  <FaMinus />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )} */}
                      <div></div>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className=' col-md-9'>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={DoctorCreate}
                              >
                                Doctor Update
                              </button>
                              &nbsp;
                              <Link
                                to='/doctor'
                                className='btn btn-danger'
                                data-dismiss='modal'
                              >
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {Thera[0] && (
                    <>
                      {/* <div className='form-group'>
                        <label>Branch</label>
                        <select
                          name='currency'
                          id=''
                          className='form-control mt-1'
                          onchange='convert(this.value)'
                          // placeholder='Filter By Branches'
                          onChange={e => setTBranch(e.target.value)}
                        >
                          <option hidden value={Thera[0]?.relatedBranch?._id}>
                            {Thera[0]?.relatedBranch?.name}
                          </option>

                          {branchLists.map(option => (
                            <option value={option._id}>{option.name}</option>
                          ))}
                        </select>
                      </div> */}
                      <div className='form-group'>
                        <label className='control-label'>Name</label>
                        <input
                          type='text'
                          defaultValue={Thera[0]?.name}
                          className='form-control'
                          name='company_address'
                          id='name'
                          onChange={e => setTName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Position</label>
                        <input
                          type='text'
                          defaultValue={Thera[0]?.speciality}
                          className='form-control'
                          id='phone'
                          name='company_contact'
                          onChange={e => setTSpecial(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Treatment Type</label>
                        <input
                          type='email'
                          className='form-control'
                          defaultValue={Thera[0]?.treatmentUnitMain}
                          id='email'
                          name='company_email'
                          onChange={e => setTTreatmentUnitMain(e.target.value)}
                        />
                      </div>
                      {/* <div className="form-group">
                      <div className="row  mt-3">
                        <div className="col-md-4">
                          <label>Amount</label>&nbsp;
                          <input
                            type="radio"
                            id="amoper"
                            name="amoper"
                            value="Amount"
                            onChange={(e) => setValueType(e.target.value)}
                          />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col-md-4">
                          <label>%</label>&nbsp;
                          <input
                            type="radio"
                            id="amoper"
                            name="amoper"
                            value="Percent"
                            onChange={(e) => setValueType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    */}
                      <div className='form-group'>
                        <label className='control-label'>Commission</label>

                        <input
                          type='number'
                          className='form-control'
                          value={2}
                        // onChange={e => setTCommission(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Joined Date</label>

                        <input
                          type='date'
                          className='form-control'
                          defaultValue={Thera[0]?.createdAt?.split('T')[0]}
                          onChange={e => setTSchedule(e.target.value)}
                        />
                      </div>
                      <div></div>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className=' col-md-9'>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={TherapiInstancestCreate}
                              >
                                TherapiInstancest Update
                              </button>
                              &nbsp;
                              <Link
                                to='/doctor'
                                className='btn btn-danger'
                                data-dismiss='modal'
                              >
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* <!-- /.row (main row) --> */}
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
          {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>
        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default LabServiceRegister

import React from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect, useState } from 'react'
// import CurrencyDialog from '../views/CurrencyDialog'
// import CurrencyUpdate from '../views/CurrencyUpdate'
import { Link, useLocation } from 'react-router-dom'
import ReactShadowScroll from 'react-shadow-scroll'
import useCollapse from 'react-collapsed'
import HeaderName from '../../HeaderName'
// import { motion, useViewportScroll, useTransform } from 'framer-motion'
import AddUser from './AddUserDialog'
import { Input } from '@nextui-org/react'

import {
  FaCashRegister,
  FaFileMedical,
  FaLongArrowAltRight,
  FaArrowLeft,
  FaAngleDown,
  FaRegEdit,
  FaAngleUp,
  FaList,
  FaRegUser,
  FaRegTrashAlt,
  FaClipboardCheck,
  FaPowerOff
} from 'react-icons/fa'
import apiInstance from '../../../../utils/api'
import { useSelector } from 'react-redux'
function Dashboard() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'

  // const api = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const purchaseListId = useLocation().pathname.split('/')[2]

  const [scroll, setScroll] = useState(true)
  const [isExpanded, setExpanded] = useState(false)
  const [isAccData, setAccData] = useState(false)
  const [open, setOpen] = useState(false)

  const { getCollapseProps, getToggleProps } = useCollapse()

  const handleAdminChange = () => {
    setExpanded(!isExpanded)
  }

  const handleAccChange = () => {
    setAccData(!isAccData)
  }
  const [userLists, setUserLists] = useState([])
  const [purchaseDetail, setPurchaseDetail] = useState([])

  const [showUpdate, setShowUpdate] = useState(false)
  const [id, setId] = useState('')
  const showDialog = () => setOpen(true)
  // const showDialog = val => {
  //   setId(val)
  //   setShowUpdate(true)
  // }

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await apiInstance.get('users')

        setUserLists(res.data.list)
        console.log(res.data.list, 'user')
      } catch (err) { }
    }

    const getRelatedMedicineLists = async () => {
      try {
        const res = await apiInstance.get('purchase/' + purchaseListId)
        console.log(res.data.data)
        setPurchaseDetail(res.data.data)
      } catch (err) { }
    }

    getUser()
    getRelatedMedicineLists()
    if (scroll) {
      document.body.style.overflow = 'scroll'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        <SideBar />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/user'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li
                      className='breadcrumb-item active'
                      style={{ marginTop: '0.1em' }}
                    >
                      Employee List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class=''>
              <div className='row ml-2'>
                <div className='col-12 mt-1'>
                  <div className='row'>
                    <div className='col-5'>
                      <p className='ml-5 fs-2'>Employee List</p>
                    </div>
                    <div className='offset-5 col-2 mt-2'>
                      <button
                        className='btn btn-sm btn-dark'
                        whileHover={{ scale: 1.2 }}
                        onHoverStart={e => { }}
                        onHoverEnd={e => { }}
                        type='button'
                        onClick={showDialog}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className='row '>
                    {userLists.map(purList => (
                      <div className='ml-5 col-5 card mt-3 '>
                        <div className='row'>
                          <div className='col-4'>
                            <img
                              src={require('../User/user-icon.webp')}
                              style={{
                                width: '150px',
                                marginTop: '30px'
                              }}
                              alt=''
                            />
                          </div>
                          <div
                            className='col-6 py-3
'
                          >
                            <p>
                              <b className='fs-5'>{purList.givenName}</b>
                              <br />
                              Role &nbsp;
                              <FaLongArrowAltRight />
                              &nbsp;
                              {purList.role}
                              <br />
                              Position &nbsp;
                              <FaLongArrowAltRight /> &nbsp;
                              {purList.position}
                              <br />
                              Phone &nbsp;
                              <FaLongArrowAltRight /> &nbsp;
                              {purList.phone}
                              <br />
                              Email &nbsp;
                              <FaLongArrowAltRight /> &nbsp;
                              {purList.email}
                              <br />
                              <b className='mt-2 float-right'>
                                <Link
                                  to={'/user-detail/' + purList._id}
                                  className='badge badge-sm badge-info px-2 py-1'
                                >
                                  Detail
                                </Link>
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <AddUser
                open={open}
                close={() => setOpen(false)}
                setUserLists={setUserLists}
                userLists={userLists}
              />
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>

        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Dashboard

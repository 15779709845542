import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://backendcherryk.kwintechnologykw11.com:4000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })
  //   console.log(nice[0].payment.imgUrl, 'img')

  //   const [id, setId] = useState(props.value)
  let imgUrl =
    'http://clinicdenovobackend.kwintechnologies.com:3000/static/cherry-k/' +
    props.value
  return (
    <div>
      <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
        <DialogTitle>
          {' '}
          <div className='modal-header ok'>
            <h4 className='modal-title'>Image</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <div className='card card-body'>
            <img src={imgUrl} alt='Treatment' />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import apiInstance from '../../../../utils/api'
import ReactPaginate from 'react-paginate'

import {
  FaCashRegister,
  FaFileMedical,
  FaPenSquare,
  FaRegEdit,
  FaRegTrashAlt
} from 'react-icons/fa'
import MedicineListDialog from '../MedicineList/MedicineListDialog'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import Footer from '../../footer'

const Income = ({ data, itemsPerPage }) => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })
  const [medicineLists, setMedicineLists] = useState([])
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showOri, setShowOri] = useState(true)
  const [searchList, setSearchList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  // Logic for paginating the data
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  console.log(data)
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem)

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const prevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const showDialog = () => setOpen(true)

  const handleDelete = event => {
    apiInstance
      .delete('medicine-list/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = medicineLists.filter(item => item._id !== event)
        setMedicineLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  const submit = id => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(id)
        },
        {
          label: 'No'
        }
      ]
    })
  }
  const search = val => {
    if (val) {
      setSearchList(
        medicineLists.filter(
          el =>
            el.name.toLowerCase().includes(val.toLowerCase()) ||
            el.code.toLowerCase().includes(val.toLowerCase()) ||
            el.relatedBrand.name.toLowerCase().includes(val.toLowerCase())
        )
      )
      setShowSearch(true)
      setShowOri(false)
    } else {
      setShowSearch(false)
      setShowOri(true)
    }
  }

  useEffect(() => {
    const getMedicineLists = async () => {
      try {
        const res = await apiInstance.get('medicine-lists?limit=100')
        console.log(res.data.list, 'data')
        setMedicineLists(res.data.list)
      } catch (err) {
        alert(err.message)
      }
    }
    getMedicineLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Medicine List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between'>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-2 col-md-4 ml-3'>
                  <div
                    className='input-group row ml-4'
                    style={{ marginBottom: '10px' }}
                  >
                    <div className='col-md-6'>
                      <input
                        type='search'
                        className='form-control rounded ml-5'
                        style={{ borderRadius: '12px' }}
                        id='search_code'
                        onChange={e => search(e.target.value)}
                        placeholder='Search'
                      />
                    </div>
                    <div className='col-md-6 mt-1'>
                      <button
                        type='button'
                           style={{backgroundColor:'#0b9490',color:'white',borderRadius: '7px'}}
                        className='btn  ml-5'
                   
                        onClick={showDialog}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    {/* <div className="card-header">
                      <div className="row justify-content-between">
                        <span className="float-right">
                          <a href="/income_type" className="btn btn-primary">
                            Income Type
                          </a>
                           <button
                              type="button"
                              className="btn btn-primary"
                              onClick={excelExport}>
                              Export Excel
                            </button>
                        </span>
                      </div>
                      <div className="row" id="trial_balance"></div>
                    </div> */}

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {/* <ExcelExport data={incomeLists} ref={_export}>
                        <ExcelExportColumn
                          field=""
                          title="No"
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field="date"
                          title="Date"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field="relatedBankAccount.name"
                          title="Bank Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedCashAccount.name"
                          title="Cash Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedAccounting.name"
                          title="Account"
                          width={150}
                        />
                        <ExcelExportColumn
                          field="remark"
                          title="Remark"
                          width={150}
                        />
                      </ExcelExport> */}
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='table-responsive' id='slimtest2'>
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead className=''>
                                <tr>
                                  <th>#</th>
                                  <th>Code</th>
                                  <th>Name</th>
                                  <th>Brand</th>
                                  <th>Category</th>
                                  <th>Sub Category</th>
                                  <th>Description</th>
                                  <th>Unit</th>
                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>
                              {showOri && (
                                <tbody className=''>
                                  {medicineLists.map((medicineList, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>{medicineList.code}</td>
                                      <td>{medicineList.name}</td>
                                      <td>
                                        {medicineList.relatedBrand
                                          ? medicineList.relatedBrand.name
                                          : ''}
                                      </td>
                                      <td>
                                        {medicineList.relatedCategory
                                          ? medicineList.relatedCategory.name
                                          : ''}
                                      </td>

                                      <td>
                                        {medicineList.relatedSubCategory
                                          ? medicineList.relatedSubCategory.name
                                          : ''}
                                      </td>
                                      <td>{medicineList.description}</td>
                                      <td className='r'>
                                        <Link to={'/unit/' + medicineList._id}>
                                          <p className='btn btn-sm'   style={{backgroundColor:'#0b9490',color:'white'}}>
                                            Unit
                                          </p>
                                        </Link>
                                      </td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/medUp/' + medicineList._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            submit(medicineList._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                              {showSearch && (
                                <tbody className=''>
                                  {searchList.map((medicineList, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>{medicineList.code}</td>
                                      <td>{medicineList.name}</td>
                                      <td>
                                        {medicineList.relatedBrand
                                          ? medicineList.relatedBrand.name
                                          : ''}
                                      </td>
                                      <td>
                                        {medicineList.relatedCategory
                                          ? medicineList.relatedCategory.name
                                          : ''}
                                      </td>

                                      <td>
                                        {medicineList.relatedSubCategory
                                          ? medicineList.relatedSubCategory.name
                                          : ''}
                                      </td>
                                      <td>{medicineList.description}</td>
                                      <td className=''>
                                        <Link to={'/unit/' + medicineList._id}>
                                          <p className='btn btn-sm btn-primary'>
                                            Unit
                                          </p>
                                        </Link>
                                      </td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/medUp/' + medicineList._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            submit(medicineList._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <MedicineListDialog
                open={open}
                setOpen={setOpen}
                close={() => setOpen(false)}
                setMedicineLists={setMedicineLists}
                medicineLists={medicineLists}
              />
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer text-center'>
       <Footer/>
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Income

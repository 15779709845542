import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiInstance from '../../utils/api'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
`
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`
const Input = styled.input`
  flex: 1;
  min-width: 500px;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`
const Filter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

const FilterTitle = styled.span`
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 3px;
`
// hello world

const FilterSelect = styled.select`
  padding: 5px;
`

const FilterOption = styled.option``

const QRBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
`

export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [relatedAccounting, setRelatedAccounting] = useState('')
  const [relatedBank, setRelatedBank] = useState('')
  const [amount, setAmount] = useState('')
  const [date, setDate] = useState('')

  const [remark, setRemark] = useState('')
  const [toAccLists, setToAccLists] = useState([])

  console.log(props.id, 'id')

  const BankTran = () => {
    const data = {
      fromAcc: props.id,
      toAcc: relatedBank,
      amount: Number(amount),
      date: date,
      remark: remark
    }

    // alert(JSON.stringify(data));
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    const res = apiInstance
      .post('transfer', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'Successful Transfer Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        console.log(err)
      })
    props.close()
  }
  useEffect(() => {
    const getAccountingLists = async () => {
      try {
        const res = await apiInstance.get('banks')

        setToAccLists(res.data.list)
        console.log(res.data.list, 'to acc')
      } catch (err) { }
    }

    getAccountingLists()
  }, [])
  return (
    <div>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle>
          <div className='modal-header ok'>
            <h4 className='modal-title'>Bank Transfer Form</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <form>
            {/* @csrf */}

            <div class='form-group'>
              <label for=''>From Account</label>

              <input
                type='text'
                name='from'
                class='form-control'
                value={props.name}
                re
              />
            </div>
            {console.log(toAccLists, 'kzl')}
            <div class='form-group'>
              <label for=''>To Account</label>
              <select
                name='transfer_acc'
                id=''
                class='form-control'
                onChange={e => setRelatedBank(e.target.value)}
              >
                <option value=''>Choose Account</option>

                {toAccLists.map(option => (
                  <option value={option.relatedAccounting._id}>
                    {option.accountName}
                  </option>
                ))}
              </select>
            </div>
            <div class='form-group'>
              <label for=''>Date</label>
              <input
                type='date'
                name='date'
                id=''
                class='form-control'
                onChange={e => setDate(e.target.value)}
              />
            </div>
            <div class='form-group'>
              <label for=''>Remark</label>
              <input
                type='text'
                name='remark'
                id=''
                class='form-control'
                onChange={e => setRemark(e.target.value)}
              />
            </div>

            <div class='form-group'>
              <label for=''>Amount</label>
              <input
                type='number'
                name='amount'
                id=''
                class='form-control'
                onChange={e => setAmount(e.target.value)}
              />
            </div>

            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-dismiss='modal'
                onClick={props.close}
              >
                Close
              </button>
              <Button className='bg-success' onClick={BankTran}>
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

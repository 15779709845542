/* eslint-disable */
import * as React from 'react'
import Swal from 'sweetalert2'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { FaArrowLeft, FaMinus } from 'react-icons/fa'
import { useState, useEffect } from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import SideBar from './SideBar'
import AccountList from './AccountList'
import { Link } from 'react-router-dom'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../utils/api'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })
  const [code, setCode] = useState('')
  const [accountingTypes, setAccountingTypes] = useState('')
  const [headingList, setHeadingList] = useState([])
  const [heading, setHeading] = useState('')
  const [subHeading, setSubHeading] = useState('')
  const [accType, setAccType] = useState([])

  const [amount, setAmount] = useState('')

  const [relatedCurrency, setRelatedCurrency] = useState('')
  const [upWork, setUpWork] = useState(false)
  const [flag, setFlag] = useState(false)
  const [upFlag, setUpFlag] = useState(true)
  const [upCode, setUpCode] = useState('')
  const [upSub, setUpSub] = useState('')
  const [upBal, setUpBal] = useState('')
  const [upCur, setUpCur] = useState('')
  const [reHead, setReHead] = useState('')
  const [reHeadName, setReHeadName] = useState('')
  const [reHeadId, setReHeadId] = useState('')
  const [reTypeName, setReTypeName] = useState('')
  const [reTypeId, setReTypeId] = useState('')
  const [natureType, setNatureType] = useState('')

  const Id = useLocation().pathname.split('/')[2]

  const AccountUpdate = () => {
    const data = {
      id: Id,
      code: upCode,
      name: upSub,
      // relatedType: reType,
      // relatedHeader: reHead,
      subHeader: upSub,
      amount: upBal,
      openingBalance: upBal,
      generalFlag: upFlag,
      relatedCurrency: upCur,
      carryForWork: upWork,
      accountNature: natureType
    }
    if (reTypeId) {
      data.relatedType = reTypeId
    } else {
      data.relatedType = reType
    }
    if (reHeadId) {
      data.relatedHeader = reHeadId
    } else {
      data.relatedHeader = reHead
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    // alert(JSON.stringify(data))
    apiInstance
      .put('accounting-list', data, config)
      .then(function (response) {
        // alert('success')
        Swal.fire({
          title: 'Successful!',
          text: 'Updated Your Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()
        // props.setAccountLists([...props.accountLists, response.data.data])
        // const index = props.accountLists.findIndex(
        //   item => item._id === props.id
        // )
        // let arr = [...props.accountLists]
        // arr[index] = {
        //   ...arr[index],
        //   ...response.data.data
        // }
        // props.setAccountLists(arr)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleHeading = async event => {
    setHeading(event)
    // console.log(heading, headingList)
  }

  const handleAccountHeader = async event => {
    setAccountingTypes(event)
    // setReType(event)
    // console.log(accountingTypes)

    const res = await apiInstance.get(`account-headers/related/${event}`)
    // console.log(res.data.data, 'Head')
    setHeadingList(res.data.data)
    setFlag(true)
    // console.log(headingList, 'heading')
  }
  useEffect(() => {
    const getAccountingType = async () => {
      try {
        const res = await apiInstance.get('account-types')
        setAccType(res.data.list)
      } catch (err) {}
    }

    const getAccount = async () => {
      try {
        console.log(Id, 'Id')
        const res = await apiInstance.get('accounting-list/' + Id)
        // console.log(res.data.data,'up')
        setUpCode(res.data.data[0].code)
        // console.log(res.data.data[0].code)

        setUpSub(res.data.data[0].subHeader)
        setUpBal(res.data.data[0].amount)
        setUpCur(res.data.data[0].relatedCurrency)

        setReHeadName(res.data.data[0].relatedHeader.name)
        setReHeadId(res.data.data[0].relatedHeader._id)
        setReTypeName(res.data.data[0].relatedType.name)
        setReTypeId(res.data.data[0].relatedType._id)
        setNatureType(
          res.data.data[0].accountNature ? res.data.data[0].accountNature : ''
        )
      } catch (err) {}
    }
    getAccountingType()
    getAccount()
  }, [])

  return (
    <>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        <HeaderName />

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row '>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/account_list'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active'>Account List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container card px-3 py-3'>
              {/* <!-- Small boxes (Stat box) --> */}

              <form action='' method='post'>
                {/* @csrf */}
                <div class='modal-body'>
                  <div class='form-group'>
                    <label for='name'>Account Code</label>
                    <input
                      type='text'
                      class='form-control border border-info'
                      name='acc_code'
                      id='acc_code'
                      defaultValue={upCode}
                      onChange={e => setUpCode(e.target.value)}
                    />
                  </div>
                  {/* <div class="form-group">
                <label for="name">Account Name</label>
                <input
                  type="text"
                  class="form-control border-info"
                  name="acc_name"
                  id="acc_name"
                  placeholder="eg. Revenue Account"
                  onChange={(e) => setName(e.target.value)}
                />
              </div> */}

                  <div class='form-group'>
                    <label for='name'>Account Type</label>
                    <select
                      class='custom-select border-info'
                      name='account_type_id'
                      onChange={e => {
                        handleAccountHeader(e.target.value)
                        setReTypeId(e.target.value)
                      }}
                    >
                      <option value={reTypeId}>{reTypeName}</option>
                      {accType.map(option => (
                        <option value={option._id}>{option.name}</option>
                      ))}
                    </select>
                  </div>
                  {reHeadName && (
                    <div class='form-group'>
                      <label for='name'>Heading</label>
                      <select
                        class='custom-select border-info'
                        name='account_type_id'
                        onChange={e => {
                          handleHeading(e.target.value)
                          setReHeadId(e.target.value)
                        }}
                      >
                        <option value={reHeadId}>{reHeadName}</option>
                        {headingList.map(option => (
                          <option value={option._id}>{option.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div class='form-group'>
                    <label for='name'>Sub Heading</label>

                    <input
                      type='text'
                      name='sub_head'
                      className='form-control border-info'
                      id=''
                      defaultValue={upSub}
                      onChange={e => setUpSub(e.target.value)}
                    />
                  </div>

                  <div class='form-group'>
                    <label for='name'>Balance</label>
                    <input
                      type='text'
                      class='form-control border-info'
                      name='balance'
                      defaultValue={upBal}
                      onChange={e => setUpBal(e.target.value)}
                    />
                  </div>
                  <div class='form-group'>
                    <label for='name'>Currency</label>
                    <input
                      type='text'
                      class='form-control border-info'
                      name='releatedCurrency'
                      defaultValue={upCur}
                      onChange={e => setUpCur(e.target.value)}
                    />
                  </div>
                  <div className='form-group mt-3'>
                    <label>Type</label>

                    <select
                      className='form-control'
                      name='bank_acc'
                      id='bank_acc'
                      onChange={e => setNatureType(e.target.value)}
                    >
                      <option value={natureType} hidden>
                        {natureType}
                      </option>

                      <option value='Credit'>Credit</option>
                      <option value='Debit'>Debit</option>
                    </select>
                  </div>

                  {/* <div class='form-group'>
                    <label for='name'>General Flag</label>
                    <div class='row'>
                      <div class='col-md-6'>
                        <div class='form-check form-check-inline'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='yes_no'
                            id='yes'
                            onChange={e => setUpFlag(true)}
                          />
                          <label class='form-check-label text-info' for='yes'>
                            Yes
                          </label>
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <div class='form-check form-check-inline'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='yes_no'
                            id='no'
                            onChange={e => setUpFlag(false)}
                          />
                          <label class='form-check-label text-info' for='no'>
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='form-group'>
                    <label for='name'>Carry Forward</label>
                    <div class='row'>
                      <div class='col-md-6'>
                        <div class='form-check form-check-inline'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='no_yes'
                            id='yes1'
                            onclick='show_project()'
                            onChange={e => setUpWork(true)}
                          />
                          <label class='form-check-label text-info' for='bank'>
                            Yes
                          </label>
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <div class='form-check form-check-inline'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='no_yes'
                            id='no1'
                            onclick='hide_project()'
                            onChange={e => setUpWork(false)}
                          />
                          <label class='form-check-label text-info' for='cash'>
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class='modal-footer'>
                  <button
                    type='button'
                    class='btn btn-secondary'
                    data-dismiss='modal'
                  >
                    Close
                  </button>
                  <Button class='btn btn-primary' onClick={AccountUpdate}>
                    Update
                  </Button>
                </div>
              </form>

              {/* <!-- /.row (main row) --> */}
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}
    </>
  )
}
// phyo
//maymyat

import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import apiInstance from '../../utils/api'
import { useSelector } from 'react-redux'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [bankList, setBankList] = useState([])
  const [cashList, setCashList] = useState([])
  const [medicineSale, setMedicineSale] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [showBank, setShowBank] = useState(false)
  const [showCash, setShowCash] = useState(false)

  //setting up for data
  const [initialAmount, setInitialAmount] = useState('')
  const [relatedBankAcc, setRelatedBankAcc] = useState('')
  const [relatedCashAcc, setRelatedCashAcc] = useState('')
  const [relatedAccounting, setRelatedAccounting] = useState('')
  const [initialCurrency, setInitialCurrency] = useState('')
  const [date, setDate] = useState('')
  const [remark, setRemark] = useState('')
  const [finalAmount, setFinalAmount] = useState('')
  const [finalCurrency, setFinalCurrency] = useState('')
  const [bankRadioValue, setBankRadioValue] = useState('')
  const [cashRadioValue, setCashRadioValue] = useState('')

  const IncomeCreate = event => {
    // alert(JSON.stringify(jsonData));
    const jsonData = {
      relatedAccounting: relatedAccounting,
      date: date,
      initialAmount: initialAmount,
      initialCurrency: initialCurrency,
      finalAmount: finalAmount,
      finalCurrency: finalCurrency,
      remark: remark
    }
    // console.log(jsonData);

    if (bankRadioValue === 1) {
      jsonData.relatedBankAccount = relatedBankAcc
    } else {
      jsonData.relatedCashAccount = relatedCashAcc
    }


    // alert(JSON.stringify(jsonData))
    // console.log(jsonData);
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    // alert(JSON.stringify(jsonData));
    apiInstance
      .post('income', jsonData, config)
      .then(function (response) {

        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })

      })
      .catch(function (err) {
        console.log(err)
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    props.close()
  }

  const handleBankRadioChange = val => {
    // console.log(parseInt(val), 'bank radio')
    setBankRadioValue(parseInt(val))
    setShowBank(true)
    setShowCash(false)
  }

  const handleCashRadioChange = val => {
    // console.log(val, 'cash radio')
    setCashRadioValue(parseInt(val))
    setShowBank(false)
    setShowCash(true)
  }

  const handleCalculation = event => {
    if (initialCurrency === 'MMK') {
      // console.log(finalCurrency)
      let current = currencyList.filter(currency => currency.code === event)[0]
        .exchangeRate
      // console.log(current)
      let ans = initialAmount / current
      // setFinalAmount(ans.toFixed(2))
      // console.log(ans)
    } else if (initialAmount != 'MMK') {
      let current = currencyList.filter(
        currency => currency.code === initialCurrency
      )[0].exchangeRate
      // console.log(initialAmount);
      // console.log(current);
      let ans = initialAmount * current
      setFinalAmount(ans.toFixed(2))
      // console.log(finalAmount)
    }
    setFinalCurrency(event)
    // console.log(initialAmount, 'Thate chit mi thwr p')
    if (initialCurrency == event) setFinalAmount(initialAmount)
  }

  useEffect(() => {
    const getCashLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')

        const cash = res.data.list.filter(
          el => el.relatedHeader.name === 'Cash in Hand'
        )
        setCashList(cash)
      } catch (err) { }
    }

    const getBankLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        console.log(res.data.list, 'bank')
        const bank = res.data.list.filter(
          el => el.relatedHeader.name == 'Cash at Bank'
        )
        setBankList(bank)
      } catch (err) { }
    }

    const getAccountingLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        const medicineSale = res.data.list
        // console.log(
        //   res.data.list.filter(e => e.relatedType.name === 'Revenues'),
        //   'Filter '
        // )
        setInitialAmount(medicineSale[0].amount)
        setMedicineSale(
          res.data.list
        )
        // console.log(medicineSale, 'Income list')
        // setAccountingList(res.data.list)
      } catch (err) { }
    }
    const getCurrencyLists = async () => {
      try {
        const res = await apiInstance.get('currencies')
        setCurrencyList(res.data.list)
        setInitialCurrency(currencyList.initialCurrency)
        setFinalCurrency(currencyList.finalCurrency)
      } catch (err) { }
    }
    getBankLists()
    getCashLists()
    // getHeaderLists();
    getAccountingLists()
    getCurrencyLists()
  }, [])

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      className='my-modal'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        <div className='modal-header'>
          <h4 className='modal-title'>Incoming</h4>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={props.close}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>

        {/* @csrf */}
        <div className='row offset-md-4'>
          <div className='col-md-2'>
            <div className='form-check form-check-inline mt-2 '>
              <input
                className='form-check-input'
                type='radio'
                name='bank_cash'
                id='bank'
                value='1'
                onClick={(e) => handleBankRadioChange(e.target.value)}
              />
              &nbsp;
              <label className='form-check-label text-success' for='bank'>
                Bank
              </label>
            </div>
          </div>
          <div className='col-md-2'>
            <div className='form-check form-check-inline mt-2 px-4'>
              <input
                className='form-check-input'
                type='radio'
                name='bank_cash'
                id='cash'
                value='2'
                onClick={(e) => handleCashRadioChange(e.target.value)}
              />
              <label className='form-check-label text-success' for='cash'>
                Cash
              </label>
            </div>
          </div>
        </div>

        {showBank && (
          <div className='form-group mt-3' id='bankkk'>
            <label className='control-label'>Bank Account</label>

            <select
              className='form-control'
              name='bank_acc'
              id='bank_acc'
              onChange={e => setRelatedBankAcc(e.target.value)}
            >
              <option hidden>Select Bank Account</option>

              {bankList.map(option => (
                <option value={option._id}>{option.name}</option>
              ))}
            </select>
          </div>
        )}

        {showCash && (
          <div className='form-group mt-3' id='cash'>
            <label className='control-label'>Cash Account</label>

            <select
              className='form-control'
              name='cash_acc'
              id='cash_acc'
              onChange={e => setRelatedCashAcc(e.target.value)}
            >
              <option hidden>Select Cash Account</option>

              {cashList.map(option => (
                <option value={option._id}>{option.name}</option>
              ))}

              {/* @endforeach */}
            </select>
          </div>
        )}

        <div className='form-group mt-3'>
          <label className='control-label'>Incoming Account</label>
          <select
            className='form-control'
            name='exp_acc'
            onChange={e => setRelatedAccounting(e.target.value)}
          >
            <option hidden>Select Incoming Account</option>
            {/* @foreach ($inc_account as $acc) */}

            {medicineSale.map(option => (
              <option value={option._id}>{option.name}</option>
            ))}

            {/* @endforeach */}
          </select>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='control-label'>Initial Amount</label>

              <input
                type='number'
                className='form-control'
                name='amount'
                id='convert_amount'
                onChange={event => setInitialAmount(event.target.value)}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='control-label'>Initial Currency</label>

              <select
                name='currency'
                id=''
                className='form-control mt-1'
                onchange='convert(this.value)'
                onChange={e => setInitialCurrency(e.target.value)}
              >
                <option hidden>Choose Currency</option>
                {currencyList.map(option => (
                  <option value={option.code}>{option.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='control-label'>Final Amount</label>

              <input
                type='number'
                className='form-control'
                name='amount'
                id='convert_amount'
                value={finalAmount}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='control-label'>Final Currency</label>

              <select
                name='currency'
                id=''
                className='form-control mt-1'
                onchange='convert(this.value)'
                onChange={e => handleCalculation(e.target.value)}
              >
                <option hidden>Choose Currency</option>

                {currencyList.map(option => (
                  <option value={option.code}>{option.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label className='control-label'>Date</label>
          <input
            type='date'
            className='form-control'
            name='date'
            onChange={e => setDate(e.target.value)}
          />
        </div>

        <div className='form-group'>
          <label className='control-label'>Remark</label>
          <input
            type='text'
            className='form-control'
            name='remark'
            onChange={e => setRemark(e.target.value)}
          />
        </div>

        <div className='row text-center'>
          <div className='col-md-9 mt-4'>
            <button
              type='submit'
              className='btn btn-success'
              onClick={IncomeCreate}
            >
              Submit
            </button>
            <button
              type='button'
              className='btn btn-inverse btn-dismiss'
              data-dismiss='modal'
              onClick={props.close}
            >
              Cancel
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
// phyo
//maymyat

import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Button } from '@nextui-org/react'

import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  FaCashRegister,
  FaFileMedical,
  FaPenSquare,
  FaRegEdit,
  FaRegTrashAlt
} from 'react-icons/fa'
import Treatement from '../Treatment/Treatment'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'
const Income = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [treatmentLists, setTreatmentLists] = useState([])
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showOri, setShowOri] = useState(true)
  const [searchList, setSearchList] = useState([])
  const [treatmentUnit, setTreatmentUnit] = useState([])

  const showDialog = () => setOpen(true)
  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.date = element.date?.split('T')[0]
      })
      _export.current.save()
    }
  }

  const handleDelete = event => {
    apiInstance
      .delete('treatment-list/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = treatmentLists.filter(item => item._id !== event)
        setTreatmentLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  const search = val => {
    if (val) {
      setSearchList(
        treatmentLists.filter(
          el => el.name == val || el.code == val || el.bodyParts == val
        )
      )
      setShowSearch(true)
      setShowOri(false)
    } else {
      setShowSearch(false)
      setShowOri(true)
    }
  }

  useEffect(() => {
    const getTreatmentLists = async () => {
      try {
        const res = await apiInstance.get('treatment-lists?limit=30')
        setTreatmentLists(res.data.list)
      } catch (err) {
        alert(err.message)
      }
    }

    const getTreatmentUnit = async () => {
      try {
        const res = await apiInstance.get('treatments')
        setTreatmentUnit(res.data.list, 'data')
        // setTreatmentLists(res.data.list)
      } catch (err) {
        alert(err.message)
      }
    }
    getTreatmentUnit()
    getTreatmentLists()

    getTreatmentLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Treatment List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  <button
                    className='btn btn-sm btn-success rounded-3'
                    onClick={excelExport}
                  >
                    Export
                  </button>
                </div>
                <div className='offset-1 col-md-4 ml-4'>
                  <div
                    className='input-group row ml-4'
                    style={{ marginBottom: '20px' }}
                  >
                    <div className='col-md-6'>
                      <input
                        type='search'
                        className='form-control rounded ml-1'
                        style={{ borderRadius: '12px' }}
                        id='search_code'
                        placeholder='Search'
                        onChange={e => search(e.target.value)}
                      />
                    </div>
                    <div className='col-md-6 mt-1'>
                      <button
                        type='button'
                        className='btn  btn-primary'
                        style={{ borderRadius: '7px' }}
                        onClick={showDialog}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    {/* <div className="card-header">
                      <div className="row justify-content-between">
                        <span className="float-right">
                          <a href="/income_type" className="btn btn-primary">
                            Income Type
                          </a>
                           <button
                              type="button"
                              className="btn btn-primary"
                              onClick={excelExport}>
                              Export Excel
                            </button>
                        </span>
                      </div>
                      <div className="row" id="trial_balance"></div>
                    </div> */}

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      <ExcelExport data={treatmentUnit} ref={_export}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />

                        <ExcelExportColumn
                          field='treatmentCode'
                          title='Treatment Unit Code'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field='name'
                          title='Treatment Unit Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='treatmentName.name'
                          title='Treatment Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field='estimateTotalPrice'
                          title='Purchase Price'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='sellingPrice'
                          title='Selling Price'
                          width={150}
                        />
                        <ExcelExportColumn
                          field='description'
                          title='Description'
                          width={150}
                        />
                      </ExcelExport>
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='table-responsive' id='slimtest2'>
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead className=''>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th className='text-center'>Code</th>
                                  <th className='text-center'>Name</th>
                                  <th className='text-center'>Body Parts</th>
                                  <th className='text-center'>Description</th>
                                  <th className='text-center'>
                                    Treatment Unit
                                  </th>
                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>

                              {showOri && (
                                <tbody className='text-center'>
                                  {treatmentLists.map((treatment, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>{treatment.code}</td>
                                      <td>{treatment.name}</td>
                                      <td>{treatment.bodyParts}</td>
                                      <td>{treatment.description}</td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/checkUnit/' + treatment._id}
                                        >
                                          <p className='btn btn-sm btn-dark rounded'>
                                            Check Unit
                                          </p>
                                        </Link>
                                      </td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/treatUpdate/' + treatment._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(treatment._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}

                              {showSearch && (
                                <tbody className='text-center'>
                                  {searchList.map((treatment, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>{treatment.code}</td>
                                      <td>{treatment.name}</td>
                                      <td>{treatment.bodyParts}</td>
                                      <td>{treatment.description}</td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/checkUnit/' + treatment._id}
                                        >
                                          <p className='btn btn-sm btn-dark rounded'>
                                            Check Unit
                                          </p>
                                        </Link>
                                      </td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/treatUpdate/' + treatment._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(treatment._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Treatement
                open={open}
                close={() => setOpen(false)}
                setTreatmentLists={setTreatmentLists}
                treatmentLists={treatmentLists}
              />
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Income

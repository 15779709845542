import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import CurrencyDialog from '../views/CurrencyDialog'
import CurrencyUpdate from '../views/CurrencyUpdate'
import { Link } from 'react-router-dom'
import SideBar from './SideBar'
import { FaRegEdit, FaRegTrashAlt, FaArrowLeft } from 'react-icons/fa'
import Swal from 'sweetalert2'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../utils/api'
function Dashboard () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [currencyLists, setCurrencyLists] = useState([])
  const [allcurrencylist, setAllCurrencyLists] = useState([])
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [code, setCode] = useState('')
  const [currencyName, setCurrencyName] = useState('')
  const [showUpdate, setShowUpdate] = useState(false)
  const [id, setId] = useState('')
  const showDialog = () => setOpen(true)
  const showCurrencyUpdate = val => {
    setId(val)
    setShowUpdate(true)
  }

  const CurrencyDelete = event => {
    console.log(event, 'delete')
    apiInstance
      .delete('currency/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = currencyLists.filter(item => item._id !== event)
        setCurrencyLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  useEffect(() => {
    const getCurrencyLists = async () => {
      try {
        const res = await apiInstance.get('currencies')

        setCurrencyLists(res.data.list)
        setAllCurrencyLists(res.data.list)
      } catch (err) {}
    }
    getCurrencyLists()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Currency List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Currency List</h3> */}

                      <button
                        type='button'
                        id=''
                        className='btn btn-primary float-right'
                        data-toggle='modal'
                        data-target='#currency'
                        onClick={showDialog}
                      >
                        {' '}
                        <i className='fa fa-plus'></i> Add
                      </button>
                    </div>
                    <div className='card-body'>
                      <table id='example1' className='table'>
                        <thead className='text-center bg-info'>
                          <tr>
                            <th>No</th>
                            <th>Date Update</th>
                            <th>Time Update</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Exchage Rate</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* <?php $i=1; ?> */}
                        <tbody className=''>
                          {/* @foreach ($currency as $cc) */}
                          {currencyLists.map((currencyList, i) => (
                            <tr>
                              <td>{++i}</td>
                              <td>{currencyList.lastUpdate.split('T')[0]}</td>
                              <td>
                                {
                                  currencyList.lastUpdate
                                    .split('T')[1]
                                    .split('.')[0]
                                }
                              </td>
                              <td>{currencyList.code}</td>
                              <td>{currencyList.name}</td>
                              <td>{currencyList.exchangeRate}</td>

                              <td className='text-center'>
                                <button
                                  type='button'
                                  class='btn btn-warning btn-sm'
                                  data-toggle='modal'
                                  onClick={() =>
                                    showCurrencyUpdate(currencyList._id)
                                  }
                                >
                                  <FaRegEdit />
                                </button>
                                &nbsp;
                                <button
                                  type='button'
                                  class='btn btn-danger btn-sm'
                                  data-toggle='modal'
                                  onClick={() =>
                                    CurrencyDelete(currencyList._id)
                                  }
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                            </tr>
                          ))}

                          {/* @endforeach */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <CurrencyDialog
                open={open}
                close={() => setOpen(false)}
                setOpen={setOpen}
                setCurrencyLists={setCurrencyLists}
                currencyLists={currencyLists}
              />
              <CurrencyUpdate
                showUpdate={showUpdate}
                close={() => setShowUpdate(false)}
                setShowUpdate={setShowUpdate}
                id={id}
                currencyLists={currencyLists}
                setCurrencyLists={setCurrencyLists}
              />
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Dashboard

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { RxCross2 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import SideBar from './SideBar'
import { FaArrowLeft } from 'react-icons/fa'
import apiInstance from '../../utils/api'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
const Button = styled.button`
  background: rgb(0, 7, 51);
  color: white;
  justify-content: center;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
`

const Create = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [bankList, setBankList] = useState([])
  const [cashList, setCashList] = useState([])
  const [subQty, setSubQty] = useState(0)
  const [subPrice, setSubPrice] = useState(0)
  const [showBank, setShowBank] = useState(false)
  const [showCash, setShowCash] = useState(false)
  const [relatedBankAcc, setRelatedBankAcc] = useState('')
  const [relatedCashAcc, setRelatedCashAcc] = useState('')
  const [relatedAcc, setRelatedAcc] = useState('')

  const [units, setUnits] = useState([])
  const [qty, setQty] = useState(0)
  const [price, setPrice] = useState(0)
  const [medicines, setMedicines] = useState([])
  const [procedures, setProcedures] = useState([])
  const [proceduresAccess, setProceduresAccess] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [value, setValue] = useState('')
  const [id, setId] = useState('')
  const [supId, setSupId] = useState('')
  const [date, setDate] = useState('')
  const [remark, setRemark] = useState('')
  const [totqty, setTotQty] = useState(0)
  const [totprice, setTotPrice] = useState(0)
  const [sell, setSell] = useState(0)
  const [expDate, setExpDate] = useState('')
  const [medicineSale, setMedicineSale] = useState([])
  const [delID, setDelID] = useState('')
  const [initialAmount, setInitialAmount] = useState('')

  const navigate = useNavigate()
  const [accList, setAccList] = useState([])

  const handleBankRadioChange = test => {
    setShowBank(true)
    setShowCash(false)
  }

  const handleCashRadioChange = () => {
    setShowBank(false)
    setShowCash(true)
  }

  useEffect(() => {
    const getBankLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')

        const bank = res.data.list.filter(
          el =>
            el.relatedHeader.name == 'Cash at Bank' &&
            el.relatedType.name === 'Assets'
        )
        setBankList(bank)
      } catch (err) {}
    }
    const getAccountingLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        setAccList(res.data.list)
        const medicineSale = res.data.list.filter(
          e =>
            e.relatedHeader.name == 'Adminstrative exp' &&
            'Selling and distribution expense' &&
            'Personal expense' &&
            e.relatedType.name == 'Profit and Loss'
        )
        setInitialAmount(medicineSale[0].amount)
        // setFinalAmount(medicineSale[0].amount);
        setMedicineSale(medicineSale)
        // setAccountingList(res.data.list);
        // set(
        //   res.data.list.filter((e) => e.relatedHeader.name == "Current Assets")
        // );
      } catch (err) {}
    }

    const getBanks = async () => {
      try {
        const res = await apiInstance.get('suppliers')
        setSuppliers(res.data.list)
        console.log('supp')
        console.log(suppliers)
      } catch (err) {}
    }

    const getCashLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')

        const cash = res.data.list.filter(
          el =>
            el.relatedHeader.name == 'Cash in Hand' &&
            el.relatedType.name === 'Assets'
        )
        setCashList(cash)
      } catch (err) {}
    }

    getCashLists()
    getBankLists()

    getAccountingLists()
    getBanks()
  }, [])

  const chgItem = val => {
    if (val == 1) {
      getMedicines()
    }
    if (val == 2) {
      getProcedures()
    }
    if (val == 3) {
      getProceduresAccess()
    }
  }
  const getMedicines = async () => {
    const res = await apiInstance.get('medicine-items')
    // console.log('bro!');
    console.log(res.data.list, 'med')
    setUnits(res.data.list)
    setValue(1)
  }
  const getProcedures = async () => {
    const res = await apiInstance.get('procedure-items')
    setUnits(res.data.list)
    setValue(2)
  }

  const getProceduresAccess = async () => {
    const res = await apiInstance.get('accessory-items')
    setUnits(res.data.list)
    setValue(3)
  }

  const chgQty = async val => {
    const res = units.filter(el => el._id == val)
    // console.log('hi')
    // console.log(res[0])
    setPrice(res[0].purchasePrice)
    setSell(res[0].sellingPrice)
    setId(val)
  }
  const addPurchase = async () => {
    const res = units.filter((el) => el._id == id)
    // console.log(res,'ress')
    if (value == 1) {
      const obj = {
        item_id: id,
        name: res[0].medicineItemName,
        qty: qty,
        totalUnit: parseInt((res[0].toUnit * qty) / res[0].fromUnit),
        unit: 'PCS',
        purchasePrice: price ? price : res[0].purchasePrice,
        sellingPrice: sell ? sell : res[0].sellingPrice,
        expiredDate:expDate,
        totalPrice: price ? price * qty : res[0].purchasePrice * qty
      }
      if (price) {
        obj.purchasePrice = price
        obj.totalPrice = price * qty
      } else {
  
        obj.totalPrice = res[0]?.purchasePrice * qty
        obj.purchasePrice = res[0]?.purchasePrice
      }
      setMedicines(arr => [...arr, obj])
     
    }
    if (value == 2) {
      const obj = {
        item_id: id,
        name: res[0].procedureItemName,
        qty: qty,
        totalUnit: parseInt((res[0].toUnit * qty) / res[0].fromUnit),
         unit: 'PCS',
        purchasePrice: price ? price : res[0].purchasePrice,
        sellingPrice: sell ? sell : res[0].sellingPrice,
        expiredDate:expDate,
        totalPrice: price ? price * qty : res[0].purchasePrice * qty
      }
      if (price) {
        obj.purchasePrice = price
        obj.totalPrice = price * qty
      } else {
  
        obj.totalPrice = res[0]?.purchasePrice * qty
        obj.purchasePrice = res[0]?.purchasePrice
      }
      setProcedures(arr => [...arr, obj])
     
    }
    if (value == 3) {
      const obj = {
        item_id: id,
        name: res[0].accessoryItemName,
        qty: qty,
        totalUnit: parseInt((res[0].toUnit * qty) / res[0].fromUnit),
          unit: 'PCS',
        purchasePrice: price ? price : res[0].purchasePrice,
        sellingPrice: sell ? sell : res[0].sellingPrice,
        expiredDate:expDate,
        totalPrice: price ? price * qty : res[0].purchasePrice * qty
      }
      if (price) {
        obj.purchasePrice = price
        obj.totalPrice = price * qty
      } else {
  
        obj.totalPrice = res[0]?.purchasePrice * qty
        obj.purchasePrice = res[0]?.purchasePrice
      }
      setProceduresAccess(arr => [...arr, obj])
     
    }
     if (price) {
      setTotPrice(totprice + price * qty)
      setTotQty(totqty + parseInt(qty))
    } else {
      setTotPrice(totprice + res[0]?.purchasePrice * qty)
      setTotQty(totqty + parseInt(qty))
    }

  }
  const del = (id, val) => {
    if (val === 1) {
      setDelID(id)

      setMedicines(medicines.filter((val, i) => i !== id))

      //for remove that you choose id items
      const remove = medicines.splice(id, 1)
      setSubQty(subQty - remove[0]?.totalUnit)
      setSubPrice(
        subPrice - remove[0]?.price * parseInt(remove[0]?.qty)
      )
    }
    if (val === 2) {
      setDelID(id)

      setProcedures(procedures.filter((val, i) => i !== id))

      //for remove that you choose id items
      const remove = procedures.splice(id, 1)
      setSubQty(subQty - remove[0]?.totalUnit)
      setSubPrice(
        subPrice - remove[0]?.price * parseInt(remove[0]?.qty)
      )
    }
    if (val === 3) {
      setDelID(id)

      setProceduresAccess(proceduresAccess.filter((val, i) => i !== id))

      //for remove that you choose id items
      const remove = proceduresAccess.splice(id, 1)
      // console.log(remove[0],'remove')
      setSubQty(subQty - remove[0]?.totalUnit)
      setSubPrice(
        subPrice - remove[0]?.price * parseInt(remove[0]?.qty)
      )
    }
  }
  const save = () => {
    const data = {
      purchaseDate: date,
      supplierName: supId,
      remark: remark,
      relatedPurchaseAccount: relatedAcc,
      medicineItems: medicines,
      procedureItems: procedures,
      accessoryItems: proceduresAccess,
      totalQTY: totqty,
      totalPrice: totprice
    }

    if (relatedBankAcc) {
      data.relatedBankAccount = relatedBankAcc
    } else {
      data.relatedCashAccount = relatedCashAcc
    }
    if (relatedAcc) {
     const res = apiInstance.post('purchase', data).then(function (response) {
      // alert("success");
      // props.setIncomeLists([...props.incomeLists, response.data.data[0]]);
      Swal.fire({
        title: 'Successful!',
        text: 'Purchased!',
        icon: 'success',
        showConfirmButton: false,

  
        timer:2000
      })
    })
    } else {
            Swal.fire({
        title: 'Something Wrong!',
        text: 'Try again',
        icon: 'error',
        showConfirmButton: false,

  
        timer:2000
      })
}
    // alert(JSON.stringify(data))
 
  }
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/purchase'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active'></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container-fluid'>
              <h5 className='font-weight-bold mt-3'>Purchase Create Form</h5>
              <div className='row mt-3'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='p-2'>
                        <div className='row'>
                          <div className='offset-4 col-2'>
                            <div className='form-check form-check-inline mt-2 '>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='bank_cash'
                                id='bank'
                                value='1'
                                onClick={handleBankRadioChange}
                              />
                              &nbsp;
                              <label
                                className='form-check-label text-success'
                                for='bank'
                              >
                                Bank
                              </label>
                            </div>
                          </div>
                          <div className='col-2'>
                            <div className='form-check form-check-inline mt-2 px-4'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='bank_cash'
                                id='cash'
                                value='2'
                                onClick={handleCashRadioChange}
                              />
                              <label
                                className='form-check-label text-success'
                                for='cash'
                              >
                                Cash
                              </label>
                            </div>
                          </div>
                        </div>

                        {showBank && (
                          <div className='form-group mt-3' id='bankkk'>
                            <label className='control-label'>
                              Bank Account
                            </label>

                            <select
                              className='form-control'
                              name='bank_acc'
                              id='bank_acc'
                              onChange={e => setRelatedBankAcc(e.target.value)}
                            >
                              <option value=''>Select Bank Account</option>

                              {bankList.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        {showCash && (
                          <div className='form-group mt-3' id='cashhh'>
                            <label className='control-label'>
                              Cash Account
                            </label>

                            <select
                              className='form-control'
                              name='cash_acc'
                              id='cash_acc'
                              onChange={e => setRelatedCashAcc(e.target.value)}
                            >
                              <option value=''>Select Cash Account</option>
                              {/* @foreach ($cash_account as $acc) */}
                              {cashList.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}

                              {/* @endforeach */}
                            </select>
                          </div>
                        )}

                        <div className='row'>
                          <div className='col-6'>
                            <label htmlFor=''>Purchase Date</label>
                            <input
                              type='date'
                              className='form-control'
                              onChange={e => setDate(e.target.value)}
                            />
                          </div>
                          <div className='col-6' style={{ marginTop: '0.5em' }}>
                            <label htmlFor=''>Supplier Name</label>
                            <select
                              name=''
                              id=''
                              className='form-control'
                              onChange={e => setSupId(e.target.value)}
                            >
                              <option hidden>Choose Supplier</option>
                              {suppliers.map((sup, i) => (
                                <option value={sup._id}>{sup.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className='col-12 mt-3'>
                            <label htmlFor=''>Remark</label>
                            <textarea
                              className='form-control'
                              onChange={e => setRemark(e.target.value)}
                            />
                          </div>
                          <div className='form-group mt-3' id='bankkk'>
                            <label className='control-label'>Account</label>

                            <select
                              className='form-control'
                              name='bank_acc'
                              id='bank_acc'
                              onChange={e => setRelatedAcc(e.target.value)}
                            >
                              <option hidden>Select Account</option>

                              {accList.map(option => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <hr className='mt-3' />
                          <div className='offset-1 col-10 mt-3'>
                            <table className='table table-hover'>
                              <thead>
                                <tr>
                                  {/* <th>#</th> */}
                                  <th scope='col'>Name</th>
                                  <th scope='col'>Qty</th>
                                  <th scope='col'>Purcshse Price</th>

                                  <th scope='col'>Total Price</th>
                       
                                </tr>
                              </thead>
                              <tbody>
                                {medicines.map((med, index) => (
                                  <tr>
                                    {/* <th>{++index}</th> */}
                                    <th>{med.name}</th>
                                    <th>{med.qty}</th>
                                    <th>{med.purchasePrice}</th>
                                    <th>{med.totalPrice}</th>
                                    {/* <th>
                                      <RxCross2
                                        onClick={() => del(index, 1)}
                                      />
                                    </th> */}
                                  </tr>
                                ))}
                                {procedures.map((pro, index) => (
                                  <tr>
                                    {/* <th>{++index}</th> */}
                                    <th>{pro.name}</th>
                                    <th>{pro.qty}</th>
                                    <th>{pro.purchasePrice}</th>

                                    <th>{pro.totalPrice}</th>
                                    {/* <th>
                                      <RxCross2
                                        onClick={() => del(index, 2)}
                                      />
                                    </th> */}
                                  </tr>
                                ))}
                                {proceduresAccess.map((proacc, index) => (
                                  <tr>
                                    {/* <th>{++index}</th> */}
                                    <th>{proacc.name}</th>
                                    <th>{proacc.qty}</th>
                                    <th>{proacc.purchasePrice}</th>

                                    <th>{proacc.totalPrice}</th>
                                    {/* <th>
                                      <RxCross2
                                        onClick={() => del(index, 3)}
                                      />
                                    </th> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className='col-6 mt-3'>
                            <label htmlFor=''>Total Quantity</label>
                            <input
                              type='number'
                              className='form-control'
                              value={totqty}
                            />
                          </div>
                          <div className='col-6 mt-3'>
                            <label htmlFor=''>Total Price</label>
                            <input
                              type='number'
                              className='form-control'
                              value={totprice}
                            />
                          </div>
                          <Button className='mt-4' onClick={save}>
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='p-2'>
                        <div class='row form-group'>
                          <label htmlFor=''>Select Category</label>
                          <select
                            name=''
                            id=''
                            onChange={e => chgItem(e.target.value)}
                            className='form-control'
                          >
                            <option hidden>Choose Category</option>
                            <option value='1'>Medicine</option>
                            <option value='2'>Procedure Medicine</option>
                            <option value='3'>Procedure Accessories</option>
                          </select>
                        </div>
                        <div class='row form-group mt-3'>
                          <label htmlFor=''>Select Item</label>
                          <select
                            name=''
                            id=''
                            onChange={e => chgQty(e.target.value)}
                            className='form-control'
                          >
                            <option hidden>Choose Item</option>
                            {units.map((unit, i) => (
                              <option value={unit._id}>
                                {unit.medicineItemName ||
                                  unit.accessoryItemName ||
                                  unit.procedureItemName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class='row form-group mt-3'>
                          <label htmlFor=''>Quantity</label>
                          <input
                            type='number'
                            className='form-control'
                            onChange={e => setQty(e.target.value)}
                          />
                        </div>
                               <div class='row form-group mt-3'>
                          <label htmlFor=''>Expired Date</label>
                          <input
                            type='date'
                            className='form-control'
                            onChange={e => setExpDate(e.target.value)}
                          />
                        </div>
                           <div class='row form-group mt-3'>
                          <label htmlFor=''> Purchase Price</label>
                          <input
                            type='number'
                            className='form-control'
                            value={price}
                            onChange={e => setPrice(e.target.value)}
                          />
                        </div>
                               <div class='row form-group mt-3'>
                          <label htmlFor=''>Selling Price</label>
                          <input
                            type='number'
                            value={sell}
                            className='form-control'
                            onChange={e => setSell(e.target.value)}
                          />
                        </div>
                     
                        <div className='row text-center mt-4'>
                          <Button onClick={addPurchase}>Add</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Create

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AiOutlinePlus } from 'react-icons/ai'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddSupplierDialog from '../views/AddSupplierDialog'
import UpdateSupplierDialog from '../views/Admin/Supplier/SupplierUpdate.jsx'
import axios from 'axios'
import { Link } from 'react-router-dom'
import SideBar from './SideBar'
import HeaderName from './HeaderName'
import { useSelector, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiInstance from '../../utils/api.js'
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`

const Left = styled.div`
  font-weight: normal;
  flex: 1;
`

const Title = styled.h5`
  font-weight: bold;
  margin-top: 10px;
`

const Right = styled.div`
  font-weight: normal;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  background: rgb(0, 7, 51);
  color: white;
  justify-content: flex-end;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
`

const Btn = styled.button`
  padding: 4px 8px;
  border-radius: 5px;
  margin-left: 13px;
`

const Div = styled.div``
const Input = styled.input`
  width: 165px;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 3px;
`
const Table = styled.table``
const Thead = styled.thead``
const Tbody = styled.tbody``
const Tr = styled.tr`
  text-align: center;
`
const Th = styled.th`
  font-size: 15px;
`
const Td = styled.td`
  font-size: 14px;
`
const Select = styled.select`
  padding: 0px 7px;
  border-radius: 5px;
`
const Option = styled.option``

const List = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })
  const navigate = useNavigate()

  const [id, setID] = useState('')
  const [suppliers, setSuppliers] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isUnit, setIsUnit] = useState(false)
  const [open, setOpen] = useState(false)
  const [updateId, setUpdateId] = useState('')

  const handleDelete = id => {
    if (window.confirm('Delete?')) {
      apiInstance
        .delete('supplier/' + id)
        .then(response => {
          Swal.fire({
            title: 'Success',
            text: 'Successfully Deleted!',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          const result = suppliers.filter(item => item._id !== id)
          setSuppliers(result)
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'CANCEL'
          })
        })
    } else {
      navigate('/supplier')
    }
  }

  // const submit = event => {
  //   setID(event)
  //   Swal({
  //     title: 'Confirm to submit',
  //     message: 'Are you sure to do this.',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => handleDelete
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => navigate('/supplier')
  //       }
  //     ]
  //   })
  // }

  const handleUpdate = id => {
    setIsUpdate(true)
    setUpdateId(id)
  }

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const res = await apiInstance.get('suppliers')
        setSuppliers(res.data.list)
        console.log(res.data.list, 'sup liist')
      } catch (err) {}
    }
    getSuppliers()
  }, [])

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Supplier List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Currency List</h3> */}

                      <button
                        type='button'
                        onClick={() => setIsShow(true)}
                        className='btn btn-sm btn-dark float-right'
                      >
                        <i className='fa fa-plus'></i> &nbsp;Add
                      </button>
                    </div>
                    <div className='card-body'>
                      <table className='table table-hover mt-4'>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Supplier Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Credit Amount ( MMK )</th>
                            <th>Credit Detail</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {suppliers.map((supplier, i) => (
                            <tr>
                              <td>{++i}</td>
                              <td>{supplier.name}</td>
                              <td>{supplier.phone}</td>
                              <td>{supplier.email ? supplier.email : ''}</td>

                              <td>{supplier.address}</td>
                              <td>{supplier.creditAmount}</td>
                              <td>
                                <Link to={'/creditDetail/' + supplier._id}>
                                  <Button>Credit Detail</Button>
                                </Link>
                              </td>
                              <td>
                                <IconButton
                                  aria-label='delete'
                                  onClick={e => handleDelete(supplier._id)}
                                >
                                  <DeleteIcon className='text-danger' />
                                </IconButton>

                                <IconButton aria-label='edit'>
                                  <Link to={'/sup-update/' + supplier._id}>
                                    <EditIcon className='text-warning' />
                                  </Link>
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <AddSupplierDialog
                open={isShow}
                close={() => setIsShow(false)}
                setSuppliers={setSuppliers}
                suppliers={suppliers}
              />
              {/* <UpdateSupplierDialog
                open={isUpdate}
                close={() => setIsUpdate(false)}
                updateId={updateId}
                setSuppliers={setSuppliers}
                suppliers={suppliers}
              /> */}
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default List

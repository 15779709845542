import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import ProAccessDialog from '../ProAccess/ProAccessDialog.jsx'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api.js'
const Income = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [proAccessLists, setProAccessLists] = useState([])
  const [open, setOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showOri, setShowOri] = useState(true)
  const [searchList, setSearchList] = useState([])

  const showDialog = () => setOpen(true)
  // const _export = React.useRef(null);
  // const excelExport = () => {
  //   if (_export.current !== null) {
  //     console.log(_export.current.props.data);
  //     _export.current.props.data.map(function (element, index) {
  //       element.date = element.date.split("T")[0];
  //     });
  //     _export.current.save();
  //   }
  // };

  const handleDelete = event => {
    apiInstance
      .delete('procedure-accessory/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = proAccessLists.filter(item => item._id !== event)
        setProAccessLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const submit = id => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(id)
        },
        {
          label: 'No'
        }
      ]
    })
  }

  const search = val => {
    if (val) {
      setSearchList(
        proAccessLists.filter(
          el =>
            el.name.toLowerCase().includes(val.toLowerCase()) ||
            el.code.toLowerCase().includes(val.toLowerCase()) ||
            el.relatedBrand.name.toLowerCase().includes(val.toLowerCase())
        )
      )
      setShowSearch(true)
      setShowOri(false)
    } else {
      setShowSearch(false)
      setShowOri(true)
    }
  }

  useEffect(() => {
    const getProMedicineLists = async () => {
      try {
        const res = await apiInstance.get('procedure-accessories?limit=100')
        setProAccessLists(res.data.list)
        console.log(res.data.list, 'data')
      } catch (err) {
        alert(err.message)
      }
    }
    getProMedicineLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Procedure Accessory List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-1 col-md-4 ml-5'>
                  <div
                    className='input-group row ml-5'
                    style={{ marginBottom: '20px' }}
                  >
                    <div className='col-md-6'>
                      <input
                        type='search'
                        className='form-control rounded ml-4'
                        style={{ borderRadius: '12px' }}
                        id='search_code'
                        onChange={e => search(e.target.value)}
                        placeholder='Search'
                      />
                    </div>
                    <div className='col-md-6 mt-1'>
                      <button
                        type='button'
                        className='btn  btn-primary ml-4'
                        style={{ borderRadius: '7px' }}
                        onClick={showDialog}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    {/* <div className="card-header">
                      <div className="row justify-content-between">
                        <span className="float-right">
                          <a href="/income_type" className="btn btn-primary">
                            Income Type
                          </a>
                           <button
                              type="button"
                              className="btn btn-primary"
                              onClick={excelExport}>
                              Export Excel
                            </button>
                        </span>
                      </div>
                      <div className="row" id="trial_balance"></div>
                    </div> */}

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {/* <ExcelExport data={incomeLists} ref={_export}>
                        <ExcelExportColumn
                          field=""
                          title="No"
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field="date"
                          title="Date"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field="relatedBankAccount.name"
                          title="Bank Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedCashAccount.name"
                          title="Cash Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedAccounting.name"
                          title="Account"
                          width={150}
                        />
                        <ExcelExportColumn
                          field="remark"
                          title="Remark"
                          width={150}
                        />
                      </ExcelExport> */}
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='table-responsive ' id='slimtest2'>
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead className=''>
                                <tr>
                                  <th>#</th>
                                  <th>Code</th>
                                  <th>Name</th>
                                  <th>Brand</th>
                                  <th>Category</th>
                                  <th>Sub Category</th>
                                  <th>Description</th>
                                  <th className='text-center'>Unit</th>
                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>

                              {showOri && (
                                <tbody>
                                  {proAccessLists.map((proAccess, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>{proAccess ? proAccess.code : ''}</td>
                                      <td>{proAccess.name}</td>
                                      <td>
                                        {proAccess.relatedBrand
                                          ? proAccess.relatedBrand.name
                                          : ''}
                                      </td>
                                      <td>
                                        {proAccess.relatedCategory
                                          ? proAccess.relatedCategory.name
                                          : ''}
                                      </td>

                                      <td>
                                        {proAccess.relatedSubCategory
                                          ? proAccess.relatedSubCategory.name
                                          : ''}
                                      </td>
                                      <td>{proAccess.description}</td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/proAccessUnit/' + proAccess._id}
                                        >
                                          <p className='btn btn-sm btn-primary'>
                                            Unit
                                          </p>
                                        </Link>
                                      </td>
                                      <td className='text-center'>
                                        <div className='row'>
                                          <div className='col-md-6'>
                                            <Link
                                              to={
                                                '/proAcessUpdate/' +
                                                proAccess._id
                                              }
                                              className='btn btn-sm btn-warning'
                                            >
                                              <FaRegEdit />
                                            </Link>
                                          </div>
                                          <div className='col-md-6'>
                                            <button
                                              className='btn btn-sm btn-danger'
                                              onClick={e =>
                                                submit(proAccess._id)
                                              }
                                            >
                                              <FaRegTrashAlt />
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                              {showSearch && (
                                <tbody>
                                  {searchList.map((proAccess, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>{proAccess ? proAccess.code : ''}</td>
                                      <td>{proAccess.name}</td>
                                      <td>
                                        {proAccess.relatedBrand
                                          ? proAccess.relatedBrand.name
                                          : ''}
                                      </td>
                                      <td>
                                        {proAccess.relatedCategory
                                          ? proAccess.relatedCategory.name
                                          : ''}
                                      </td>

                                      <td>
                                        {proAccess.relatedSubCategory
                                          ? proAccess.relatedSubCategory.name
                                          : ''}
                                      </td>
                                      <td>{proAccess.description}</td>
                                      <td className='text-center'>
                                        <Link
                                          to={'/proAccessUnit/' + proAccess._id}
                                        >
                                          <p className='btn btn-sm btn-primary'>
                                            Unit
                                          </p>
                                        </Link>
                                      </td>
                                      <td className='text-center'>
                                        <div className='row'>
                                          <div className='col-md-6'>
                                            <Link
                                              to={
                                                '/proAcessUpdate/' +
                                                proAccess._id
                                              }
                                              className='btn btn-sm btn-warning'
                                            >
                                              <FaRegEdit />
                                            </Link>
                                          </div>
                                          <div className='col-md-6'>
                                            <button
                                              className='btn btn-sm btn-danger'
                                              onClick={e =>
                                                submit(proAccess._id)
                                              }
                                            >
                                              <FaRegTrashAlt />
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ProAccessDialog
                open={open}
                close={() => setOpen(false)}
                setProAccessLists={setProAccessLists}
                proAccessLists={proAccessLists}
              />
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Income

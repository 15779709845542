import React from 'react'
import axios from 'axios'
import SideBar from './SideBar'
import { useEffect, useState } from 'react'
import CurrencyDialog from '../views/CurrencyDialog'
import CurrencyUpdate from '../views/CurrencyUpdate'
import { Link, useLocation } from 'react-router-dom'
import ReactShadowScroll from 'react-shadow-scroll'
import useCollapse from 'react-collapsed'
import HeaderName from './HeaderName'
import {
  FaCashRegister,
  FaFileMedical,
  FaArrowLeft,
  FaAngleDown,
  FaRegEdit,
  FaAngleUp,
  FaList,
  FaRegUser,
  FaRegTrashAlt,
  FaClipboardCheck,
  FaPowerOff
} from 'react-icons/fa'
import apiInstance from '../../utils/api'
function Dashboard () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const purchaseListId = useLocation().pathname.split('/')[2]

  const [scroll, setScroll] = useState(true)
  const [isExpanded, setExpanded] = useState(false)
  const [isAccData, setAccData] = useState(false)
  const [itemList, setItemList] = useState([])

  const { getCollapseProps, getToggleProps } = useCollapse()

  const handleAdminChange = () => {
    setExpanded(!isExpanded)
  }

  const handleAccChange = () => {
    setAccData(!isAccData)
  }
  const [purchaseLists, setPurchaseLists] = useState([])
  const [purchaseDetail, setPurchaseDetail] = useState([])

  const [open, setOpen] = useState(false)

  const [showUpdate, setShowUpdate] = useState(false)
  const [id, setId] = useState('')
  const showDialog = () => setOpen(true)
  const showCurrencyUpdate = val => {
    setId(val)
    setShowUpdate(true)
  }

  useEffect(() => {
    const getPurchaseLists = async () => {
      try {
        const res = await apiInstance.get('purchase/' + purchaseListId)

        setPurchaseLists(res.data.data)
        console.log(res.data.data, 'bay')
      } catch (err) {}
    }

    const getRelatedMedicineLists = async () => {
      try {
        const res = await apiInstance.get('purchase/' + purchaseListId)
        console.log(res.data.data, 'pur req')

        setPurchaseDetail(res.data.data)
        setItemList([
          ...res.data.data[0].medicineItems,
          ...res.data.data[0].procedureItems,
          ...res.data.data[0].accessoryItems
        ])
        // setItemList([...itemList, ...res.data.data[0].accessoryItems])
      } catch (err) {}
    }

    getPurchaseLists()
    getRelatedMedicineLists()
    if (scroll) {
      document.body.style.overflow = 'scroll'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <div className='row'>
          <div className='col-md-3'>
            <SideBar />
          </div>
          <div className='col-md-9'>
            <HeaderName />

            {/* <!-- /.navbar --> */}

            {/* <!-- Main Sidebar Container --> */}

            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className='content-wrapper'>
              {/* <!-- Content Header (Page header) --> */}
              <div className='content-header'>
                <div className='container-fluid'>
                  <div className='row mb-2'>
                    <div className='col-sm-12'>
                      <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                          <Link to='/purchase'>
                            <i>
                              <FaArrowLeft />
                            </i>
                          </Link>
                        </li>
                        <li
                          className='breadcrumb-item active'
                          style={{ marginTop: '0.1em' }}
                        >
                          Purchase List
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Main content --> */}
              <section class='content'>
                <div class=''>
                  <div className='row ml-2'>
                    <h4 className='mr-2'>Purchase Detail</h4>
                    <div className='card col-4 mt-1'>
                      <table className='table table-borderless'>
                        {purchaseLists.map(purList => (
                          <div>
                            <tr>
                              <td>Purchase Date</td>
                              <td>{purList.purchaseDate.split('T')[0]}</td>
                            </tr>
                            <tr>
                              <td>Supplier Name</td>
                              <td>
                                {purList.supplierName
                                  ? purList.supplierName.name
                                  : 'No Name'}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Quantity</td>
                              <td>{purList.totalQTY}</td>
                            </tr>
                            <tr>
                              <td>Total Price</td>
                              <td>{purList.totalPrice}</td>
                            </tr>
                            <tr>
                              <td>Remark</td>
                              <td>{purList.remark}</td>
                            </tr>
                          </div>
                        ))}
                      </table>
                      {/* <div className='d-flex justify-content-center gap-3 mb-3'>
                        <button className='btn btn-warning'>Edit</button>
                        &nbsp;
                        <button className='btn  btn-danger'>Delete</button>
                      </div> */}
                    </div>
                    <div className=' col-8 right-table mt-1 rounded shadow-m'>
                      <div className='card px-3 py-1'>
                        <h6 className='py-3'>Purchase Unit List</h6>

                        <table className='table table-striped'>
                          <thead className=''>
                            <tr>
                              <th>No</th>
                              <th>Item Name</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Sub Total</th>
                            </tr>
                          </thead>
                          {/* <?php $i=1; ?> */}
                          <tbody className=''>
                            {itemList.map((item, i) => (
                              <tr>
                                <td>{++i}</td>
                                <td>
                                  {item.item_id
                                    ? item.item_id.medicineItemName
                                    : 'no'}

                                  {item.item_id
                                    ? item.item_id.procedureItemName
                                    : 'no'}
                                  {item.item_id
                                    ? item.item_id.accessoryItemName
                                    : 'no'}
                                </td>

                                <td>{item ? item.qty : ''}</td>
                                <td>{item.item_id ? item.item_id?.purchasePrice : ''}</td>
                                <td>{item.item_id ? item.item_id?.purchasePrice * item.qty : ''}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <CurrencyDialog
                    open={open}
                    close={() => setOpen(false)}
                    setOpen={setOpen}
                    setPurchaseLists={setPurchaseLists}
                    purchaseLists={purchaseLists}
                  />
                </div>
              </section>
            </div>

            {/* <!-- /.content-wrapper --> */}
            <footer className='main-footer'>
              <strong>
                Copyright &copy; 2017-2020{' '}
                <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
              </strong>
              All rights reserved.
            </footer>

            {/* <!-- Control Sidebar --> */}
            <aside classNameName='control-sidebar control-sidebar-dark'>
              {/* <!-- Control sidebar content goes here --> */}
            </aside>
          </div>
        </div>

        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Dashboard

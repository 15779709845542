import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  FaCashRegister,
  FaFileMedical,
  FaRegEdit,
  FaRegTrashAlt
} from 'react-icons/fa'
import axios from 'axios'
import SideBar from './SideBar'
import SellEndDialog from './SellEndDialog'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import apiInstance from '../../utils/api'
function FixedAsset () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [fixedAssetLists, setFixedAssetLists] = useState([])
  const [id, setId] = useState('')
  const [isShow, setIsShow] = useState(false)
  const [relatedLists, setRelatedLists] = useState([])
  const [plantList, setPlantList] = useState([])
  const [showGroup, setShowGroup] = useState(false)
  const [origin, setOrigin] = useState(true)
  const [initialTotal, setInitialTotal] = useState('')
  const [depTotal, setDepTotal] = useState('')
  const [currentTotal, setCurrentTotal] = useState('')

  const [name, setName] = useState('')
  useEffect(() => {
    const getFixedAsset = async () => {
      try {
        const res = await apiInstance.get('fixed-assets')

        setFixedAssetLists(res.data.list)
        // console.log(res.data.list, 'res')
      } catch (err) {}
    }

    getFixedAsset()
  }, [])

  const handleSelect = val => {
    if (val === '1') {
      handleForcedEntry()
    }
    if (val === '2') {
      navigate('/add_asset')
    }
    if (val === '3') {
      setShowGroup(true)
      setOrigin(false)
    }
    if (val === '4') {
      window.location.reload()
      setShowGroup(false)
      setOrigin(true)
    }
  }

  const handleChooseType = val => {
    setName(val)
    if (val === 'Plant & Machinery') {
      setPlantList(
        fixedAssetLists.filter(el => el.type === 'Plant & Machinery')
      )

      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Plant & Machinery')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Plant & Machinery')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Plant & Machinery')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Computer & IT Equipment') {
      setPlantList(
        fixedAssetLists.filter(el => el.type === 'Computer & IT Equipment')
      )
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Computer & IT Equipment')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Computer & IT Equipment')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Computer & IT Equipment')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Medical Machinery') {
      setPlantList(
        fixedAssetLists.filter(el => el.type === 'Medical Machinery')
      )
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Medical Machinery')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Medical Machinery')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Medical Machinery')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Furniture & Fitting') {
      setPlantList(
        fixedAssetLists.filter(el => el.type === 'Furniture & Fitting')
      )
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Furniture & Fitting')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Furniture & Fitting')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Furniture & Fitting')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Office Equipment') {
      setPlantList(fixedAssetLists.filter(el => el.type === 'Office Equipment'))
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Office Equipment')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Office Equipment')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Office Equipment')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Medical Equipment') {
      setPlantList(
        fixedAssetLists.filter(el => el.type === 'Medical Equipment')
      )
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Medical Equipment')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Medical Equipment')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Medical Equipment')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Surgery Equipment') {
      setPlantList(
        fixedAssetLists.filter(el => el.type === 'Surgery Equipment')
      )
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Surgery Equipment')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Surgery Equipment')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Surgery Equipment')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Air Con') {
      setPlantList(fixedAssetLists.filter(el => el.type === 'Air Con'))
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Air Con')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Air Con')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Air Con')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
    if (val === 'Motor Vehicle') {
      setPlantList(fixedAssetLists.filter(el => el.type === 'Motor Vehicle'))
      setInitialTotal(
        fixedAssetLists
          .filter(el => el.type === 'Motor Vehicle')
          .reduce((acc, current) => acc + current.initialPrice, 0)
      )
      setDepTotal(
        fixedAssetLists
          .filter(el => el.type === 'Motor Vehicle')
          .reduce((acc, current) => acc + current.depriciationTotal, 0)
      )
      setCurrentTotal(
        fixedAssetLists
          .filter(el => el.type === 'Motor Vehicle')
          .reduce((acc, current) => acc + current.currentPrice, 0)
      )
    }
  }
  const handleTransactionShow = val => {
    console.log(val)
    const getRelated = async () => {
      try {
        console.log(val)
        const res = await apiInstance.get('transactions/related/' + val)

        console.log(res)
        setRelatedLists(res.data.data)
      } catch (err) {}
    }

    getRelated()

    if (isShow) {
      document.getElementById('toggle' + val).removeAttribute('hidden')
    } else {
      document.getElementById('toggle' + val).setAttribute('hidden', 'hidden')
    }
    setIsShow(!isShow)
  }

  const showDialog = val => {
    setOpen(true)
    setId(val)
  }

  const handleForcedEntry = async prop => {
    try {
      await apiInstance
        .get('fixed-assets/entry')
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Successful!',
            text: 'Successfully Forced The Entry!'
          })
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        })
    } catch (error) {}
  }

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}
      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='px-3 col-6'>
                  <ol className='breadcrumb'>
                    {showGroup ? (
                      <li className='breadcrumb-item active text-bold fs-5'>
                        Type Groups
                      </li>
                    ) : (
                      <li className='breadcrumb-item active fs-3 text-bold'>
                        Fix Asset List
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='card mb-3'>
                {/* <div className='py-3 px-3'>
                  <button className='btn btn-primary'>Show Type Group</button>
                </div> */}
                <div className='card-body p-b-0 '>
                  {/* <label className="">Fixed Asset List</label> */}

                  <div className='row align-content-end'>
                    {showGroup ? (
                      <div className='col-3'>
                        <div className='mb-3' style={{ width: '250px' }}>
                          <select
                            className='form-select'
                            aria-label='Default select example'
                            onChange={e => handleChooseType(e.target.value)}
                          >
                            <option hidden>
                              <span style={{ fontWeight: 'bold' }}>
                                Choose Type
                              </span>
                            </option>
                            <option value='Computer & IT Equipment'>
                              Computer & IT Equipment
                            </option>
                            <option value='Plant & Machinery'>
                              Plant & Machinery
                            </option>
                            <option value='Furniture & Fitting'>
                              Furniture & Fitting
                            </option>
                            <option value='Office Equipment'>
                              Office Equipment
                            </option>
                            <option value='Motor Vehicle'>Motor Vehicle</option>
                            <option value='Medical Machinery'>
                              Medical Machinery
                            </option>
                            <option value='Medical Equipment'>
                              Medical Equipment
                            </option>
                            <option value='Air Con'>Air Con</option>
                            <option value='Surgery Equipment'>
                              Surgery Equipment
                            </option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='col-2 mb-3' style={{ width: '100px' }}>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        onChange={e => handleSelect(e.target.value)}
                      >
                        <option hidden>
                          <span style={{ fontWeight: 'bold' }}>Action</span>
                        </option>
                        <option value='1' style={{ fontWeight: 'bold' }}>
                          Entry
                        </option>
                        <option value='2' style={{ fontWeight: 'bold' }}>
                          Create
                        </option>
                        <option value='3' style={{ fontWeight: 'bold' }}>
                          Show Group
                        </option>
                        {showGroup ? (
                          <option value='4' style={{ fontWeight: 'bold' }}>
                            Show Table
                          </option>
                        ) : (
                          ''
                        )}
                      </select>
                    </div>
                  </div>

                  {origin ? (
                    <table className='table table-responsive text-black'>
                      <thead className='bg-info text-white'>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Start Date</th>
                          <th>Initial Price</th>
                          <th>Salvage Value</th>
                          {/* {{-- <th>Current Value</th> --}} */}
                          <th>Use Life</th>

                          <th>Depreciate Total</th>

                          <th className='text-center'>Action</th>
                        </tr>
                      </thead>
                      {fixedAssetLists.map((fixedAsset, i) => (
                        <tbody>
                          <tr>
                            <td>{++i}</td>
                            <td>{fixedAsset.name}</td>
                            <td>{fixedAsset.startDate?.split('T')[0]}</td>
                            <td>{fixedAsset.initialPrice} </td>
                            <td>{fixedAsset.salvageValue}</td>
                            <td>{fixedAsset.useLife}</td>

                            <td>
                              {fixedAsset.depriciationTotal
                                ? fixedAsset.depriciationTotal
                                : '-'}
                            </td>

                            <td className='text-center'>
                              <Link
                                to={'/sellEnd/' + fixedAsset._id}
                                className='btn btn-sm btn-primary'

                                // onClick={() => showDialog(fixedAsset._id)}
                              >
                                Sell/End
                              </Link>
                              &nbsp;
                              {/* <button
                              type='button'
                              className='btn btn-sm btn-primary ml-2'
                              onClick={() =>
                                handleTransactionShow(
                                  fixedAsset.relatedAccount._id
                                )
                              }
                            >
                              Transaction
                            </button> */}
                              <Link
                                to={'/update-fix/' + fixedAsset._id}
                                className='btn btn-sm btn-warning'

                                // onClick={() => showDialog(fixedAsset._id)}
                              >
                                <FaRegEdit />
                              </Link>
                            </td>
                          </tr>

                          {/* <tr
                          className='bg-light'
                          id={'toggle' + fixedAsset.relatedAccount._id}
                          hidden
                        >
                          <td colspan='12'>
                            <div>
                              <div class='row'>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    No
                                  </label>
                                </div>
                                <div class='col-md-3'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Account
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Type
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Date
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Amount
                                  </label>
                                </div>
                              </div>

                              {relatedLists
                                ? relatedLists.map((reList, i) => (
                                    <div class='row'>
                                      <div class='col-md-2'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {++i}
                                        </div>
                                      </div>
                                      <div class='col-md-3'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {reList.relatedAccounting.name}
                                        </div>
                                      </div>
                                      <div class='col-md-2'>{reList.type}</div>
                                      <div class='col-md-2'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {reList.date.split('T')[0]}
                                        </div>
                                      </div>
                                      <div class='col-md-2'>
                                        <div
                                          style={{
                                            fontSize: '15px'
                                          }}
                                        >
                                          {reList.amount}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : ''}
                            </div>
                          </td>
                        </tr> */}
                        </tbody>
                      ))}
                    </table>
                  ) : (
                    <div>
                      <table className='table table-bordered'>
                        <tr>
                          <td
                            colspan='4'
                            className='text-center fs-4 font-weight-bold'
                          >
                            {name}
                          </td>

                          <td colspan='3' className='text-center fs-4'>
                            Original Cost
                          </td>
                        </tr>
                        <tr>
                          <th style={{ fontSize: '16px' }}>No</th>
                          <th style={{ fontSize: '16px' }}>Date</th>
                          <th style={{ fontSize: '16px' }}>Name</th>
                          <th style={{ fontSize: '16px' }}>Type</th>

                          <th style={{ fontSize: '16px' }}>Intial Amount</th>
                          <th style={{ fontSize: '16px' }}>
                            Year Dipreciation
                          </th>
                          <th style={{ fontSize: '16px' }}>Current Amount</th>
                        </tr>
                        {plantList ? (
                          plantList.map((i, index) => (
                            <>
                              <tr
                                // style={{ border: '1px solid' }}
                                className='fs-5 py-2 px-1'
                              >
                                <td>{index + 1}</td>
                                <td>
                                  {i.startDate?.split('T')[0]
                                    ? i.startDate?.split('T')[0]
                                    : ''}
                                </td>
                                <td>{i.name ? i.name : ''}</td>
                                <td>{i.type ? i.type : ''}</td>
                                <td>{i?.initialPrice}</td>
                                <td>{i?.depriciationTotal}</td>
                                <td>{i?.currentPrice}</td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr
                            // style={{ border: '1px solid' }}
                            className='fs-5 py-2 px-1'
                          >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className='fs-5'>
                            <b>Initial-Total:</b> {initialTotal}
                          </td>
                          <td className='fs-5'>
                            <b>Dep-Total:</b> {depTotal}
                          </td>

                          <td className='fs-5'>
                            <b>Current-Total:</b> {currentTotal}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <footer className='main-footer text-center fs-5'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='https://www.facebook.com/kwintechnologies/'>
            K-win Technology
          </a>
          .
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- /.content-wrapper --> */}
      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- /.control-sidebar --> */}
    </div>
  )
}
export default FixedAsset

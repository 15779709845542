import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import SideBar from '../../SideBar.js'
import Swal from 'sweetalert2'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import apiInstance from '../../../../utils/api.js'
import { FaRegEdit, FaFileExcel, FaRegTrashAlt } from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'

const LabServiceList = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'

  // const api = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)

  const [doctorID, setDoctorID] = useState('')
  const [showDoctor, setShowDoctor] = useState(true)
  const [showNurse, setShowNurse] = useState(false)
  const [showTherapist, setShowTherapist] = useState(false)
  const [doctorLists, setDoctorLists] = useState([])
  const [therapistLists, setTherapistLists] = useState([])
  const [nurseLists, setNurseLists] = useState([])

  const [showDoctorBranch, setShowDoctorBranch] = useState(false)
  const [branchLists, setBranchesLists] = useState([])
  const [headTreatmentList, setHeadTreatmentList] = useState([])
  const [filterDoctor, setFilterDoctor] = useState([])

  const [relatedNurseBranch, setRelatedNurseBranch] = useState([])
  const [nurseBranch, setNurseBranch] = useState(false)

  const [relatedTherapistBranch, setRelatedTherapistBranch] = useState([])
  const [therapistBranch, setTherapistBranch] = useState(false)
  const [doctorEvent, setDoctorEvent] = useState('')
  const [nurseEvent, setNurseEvent] = useState('')
  const [theEvent, setTheEvent] = useState('')
  const [doctorSearch, setDoctorSearch] = useState([])
  const [showDrSearch, setShowDrSearch] = useState(false)

  //Doctor's Branch Filter

  const handleDoctorBranchShowHide = () => {
    // if (showDoctorBranch == 'true') {
    console.log(doctorEvent, 'branch show hide ID')
    if (doctorEvent) {
      setShowDoctorBranch(true)
      setTherapistBranch(false)
      setShowDoctor(false)
      setShowTherapist(false)
      setShowNurse(false)
      setNurseBranch(false)
    } else {
      setShowDoctorBranch(false)
      setTherapistBranch(false)
      setShowDoctor(true)
      setShowTherapist(false)
      setShowNurse(false)
      setNurseBranch(false)
    }
    // } else {
    //   setShowDoctorBranch(true)
    //   setTherapistBranch(false)
    //   setShowDoctor(false)
    //   setShowTherapist(false)
    //   setShowNurse(false)
    //   setNurseBranch(false)
    // }
  }
  //Nurse's Branch Filter

  const handleNurseBranchShowHide = () => {
    if (nurseEvent) {
      setShowDoctorBranch(false)
      setTherapistBranch(false)
      setShowDoctor(false)
      setShowTherapist(false)
      setShowNurse(false)
      setNurseBranch(true)
    } else {
      setShowDoctorBranch(false)
      setTherapistBranch(false)
      setShowDoctor(false)
      setShowTherapist(false)
      setShowNurse(true)
      setNurseBranch(false)
    }
  }

  //Therapist's Branch Filter

  const handleTheBranchShowHide = () => {
    if (theEvent) {
      setShowDoctorBranch(false)
      setTherapistBranch(true)
      setShowDoctor(false)
      setShowTherapist(false)
      setShowNurse(false)
      setNurseBranch(false)
    } else {
      setShowDoctorBranch(false)
      setTherapistBranch(false)
      setShowDoctor(false)
      setShowTherapist(true)
      setShowNurse(false)
      setNurseBranch(false)
    }
  }

  // const url = 'http://backendcherryk.cherrykmyanmar.com:4000/api'
  const handleDelete = event => {
    console.log(event, 'event')
    apiInstance
      .delete('doctor/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = doctorLists.filter(item => item._id !== event)
        setDoctorLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleNurseDelete = event => {
    console.log(event, 'event')
    apiInstance
      .delete('nurse/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = nurseLists.filter(item => item._id !== event)
        setNurseLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleTheDelete = event => {
    console.log(event, 'event')
    apiInstance.delete('therapist/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = therapistLists.filter(item => item._id !== event)
        setTherapistLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.date = element.date?.split('T')[0]
        element.commission = 2
      })
      _export.current.save()
    }
  }
  useEffect(() => {
    const getBranches = async () => {
      try {
        const res = await apiInstance.get('branches')
        setBranchesLists(res.data.list)
      } catch (err) {
        alert(err.message)
      }
    }

    const getDoctorLists = async () => {
      const res = await apiInstance.get('doctors')

      setDoctorLists(res.data.data)
      setDoctorID(res.data.data)
      console.log(res.data.data, 'd ID')
    }
    getDoctorLists()

    const getTherapistLists = async () => {
      const res = await apiInstance.get('therapists')

      setTherapistLists(res.data.data)
    }
    const getNurseLists = async () => {
      const res = await apiInstance.get('nurses')

      setNurseLists(res.data.list)
    }
    // const getFilterDoctor = async () => {
    //   const res = relatedDoctorBranch.filter(el => el.speciality == 'M.O')
    //   setFilterDoctor(res)
    //   console.log(res, 'Filter')
    // }
    // getFilterDoctor()
    const getHeadTreatment = async () => {

      try {
        const res = await apiInstance.get('treatment-lists')

        // console.log(res.data.list, 'd list one')
        setHeadTreatmentList(res.data.list)
      } catch (err) { }
    }

    getHeadTreatment()
    getDoctorLists()
    getNurseLists()

    // getBranches()
    getTherapistLists()
  }, [])

  const handleDoctorSearch = name => {
    setDoctorSearch(
      doctorLists.filter(el =>
        el.name.toLowerCase().includes(name.toLowerCase())
      )
    )
    // console.log(
    //   doctorLists.filter(
    //     el =>
    //       el.name
    //         .toLowerCase()
    //         .split(' ')
    //         .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    //         .join(' ') === name
    //   ),
    //   'dr search'
    // )
    if (name) {
      setShowDrSearch(true)
      setShowDoctorBranch(true)
      setTherapistBranch(false)
      setShowDoctor(false)
      setShowTherapist(false)
      setShowNurse(false)
      setNurseBranch(false)
    } else {
      setShowDrSearch(false)
      setShowDoctorBranch(true)
      setTherapistBranch(false)
      setShowDoctor(true)
      setShowTherapist(false)
      setShowNurse(false)
      setNurseBranch(false)
    }
  }
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        <HeaderName />
        <SideBar />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active'>
                      Doctor-Clinic List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row card-header d-flex justify-content-between'>
                <div className='col-md-4'>
                  <div className='input-group' style={{ marginTop: '30px' }}>
                    <input
                      type='search'
                      className='form-control rounded'
                      id='search_code'
                      placeholder='Enter Doctor Name'
                      onChange={e => handleDoctorSearch(e.target.value)}
                    />
                    &nbsp;
                  </div>
                </div>

                {/* <div
                  className='offset-3 col-md-3'
                  style={{ marginTop: '2.3em' }}
                >
                  <div className='form-group'>
                    <select
                      name='currency'
                      id=''
                      className='form-control mt-1'
                      onchange='convert(this.value)'
                      // placeholder='Filter By Branches'
                      onChange={e => {
                        handleDoctorBranch(e.target.value)
                        handleNurseBranch(e.target.value)
                        handleTherapistBranch(e.target.value)
                      }}
                    >
                      <option hidden>Filter By Branches</option>

                      {branchLists.map(option => (
                        <option value={option._id}>{option.name}</option>
                      ))}
                    </select>
                  </div>
                </div> */}
              </div>

              <div class='card-body'>
                {/* Export data in Excel */}
                {showDoctor && (
                  <ExcelExport data={doctorLists} ref={_export}>
                    <ExcelExportColumn
                      field=''
                      title='No'
                      locked={true}
                      width={30}
                    />
                    <ExcelExportColumn
                      field='name'
                      title='Name'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={200}
                    />

                    <ExcelExportColumn
                      field='speciality'
                      title='Speciality'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={250}
                    />

                    <ExcelExportColumn
                      field='treatmentUnitMain'
                      title='Treatment Type'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={250}
                    />

                    <ExcelExportColumn
                      field='commission'
                      title='Commission'
                      width={150}
                    />
                    <ExcelExportColumn
                      field='remark'
                      title='Remark'
                      width={150}
                    />
                  </ExcelExport>
                )}
                {showTherapist && (
                  <ExcelExport data={therapistLists} ref={_export}>
                    <ExcelExportColumn
                      field=''
                      title='No'
                      locked={true}
                      width={30}
                    />
                    <ExcelExportColumn
                      field='name'
                      title='Name'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={200}
                    />

                    <ExcelExportColumn
                      field='speciality'
                      title='Speciality'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={250}
                    />

                    <ExcelExportColumn
                      field='treatmentUnitMain'
                      title='Treatment Type'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={250}
                    />

                    <ExcelExportColumn
                      field='commission'
                      title='Commission'
                      width={150}
                    />
                    <ExcelExportColumn
                      field='remark'
                      title='Remark'
                      width={150}
                    />
                  </ExcelExport>
                )}
                {showDrSearch && (
                  <ExcelExport data={doctorSearch} ref={_export}>
                    <ExcelExportColumn
                      field=''
                      title='No'
                      locked={true}
                      width={30}
                    />
                    <ExcelExportColumn
                      field='name'
                      title='Name'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={200}
                    />

                    <ExcelExportColumn
                      field='speciality'
                      title='Speciality'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={250}
                    />

                    <ExcelExportColumn
                      field='treatmentUnitMain'
                      title='Treatment Type'
                      headerCellOptions={{
                        textAlign: 'center'
                      }}
                      width={250}
                    />

                    <ExcelExportColumn
                      field='commission'
                      title='Commission'
                      width={150}
                    />
                    <ExcelExportColumn
                      field='remark'
                      title='Remark'
                      width={150}
                    />
                  </ExcelExport>
                )}

                {/* Export Data in Excel end */}
                <div className='row py-3 d-flex justify-content-between'>
                  <div className='col-md-6'>
                    <button
                      onClick={handleDoctorBranchShowHide}
                      className='btn btn-sm bg-gradient-gray'
                    >
                      Doctor Lists
                    </button>
                    &nbsp;
                    <button
                      onClick={handleNurseBranchShowHide}
                      className='btn btn-sm bg-gradient-gray '
                    >
                      Nurse Aid Lists
                    </button>
                    &nbsp;
                    <button
                      onClick={handleTheBranchShowHide}
                      className='btn btn-sm bg-gradient-gray '
                    >
                      Therapist Lists
                    </button>
                  </div>

                  <div className='offset-2 col-md-2'>
                    <Link to='/doctor-reg' className='btn btn-sm btn-primary'>
                      <i class='fas fa-plus'></i> &nbsp;Add
                    </Link>
                    &nbsp;
                    {/* <a href="/expense_type" class="btn btn-primary">
                              Expense Type
                            </a> */}
                    <button
                      type='button'
                      className='btn btn-sm btn-success'
                      onClick={excelExport}
                    >
                      <FaFileExcel />
                      &nbsp;Export
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div class='col-md-12 card px-3 py-3'>
                    <div class='table-responsive text-black' id='slimtest2'>
                      <table class='table table-hover' id='filter_date'>
                        <thead class='bg-info text-white'>
                          <tr>
                            <th>#</th>

                            {/* <th>Bank / Cash Account</th>  */}
                            <th>Name</th>
                            <th>Treatments</th>
                            <th>Speciality</th>
                            <th>Treatment Unit Main</th>
                            <th>Commission</th>
                            <th className='text-center'>Action</th>
                          </tr>
                        </thead>
                        {showDrSearch &&
                          doctorSearch.map((reDoctor, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>

                                <td>{reDoctor.name ? reDoctor.name : ''}</td>

                                <td>{reDoctor.speciality}</td>
                                <td>
                                  {reDoctor.treatmentUnitMain
                                    ? reDoctor.treatmentUnitMain
                                    : ''}
                                </td>
                                <td>5</td>

                                <td className='text-center'>
                                  <Link
                                    to={'/refDoctor/' + reDoctor._id}
                                    className='btn btn-sm btn-info'
                                  >
                                    Commission
                                  </Link>
                                  &nbsp;
                                  <Link
                                    to={'/doctor-update/' + reDoctor._id}
                                    className='btn btn-sm btn-warning text-light'
                                  >
                                    <FaRegEdit />
                                  </Link>
                                  &nbsp;
                                  <button
                                    className='btn btn-sm btn-danger'
                                    onClick={e => handleDelete(reDoctor._id)}
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          ))}

                        {showDoctor && (
                          <>
                            {doctorLists.map((doctor, i) => (
                              <tbody className=''>
                                <tr>
                                  <td>{++i}</td>

                                  <td>{doctor.name ? doctor.name : ''}</td>
                                  <td>  {doctor.commission.slice(0, 2).map((i, ind) => (
                                    <b>({ind + 1})&nbsp;{headTreatmentList.filter(el => el._id === i.relatedTreatmentList)[0]?.name}<br></br></b>
                                  ))} &nbsp;more....</td>
                                  <td>{doctor.speciality}</td>
                                  <td>
                                    {doctor.treatmentUnitMain
                                      ? doctor.treatmentUnitMain
                                      : ''}
                                  </td>
                                  <td> {doctor.commission.slice(0, 2).map((i, ind) => (
                                    <b>({ind + 1})&nbsp;{i.commissionPercent} <br></br></b>
                                  ))} &nbsp;more....</td>

                                  <td className='text-center'>
                                    <Link
                                      to={'/refDoctor/' + doctor._id}
                                      className='btn btn-sm btn-info'
                                    >
                                      Commission
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={'/doctor-update/' + doctor._id}
                                      className='btn btn-sm btn-warning text-light'
                                    >
                                      <FaRegEdit />
                                    </Link>
                                    &nbsp;
                                    <button
                                      className='btn btn-sm btn-danger'
                                      onClick={e => handleDelete(doctor._id)}
                                    >
                                      <FaRegTrashAlt />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </>
                        )}

                        {showTherapist && (
                          <>
                            {therapistLists.map((the, i) => (
                              <tbody className=''>
                                <tr>
                                  <td>{++i}</td>

                                  <td>{the.name ? the.name : ''}</td>

                                  <td>{the.speciality}</td>
                                  <td>
                                    {the.treatmentUnitMain
                                      ? the.treatmentUnitMain
                                      : ''}
                                  </td>
                                  <td>2</td>

                                  <td>
                                    <Link
                                      to={'/refthe/' + the._id}
                                      className='btn btn-sm btn-info'
                                    >
                                      Commission
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={'/doctor-update/' + the._id}
                                      className='btn btn-sm btn-warning text-light'
                                    >
                                      <FaRegEdit />
                                    </Link>
                                    &nbsp;
                                    <button
                                      className='btn btn-sm btn-danger'
                                      onClick={e => handleTheDelete(the._id)}
                                    >
                                      <FaRegTrashAlt />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </>
                        )}
                        {showNurse && (
                          <>
                            {nurseLists.map((the, i) => (
                              <tbody className=''>
                                <tr>
                                  <td>{++i}</td>

                                  <td>{the.name ? the.name : ''}</td>

                                  <td>{the.speciality}</td>
                                  <td>
                                    {the.treatmentUnitMain
                                      ? the.treatmentUnitMain
                                      : ''}
                                  </td>
                                  <td>2</td>

                                  <td className='text-center'>
                                    <Link
                                      to={'/refnur/' + the._id}
                                      className='btn btn-sm btn-info'
                                    >
                                      Commission
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={'/doctor-update/' + the._id}
                                      className='btn btn-sm btn-warning text-light'
                                    >
                                      <FaRegEdit />
                                    </Link>
                                    <button
                                      className='btn btn-sm btn-danger'
                                      onClick={e => handleNurseDelete(the._id)}
                                    >
                                      <FaRegTrashAlt />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <CommissionDialog
                open={open}
                close={() => setOpen(false)}
                expenseLists={expenseLists}
                setExpenseLists={setExpenseLists}
              /> */}
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default LabServiceList

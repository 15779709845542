import SideBar from '../../SideBar'
import { SegmentedControl } from '@mantine/core'
import { Button } from '@mantine/core'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import apiInstance from '../../../../utils/api'
import HeaderName from '../../HeaderName'

export default function ProfitAndLoss() {

  const RName = localStorage.getItem('Name')
  const [currentDate, setCurrentDate] = useState(new Date())

  const [showPrint, setShowPrint] = useState(false)
  const [hideBtn, setHideBtn] = useState(true)
  const Weeks = ['First Week', 'Second Week', 'Third Week', 'Fourth Week']
  const Months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const [value, setValue] = useState('Daily')
  const [daily, setDaily] = useState(true)
  const [weekly, setWeekly] = useState(false)
  const [monthly, setMonthly] = useState(false)
  const [msList, setMSList] = useState([])
  const [tvList, setTVList] = useState([])
  const [expenseList, setExpenseList] = useState([])
  const [mstotal, setmstotal] = useState('0')
  const [tvtotal, settvtotal] = useState('0')
  const [expensetotal, setexpensetotal] = useState('0')
  const [fromDate, setFromDate] = useState('')
  const [profittotal, setprofittotal] = useState(0)
  const [show, setShow] = useState('msTable')
  const [payment, setPayment] = useState('')
  const [method, setMethod] = useState('')
  const [cashDown, setCashDown] = useState('')
  const [bankTransition, setBankTransition] = useState('')
  const [appointment, setAppointment] = useState('')
  const [advanced, setAdvanced] = useState('')
  const [branchList, setBranchList] = useState([])
  const [branch, setBranch] = useState('')
  const [toDate, setToDate] = useState('')
  const [weekName, setWeekName] = useState('')
  const [monthName, setMonthName] = useState('')
  const [incometotal, setincometotal] = useState('0')
  const [incomeList, setIncomeList] = useState([])
  const [cogsList, setCOGSList] = useState([])
  const [cogsTotal, setCOGSTotal] = useState('0')

  const handlePAndLFilter = async () => {
    let data = {}
    if (branch) data.relatedBranch = branch
    if (fromDate && toDate) {
      data.start = fromDate
      data.end = toDate
    } else if (weekName) {
      data.weekName = weekName
    } else if (monthName) {
      data.monthName = monthName
    }

    apiInstance
      .get('profit-and-losses/total-filter', { params: data })
      .then(response => {
        let data = response.data
        console.log(response.data)
        setmstotal(data.MSTotal)
        settvtotal(data.TVTotal)
        setexpensetotal(data.expenseTotal)
        setincometotal(data.incomeTotal)
        setCOGSTotal(data.COGSTotal)
        setprofittotal(
          data.MSTotal + data.TVTotal + data.incomeTotal - data.expenseTotal
        )
        setMSList(data.MedicineSaleResult)
        setTVList(data.TreatmentVoucherResult)
        setExpenseList(data.ExpenseResult)
        setIncomeList(data.IncomeResult)
        setCOGSList(data.COGSResult)

        if (data.msPaymentMethod['Cash Down'])
          setCashDown(data.msPaymentMethod['Cash Down'] + ' MMK')
        if (data.msPaymentMethod['Bank Transition'])
          setBankTransition(data.msPaymentMethod['Bank Transition'] + ' MMK')
        if (data.tvPaymentMethod['by Appointment'])
          setAppointment(data.tvPaymentMethod['by Appointment'] + ' MMK')
        if (data.tvPaymentMethod.Advanced)
          setAdvanced(data.tvPaymentMethod.Advanced + ' MMK')
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const print = () => {
    // document.getElementById('printtop').style({ marginTop: '100px' })

    // document.getElementById('printtop').style.position = 'running(footer)'

    let printContents = document.getElementById('printarea').innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
    window.location.reload()
  }

  const handleBottomChange = event => {
    // setShowBottom(false);
    // document.getElementById('printtop').style.marginTop = '100px'

    // document.getElementById('printtop').style.position = 'running(footer)'
    //document.getElementById("printtop").classList.add("fixed-bottom");
    //document.getElementById("printtop").style.bottom= "0";
    // var content = document.getElementById('print')
    // var pri = document.getElementById('printdiv').contentWindow
    // pri.document.open()
    // pri.document.write(content.innerHTML)
    // pri.document.close()
    // pri.focus()
    // pri.print()

    let printContents = document.getElementById('print').innerHTML
    let originalContents = document.body.main.innerHTML
    document.body.main.innerHTML = printContents
    window.print()
    document.body.main.innerHTML = originalContents
    window.location.reload()
  }

  const handlePayment = event => {
    let data = event.target.value
    console.log(data)
    switch (data) {
      case 'Cash Down':
        setPayment(cashDown)
        setMethod('Cash Down: ')
        break
      case 'Bank Transition':
        setPayment(bankTransition)
        setMethod('Bank Transition: ')
        break
      case 'by Appointment':
        setMethod('By Appointment: ')
        setPayment(appointment)
        break
      case 'Advanced':
        setMethod('Advanced: ')
        setPayment(advanced)
        break
      default:
        return data
    }
  }

  const handleSegment = value => {
    switch (value) {
      case 'Daily': {
        setDaily(true)
        setWeekly(false)
        setMonthly(false)
        setBranch('')
        setFromDate('')
        setToDate('')
        setWeekName('')
        setMonthName('')
        break
      }
      case 'Weekly': {
        setWeekly(true)
        setDaily(false)
        setMonthly(false)
        setBranch('')
        setFromDate('')
        setToDate('')
        setWeekName('')
        setMonthName('')
        break
      }
      case 'Monthly': {
        setMonthly(true)
        setWeekly(false)
        setDaily(false)
        setBranch('')
        setFromDate('')
        setToDate('')
        setWeekName('')
        setMonthName('')
        break
      }
      default:
        return value
    }
    setValue(value)
  }

  const showPrintPage = () => {
    setCurrentDate(new Date())
    setHideBtn(false)
    setShowPrint(true)
  }
  useEffect(() => {
    const getBranch = () => {
      apiInstance.get('branches').then(res => {
        setBranchList(res.data.list)
      })
    }

    // handleSegment(value)
  }, [])

  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper no-print'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Profit & Loss List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className='row ml-1'>
            <div className='col-2 mt-1'>
              <SegmentedControl
                data={[
                  { label: 'Daily', value: 'Daily' },
                  { label: 'Weekly', value: 'Weekly' },
                  { label: 'Monthly', value: 'Monthly' }
                ]}
                radius='lg'
                size='sm'
                color='cyan'
                transitionDuration={250}
                transitionTimingFunction='linear'
                value={value}
                onChange={handleSegment}
                style={{ backgroundColor: 'white' }}
              ></SegmentedControl>
            </div>
            {daily ? (
              <>
                <div className='ml-3 col-2'>
                  <input
                    type='date'
                    className='form-control'
                    onChange={e => setFromDate(e.target.value)}
                  ></input>
                </div>
                <div className='col-2'>
                  <input
                    type='date'
                    className='form-control'
                    onChange={e => setToDate(e.target.value)}
                  ></input>
                </div>

                {/* <div className='col-2 mt-2'>
                  <select
                    className='form-control'
                    onChange={e => setBranch(e.target.value)}
                  >
                    <option hidden>Select Branch</option>
                    {console.log(branchList, 'BRANCHLIST')}
                    {branchList.map((e, i) => (
                      <option value={e._id}>{e.name}</option>
                    ))}
                  </select>
                </div> */}
                <div className='col-1 mt-2'>
                  <button
                    className='form-control btn btn-sm btn-primary'
                    onClick={handlePAndLFilter}
                  >
                    Search
                  </button>
                </div>
                <div className='col-2 mt-2 '>
                  <select className='form-control' onChange={handlePayment}>
                    <option hidden>Select Payment Method</option>
                    <option value='Cash Down'>Cash Down</option>
                    <option value='Bank Transition'>Bank Transition</option>
                    <option value='by Appointment'>by Appointment</option>
                    <option value='Advanced'>Advanced</option>
                  </select>
                </div>
                <div className='col-2 mt-1'>
                  <p style={{ fontSize: '20px' }}>
                    {method}
                    {payment}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}
            {weekly ? (
              <>
                <div className='col-2 mt-1 ml-4'>
                  <select
                    className='form-control'
                    onChange={e => setWeekName(e.target.value)}
                  >
                    <option hidden>Select Week</option>
                    {Weeks.map((e, i) => (
                      <option>{e}</option>
                    ))}
                  </select>
                </div>
                <div className='col-2 mt-1'>
                  <select
                    className='form-control'
                    onChange={e => setBranch(e.target.value)}
                  >
                    <option hidden>Select Branch</option>
                    {console.log(branchList, 'BRANCHLIST')}
                    {branchList.map((e, i) => (
                      <option value={e._id}>{e.name}</option>
                    ))}
                  </select>
                </div>
                <div className='col-1 mt-1'>
                  <button
                    className='form-control btn btn-sm btn-primary'
                    onClick={handlePAndLFilter}
                  >
                    Search
                  </button>
                </div>
                <div className='col-2 mt-1 '>
                  <select className='form-control' onChange={handlePayment}>
                    <option hidden>Select Payment Method</option>
                    <option value='Cash Down'>Cash Down</option>
                    <option value='Bank Transition'>Bank Transition</option>
                    <option value='by Appointment'>by Appointment</option>
                    <option value='Advanced'>Advanced</option>
                  </select>
                </div>
                <div className='col-4 mt-1'>
                  <p style={{ fontSize: '20px' }}>
                    {method}
                    {payment}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}
            {monthly ? (
              <>
                <div className='col-2 mt-1 ml-4'>
                  <select
                    className='form-control'
                    onChange={e => setMonthName(e.target.value)}
                  >
                    <option hidden>Select Month</option>
                    {Months.map((e, i) => (
                      <option>{e}</option>
                    ))}
                  </select>
                </div>
                <div className='col-2 mt-1'>
                  <select
                    className='form-control'
                    onChange={e => setBranch(e.target.value)}
                  >
                    <option hidden>Select Branch</option>
                    {console.log(branchList, 'BRANCHLIST')}
                    {branchList.map((e, i) => (
                      <option value={e._id}>{e.name}</option>
                    ))}
                  </select>
                </div>
                <div className='col-1 mt-1'>
                  <button
                    className='form-control btn btn-sm btn-primary'
                    onClick={handlePAndLFilter}
                  >
                    Search
                  </button>
                </div>
                <div className='col-2 mt-1 '>
                  <select className='form-control' onChange={handlePayment}>
                    <option hidden>Select Payment Method</option>
                    <option value='Cash Down'>Cash Down</option>
                    <option value='Bank Transition'>Bank Transition</option>
                    <option value='by Appointment'>by Appointment</option>
                    <option value='Advanced'>Advanced</option>
                  </select>
                </div>
                <div className='col-4 mt-1'>
                  <p style={{ fontSize: '20px' }}>
                    {method}
                    {payment}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          {/* <!-- Main content --> */}

          <div className='card mt-2 ml-2'>
            <div className='card-header'>
              <div className='d-flex justify-content-between'>
                <div className=''>
                  <h6 className='mt-1'>
                    <b>Profit: {profittotal} MMK</b>
                  </h6>
                </div>

                <div className=''>
                  <Button
                    color='cyan'
                    radius='sm'
                    size='sm'
                    onClick={() => setShow('msTable')}
                  >
                    Medicine Sale({mstotal} MMK)
                  </Button>
                </div>
                <div className=''>
                  <Button
                    color='cyan'
                    radius='sm'
                    size='sm'
                    onClick={() => setShow('tbTable')}
                  >
                    Treatment Voucher({tvtotal} MMK)
                  </Button>
                </div>
                <div className=' '>
                  <Button
                    color='cyan'
                    radius='sm'
                    size='sm'
                    onClick={() => setShow('income')}
                  >
                    Incomes({incometotal} MMK)
                  </Button>
                </div>
                <div className=''>
                  <Button
                    color='cyan'
                    radius='sm'
                    size='sm'
                    onClick={() => setShow('else')}
                  >
                    Expenses({expensetotal} MMK)
                  </Button>
                </div>
              </div>
            </div>

            {show === 'msTable' ? (
              <div className='card-body'>
                <table id='example1' className='table'>
                  <thead className=''>
                    <tr>
                      <th>No</th>
                      <th>Date</th>
                      <th>Voucher No</th>
                      {/* <th>Related Treatment</th> */}
                      <th>Related Patient</th>

                      <th>Payment Method</th>
                      <th>Grand Total</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>

                  {msList.map((medicineSaleList, i) => (
                    <tbody className=''>
                      <tr>
                        <td>{++i}</td>
                        <td>
                          {medicineSaleList.createdAt
                            ? medicineSaleList.createdAt.split('T')[0]
                            : ''}
                        </td>
                        <td>
                          {medicineSaleList.code ? medicineSaleList.code : ''}
                        </td>

                        <td>{medicineSaleList.relatedPatient?.name}</td>

                        <td>
                          {medicineSaleList.relatedCash
                            ? 'Cash'
                            : medicineSaleList.relatedBank
                              ? 'Bank'
                              : 'Not Set'}
                        </td>
                        <td>
                          {medicineSaleList.msTotalAmount
                            ? medicineSaleList.msTotalAmount.toLocaleString()
                            : ''}
                        </td>

                        <td className='text-center'>
                          <button class='btn btn-primary btn-sm'>
                            Related
                          </button>
                        </td>
                      </tr>

                      {/* <tr
                                                        className="bg-gradient-light"
                                                        id={"toggle" + medicineSaleList._id}
                                                        hidden>
                                                        <td colspan="12">
                                                            <div>
                                                                <div class="row">
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-3">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Account
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Type
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Date
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Amount
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {/* {medRelated
                                                                ? medRelated.map((tran, i) => (
                                                                    <div class="row">
                                                                        <div class="col-md-2">
                                                                            <div style={{ fontSize: "15px" }}>
                                                                                {++i}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <div style={{ fontSize: "15px" }}>
                                                                                {tran.type === "Credit" && vouCode}

                                                                                {tran.type === "Debit" &&
                                                                                    (tran.relatedBank
                                                                                        ? "Bank"
                                                                                        : "Cash")}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            {tran.type}
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            <div style={{ fontSize: "15px" }}>
                                                                                {
                                                                                    tran.date.split(
                                                                                        "T"
                                                                                    )[0]
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            <div style={{ fontSize: "15px" }}>
                                                                                {tran.amount}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                : "No Record found!"} */}
                      {/* </div>
                                                        </td>
                                                    </tr> */}
                    </tbody>
                  ))}
                </table>
              </div>
            ) : show === 'tbTable' ? (
              <div className='card-body'>
                <table id='example1' className='table'>
                  <thead className='ok'>
                    <tr>
                      <th>No</th>
                      <th>Date</th>
                      <th>Voucher No</th>
                      {/* <th>Related Treatment</th> */}
                      <th>Patient</th>
                      <th>Appointment</th>

                      <th>Payment Method</th>
                      <th>Grand Total</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>

                  <>
                    {console.log(tvList, 'tv')}
                    {tvList.map((tSale, i) => (
                      <tbody key={tSale._id}>
                        <tr>
                          <td>{++i}</td>
                          <td>
                            {tSale.createdAt
                              ? tSale.createdAt.split('T')[0]
                              : ''}
                          </td>
                          <td>{tSale.code ? tSale.code : ''}</td>
                          <td>
                            {tSale.relatedPatient
                              ? tSale.relatedPatient.name
                              : ''}
                          </td>
                          <td>
                            {tSale.relatedAppointment
                              ? tSale.relatedAppointment.originalDate.split(
                                'T'
                              )[0]
                              : ''}
                          </td>
                          <td>
                            {tSale.paymentMethod ? tSale.paymentMethod : ''}
                          </td>
                          <td>{tSale.totalAmount ? tSale.totalAmount : ''}</td>
                          {/* <td>
                            {medicineSaleList.relatedTreatment.treatmentName}
                          </td>
                          {/* <td>{tSale.relatedPatient.name}</td>

                          <td>{tSale.paymentMethod}</td>
                          <td>
                            {tSale.totalAmount
                              .toLocaleString()}
                          </td> */}

                          <td className='text-center'>
                            <button class='btn btn-primary btn-sm'>
                              Related
                            </button>
                          </td>
                        </tr>

                        <tr
                          className='bg-gradient-light'
                          id={'toggle' + tSale._id}
                          hidden
                        >
                          <td colspan='12'>
                            <div>
                              <div class='row'>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    No
                                  </label>
                                </div>
                                <div class='col-md-3'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Account
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Type
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Date
                                  </label>
                                </div>
                                <div class='col-md-2'>
                                  <label
                                    style={{ fontSize: '15px' }}
                                    class='text-dark'
                                  >
                                    Amount
                                  </label>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                </table>
              </div>
            ) : show === 'else' ? (
              <div class='table-responsive text-black' id='slimtest2'>
                <table class='table table-hover' id='filter_date'>
                  <thead class='ok'>
                    <tr>
                      <th style={{ color: 'black' }}>#</th>
                      <th>Date</th>
                      <th>Bank / Cash Account</th>
                      <th>Account</th>

                      <th>Balance</th>
                      <th>Currency</th>
                      <th>Remark</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {expenseList.map((expenseList, i) => (
                    <tbody className=''>
                      <tr>
                        <td>{++i}</td>
                        <td>{expenseList.date.split('T')[0]}</td>
                        <td>
                          {expenseList.relatedBankAccount
                            ? expenseList.relatedBankAccount.name
                            : expenseList.relatedCashAccount.name}
                        </td>
                        <td>
                          {expenseList.relatedAccounting
                            ? expenseList.relatedAccounting.name
                            : 'No'}
                        </td>

                        <td>
                          {expenseList.finalAmount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                        </td>
                        <td>{expenseList.finalCurrency}</td>
                        <td>{expenseList.remark}</td>

                        <td className='text-center'>
                          <button
                            type='button'
                            className='btn btn-sm btn-primary ml-2'
                          >
                            Transaction
                          </button>
                        </td>
                      </tr>

                      {/* <tr
                                                        className="bg-light"
                                                        id={"toggle" + expenseList._id}
                                                        hidden>
                                                        <td colspan="12">
                                                            <div>
                                                                <div class="row">
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-3">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Account
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Type
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Date
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <label
                                                                            style={{ fontSize: "15px" }}
                                                                            class="text-dark">
                                                                            Amount
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {relatedLists
                                                                    ? relatedLists.map((reList, i) => (
                                                                        <div class="row">
                                                                            <div class="col-md-2">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                    }}>
                                                                                    {++i}
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                    }}>
                                                                                    {
                                                                                        reList.relatedAccounting
                                                                                            .name
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                {reList.type}
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                    }}>
                                                                                    {reList.date.split("T")[0]}
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                    }}>
                                                                                    {reList.amount}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                    </tr> */}
                    </tbody>
                  ))}
                </table>
              </div>
            ) : show === 'cogs' ? (
              <div className='card-body'>
                <table id='example1' className='table'>
                  <thead className='ok'>
                    <tr>
                      <th>No</th>
                      <th>Code</th>
                      <th>Type</th>
                      {/* <th>Related Treatment</th> */}
                      <th>Header</th>

                      <th>Name</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {cogsList.map((element, i) => (
                    <tbody className=''>
                      <tr>
                        <td>{++i}</td>
                        <td>{element.code ? element.code : ''}</td>
                        <td>
                          {element.relatedType ? element.relatedType.name : ''}
                        </td>
                        <td>
                          {element.relatedHeader
                            ? element.relatedHeader.name
                            : ''}
                        </td>
                        <td>{element.name ? element.name : ''}</td>
                        <td>{element.amount ? element.amount : ''}</td>
                        <td className='text-center'>
                          <button class='btn btn-primary btn-sm'>
                            Related
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            ) : (
              <div className='card-body'>
                {/* Export data in Excel */}

                {/* Export Data in Excel end */}
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='table-responsive text-black' id='slimtest2'>
                      <table className='table table-hover' id='filter_date'>
                        <thead className='ok'>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Bank / Cash Account</th>
                            <th>Account</th>
                            <th>Balance</th>
                            <th>Currency</th>
                            <th>Remark</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {incomeList.map((incomeList, i) => (
                          <tbody className=''>
                            <tr>
                              <td>{++i}</td>
                              <td>{incomeList.date.split('T')[0]}</td>
                              <td>
                                {incomeList.relatedBankAccount
                                  ? incomeList.relatedBankAccount.name
                                  : ''}
                                {incomeList.relatedCashAccount
                                  ? incomeList.relatedCashAccount.name
                                  : ''}
                              </td>

                              <td>{incomeList.relatedAccounting.name}</td>

                              <td>
                                {incomeList.finalAmount
                                  ? incomeList.finalAmount.toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                    }
                                  )
                                  : ''}
                              </td>
                              <td>{incomeList.finalCurrency}</td>
                              <td>{incomeList.remark}</td>

                              <td className='text-center'>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary ml-2'
                                >
                                  Transaction
                                </button>
                              </td>
                            </tr>

                            <tr
                              className='bg-light'
                              id={'toggle' + incomeList._id}
                              hidden
                            >
                              <td colspan='12'>
                                <div>
                                  <div class='row'>
                                    <div class='col-md-2'>
                                      <label
                                        style={{ fontSize: '15px' }}
                                        class='text-dark'
                                      >
                                        No
                                      </label>
                                    </div>
                                    <div class='col-md-3'>
                                      <label
                                        style={{ fontSize: '15px' }}
                                        class='text-dark'
                                      >
                                        Account
                                      </label>
                                    </div>
                                    <div class='col-md-2'>
                                      <label
                                        style={{ fontSize: '15px' }}
                                        class='text-dark'
                                      >
                                        Type
                                      </label>
                                    </div>
                                    <div class='col-md-2'>
                                      <label
                                        style={{ fontSize: '15px' }}
                                        class='text-dark'
                                      >
                                        Date
                                      </label>
                                    </div>
                                    <div class='col-md-2'>
                                      <label
                                        style={{ fontSize: '15px' }}
                                        class='text-dark'
                                      >
                                        Amount
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showPrint && (
              <div className='printdiv card offset-2 col-8 px-2 text-center text-bold fs-7 mb-3'>
                <div className='mt-2' id='printarea'>
                  <h5 className='text-bold text-center'>
                    Profit & Loss Report
                  </h5>
                  <div
                    className='row ml-5 py-3'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Date</div>
                    <div className='offset-3 col-4'>
                      {currentDate.toLocaleString()}
                    </div>
                  </div>

                  <div
                    className='row ml-5'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Reported By</div>
                    <div className='offset-3 col-4'>{RName}</div>
                  </div>
                  <div
                    className='row ml-5 py-3'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Medical Sales</div>
                    <div className='offset-3 col-4'>
                      {mstotal}
                      MMK
                    </div>
                  </div>
                  <div
                    className='row ml-2 py-3'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Treatemant Vouchers</div>
                    <div className='offset-3 col-4'>{tvtotal} MMK</div>
                  </div>
                  <div
                    className='row ml-2 py-3'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Income</div>
                    <div className='offset-3 col-4'>{incometotal} MMK</div>
                  </div>
                  <div
                    className='row ml-2 py-3'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Expenses</div>
                    <div className='offset-3 col-4'>{expensetotal} MMK</div>
                  </div>

                  <div style={{ borderBottom: '2px solid' }}></div>

                  <div
                    className='row ml-2 py-3'
                    style={{
                      textAlign: 'right',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <div className='col-3'>Net Profit</div>
                    <div className='offset-3 col-4'>{profittotal} MMK</div>
                  </div>

                  <div
                    className='row py-3 mt-5 d-flex justify-content-between'
                    style={{
                      textAlign: 'center',
                      fontSize: '20px',
                      marginTop: '20em'
                    }}
                    id='printtop
'
                  >
                    <div className='col-3 '>
                      Checked By
                      <div
                        className='mt-5 '
                        style={{ borderBottom: '1px solid' }}
                      ></div>
                    </div>
                    <div className='col-3'>
                      Approved By
                      <div
                        className='mt-5'
                        style={{
                          borderBottom: '1px solid'
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className='mt-3'>
                  <button
                    onclick="print('printarea')"
                    id='print'
                    onClick={print}
                    className='ml-1 col-2 btn btn-sm btn-success mb-2 mt-2'
                  >
                    Print
                  </button>
                </div>
              </div>
            )}

            {hideBtn && (
              <button
                onClick={showPrintPage}
                className='offset-5 col-1 btn btn-sm btn-success mb-2 mt-2'
              >
                Print
              </button>
            )}
          </div>

          {/* <MedicineSaleDialog
            open={open}
            close={() => setOpen(false)}
            setOpen={setOpen}
            grand={grand}
          /> */}
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}

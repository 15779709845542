import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import axios from 'axios'
import Swal from 'sweetalert2'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'
const AddCreditDialog = props => {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [pay, setPay] = useState('')
  const [isShow, setIsShow] = useState(true)

  const save = () => {
    const data = {
      id: props.id,
      creditAmount: props.credit,
      payAmount: -pay
    }
    api
      .put('suppliers/pay', data)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    props.close()
  }

  return (
    <div>
      {isShow && (
        <Dialog open={props.open} onClose={props.close}>
          <DialogTitle className='text-center'>
            <b>Pay Credit Amount</b>
          </DialogTitle>
          <DialogContent>
            <div className='row form-group'>
              <div className='col-12'>
                <label htmlFor=''>Credit Amount:</label>
                <input
                  type='number'
                  className='form-control'
                  value={Math.abs(props.credit)}
                />
              </div>
              <div className='col-12 mt-2'>
                <label htmlFor=''>Pay Amount:</label>
                <input
                  type='number'
                  className='form-control'
                  onChange={e => setPay(e.target.value)}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button onClick={save}>Pay</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default AddCreditDialog

import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { useState, useEffect } from 'react'

import axios from 'axios'
import { useSelector } from 'react-redux'

export default function TreatmentDeferDialog (props) {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [defferAmount, setDefferAmount] = useState('')

  const [date, setDate] = useState('')
  const [remark, setRemark] = useState('')
  const [firstAccount, setFirstAccount] = useState([])
  const [secAccount, setSecAccount] = useState([])
  const [relatedPatient, setRelatedPatient] = useState([])
  const [rpatient, setRPatient] = useState('')

  //setting up for data

  const DefferCreate = () => {
    const jsonData = {
      remark: remark,
      leftOverAmount: props.leftAmo,
      deferredAmount: defferAmount,
      deferredDate: date,
      firstAccount: '641a7ff89bcfcd3c9cc7c4e4',
      secAccount: '641bd74204aa5b0aa8ca3646',
      relatedPatientTreatment: rpatient
    }
    // alert(JSON.stringify(jsonData));
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    const res = api.post('defer', jsonData, config).then(function (response) {
      alert('success')
    })

    props.setOpen(false)
  }

  useEffect(() => {
    const getAccountingLists = async () => {
      const res = await api.get('accounting-lists')
      const FirAcc = res.data.list.filter(
        e => e.name == 'Deferred Treatment Sale'
      )
      setFirstAccount(FirAcc)
      const SecAcc = res.data.list.filter(e => e.name === 'Treatment Sale')
      setSecAccount(SecAcc)
    }

    const getPatientTreatment = async () => {
      const res = await api.get('patient-treatments')

      setRelatedPatient(res.data.list)
    }

    // getBankLists();

    getAccountingLists()
    getPatientTreatment()
  }, [])

  return (
    <div>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle>
          {' '}
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Deffer</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <form>
            <div className='form-group mt-3'>
              <label className='control-label'>Related Patient Treatment</label>

              <select
                className='form-control'
                name='re_patient'
                onChange={e => setRPatient(e.target.value)}
              >
                <option value=''>Select Patient Treatment</option>

                {/* {relatedPatient.map((option) => (
                  <option value={option._id}>
                    {option.relatedTreatment.treatmentName}
                  </option>
                ))} */}
              </select>
            </div>
            <div className='form-group mt-3'>
              <label className='control-label'>First Account</label>

              <select
                className='form-control'
                name='fir_acc'
                onChange={e => setFirstAccount(e.target.value)}
              >
                {/* <option value="">Select Account</option> */}

                {firstAccount.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div className='form-group mt-3'>
              <label className='control-label'>Second Account</label>

              <select
                className='form-control'
                name='fir_acc'
                onChange={e => setSecAccount(e.target.value)}
              >
                {/* <option value="">Select Account</option> */}

                {secAccount.map(option => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div className='form-group mt-3'>
              <label>Left Over Amount</label>
              <input
                type='number'
                name='left_amo'
                id=''
                className='form-control'
                value={props.leftAmo}
                defaultValue={props.leftAmo}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Deffered Amount</label>
              <input
                type='number'
                name='def_amo'
                id=''
                className='form-control'
                onChange={e => setDefferAmount(e.target.value)}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Deffered Date</label>
              <input
                type='date'
                name='date'
                id=''
                className='form-control'
                onChange={e => setDate(e.target.value)}
              />
            </div>
            <div className='form-group mt-3'>
              <label>Remark</label>
              <input
                type='text'
                name='remark'
                id=''
                className='form-control'
                onChange={e => setRemark(e.target.value)}
              />
            </div>

            <div className='row'>
              <div className='col-md-9 mt-4'>
                <button
                  type='submit'
                  className='btn btn-success'
                  onClick={DefferCreate}
                >
                  Submit
                </button>
                <button
                  type='button'
                  className='btn btn-inverse btn-dismiss'
                  data-dismiss='modal'
                  onClick={props.close}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { Input, Spacer } from '@nextui-org/react'
import apiInstance from '../../../../utils/api'
export default function BankInfoDialog(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [gender, setGender] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [position, setPosition] = useState('')
  const [branch, setBranch] = useState('')
  const [branchName, setBranchName] = useState('')

  const [branchLists, setBranchLists] = useState([])

  // const handleBranch = event => {
  //   // console.log(event, 'b')
  //   setBranch(event.split(',')[0])
  //   setBranchName(event.split(',')[1])
  // }
  const Create = () => {
    const data = {
      givenName: name,
      email: email,
      password: password,
      address: address,
      position: position,
      role: role,
      phone: phone,
      gender: gender
      // branch: branch,
      // branchName: branchName
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.post('user', data, config).then(function (response) {
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })

      props.setUserLists([...props.userLists, response.data.list])
    })
    props.close()
  }

  useEffect(() => {
    // const getBranchLists = async () => {
    //   try {
    //     const res = await apiInstance.get('branches')
    //     setBranchLists(res.data.list)
    //     console.log(res.data.list, 'branch list')
    //   } catch (err) {
    //     alert(err.message)
    //   }
    // }
    // getBranchLists()
  }, [])

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='sm'>
        <DialogTitle>
          {' '}
          <div className='modal-header ok'>
            <h4 className='modal-title'>Create New Employee</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          {/* @csrf */}
          <div className='row'>
            <div className='col-6'>
              <Spacer y={1.5} />

              <div class='form-group'>
                <Input
                  labelPlaceholder='Name'
                  type='text'
                  color='primary'
                  className='form-control border border-info'
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </div>
            <div className='col-6'>
              <Spacer y={1.5} />
              <div class='form-group'>
                <Input
                  type='email'
                  color='primary'
                  labelPlaceholder='Email'
                  className='form-control border border-info'
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <Spacer y={1.5} />
              <div class='form-group'>
                <Input
                  type='password'
                  color='primary'
                  labelPlaceholder='Password'
                  className='form-control border border-info'
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className='col-6'>
              <Spacer y={1.5} />
              <div class='form-group'>
                <Input
                  type='phone'
                  color='primary'
                  labelPlaceholder='Phone'
                  className='form-control border border-info'
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Spacer y={1.5} />
              <div class='form-group'>
                <Input
                  type='text'
                  color='primary'
                  labelPlaceholder='Address'
                  className='form-control border border-info'
                  onChange={e => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div className='col-6'>
              <Spacer y={1.5} />
              <div class='form-group'>
                <Input
                  type='text'
                  color='primary'
                  labelPlaceholder='Position'
                  className='form-control border border-info'
                  onChange={e => setPosition(e.target.value)}
                />
              </div>
            </div>
          </div>

          <Spacer y={1.5} />
          <div class='form-group'>
            <label>Gender</label>
            <select
              class='custom-select border-info'
              name='account_type_id'
              id='cat'
              onChange={e => setGender(e.target.value)}
            >
              <option hidden>Choose </option>
              <option value='Male'>Male</option>
              <option value='Female'>Female </option>
            </select>
          </div>

          <Spacer y={1.5} />
          <div class='form-group'>
            <label>Role</label>
            <select
              class='custom-select border-info'
              name='account_type_id'
              id='cat'
              onChange={e => setRole(e.target.value)}
            >
              <option hidden>Choose </option>
              <option value='Doctor'>Doctor</option>
              <option value='User'>User</option>
              <option value='Admin'>Admin </option>
            </select>
          </div>

          {/* <div class='form-group'>
            <label>Branch</label>
            <select
              class='custom-select border-info'
              name='account_type_id'
              id='cat'
              onChange={e => handleBranch(e.target.value)}
            >
              <option hidden>Choose </option>
              {branchLists.map(option => (
                <option value={option._id + ',' + option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div> */}

          <div class='modal-footer'>
            <Button
              class='btn btn-secondary'
              data-dismiss='modal'
              onClick={props.close}
            >
              Close
            </Button>
            <Button class='btn btn-primary' onClick={Create}>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

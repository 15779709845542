import TreatmentDeferDialog from '../views/TreatmentDeferDialog'
import IncomeDialog from '../views/IncomeDialog'
import React from 'react'
import axios from 'axios'
import SideBar from './SideBar'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { FaCashRegister, FaFileMedical } from 'react-icons/fa'
import HeaderName from './HeaderName'
import { useSelector } from 'react-redux'

function Treatment () {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [outStandings, setOutStandings] = useState([])
  const [wellDone, setShowWellDone] = useState([])
  const [treatmentLists, setTreatmentLists] = useState([])
  const [showTreatmentLists, setShowTreatmentLists] = useState(true)
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [leftAmo, setLeftAmo] = useState('')
  const [showOut, setShowOut] = useState(false)
  const [showWell, setShowWell] = useState(false)

  const showDialog = val => {
    setOpen(true)
    setLeftAmo(val)
  }

  const handleDelete = event => {
    api
      .delete('treatment-list/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = treatmentLists.filter(item => item._id !== event)
        setTreatmentLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleOutStandingChange = () => {
    setShowOut(true)
    setShowWell(false)
    setShowTreatmentLists(false)
  }

  const handleWellDoneChange = () => {
    setShowOut(false)
    setShowWell(true)
    setShowTreatmentLists(false)
  }

  useEffect(() => {
    const getTreatmentList = async () => {
      try {
        const res = await api.get('patient-treatments')
        setTreatmentLists(res.data.list)
      } catch (err) {}
    }
    const getOutStandings = async () => {
      try {
        const res = await api.get('patient-treatments/outstanding')
        setOutStandings(res.data.data)
      } catch (err) {}
    }

    const getWellDone = async () => {
      try {
        const res = await api.get('patient-treatments/well-done')
        setShowWellDone(res.data.data)
      } catch (err) {}
    }

    getWellDone()
    getOutStandings()
    getTreatmentList()
  }, [])
  return (
    <div classNameName='App'>
      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active'>
                      Receiveable Treatment List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row'>
                    <div className='col-md-6 ' style={{ marginTop: '20px' }}>
                      <button
                        className='btn btn-primary'
                        onClick={handleOutStandingChange}
                      >
                        Outstanding
                      </button>
                      &nbsp;
                      <button
                        className='btn btn-primary'
                        onClick={handleWellDoneChange}
                      >
                        WellDone
                      </button>
                    </div>
                    <div className='offset-md-1 col-md-5'>
                      <div
                        className='input-group'
                        style={{ marginTop: '15px' }}
                      >
                        <input
                          type='search'
                          className='form-control rounded'
                          id='search_code'
                          placeholder='Enter Treatment Name'
                        />
                        <button
                          type='button'
                          className='btn btn-outline-primary'
                          style={{ height: '0.97cm', marginTop: '0.2cm' }}
                          onclick='acc_code_search()'
                        >
                          search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {showTreatmentLists && (
                  <div className='card-body'>
                    <table id='example1' className='table'>
                      <thead className='text-center bg-info'>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Related Treatment</th>
                          <th>Related Patient</th>
                          <th>Balance</th>
                          <th>Paid Amount</th>
                          <th>Fully Paid</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {treatmentLists.map((treatmentList, i) => (
                        <tbody className=''>
                          <tr>
                            <td>{++i}</td>
                            <td>
                              {treatmentList.date
                                ? treatmentList.date.split('T')[0]
                                : ''}
                            </td>
                            <td>
                              {/* {treatmentList.relatedTreatment.treatmentName} */}
                            </td>
                            <td>
                              {treatmentList.relatedPatient
                                ? treatmentList.relatedPatient.name
                                : ''}
                            </td>
                            <td>
                              {treatmentList.leftOverAmount.toLocaleString()}
                            </td>
                            <td>
                              {treatmentList.paidAmount
                                ? treatmentList.paidAmount.toLocaleString()
                                : ''}
                            </td>
                            <td>{treatmentList.fullyPaid ? 'Yes' : 'No'}</td>

                            <td className='text-center'>
                              <button
                                className='btn btn-primary btn-sm px-3'
                                onClick={() => {
                                  showDialog(treatmentList.leftOverAmount)
                                }}
                              >
                                Defer
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                )}

                {showOut && (
                  <div className='card-body'>
                    <table id='example1' className='table'>
                      <thead className='text-center bg-info'>
                        <tr>
                          <th>No</th>
                          <th>Related Treatment</th>
                          <th>Related Patient</th>
                          <th>Outstanding Balance</th>
                          <th>Paid Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody className='text-center'>
                        {outStandings.map((outstanding, i) => (
                          <tr>
                            <td>{++i}</td>
                            <td>
                              {/* {outstanding.relatedTreatment.treatmentName} */}
                            </td>
                            <td>{outstanding.relatedPatient.name}</td>
                            <td>{outstanding.leftOverAmount}</td>
                            <td>{outstanding.paidAmount}</td>

                            <td>
                              <button class='btn btn-primary btn-sm px-3'>
                                Defer
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {showWell && (
                  <div className='card-body'>
                    <table id='example1' className='table'>
                      <thead className='text-center bg-info'>
                        <tr>
                          <th>No</th>
                          <th>Related Treatment</th>
                          <th>Related Patient</th>
                          <th>WellDone Balance</th>
                          <th>Amount</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      {wellDone.map((wellD, i) => (
                        <tbody className='text-center'>
                          <tr>
                            <td>{++i}</td>
                            <td>
                              {/* {wellD.relatedTreatment.treatmentName} */}
                            </td>
                            <td>{wellD.relatedPatient?.name}</td>
                            <td>{wellD.leftOverAmount}</td>
                            <td>{wellD.paidAmount}</td>

                            <td>
                              <button class='btn btn-primary btn-sm px-3'>
                                Defer
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <TreatmentDeferDialog
            open={open}
            close={() => setOpen(false)}
            setOpen={setOpen}
            leftAmo={leftAmo}
          />
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside classNameName='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Treatment

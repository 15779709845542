import React,{useState} from 'react'
import IncomeCreateAcc from '../views/IncomeCreateAcc'
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import { FaCashRegister, FaFileMedical } from "react-icons/fa";

const Income = () => {
  const [open,setOpen] = useState(false);
  const [close,setClose] = useState(false);

  const showDialog = () =>setOpen(true);
 
  return (
    <div classNameName="App">
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      {/* < className="wrapper"> */}
      {/* <!-- Navbar --> */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="/dashboard">
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>
        <div className="title">
          <h1>Inventory Management </h1>
        </div>

        {/* {{-- <h1 style="font-family:nunito" classNameName="text-center font-weight-bold font-italic text-info ml-auto">Inventory Management</h1> --}} */}

        {/* <!-- end youtube test -->
    <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto"></ul>
      </nav>
      {/* <!-- /.navbar --> */}

      {/* <!-- Main Sidebar Container --> */}
      <SideBar />

      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        {/* <!-- Content Header (Page header) --> */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Income List</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Main content --> */}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row justify-content-between">
                      <label className="">
                        Account List
                        <span className="float-right">
                          {" "}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={showDialog}>
                            <i className="fas fa-plus"></i> Create Accounting
                          </button>
                          &nbsp;
                        </span>
                      </label>
                    </div>
                    <div className="row" id="trial_balance"></div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="table-responsive text-black"
                          id="slimtest2">
                          <table className="table table-hover" id="filter_date">
                            <thead className="bg-info text-white">
                              <tr>
                                <th>#</th>
                                <th className="text-center">Account Name</th>
                                <th className="text-center">Account Code</th>
                                <th className="text-center">Project Name</th>
                                <th className="text-center">Related Project</th>
                              </tr>
                            </thead>

                            <tbody>
                              {/* <?php $i = 1; ?>
                            @foreach ($expense_tran as $trans)
                            @if($trans->type_flag == 3) */}
                              <tr>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  1
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  Staff Loan2-21007
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  001
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  MOC
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  Yes
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  2
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  Staff Loan2-21007
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  002
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  -
                                </td>
                                <td
                                  style={{ fontSize: "15px" }}
                                  className="text-center">
                                  No
                                </td>
                              </tr>

                              {/* <tr> */}
                              {/* <td></td> */}

                              {/* <td colspan="6">
                                    <div className="collapse out container mr-5" id="related{{$trans->id}}">
                                        <div class="row"> */}
                              {/* <?php $j=1 ?>
                                            @foreach($bank_cash_tran as $transa)
                                            @if($trans->related_transaction_id == $transa->id)
                                            @if($transa->type_flag == 4) */}
                              {/* <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">No</label>
                                                <div style="font-size:15px;">{{$j++}}</div>

                                            </div>
                                            <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">Account</label>

                                                <div style="font-size:15px;">{{$transa->accounting->account_code}}-({{$transa->accounting->account_name}})</div>


                                            </div>
                                            <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">Type</label>
                                                <div style="font-size:15px;">{{$transa->type}}</div>

                                            </div>
                                            <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">Date</label>

                                                <div style="font-size:15px;">{{$transa->date}}</div>

                                            </div>
                                            <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">Amount</label>

                                                <div style="font-size:15px;">{{$transa->amount}}</div>

                                            </div> */}
                              {/* @if ($transa->project_id == null) */}
                              {/* <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">Projected Related</label>
                                                <div style="font-size:15px;">No</div>

                                            </div> */}
                              {/* @else */}
                              {/* <div class="col-md-2">
                                                <label style="font-size:15px;" class="text-info">Projected Related</label>
                                                <div style="font-size:15px;">Yes</div>

                                            </div>
                                            @endif */}

                              {/* @endif
                                            @endif
                                           @endforeach */}
                              {/* </div> */}
                              {/* </div> */}

                              {/* </td> */}
                              {/* </tr> */}
                            </tbody>

                            {/* @endif
                        @endforeach */}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <IncomeCreateAcc open={open} close={() => setOpen(false)} />
          </div>
        </section>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className="main-footer">
        <strong>
          Copyright &copy; 2017-2020{" "}
          <a href="http://www.kwintechnologies.com">K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  );
}

export default Income
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import { FaSearch } from 'react-icons/fa'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'


export default function ProfitLossDialog(props) {
    const months = [
        { name: 'January', value: 'Jan' },
        { name: 'February', value: 'Feb' },
        { name: 'March', value: 'Mar' },
        { name: 'April', value: 'Apr' },
        { name: 'May', value: 'May' },
        { name: 'June', value: 'Jun' },
        { name: 'July', value: 'Jul' },
        { name: 'August', value: 'Aug' },
        { name: 'September', value: 'Sep' },
        { name: 'October', value: 'Oct' },
        { name: 'November', value: 'Nov' },
        { name: 'December', value: 'Dec' }
    ];

    const token = localStorage.getItem('token')
    const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
    const api = axios.create({
        baseURL: url,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    const [monthValue, setMonthValue] = useState('');
    const [bankList, setBankList] = useState([])
    const [cashList, setCashList] = useState([])
    const [accountingList, setAccountingList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [showBank, setShowBank] = useState(false)
    const [showCash, setShowCash] = useState(false)
    const [medicineSale, setMedicineSale] = useState([])

    const [initialAmount, setInitialAmount] = useState('')
    const [relatedBankAcc, setRelatedBankAcc] = useState('')
    const [relatedCashAcc, setRelatedCashAcc] = useState('')
    const [relatedAccounting, setRelatedAccounting] = useState('')
    const [initialCurrency, setInitialCurrency] = useState('')
    const [date, setDate] = useState('')
    const [remark, setRemark] = useState('')
    const [finalAmount, setFinalAmount] = useState('')
    const [finalCurrency, setFinalCurrency] = useState('')
    const [currency, setCurrency] = useState('')

    const _export = React.useRef(null)
    const _export1 = React.useRef(null)
{console.log(props.monthName,'here')}
    const excelExport = () => {
        console.log('clinic1', _export.current)
        if (_export.current !== null) {
            console.log(_export.current.props.data, 'clinic')
            _export.current.props.data.map(function (element, index) {
                element.date = element.date ? element.date.split('T')[0] : ''
            })
            _export.current.save()
        }
    }

    const excelExport1 = () => {
        console.log('here')
        if (_export1.current !== null) {
            console.log(_export1.current.props.data, 'data')
            _export1.current.props.data.map(function (element, index) {
                element.date = element.date ? element.date.split('T')[0] : ''
            })
            _export1.current.save()
        }
    }

    const expenseCreate = () => {
        //  event.preventDefault();
        const jsonData = {
            relatedAccounting: relatedAccounting,
            date: date,
            initialAmount: initialAmount,
            initialCurrency: initialCurrency,
            finalAmount: finalAmount,
            finalCurrency: finalCurrency,
            remark: remark,
            finalCurrency: finalCurrency
        }

        if (relatedCashAcc) jsonData.relatedCashAccount = relatedCashAcc
        if (relatedBankAcc) jsonData.relatedBankAccount = relatedBankAcc
        // alert(JSON.stringify(jsonData));
        const config = {
            headers: { 'Content-Type': 'application/json' }
        }

        api
            .post('expense', jsonData, config)
            .then(function (response) {
                // alert("success");
                setFinalAmount('')
                Swal.fire({
                    title: 'Successful!',
                    text: 'You Created Expense Data!',
                    icon: 'success',
                    // showCancelButton: true,

                    cancelButtonText: 'Close'
                })

                // console.log(
                //   response.data.data
                // );

                props.setExpenseLists([...props.expenseLists, response.data.data[0]])
            })
            .catch(function (err) {
                Swal.fire({
                    title: 'Something Wrong!',
                    text: 'Try again, Please.',
                    icon: 'warning',
                    // showCancelButton: true,

                    cancelButtonText: 'Close'
                })
            })

        props.close()
    }

    const handleSearch = async () => {
        props.handleNoteModal(props.noteID, monthValue)
    }

    useEffect(() => {
        const getCashLists = async () => {
            try {
                const res = await api.get('accounting-lists')

                const cash = res.data.list.filter(
                    el =>
                        el.relatedHeader.name == 'Cash in Hand' &&
                        el.relatedType.name === 'Assets'
                )
                setCashList(cash)
            } catch (err) { }
        }

        const getBankLists = async () => {
            try {
                const res = await api.get('accounting-lists')

                const bank = res.data.list.filter(
                    el =>
                        el.relatedHeader.name == 'Cash at Bank' &&
                        el.relatedType.name === 'Assets'
                )
                setBankList(bank)
            } catch (err) { }
        }

        const getInitialCurrency = async () => {
            try {
                const res = await api.get(`currency/${initialCurrency}`)
                // console.log(res)
                setCurrency(res.data.list)
            } catch (err) { }
        }
        const getAccountingLists = async () => {
            try {
                const res = await api.get('accounting-lists')
                const medicineSale = res.data.list
                setInitialAmount(medicineSale[0].amount)
                // setFinalAmount(medicineSale[0].amount);
                setMedicineSale(medicineSale)
                // setAccountingList(res.data.list);
                // set(
                //   res.data.list.filter((e) => e.relatedHeader.name == "Current Assets")
                // );
            } catch (err) { }
        }
        const getCurrencyLists = async () => {
            try {
                const res = await api.get('currencies')
                // const currency = res.data.list.filter((e)=>e.code=='MMK')
                setCurrencyList(res.data.list)
                setInitialCurrency(currencyList.initialCurrency)
                setFinalCurrency(currencyList.finalCurrency)
            } catch (err) { }
        }

        getCashLists()
        getAccountingLists()
        getInitialCurrency()
        getCurrencyLists()
        getBankLists()
    }, [])

    const updatedSurgeryTable = props.surgeryTabel.map((row, index) => ({
        ...row,
        ...(index === props.surgeryTabel.length - 1 && { surgeryTotal: props.surgeryTotal, monthName: props.monthName }),
    }));

    const updatedClinicTable = props.clinicTable.map((row, index) => ({
        ...row,
        ...(index === props.clinicTable.length - 1 && { clinicTotal: props.clinicTotal, monthName: props.monthName }),
    }));

    return (
        <div>
            <Dialog open={props.open} onClose={props.close}>
                <DialogTitle>
                    {' '}
                    <div className='modal-header'>
                        <h4 className='modal-title'>Note Details</h4>
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={props.close}
                        >
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent style={{ width: '600px' }}>
                    <DialogContentText></DialogContentText>
                    <div className='row card-body '>
                        <div className='col-7 mt-1'>
                            <h5 className='control-label'>{props.noteName}</h5>
                        </div>
                        <div className='col-5 mt-1 input-group'>
                            <select
                                className='form-control'
                                name='month'
                                id='month'
                                onChange={(e) => setMonthValue(e.target.value)}
                            >
                                <option hidden> Select Month</option>
                                {months.map(item => (
                                    <option value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <Button variant='contained' className='input-group-prepend' onClick={() => handleSearch()}>
                                <FaSearch />
                            </Button>
                        </div>
                    </div>
                    <div className='card-body'>
                        {console.log('month',props.monthName)}
                        {props.clinicTable ? (
                            <>
                            {console.log(updatedClinicTable)}
                                <ExcelExport data={updatedClinicTable} ref={_export}>
                                    <ExcelExportColumn
                                        field=''
                                        title='No'
                                        locked={true}
                                        width={30}
                                    />

                                    <ExcelExportColumn
                                        field='operator'
                                        title='Operator'
                                        headerCellOptions={{
                                            textAlign: 'center'
                                        }}
                                        width={200}
                                    />
                                    <ExcelExportColumn
                                        field='name'
                                        title='Account Name'
                                        headerCellOptions={{
                                            textAlign: 'center'
                                        }}
                                        width={200}
                                    />

                                    <ExcelExportColumn
                                        field='amount'
                                        title='Amount'
                                        headerCellOptions={{
                                            textAlign: 'center'
                                        }}
                                        width={250}
                                    />

                                    <ExcelExportColumn
                                        field='clinicTotal'
                                        title='Total'
                                        width={200}
                                    />
                                    <ExcelExportColumn
                                        field='monthName'
                                        title='Month'
                                        width={200}
                                    />

                                </ExcelExport>
                                <div className='d-flex flex-row justify-content-between'>
                                    <label className=''>First Notes</label>
                                    <div className=''>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-success'
                                            onClick={excelExport}
                                        >
                                            Export
                                        </button>
                                    </div>
                                </div>
                                <table id='noteTable' className='table'>
                                    <thead className='text-justify bg-info'>
                                        <tr>
                                            <th>Account Name</th>
                                            <th>Amount</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>

                                    <tbody className='text-justify'>
                                        {props.clinicTable.map(item => (
                                            <tr>
                                                <td>{item.operator === 'Minus' ? 'Less:' + item.name : item.name}</td>
                                                <td>{item.amount}</td>
                                                <td></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td></td>
                                            <td>Total</td>
                                            <td>{props.clinicTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        ) : <></>}
                    </div>

                    {props.surgeryTabel ? (
                        <div className='card-body'>

                            {props.surgeryTabel.length > 0 ? (
                                <>
                                    <ExcelExport data={updatedSurgeryTable} ref={_export1}>
                                        <ExcelExportColumn
                                            field=''
                                            title='No'
                                            locked={true}
                                            width={30}
                                        />
                                        <ExcelExportColumn
                                            field='operator'
                                            title='Operator'
                                            headerCellOptions={{
                                                textAlign: 'center'
                                            }}
                                            width={200}
                                        />
                                        <ExcelExportColumn
                                            field='name'
                                            title='Account Name'
                                            headerCellOptions={{
                                                textAlign: 'center'
                                            }}
                                            width={200}
                                        />

                                        <ExcelExportColumn
                                            field='amount'
                                            title='Amount'
                                            headerCellOptions={{
                                                textAlign: 'center'
                                            }}
                                            width={250}
                                        />

                                        <ExcelExportColumn
                                            field='surgeryTotal'
                                            title='Total'
                                            width={200}
                                        />

                                        <ExcelExportColumn
                                            field='monthName'
                                            title='Month'
                                            width={200}
                                        />
                                    </ExcelExport>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <label className=''>Second Notes</label>
                                        <div className=''>
                                            <button
                                                type='button'
                                                className='btn btn-sm btn-success'
                                                onClick={excelExport1}
                                            >
                                                Export
                                            </button>
                                        </div>
                                    </div>
                                    <table id='noteTable' className='table'>
                                        <thead className='text-justify bg-info'>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Amount</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody className='text-justify'>
                                            {props.surgeryTabel.map(item => (
                                                <tr>
                                                    <td>{item.operator === 'Minus' ? 'Less:' + item.name : item.name}</td>
                                                    <td>{item.amount}</td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td></td>
                                                <td>Surgery Total</td>
                                                <td>{props.surgeryTotal}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </>

                            ) : <></>}
                        </div>

                    ) : ''}
                </DialogContent>
            </Dialog>
        </div >
    )
}

import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { useLocation, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Input } from '@nextui-org/react'

import {
  FaCashRegister,
  FaFileMedical,
  FaPenSquare,
  FaPaperPlane,
  FaRegEdit,
  FaRegTrashAlt
} from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api'

export default function Income() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // //   const url = 'http://localhost:9000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const scroll = {
    height: '600px',
    overflowY: 'scroll'
  }

  //   const scrollY = {
  //     height: '600px',
  //     overflowY: 'scroll'
  //   }

  const sticky = {
    position: 'sticky',
    top: '10px'
  }
  const [stockTranLists, setStockTranLists] = useState([])

  const [customerLists, setCustomerLists] = useState([])
  const [reqCount, setReqCount] = useState('')
  const [showOrigin, setShowOrigin] = useState(true)
  const [showSearchDate, setShowSearchDate] = useState(false)
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [searchByDateList, setSearchByDateList] = useState([])

  const [searchList, setSearchList] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [showOri, setShowOri] = useState(true)
  // const [branchesLists, setBranchesLists] = useState([])
  // const [branchFilterLists, setBranchFilterLists] = useState([])
  // const [showBranchFilter, setShowBranchFilter] = useState(false)
  const [reqID, setReqID] = useState('')
  const TreatId = useLocation().pathname.split('/')[2]

  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) { })
      _export.current.save()
    }
  }
  // const handleChoose = event => {
  //   console.log(event, 'branch')

  //   setBranchFilterLists(
  //     customerLists.filter(el => el.relatedBranch?._id === event)
  //   )
  //   setShowOrigin(false)

  //   setShowBranchFilter(true)
  //   setShowSearchDate(false)
  // }

  const handleFilterDate = () => {
    setSearchByDateList(
      customerLists.filter(
        el => el.date >= from && (el.date = to || el.date < to)
      )
    )

    setShowSearchDate(false)
    setShowSearchDate(true)
    // setShowBranchFilter(false)

    setShowOrigin(false)
  }

  const handleDelete = event => {
    apiInstance
      .delete('stock-request/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        // const result = stockReqLists.filter(item => item._id !== event)
        // setStockReqLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const search = val => {
    if (val) {
      setSearchList(
        customerLists.filter(
          el =>
            el.name.toLowerCase().includes(val.toLowerCase()) ||
            el.patientID.toLowerCase().includes(val.toLowerCase())
        )
      )
      setShowSearch(true)

      setShowSearchDate(false)

      setShowOrigin(false)
    } else {
      setShowSearch(false)

      setShowSearchDate(false)

      setShowOrigin(true)
    }
  }
  useEffect(() => {
    const getStockReqLists = async () => {
      try {
        const res = await apiInstance.get('patients')
        setCustomerLists(res.data.list)
        // setReqCount(res.data.list.length)
        // setTreatmentLists(res.data.list)
        setReqID(res.data.list._id)

        console.log(res.data.list, 'patient')
      } catch (err) {
        alert(err.message)
      }
    }

    const getStockTranLists = async () => {
      try {
        const res = await apiInstance.get(
          'stock-transfers'
          //'http://localhost:9000/apiInstance/treatments'
        )
        setStockTranLists(res.data.list)

        // setTreatmentLists(res.data.list)

        console.log(res.data.list, 'tran')
      } catch (err) {
        alert(err.message)
      }
    }

    getStockTranLists()
    getStockReqLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'></div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <h3 className='text-center'>Customer List</h3>
                <div className='col-6'>
                  <div className='row'>
                    <div className='form-group col-md-3'>
                      <label>From</label>
                      <input
                        type='date'
                        name='from'
                        id='from'
                        onChange={e => setFrom(e.target.value)}
                        className='form-control'
                      />
                    </div>
                    <div className='form-group col-md-3'>
                      <label>To</label>
                      <input
                        type='date'
                        name='to'
                        id='to'
                        onChange={e => setTo(e.target.value)}
                        className='form-control'
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <button
                        className='btn btn btn-primary'
                        style={{ marginTop: '35px' }}
                        onClick={handleFilterDate}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className='offset-2 col-4' style={{ marginTop: '2.3em' }}>
                  <div className='row'>
                    <div className='offset-1 col-md-6'>
                      <Input
                        type='search'
                        className='form-control rounded ml-1'
                        style={{ borderRadius: '12px' }}
                        id='search_code'
                        labelPlaceholder='Search'
                        onChange={e => search(e.target.value)}
                      />
                    </div>
                    <div className='col-md-3 '>
                      <button
                        type='button'
                        className='btn btn-success'
                        onClick={excelExport}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='row justify-content-between'>
                        <span className='float-right'></span>
                      </div>
                      <div className='row' id='trial_balance'></div>
                    </div>

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      <ExcelExport data={customerLists} ref={_export}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='patientID'
                          title='ID'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field='name'
                          title='Name'
                          headerCellOptions={{
                            textAlign: 'center'
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='age'
                          title='Age'
                          headerCellOptions={{
                            justify: 'center'
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='phone'
                          title='Phone'
                          width={150}
                        />
                        <ExcelExportColumn
                          field='gender'
                          title='Gender'
                          width={150}
                        />
                        <ExcelExportColumn
                          field='address'
                          title='Address'
                          width={150}
                        />
                        <ExcelExportColumn
                          field='treatmentPackageQty'
                          title='Treatment Package Qty'
                          width={250}
                        />
                        <ExcelExportColumn
                          field='treatmentPackageQty'
                          title='Total Amount'
                          width={250}
                        />
                        <ExcelExportColumn
                          field='totalAppointments'
                          title='Total Appointments'
                          width={250}
                        />

                        <ExcelExportColumn
                          field='totalAppointments'
                          title='Finished Appointments'
                          width={250}
                        />

                        <ExcelExportColumn
                          field='totalAppointments'
                          title='Unfinished Appointments'
                          width={250}
                        />
                      </ExcelExport>
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div
                            className='table-responsive'
                            id='slimtest2'
                            style={scroll}
                          >
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead
                                className='sticky-top text-white'
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold'
                                }}
                              >
                                <tr>
                                  <td>#</td>
                                  <td>ID</td>
                                  <td>Name</td>
                                  <td>Age</td>
                                  <td>Phone</td>
                                  <td>Gender</td>

                                  <td>Address</td>
                                  <td>Branch</td>
                                  <td>Package Qty</td>
                                  <td>Total Amount</td>
                                  <td>Total Appointments</td>
                                  <td>Finished Appointments</td>

                                  <td>Unfinished Appointments</td>

                                  <td className='text-center'>Action</td>
                                </tr>
                              </thead>

                              {showSearch && (
                                <tbody>
                                  {searchList.map((sq, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {sq.patientID
                                          ? sq.patientID
                                          : 'Not Set'}
                                      </td>
                                      <td>{sq.name ? sq.name : 'Not Set'}</td>
                                      <td>{sq.age ? sq.age : 'Not Set'}</td>
                                      <td>{sq.phone ? sq.phone : 'Not Set'}</td>
                                      <td>
                                        {sq.gender ? sq.gender : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.address ? sq.address : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.relatedBranch
                                          ? sq.relatedBranch.name
                                          : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.treatmentPackageQty
                                          ? sq.treatmentPackageQty
                                          : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.totalAmount
                                          ? sq.totalAmount
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.totalAppointments
                                          ? sq.totalAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.finishedAppointments
                                          ? sq.finishedAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.unfinishedAppointments
                                          ? sq.unfinishedAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td className='text-center flex flex-row'>
                                        <div>
                                          <Link
                                            to={'/sq-Update/' + sq._id}
                                            className='btn btn-sm btn-primary'
                                            disabled
                                          >
                                            Detail
                                          </Link>
                                        </div>
                                        &nbsp;
                                        {/* <div>
                                          <button
                                            className='btn btn-sm btn-danger'
                                            onClick={e => handleDelete(sq._id)}
                                          >
                                            <FaRegTrashAlt />
                                          </button>
                                        </div>
                                        &nbsp; */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}

                              {showOrigin && (
                                <tbody>
                                  {customerLists.map((sq, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {sq.patientID
                                          ? sq.patientID
                                          : 'Not Set'}
                                      </td>
                                      <td>{sq.name ? sq.name : 'Not Set'}</td>
                                      <td>{sq.age ? sq.age : 'Not Set'}</td>
                                      <td>{sq.phone ? sq.phone : 'Not Set'}</td>
                                      <td>
                                        {sq.gender ? sq.gender : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.address ? sq.address : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.relatedBranch
                                          ? sq.relatedBranch.name
                                          : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.treatmentPackageQty
                                          ? sq.treatmentPackageQty
                                          : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.totalAmount
                                          ? sq.totalAmount
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.totalAppointments
                                          ? sq.totalAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.finishedAppointments
                                          ? sq.finishedAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.unfinishedAppointments
                                          ? sq.unfinishedAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td className='text-center flex flex-row'>
                                        <div>
                                          <Link
                                            to={'/sq-Update/' + sq._id}
                                            className='btn btn-sm btn-primary'
                                            disabled
                                          >
                                            Detail
                                          </Link>
                                        </div>
                                        &nbsp;
                                        {/* <div>
                                          <button
                                            className='btn btn-sm btn-danger'
                                            onClick={e => handleDelete(sq._id)}
                                          >
                                            <FaRegTrashAlt />
                                          </button>
                                        </div>
                                        &nbsp; */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}

                              {showSearchDate && (
                                <tbody>
                                  {searchByDateList.map((sq, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {sq.patientID
                                          ? sq.patientID
                                          : 'Not Set'}
                                      </td>
                                      <td>{sq.name ? sq.name : 'Not Set'}</td>
                                      <td>{sq.age ? sq.age : 'Not Set'}</td>
                                      <td>{sq.phone ? sq.phone : 'Not Set'}</td>
                                      <td>
                                        {sq.gender ? sq.gender : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.address ? sq.address : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.relatedBranch
                                          ? sq.relatedBranch.name
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.treatmentPackageQty
                                          ? sq.treatmentPackageQty
                                          : 'Not Set'}
                                      </td>
                                      <td>
                                        {sq.totalAmount
                                          ? sq.totalAmount
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.totalAppointments
                                          ? sq.totalAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.finishedAppointments
                                          ? sq.finishedAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td>
                                        {sq.unfinishedAppointments
                                          ? sq.unfinishedAppointments
                                          : 'Not Set'}
                                      </td>

                                      <td className='text-center flex flex-row'>
                                        <div>
                                          <Link
                                            to={'/sq-Update/' + sq._id}
                                            className='btn btn-sm btn-primary'
                                            disabled
                                          >
                                            Detail
                                          </Link>
                                        </div>
                                        &nbsp;
                                        {/* <div>
                                          <button
                                            className='btn btn-sm btn-danger'
                                            onClick={e => handleDelete(sq._id)}
                                          >
                                            <FaRegTrashAlt />
                                          </button>
                                        </div>
                                        &nbsp; */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Treatement
                open={open}
                close={() => setOpen(false)}
                setTreatmentLists={setTreatmentLists}
                treatmentLists={treatmentLists}
              /> */}
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

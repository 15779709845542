/* eslint-disable */
import SideBar from '../../SideBar.js'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'

import HeaderName from '../../HeaderName'
function CatRegister() {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [packageLists, setPackageLists] = useState([])

  const handleDelete = event => {
    const res = api
      .delete('package/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = packageLists.filter(item => item._id !== event)
        setPackageLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }
  useEffect(() => {
    const getPackage = async () => {
      try {
        const res = await api.get('packages')
        console.log(res.data.list, 'pa list')
        setPackageLists(res.data.list)
      } catch (err) {}
    }
    getPackage()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        <HeaderName />
        <SideBar />
        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2 card-header py-3'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active'></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='offset-10 col-2 mb-3'>
                  <Link to='/packageReg' className='btn btn-primary ml-4'>
                    <i class='fas fa-plus'></i> &nbsp;Register
                  </Link>
                </div>

                <div class='row'>
                  <div class='col-md-12 py-3 card'>
                    <div class='card-header'>
                      <div className='row fs-5' id='trial_balance'>
                        Package List
                      </div>
                    </div>
                    <div class='table-responsive text-black' id='slimtest2'>
                      <table class='table table-hover px-3' id='filter_date'>
                        <thead class='bg-info text-white'>
                          <tr>
                            <th>No.</th>
                            <th>Package Name</th>

                            <th>Treatment</th>
                            <th>Total Price</th>
                            <th>Total Cost</th>

                            <th>Description</th>
                            <th>Status</th>

                            <th className='text-center'>Action</th>
                          </tr>
                        </thead>

                        {packageLists &&
                          packageLists.map((pack, i) => (
                            <tbody className=''>
                              <tr>
                                <td>{++i}</td>
                                <td>{pack.name}</td>
                                <td>Test</td>
                                <td>{pack.description}</td>

                                <td>{pack.totalPrice}</td>
                                <td>{pack.totalCost}</td>
                                <td className=''>
                                  {pack.status == 'Active' ? (
                                    <div className='badge badge-sm badge-success'>
                                      Active
                                    </div>
                                  ) : (
                                    <div className='badge badge-sm badge-warning'>
                                      Pending
                                    </div>
                                  )}
                                </td>
                                <td className='text-center'>
                                  {/* <div>
                                        <UnitUpdate2 TreatId={TreatId} />
                                      </div> */}
                                  <Link
                                    to={'/package-update/' + pack._id}
                                    className='btn btn-sm btn-warning'
                                  >
                                    <FaRegEdit />
                                  </Link>
                                  &nbsp;
                                  <button
                                    className='btn btn-sm btn-danger'
                                    onClick={() => handleDelete(pack._id)}
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="card px-3 py-3">
                      <h5 className="card-header mb-4 text-secondary">
                        Create Reagent
                      </h5>

                      <div class="form-group">
                        <label for="code" className="text-secondary">
                          Code
                        </label>
                        <input
                          type="text"
                          id="code"
                          class="form-control border-info"
                          name="code"
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                      <div class="form-group">
                        <label for="name" className="text-secondary">
                          Name
                        </label>
                        <input
                          type="text"
                          class="form-control border-info"
                          name="name"
                          id="name"
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div class="form-group">
                        <label for="name" className="text-secondary">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="desc"
                          //   ref={(el) => (this.description = el)}
                          onChange={(e) =>
                            setDescription(e.target.value)
                          }></textarea>
                      </div>
                      <div class="form-group">
                        <label for="name" className="text-secondary">
                          Flag
                        </label>
                        <select
                          class="custom-select border-info"
                          name="account_type_id"
                          id="flag"
                          onChange={(e) => setFlag(e.target.value)}>
                          <option></option>
                          <option value="Service">Service</option>
                          <option value="Reagent">Reagent</option>
                        </select>
                      </div>
                      <button
                        className="btn btn-primary form-control text-center fw-5"
                        type="submit"
                        onClick={CategoryCreate}>
                        Save
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <ExpenseDialog
                open={open}
                close={() => setOpen(false)}
                expenseLists={expenseLists}
                setExpenseLists={setExpenseLists}
              /> */}
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default CatRegister

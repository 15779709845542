import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import apiInstance from "../../../../utils/api";
import { useSelector } from "react-redux";

// function getOneItemPerId(array) {
//   const encounteredIds = new Set();
//   return array && array.filter(item => {
//     if (!encounteredIds.has(item.relatedTreatment._id)) {
//       encounteredIds.add(item.relatedTreatment._id);
//       return true;
//     }
//     return false;
//   });
// }

export default function DeferDialog(props) {
  // const token = localStorage.getItem("token");
  // const url = "http://backendcherryk.kwintechnologykw11.com:4000/apiInstance/";
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  // });
  const [arrayList, setArrayList] = useState([])
  const [TVCList, setTVCList] = useState([]);
  // console.log(props.value._id, "img url");
  useEffect(() => {
    const getTreatmentVoucherList = async () => {
      await apiInstance.get("treatment-vouchers").then((res) => {
        setTVCList(res.data.list);
        // console.log(
        //   res.data.list.filter((el) => el._id === props.value._id),
        //   "img List"
        // );
        // console.log(res.data.list, 'img url')
      });
    };
    getTreatmentVoucherList();
  }, [props.value._id]);

  //defer total
  const DeferTotal = props.value?.relatedTreatmentSelection && props.value?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0) === 0 ? props.value?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.totalAmount, 0) : props.value?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.totalAmount, 0) - props.value?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0)
  const sumAmountsById = (data) => {
    const amountById = {};

    // Iterate through the data array
    data && data.map((item) => {
      // console.log(item.relatedTreatment?._id)
      // If the ID already exists in the amountById object, add the amount to the existing total
      if (amountById[item.relatedTreatment?._id]) {
        amountById[item.relatedTreatment?._id] += item.totalAmount;


      } else {
        // Otherwise, create a new entry with the amount for the ID
        amountById[item.relatedTreatment?._id] = item.totalAmount;

      }
    });

    return amountById;
  };

  //same id as one time and then sum their totalAMount
  const amountById = sumAmountsById(props.value.relatedPatient?.relatedTreatmentSelection);
  // console.log(amountById, 'du')

  //to get specific object data
  const obj = Object.entries(amountById).map(([id, totalAmount]) => {
    return {
      id: id,
      total: totalAmount,
    }

  })
  const hee = props.value?.relatedTreatmentSelection && props.value?.relatedTreatmentSelection[0]?.relatedTreatment
  const TreatmentName = props.value && props.value.relatedPatient?.relatedTreatmentSelection.filter((el) => el?.relatedTreatment._id === hee)[0]?.relatedTreatment?.name
  // console.log(TreatmentName, 'du')
  // const uniqueItemsArray1 = getOneItemPerId(hee);

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth='xl'>
        <DialogTitle>
          {" "}
          <div className='modal-header ok'>
            <span className='text-xl align-items-center'>Defer Amount</span>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent className=''>

          {/* First Row */}
          <div className='row text-center'>

            <div className='col-4'>
              <span className='text-lg'>
                <b className='text-decoration-underline'>Voucher Code</b> :
                {props.value.code}
              </span>
            </div>
            <div className='col-4'>
              {" "}
              <span className='text-lg'>
                <b className='text-decoration-underline'>Patient Name</b> :{" "}
                {props.value?.relatedPatient?.name}
              </span>{" "}
            </div>
            <div className='col-4'>
              {" "}
              <span className='text-lg'>
                <b className='text-decoration-underline'>
                  Total Treatment Amount
                </b>{" "}
                : {props.value && props.value.relatedTreatmentSelection.reduce((cal, val) => cal + val.totalAmount, 0)}
              </span>{" "}
            </div>
          </div>
          {/* Second Row */}
          <div className='row p-5'>
            <table className='table table-borderless table-responsive-lg mt-5 col-12'>
              <thead className='text-center'>
                <tr>
                  <th>No</th>

                  <th>Treatment Name</th>
                  <th>Treatment Amount</th>
                  <th>Appointment Times</th>
                  <th>Actual Amount</th>
                  <th>Discount</th>
                  <th>Defer Amount</th>
                </tr>
              </thead>
              {props.value && props.value?.relatedTreatmentSelection.map((item, inde) => (
                <tbody className='text-center'>
                  <tr>
                    <td><p className='text-lg block '>{inde + 1}</p></td>
                    {/* <td><p className='text-lg block '>{item?.VoucherCode}</p></td> */}
                    <td> <p className='text-lg block '>{TreatmentName}</p></td>
                    <td>

                      <span className='text-lg block'>{item.totalAmount}</span>


                    </td>
                    <td><p className='text-lg block'>{item.relatedAppointments.length}</p></td>
                    <td>    <p className='text-lg block'>{item.actualRevenue}</p></td>
                    <td>
                      <p className='text-lg block'>{props.value?.relatedTreatmentSelection[0]?.discount ? (props.value?.relatedTreatmentSelection[0]?.discount / (props.value?.relatedTreatmentSelection.length)).toFixed(2) : 0}</p>
                    </td>
                    <td>

                      <p className='text-lg block'>{item.deferRevenue === 0 ? item.totalAmount : item.deferRevenue}</p>

                    </td>

                  </tr>


                </tbody>

              ))}
              <tbody>
                <tr style={{ border: '1px solid gray' }}></tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td className='text-lg text-center text-bold'>Total : </td>
                  <td className='text-lg text-center text-bold'>{props.value?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.actualRevenue, 0)}</td>
                  <td className='text-lg text-center text-bold'>

                    {props.value?.relatedTreatmentSelection ? (props.value?.relatedTreatmentSelection[0].discount ? Number(props.value?.relatedTreatmentSelection[0].discount).toFixed(2) : 0) : 0}

                  </td>
                  <td className='text-lg text-center text-bold'>

                    {DeferTotal.toFixed(2)}

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
// phyo
//maymyat

import React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../SideBar";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { FaRegEdit, FaRegTrashAlt, FaArrowLeft } from "react-icons/fa";
import HorizontalScroll from "react-horizontal-scrolling";
import HeaderName from "../../HeaderName";
import apiInstance from "../../../../utils/api";
import { useSelector } from "react-redux";
import Footer from "../../footer";
function Unit() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const scroll = {
    height: "550px",
    overflowY: "scroll",
  };

  const [medicineLists, setMedicineLists] = useState("");
  const [unitLists, setUnitLists] = useState([]);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [currentQuantity, setCurrentQuantity] = useState("");
  const [reorder, setReorder] = useState("");
  const [fromUnit, setFromUnit] = useState("");
  const [toUnit, setToUnit] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [totalUnit, setTotalUnit] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [Id, setId] = useState("");

  const [description, setDescription] = useState("");
  const [upCode, setUpCode] = useState("");
  const [upName, setUpName] = useState("");
  const [upDesc, setUpDesc] = useState("");
  const [upCurQTY, setUpCurQTY] = useState("");
  const [upReOrder, setUpReOrder] = useState("");
  const [upFrom, setUpFrom] = useState("");
  const [upTo, setUpTo] = useState("");
  const [upTotal, setUpTotal] = useState("");
  const [upPur, setUpPur] = useState("");
  const [upSell, setUpSell] = useState("");
  const [type, setType] = useState("");

  // const [medicineListName, setMedicineListname] = useState('');
  const medicineListId = useLocation().pathname.split("/")[2];

  const handleDelete = (event) => {
    console.log(event, "delete");
    apiInstance
      .delete("medicine-item/" + event)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Successfully Deleted!",
          icon: "success",
          confirmButtonText: "OK",
        });
        const result = unitLists.filter((item) => item._id !== event);
        setUnitLists(result);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "CANCEL",
        });
      });
  };

  const submit = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleUpdate = (event) => {
    console.log(event, "event");
    const getMedUnitUpdate = async () => {
      try {
        const res = await apiInstance.get("medicine-item/" + event);

        console.log(res.data.data[0]);
        setUpCode(res.data.data[0].code);
        console.log(res.data.data[0].code, "code");

        setUpName(res.data.data[0].medicineItemName);
        setUpCurQTY(res.data.data[0].currentQuantity);
        setUpReOrder(res.data.data[0].reOrderQuantity);
        setUpFrom(res.data.data[0].fromUnit);
        setUpTo(res.data.data[0].toUnit);
        setUpTotal(res.data.data[0].totalUnit);
        setUpPur(res.data.data[0].purchasePrice);
        setUpSell(res.data.data[0].sellingPrice);
        setUpDesc(res.data.data[0].description);
        setType(res.data.data[0].itemType);
      } catch (err) {}
    };
    getMedUnitUpdate();
    setShowUpdate(true);
    setId(event);
  };

  const UnitUpdate = () => {
    const data = {
      id: Id,
      name: medicineListId,
      code: upCode,
      medicineItemName: upName,
      currentQuantity: upCurQTY,
      reOrderQuantity: upReOrder,
      purchasePrice: upPur,
      sellingPrice: upSell,
      fromUnit: upFrom,
      toUnit: upTo,
      totalUnit: upTotal,
      itemType: type,
      description: upDesc,
    };

    const config = {
      headers: { "Content-Type": "application/json" },
    };
    apiInstance.put("medicine-item", data, config).then(function (response) {
      Swal.fire({
        title: "Successful!",
        text: "You Created Income Data!",
        icon: "success",

        cancelButtonText: "Close",
      });
      window.location.reload();

      // setUnitLists([...unitLists,response.data.list[0]])

      // setUnitLists(
      //   unitLists.map(category => {
      //     if (category._id === response.data.list._id) {
      //       return response.data.list
      //     } else {
      //       return category
      //     }
      //   })
      // )
    });
    // .catch(function (err) {
    //   Swal.fire({
    //     title: 'Something Wrong!',
    //     text: 'Try again, Please.',
    //     icon: 'warning',
    //     // showCancelButton: true,

    //     cancelButtonText: 'Close'
    //   })
    // })
    // document.getElementById('desc').value = ''
    // document.getElementById('name').value = ''
    // document.getElementById('code').value = ''
  };

  const handleUpdateUnitCalculation = (event) => {
    console.log(upCurQTY);
    console.log(event);

    if (upCurQTY) {
      let multi = upCurQTY * event;
      let ans = multi / upFrom;

      setUpTotal(ans.toFixed(2));
    }

    setUpTo(event);
  };

  const handleUnitCalculation = (event) => {
    console.log(currentQuantity);
    console.log(event);

    if (currentQuantity) {
      let multi = currentQuantity * event;
      let ans = multi / fromUnit;

      setTotalUnit(ans.toFixed(2));
    }

    setToUnit(event);
  };

  const UnitCreate = () => {
    const data = {
      id: Id,
      name: medicineListId,
      code: code,
      medicineItemName: name,
      currentQuantity: currentQuantity,
      reOrderQuantity: reorder,
      purchasePrice: purchasePrice ? purchasePrice : 0,
      sellingPrice: sellingPrice ? sellingPrice : 0,
      fromUnit: fromUnit,
      toUnit: toUnit,
      totalUnit: totalUnit,
      itemType: type,
      description: description,
    };

    // // console.log(medicineListId);
    // alert(JSON.stringify(data));
    // console.log(data);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    apiInstance
      .post("medicine-item", data, config)
      .then(function (response) {
        Swal.fire({
          title: "Successful!",
          text: "You Created Income Data!",
          icon: "success",

          cancelButtonText: "Close",
        });
        window.location.reload();

        setUnitLists([...unitLists, response.data.data]);
      })
      .catch(function (err) {
        Swal.fire({
          title: "Something Wrong!",
          text: "Try again, Please.",
          icon: "warning",
          // showCancelButton: true,

          cancelButtonText: "Close",
        });
      });
    document.getElementById("description").value = "";
    document.getElementById("name").value = "";
    document.getElementById("code").value = "";
    document.getElementById("cur_qty").value = "";
    document.getElementById("reorder").value = "";
    document.getElementById("purchase").value = "";
    document.getElementById("from").value = "";
    document.getElementById("to").value = "";
    document.getElementById("sellprice").value = "";
    document.getElementById("total").value = "";

    //      props.setOpen(false);
  };
  useEffect(() => {
    const getUnitLists = async () => {
      try {
        const res = await apiInstance.get("medicine-items/" + medicineListId);

        // setUnitLists(res.data.list.filter((el)=>el.name._id == medicineListId))
        setUnitLists(res.data.data);
        // console.log(res.data.data, 'list')
      } catch (err) {}
    };

    const getMedicineLists = async () => {
      try {
        const res = await apiInstance.get("medicine-list/" + medicineListId);
        let setMedicineListname = res.data.data[0].name;
        setMedicineLists(setMedicineListname);
      } catch (err) {
        alert(err.message);
      }
    };

    getMedicineLists();
    getUnitLists();
  }, []);
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/medicine_list'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li
                      className='breadcrumb-item active'
                      style={{ marginTop: "0.2em" }}
                    >
                      <i>Medicine Item Unit List</i>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-header'>{medicineLists}</div>
                    <div className='card-body bg-light' style={scroll}>
                      <table id='' className='table'>
                        <thead className='text-center bg-info sticky-top '>
                          <tr>
                            <th>No</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Current Qty</th>
                            <th>Reorder Qty</th>
                            <th>Purchase Price</th>
                            <th>Selling Price</th>
                            <th>Unit Convention</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {unitLists.map((unit, i) => (
                          <tbody className=''>
                            <tr>
                              <td>{++i}</td>
                              <td>{unit.code}</td>
                              <td>{unit.medicineItemName}</td>
                              <td>
                                {unit.currentQuantity
                                  ? unit.currentQuantity
                                  : "0"}
                              </td>
                              <td>{unit.reOrderQuantity}</td>
                              <td>{unit.purchasePrice}</td>
                              <td>{unit.sellingPrice}</td>

                              <td>{unit.toUnit ? unit.toUnit : "0"}</td>
                              <td>{unit.itemType ? unit.itemType : ""}</td>
                              <td>{unit.description}</td>
                              <td className='text-center'>
                                <button
                                  className='btn btn-sm btn-warning'
                                  onClick={(e) => handleUpdate(unit._id)}
                                >
                                  <FaRegEdit />
                                </button>
                                &nbsp;
                                <button
                                  className='btn btn-sm btn-danger'
                                  onClick={(e) => submit(unit._id)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                {showUpdate ? (
                  <div className='col-md-3'>
                    <div className='card px-3 py-3'>
                      <h3 className='card-header mb-4 fw-5 text-secondary'>
                        Update Item
                      </h3>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Code
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='codename'
                          id='code'
                          value={upCode}
                          onChange={(e) => setUpCode(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Name
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='balance'
                          id='name'
                          value={upName}
                          onChange={(e) => setUpName(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Current Quantity
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='cur_qty'
                          value={upCurQTY}
                          onChange={(e) => setUpCurQTY(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Reorder Quantity (Optional)
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='reorder'
                          value={upReOrder}
                          onChange={(e) => setUpReOrder(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Unit Convention (Optional)
                        </label>
                        <div className='row'>
                          <div className='col-md-6'>
                            <input
                              type='number'
                              class='form-control border-info'
                              name='balance'
                              id='from'
                              value={upFrom}
                              onChange={(e) => setUpFrom(e.target.value)}
                            />
                          </div>
                          <div className='col-md-6'>
                            <input
                              type='number'
                              class='form-control border-info'
                              name='balance'
                              id='to'
                              value={upTo}
                              onChange={(e) =>
                                handleUpdateUnitCalculation(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Total Unit
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='total'
                          value={upTotal}
                        />
                      </div>

                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Purchase Price
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='purchase'
                          value={upPur}
                          onChange={(e) => setUpPur(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Selling Price
                        </label>
                        <div className='row'>
                          <div className='col-md-12'>
                            <input
                              type='number'
                              class='form-control border-info'
                              name='balance'
                              id='sellprice'
                              value={upSell}
                              onChange={(e) => setUpSell(e.target.value)}
                            />
                          </div>
                          {/* <div className="col-md-4">
                          <input
                            type="number"
                            class="form-control border-info"
                            name="balance"
                            id="name"
                            placeholder="%"
                          />
                        </div> */}
                        </div>
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Type
                        </label>
                        <select
                          className='form-control'
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value={type} hidden>
                            {type}
                          </option>
                          <option value='Oral'>Oral</option>
                          <option value='Topical'>Topical</option>
                          <option value='Cosmetic'>Cosmetic</option>
                          <option value='Consignment'>Consignment</option>
                          <option value='Accessories'>Accessories</option>
                        </select>
                      </div>

                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Description
                        </label>
                        <textarea
                          className='form-control'
                          id='description'
                          value={upDesc}
                          onChange={(e) => setUpDesc(e.target.value)}
                        ></textarea>
                      </div>
                      <button
                        className='btn form-control text-center'
                        style={{ backgroundColor: "#0b9490", color: "white" }}
                        onClick={UnitUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='col-md-3'>
                    <div className='card px-3 py-3'>
                      <h3 className='card-header mb-4 fw-5 text-secondary'>
                        Create Item
                      </h3>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Code
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='codename'
                          id='code'
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Name
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='balance'
                          id='name'
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Current Quantity
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='cur_qty'
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setCurrentQuantity(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Reorder Quantity (Optional)
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='reorder'
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setReorder(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Unit Convention (Optional)
                        </label>
                        <div className='row'>
                          <div className='col-md-6'>
                            <input
                              type='number'
                              class='form-control border-info'
                              name='balance'
                              id='from'
                              placeholder='Form Unit'
                              onChange={(e) => setFromUnit(e.target.value)}
                            />
                          </div>
                          <div className='col-md-6'>
                            <input
                              type='number'
                              class='form-control border-info'
                              name='balance'
                              id='to'
                              placeholder='To Unit'
                              onChange={(e) =>
                                handleUnitCalculation(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Total Unit
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='total'
                          //   ref={(el) => (this.name = el)}
                          defaultValue={totalUnit}
                        />
                      </div>

                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Purchase Price
                        </label>
                        <input
                          type='number'
                          class='form-control border-info'
                          name='balance'
                          id='purchase'
                          defaultValue='0'
                          //   ref={(el) => (this.name = el)}
                          onChange={(e) => setPurchasePrice(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Selling Price
                        </label>
                        <div className='row'>
                          <div className='col-md-12'>
                            <input
                              type='number'
                              class='form-control border-info'
                              name='balance'
                              id='sellprice'
                              defaultValue='0'
                              onChange={(e) => setSellingPrice(e.target.value)}
                            />
                          </div>
                          {/* <div className="col-md-4">
                          <input
                            type="number"
                            class="form-control border-info"
                            name="balance"
                            id="name"
                            placeholder="%"
                          />
                        </div> */}
                        </div>
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Type
                        </label>
                        <select
                          className='form-control'
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option hidden>Select Type</option>
                          <option value='Oral'>Oral</option>
                          <option value='Topical'>Topical</option>
                          <option value='Cosmetic'>Cosmetic</option>
                          <option value='Consignment'>Consignment</option>
                          <option value='Accessories'>Accessories</option>
                        </select>
                      </div>

                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Description
                        </label>
                        <textarea
                          className='form-control'
                          id='description'
                          //   ref={(el) => (this.description = el)}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <button
                        className='btn form-control text-center'
                        onClick={UnitCreate}
                        style={{ backgroundColor: "#0b9490", color: "white" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer text-center'>
        <Footer />
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  );
}
export default Unit;

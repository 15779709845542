import MedicineSaleDialog from "./MedicineSaleDialog";
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Swal from "sweetalert2";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { FaCashRegister, FaFileMedical, FaFileExcel } from "react-icons/fa";
import ImageDialog from "./Admin/Treatment Sale/ImageDialog";
import DeferDialog from "./Admin/Treatment Sale/DeferDialog.jsx";
// import { translateRect } from '../../pluginsfullcalendar/main.d';
import HeaderName from "./HeaderName";
import { useSelector } from "react-redux";
import { Button } from "@mantine/core";
import { convertAndDisplayTZ } from "../../utils/momentTime.js";
import apiInstance from "../../utils/api.js";
function TreatementSale() {
  // const token = localStorage.getItem("token");
  // const url = "http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/";

  // //const url = "http://localhost:9000/apiInstance/"
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  // });

  const scroll = {
    height: "600px",
    overflow: "scroll",
    width: "1440px",
  };

  const _export = React.useRef(null);
  const _export1 = React.useRef(null);

  const _export2 = React.useRef(null);
  const _export3 = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data);
      _export.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt
          ? element.createdAt.split("T")[0]
          : "";
        element.doctor = element.relatedDoctor?.name;
        element.type = element.purchaseType;
        element.grandTotal = element.relatedTreatmentSelection[0]?.totalAmount;
        element.paidAmount = !element.relatedTreatmentSelection[0]?.paidAmount
          ? 0
          : element.relatedTreatmentSelection[0]?.paidAmount;

        element.balance =
          element.relatedTreatmentSelection[0]?.totalAmount !==
            element.relatedTreatmentSelection[0]?.paidAmount
            ? element.relatedTreatmentSelection[0]?.totalAmount -
            element.relatedTreatmentSelection[0]?.paidAmount
            : 0;
      });
      _export.current.save();
    }
  };

  const excelExport1 = () => {
    if (_export1.current !== null) {
      console.log(_export1.current.props.data);
      _export1.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt
          ? element.createdAt.split("T")[0]
          : "";

        element.doctor = element.relatedDoctor?.name;
        element.type = element.purchaseType;
        element.grandTotal = element.relatedTreatmentSelection[0]?.totalAmount;
        element.paidAmount = !element.relatedTreatmentSelection[0]?.paidAmount
          ? 0
          : element.relatedTreatmentSelection[0]?.paidAmount;

        element.balance =
          element.relatedTreatmentSelection[0]?.totalAmount !==
            element.relatedTreatmentSelection[0]?.paidAmount
            ? element.relatedTreatmentSelection[0]?.totalAmount -
            element.relatedTreatmentSelection[0]?.paidAmount
            : 0;
      });
      _export1.current.save();
    }
  };

  const excelExport2 = () => {
    if (_export2.current !== null) {
      console.log(_export2.current.props.data, "ex2");
      _export2.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt
          ? element.createdAt.split("T")[0]
          : "";

        element.mul = element.multiTreatment.map((ele) => {
          return {
            name: ele.item_id.name,
            price: ele.price,
            qty: ele.qty,
          };
        });

        element.Name = element.mul.map((i) => i.name).join("/ ");

        element.Qty = element.mul.map((ele) => ele.qty).join("/ ");
        element.Price = element.mul.map((i) => i.price).join("/ ");
      });

      _export2.current.save();
    }
  };

  const excelExport3 = () => {
    if (_export3.current !== null) {
      _export3.current.props.data.map(function (element, index) {
        element.createdAt = element.createdAt
          ? element.createdAt.split("T")[0]
          : "";

        element.mul = element.multiTreatment.map((ele) => {
          return {
            name: ele.item_id.name,
            price: ele.price,
            qty: ele.qty,
          };
        });

        element.Name = element.mul.map((i) => i.name).join("/ ");

        element.Qty = element.mul.map((ele) => ele.qty).join("/ ");
        element.Price = element.mul.map((i) => i.price).join("/ ");
      });
      _export3.current.save();
    }
  };

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const today_date = today.toISOString().split("T")[0];
  console.log(today_date);

  const [showOri, setShowOri] = useState(true);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [payFilterList, setPayFilterList] = useState([]);
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [bankID, setBankID] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [userID, setUserID] = useState("");
  const [open, setOpen] = useState("");
  const [openDefer, setOpenDefer] = useState("");
  const [close, setClose] = useState("");
  const [value, setValue] = useState("");
  const [imgList, setImgList] = useState([]);
  const [purchaseType, setPurchaseType] = useState("");
  const [doctorID, setDoctorID] = useState("");
  const [type, setType] = useState("");
  const [bankTotal, setBankTotal] = useState(0);
  const [cashTotal, setCashTotal] = useState(0);
  const [multiList, setMultiList] = useState([]);
  const [multiCashList, setMultiCashList] = useState([]);

  const [bankIncomeList, setBankIncomeList] = useState([]);
  const [cashIncomeList, setCashIncomeList] = useState([]);
  const [bankNameList, setBankNameList] = useState([]);
  const [cashNameList, setCashNameList] = useState([]);
  const [bcshow, setBCShow] = useState("bankTable");
  const [cashDoctorList, setCashDoctorList] = useState([]);
  const [filterCashDoctorList, setFilterCashDoctorList] = useState([]);
  const [showDocCash, setShowDocCash] = useState(false);
  const [bankDoctorList, setBankDoctorList] = useState([]);
  const [filterBankDoctorList, setFilterBankDoctorList] = useState([]);
  const [showDocBank, setShowDocBank] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [oriList, setOriList] = useState([]);
  const [showType, setShowType] = useState(false);
  const [showTS, setShowTS] = useState(false);
  const [bankType, setBankType] = useState("");
  const [bankFilList, setBankFilList] = useState([]);
  const [cashFilList, setCashFilList] = useState([]);
  const [bankTSList, setBankTSList] = useState([]);
  const [cashTSList, setCashTSList] = useState([]);

  const [showBankTS, setShowBankTS] = useState(false);
  const [showCashTS, setShowCashTS] = useState(false);
  const [showBT, setShowBT] = useState(false);
  const [showCT, setShowCT] = useState(false);
  const [purType, setPurType] = useState("");
  const [deferValue, setDeferValue] = useState("");
  // const paymentFilter = e => {
  //   const filteredArray = todayList.filter(
  //     obj => obj.paymentMethod === e.target.value
  //   )
  //   setPayFilterList(filteredArray)
  //   setShow(true)
  // }

  const showDialog = (val) => {
    const imgPath = imgList.filter((item) => item._id === val);
    console.log(imgPath, "imgPath");
    setValue(imgPath[0].payment ? imgPath[0].payment.imgUrl : "");
    setOpen(true);
  };
  const showDeferDialog = (val) => {
    console.log(val, "detail");
    setDeferValue(val);
    setOpenDefer(true);
  };
  const handleCashDoctor = (event) => {
    setFilterCashDoctorList(cashDoctorList.filter((el) => el._id == event));
    console.log(
      cashDoctorList.filter((el) => el._id == event),
      "doc fil l"
    );
    setShowDocCash(true);
    setShowDocBank(false);
  };
  const handleBankDoctor = (event) => {
    setFilterBankDoctorList(bankDoctorList.filter((el) => el._id == event));
    console.log(
      cashDoctorList.filter((el) => el._id == event),
      "doc fil l"
    );
    setShowDocBank(true);
    setShowDocCash(false);
  };

  const handleAllFilter = async () => {
    // if (fromDate) {
    setShowType(true);
    setShowTS(true);

    const nDate = new Date(endDate);
    const toDate = nDate.setDate(nDate.getDate() + 1);

    try {
      Swal.fire({
        title: "Loading",
        text: "Please wait...",
        allowOutsideClick: false,
      });
      let data = {};
      if (purType) {
        data.paymentMethod = purType;
      }
      if (type) {
        data.tsType = type;
      } else {
      }
      if (bankID) {
        data.bankID = bankID;
      }
      if (bankType) {
        data.bankType = bankType;
      }
      if (startDate && toDate) {
        data.startDate = startDate;
        data.endDate = toDate;
      }
      if (userID) {
        data.createdBy = userID;
      }
      if (doctorID) {
        data.relatedDoctor = doctorID;
      }

      await apiInstance
        .get(`treatment-vouchers/TV-Filter`, { params: data })
        .then((res) => {
          // setAccountLists(res.data.data)
          // setTransactionLists(res.data.transaction)
          // console.log(res.data.data, 'main lis')
          setOriList(res.data.data);
          if (res.data.data.CashList) {
            setCashTotal(res.data.data.CashTotal);

            if (purType) {
              setCashIncomeList(
                res.data.data.CashList.filter((el) => el.tsType === "TS")
              );
            } else {
              setCashIncomeList(
                res.data.data.CashList.filter(
                  (el) =>
                    el.tsType === "TS" ||
                    el.tsType === "TSMulti" ||
                    el.tsType === "Combined" ||
                    el.tsType === "PS"
                )
              );
              setMultiCashList(
                res.data.data.CashList.filter((el) => el.tsType === "TSMulti")
              );
            }

            setShowBT(false);
            setShowCT(false);

            setShowBankTS(false);
            setShowCashTS(false);

            setShowOri(true);
          }
          if (res.data.data.BankList) {
            setBankTotal(res.data.data.BankTotal);

            if (purType) {
              setBankIncomeList(
                res.data.data.BankList.filter((el) => el.tsType === "TS")
              );
            } else {
              setBankIncomeList(
                res.data.data.BankList.filter(
                  (el) =>
                    el.tsType === "TS" ||
                    el.tsType === "TSMulti" ||
                    el.tsType === "Combined" ||
                    el.tsType === "PS"
                )
              );
              setMultiList(
                res.data.data.BankList.filter((el) => el.tsType === "TSMulti")
              );
            }

            setShowBT(false);
            setShowCT(false);

            setShowBankTS(false);
            setShowCashTS(false);

            setShowOri(true);
          }

          setBankNameList(res.data.data);

          console.log(
            res.data.data.BankList.map((i) => i.relatedBank?.name),
            "c NAME"
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
      Swal.close();

      // Process the response data
      //console.log(data);
    } catch (error) {
      Swal.close();
      console.error("Error occurred while fetching data.", error);
    }
  };
  const handleBankTS = (value) => {
    console.log(value, "val");
    if (value) {
      const bankFiltered = bankIncomeList.filter(
        (item) => item.purchaseType === value
      );
      const cashFiltered = cashIncomeList.filter(
        (item) => item.purchaseType === value
      );
      setCashFilList(cashFiltered);

      console.log(bankFiltered, "ban");
      setBankFilList(bankFiltered);
      setShowBankTS(true);
      setShowCashTS(true);
      setShowBT(false);
      setShowCT(false);

      setShowOri(false);
    } else {
      setShowBT(false);
      setShowCT(false);

      setShowBankTS(false);
      setShowCashTS(false);

      setShowOri(true);
    }
  };
  const handleFilterTS = (value) => {
    console.log(value, "val");
    if (value) {
      const bankTS = bankIncomeList.filter((item) => item.tsType === value);
      const cashTS = cashIncomeList.filter((item) => item.tsType === value);
      setCashTSList(cashTS);

      console.log(bankTS, "ban");
      setBankTSList(bankTS);
      setShowBT(true);
      setShowCT(true);
      setShowBankTS(false);
      setShowCashTS(false);

      setShowOri(false);
    } else {
      setShowBankTS(false);
      setShowCashTS(false);
      setShowBT(false);
      setShowCT(false);

      setShowOri(true);
    }
  };

  useEffect(() => {
    const getTreatmentVoucher = async () => {
      const res = await apiInstance.get("treatment-vouchers").then((res) => {
        // setTodayList(res.data.list)
        // console.log(res.data.list, 'data')
      });

      // .catch(error => {
      //   Swal.fire({
      //     title: 'Error',
      //     text: error.response.data.message,
      //     icon: 'error',
      //     confirmButtonText: 'CANCEL'
      //   })
      // })
    };

    const getUsers = async () => {
      apiInstance.get("users").then((res) => {
        setUsers(res.data.list);
      });
    };

    const getDoctors = async () => {
      apiInstance.get("doctors").then((res) => {
        setDoctors(res.data.data);
      });
    };

    const getImageList = async () => {
      await apiInstance.get("treatment-vouchers").then((res) => {
        setImgList(res.data.list);
        // console.log(res.data.list, 'img List')
        // console.log(res.data.list, 'img url')
      });
    };
    const getBankList = async () => {
      await apiInstance.get("accounting-lists").then((res) => {
        setBankList(
          res.data.list.filter(
            (el) =>
              el.relatedType.name == "Assets" &&
              el.relatedHeader.name == "Cash at Bank"
          )
        );

        // console.log(res.data.list, 'img url')
      });
    };
    getBankList();
    getDoctors();
    getUsers();
    getImageList();
    // getBranches()
    getUsers();
    getTreatmentVoucher();
  }, []);

  const BankDeferALlTotal = (showOri
    ? bankIncomeList
    : showBankTS
      ? bankFilList
      : bankTSList
  ).reduce((acc, val) => acc + (val?.relatedTreatmentSelection.reduce((acc, val) => acc + val.deferRevenue, 0) === 0 ? val.relatedTreatmentSelection[0]
    ?.totalAmount : val?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0)), 0)

  const CashDeferALlTotal = (showOri
    ? cashIncomeList
    : showCashTS
      ? cashFilList
      : cashTSList
  ).reduce((acc, val) => acc + (val?.relatedTreatmentSelection.reduce((acc, val) => acc + val.deferRevenue, 0) === 0 ? val.relatedTreatmentSelection[0]
    ?.totalAmount : val?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0)), 0)

  const BankActualALlTotal = (showOri
    ? bankIncomeList
    : showBankTS
      ? bankFilList
      : bankTSList
  ).reduce((acc, val) => acc + (val?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.actualRevenue, 0)), 0)

  const CashActualALlTotal = (showOri
    ? cashIncomeList
    : showCashTS
      ? cashFilList
      : cashTSList
  ).reduce((acc, val) => acc + (val?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.actualRevenue, 0)), 0)
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-5'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active text-bold  fs-5'>
                      Treatment Voucher List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className='row ml-2'>
            <div className='form-group col-md-2'>
              <label>From</label>
              <input
                type='date'
                name='from'
                id='from'
                className='form-control'
                //onChange={e => setStart(e.target.value)}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className='form-group col-md-2'>
              <label>To</label>
              <input
                type='date'
                name='to'
                id='to'
                className='form-control'
                // onChange={e => handleDateFilter(e.target.value)}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div className='form-group col-md-1' style={{ marginTop: "2.6em" }}>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={(e) => setUserID(e.target.value)}
              >
                <option hidden>Issued By</option>
                {users.map((e, i) => (
                  <option value={e._id}>{e.givenName}</option>
                ))}
              </select>
            </div>

            <div className='col-1'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={(e) => setDoctorID(e.target.value)}
                style={{ marginTop: "2.2em" }}
              >
                <option hidden>Doctor</option>
                {doctors.map((option) => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div className='col-2'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={(e) => setBankID(e.target.value)}
                style={{ marginTop: "2.2em" }}
              >
                <option hidden>Select Bank</option>
                {/* <option value='TS'>Treatment Selection</option>
                <option value='TSM'>Multiple Treatment Selection</option>
                <option value='MS'>Medicine Sale</option>
                <option value='Combined'>Combined</option> */}
                {bankList.map((option) => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </select>
            </div>
            <div className='col-md-2'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={(e) => setBankType(e.target.value)}
                style={{ marginTop: "2.2em" }}
              >
                <option hidden>Choose Bank </option>
                <option value='POS'>POS</option>
                <option value='Normal'>Normal</option>
                <option value='Pay'>Pay</option>
              </select>
            </div>
            <div className='col-md-1'>
              <select
                class='custom-select border-info'
                name='account_type_id'
                onChange={(e) => setPurType(e.target.value)}
                style={{ marginTop: "2.2em" }}
              >
                <option hidden>Choose Type </option>
                <option value='by Appointment'>Appointment</option>
                <option value='Lumpsum'>Lumpsum</option>
                <option value='Total'>Total</option>
                <option value='Advanced'>Advanced</option>
                <option value='FOC'>FOC</option>
                <option value='pAdvance'>pAdvance</option>
              </select>
            </div>

            <div className='form-group col-md-1'>
              <button
                className='btn btn-sm btn-primary'
                style={{ marginTop: "38px" }}
                onClick={handleAllFilter}
              >
                Filter
              </button>
            </div>
          </div>
          {/* <!-- Main content --> */}
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  {/* <div className='row'>
                      {bcshow == 'bankTable' ? (
                        <div className='col-12'>
                          <select
                            class='custom-select border-info'
                            name='account_type_id'
                            onChange={e => handleBankDoctor(e.target.value)}
                          >
                            <option hidden>Filter By Doctor</option>
                            {bankDoctorList.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className='col-12'>
                          <select
                            class='custom-select border-info'
                            name='account_type_id'
                            onChange={e => handleCashDoctor(e.target.value)}
                          >
                            <option hidden>Filter By Doctor</option>
                            {cashDoctorList.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div> */}

                  <div className='row'>
                    {showTS && (
                      <div className='col-2'>
                        <select
                          class='custom-select border-info'
                          name='account_type_id'
                          onChange={(e) => handleFilterTS(e.target.value)}
                        >
                          <option hidden>Filter By Type </option>
                          <option value=''>All</option>
                          <option value='TS'>Single Treatment</option>
                          <option value='TSMulti'>Multiple Treatment</option>
                          <option value='PS'>Package</option>
                          <option value='Combined'>Combined</option>
                        </select>
                      </div>
                    )}

                    <div className='col-4' >
                      <div className='row'>
                        {showType && (
                          <div className='col-5'>
                            <select
                              class='custom-select border-info'
                              name='account_type_id'
                              onChange={(e) => handleBankTS(e.target.value)}
                            >
                              <option hidden>Filter By Treatment Type </option>
                              <option value=''>All</option>

                              <option value='Surgery'>Surgery</option>
                              <option value='Clinic'>Asthetic</option>
                            </select>
                          </div>
                        )}

                        <div className='col-4  d-flex justify-content-end'>
                          {bcshow == "bankTable" ? (
                            <>
                              <button
                                type='button'
                                className='btn btn-sm btn-success'
                                onClick={excelExport}
                              >
                                <FaFileExcel />
                                &nbsp;Lists
                              </button>
                              &nbsp;
                              <button
                                type='button'
                                className='btn btn-sm btn-success'
                                onClick={excelExport2}
                              >
                                <FaFileExcel />
                                &nbsp;Items
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type='button'
                                className='btn btn-sm btn-success'
                                onClick={excelExport1}
                              >
                                <FaFileExcel />
                                &nbsp;Lists
                              </button>
                              &nbsp;
                              <button
                                type='button'
                                className='btn btn-sm btn-success'
                                onClick={excelExport3}
                              >
                                <FaFileExcel />
                                &nbsp;Items
                              </button>
                            </>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>


                  {/* Amount Row */}
                  <div className='row mt-4 text-center'>
                    <div className='col-3'>
                      <Button
                        color='cyan'
                        radius='sm'
                        size='sm'
                        onClick={() => setBCShow("bankTable")}
                      >
                        Bank({bankTotal} MMK)
                      </Button>
                    </div>{" "}
                    {bankType ? (
                      <div className='col-4'>
                        <div className='invisible'>
                          <Button
                            color='cyan'
                            radius='sm'
                            size='sm'
                            onClick={() => setBCShow("cashTable")}
                          >
                            Cash({cashTotal} MMK)
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className='col-3'>
                        <div className=''>
                          <Button
                            color='cyan'
                            radius='sm'
                            size='sm'
                            onClick={() => setBCShow("cashTable")}
                          >
                            Cash({cashTotal} MMK)
                          </Button>
                        </div>
                      </div>
                    )}
                    <div className='col-3'>
                      <Button
                        color='cyan'
                        radius='sm'
                        size='sm'
                        className='w-75 p-2'
                      >

                        Deferred Total :{bcshow === "bankTable" ? BankDeferALlTotal : CashDeferALlTotal}
                      </Button>


                    </div>
                    <div className='col-3'>
                      <Button
                        color='cyan'
                        radius='sm'
                        size='sm'
                        className='w-75 p-2'
                      >

                        Actual Total :{bcshow === "bankTable" ? BankActualALlTotal : CashActualALlTotal}
                      </Button>
                    </div>
                  </div>

                </div>


                <div className='card-body' style={{ scroll }}>
                  {/* Export data in Excel */}
                  {bcshow == "bankTable" ? (
                    <>
                      <ExcelExport data={bankIncomeList} ref={_export}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt
'
                          title='Date'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='code
'
                          title='Voucher No'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedPatient.name
'
                          title='Related Patient'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='doctor

'
                          title='Doctor'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='type
'
                          title='Type'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='paymentMethod'
                          title='Payment Method'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='grandTotal
'
                          title='Grand Total'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='paidAmount
'
                          title='Paid Amount'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='balance
'
                          title='Balance'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedBank.name
'
                          title='Bank Name'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                      </ExcelExport>
                      <ExcelExport data={multiList} ref={_export2}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt
'
                          title='Date'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='code
'
                          title='Voucher No'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedPatient.name
'
                          title='Related Patient'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='Name
'
                          title='Multi Item'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={650}
                        />
                        <ExcelExportColumn
                          field='relatedBank.name
'
                          title='Bank Name'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='Price
'
                          title='Grand Total'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={350}
                        />
                        <ExcelExportColumn
                          field='Qty
'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                      </ExcelExport>
                    </>
                  ) : (
                    <>
                      <ExcelExport data={cashIncomeList} ref={_export1}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt
'
                          title='Date'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='code
'
                          title='Voucher No'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedPatient.name
'
                          title='Related Patient'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='doctor

'
                          title='Doctor'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='type
'
                          title='Type'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='paymentMethod'
                          title='Payment Method'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='grandTotal
'
                          title='Grand Total'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='paidAmount
'
                          title='Paid Amount'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                        <ExcelExportColumn
                          field='balance
'
                          title='Balance'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />

                        <ExcelExportColumn
                          field='relatedCash.name
'
                          title='Bank Name'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                      </ExcelExport>

                      <ExcelExport data={multiCashList} ref={_export3}>
                        <ExcelExportColumn
                          field=''
                          title='No'
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field='createdAt
'
                          title='Date'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />
                        <ExcelExportColumn
                          field='code
'
                          title='Voucher No'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />
                        <ExcelExportColumn
                          field='relatedPatient.name
'
                          title='Related Patient'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='Name'
                          title='Multi Item'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={650}
                        />

                        <ExcelExportColumn
                          field='relatedCash.name
'
                          title='Cash Name'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field='Price
'
                          title='Price'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={350}
                        />

                        <ExcelExportColumn
                          field='Qty'
                          title='Qty'
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={150}
                        />
                      </ExcelExport>
                    </>
                  )}

                  {/* Export Data in Excel end */}
                  <table id='example1' className='table table-responsive-lg'>
                    <thead className='sticky-top'>
                      <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Voucher No</th>
                        {/* <th>Related Treatment</th> */}
                        <th>Patient</th>
                        <th>Doctor</th>
                        <th>Type</th>

                        <th>Purchase Type</th>
                        <th>Actual Amount</th>
                        <th>Defer Amount</th>
                        <th>Grand Total</th>
                        <th>Paid Amount</th>
                        <th>Balance</th>
                        <th>User</th>
                        <th>
                          {bcshow === "bankTable" ? "Bank Name" : "Cash Name"}
                        </th>
                        <th>{bcshow === "bankTable" && "Bank Type"}</th>
                        <th>Treatment Type</th>
                        <th>Deposit</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>

                    {bcshow === "bankTable"
                      ? (showOri
                        ? bankIncomeList
                        : showBankTS
                          ? bankFilList
                          : bankTSList
                      ).map((tSale, i) => (
                        <tbody key={tSale._id}>
                          <tr>
                            <td>{++i}</td>
                            <td>
                              {tSale.createdAt
                                ? convertAndDisplayTZ(tSale.createdAt)
                                : ""}
                            </td>
                            <td>{tSale.code ? tSale.code : ""}</td>
                            <td>
                              {tSale.relatedPatient
                                ? tSale.relatedPatient.name
                                : ""}
                            </td>
                            <td>
                              {tSale.relatedDoctor
                                ? tSale.relatedDoctor.name
                                : "-"}
                            </td>
                            <td>
                              {tSale.tsType === "TS"
                                ? "Single Treatment"
                                : "" || tSale.tsType === "TSMulti"
                                  ? "Multiple Treatment"
                                  : "" || tSale.tsType === "Combined"
                                    ? "Combined"
                                    : "" || tSale.tsType === "PS"
                                      ? "Package Selection"
                                      : ""}
                              {/* {tSale.msGrandTotal ? tSale.msGrandTotal : ''} */}
                            </td>
                            <td>
                              {tSale.tsType === "TS"
                                ? tSale.paymentMethod
                                : "-"}
                            </td>
                            <td>{tSale?.relatedTreatmentSelection.reduce((acc, val) => acc + val.actualRevenue, 0)}</td>
                            <td>{tSale?.relatedTreatmentSelection.reduce((acc, val) => acc + val.deferRevenue, 0) === 0 ? tSale.relatedTreatmentSelection[0]
                              ?.totalAmount : tSale?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0)}</td>
                            <td>
                              {
                                tSale.relatedTreatmentSelection[0]
                                  ?.totalAmount
                              }
                            </td>
                            <td>
                              {!tSale.relatedTreatmentSelection[0]?.paidAmount
                                ? 0
                                : tSale.relatedTreatmentSelection[0]
                                  ?.paidAmount}
                            </td>
                            <td>
                              {tSale.relatedTreatmentSelection[0]
                                ?.totalAmount ===
                                tSale.relatedTreatmentSelection[0]?.paidAmount
                                ? 0
                                : !tSale.relatedTreatmentSelection[0]
                                  ?.paidAmount
                                  ? 0
                                  : tSale.relatedTreatmentSelection[0]
                                    ?.totalAmount -
                                  tSale.relatedTreatmentSelection[0]
                                    ?.paidAmount}
                            </td>

                            <td>
                              {tSale.createdBy
                                ? tSale.createdBy.givenName
                                : ""}
                            </td>
                            <td>
                              {tSale.relatedBank
                                ? tSale.relatedBank.name
                                : ""}
                            </td>
                            <td>{tSale.bankType}</td>
                            <td>
                              {tSale.purchaseType === "Clinic"
                                ? "Asthetic"
                                : tSale.purchaseType === "Surgery"
                                  ? "Surgery"
                                  : "-"}
                            </td>
                            <td>
                              {tSale.purchaseType === "Clinic"
                                ? "0"
                                : "Deposit"}
                            </td>
                            <td className='flex w-auto text-center'>
                              <button
                                className='btn btn-primary btn-sm'
                                onClick={() => showDialog(tSale._id)}
                              >
                                Image
                              </button>
                              <button
                                className='btn btn-primary btn-sm mt-1'
                                onClick={() => showDeferDialog(tSale)}
                              >
                                DEFERR
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))
                      : (showOri
                        ? cashIncomeList
                        : showCashTS
                          ? cashFilList
                          : cashTSList
                      ).map((tSale, i) => (
                        <tbody key={tSale._id}>
                          <tr>
                            <td>{++i}</td>
                            <td>
                              {tSale.createdAt
                                ? convertAndDisplayTZ(tSale.createdAt)
                                : ""}
                            </td>
                            <td>{tSale.code ? tSale.code : "-"}</td>
                            <td>
                              {tSale.relatedPatient
                                ? tSale.relatedPatient.name
                                : ""}
                            </td>
                            <td>
                              {tSale.relatedDoctor
                                ? tSale.relatedDoctor.name
                                : "-"}
                            </td>

                            <td>
                              {tSale.tsType === "TS"
                                ? "Single Treatment"
                                : "" || tSale.tsType === "TSMulti"
                                  ? "Multiple Treatment"
                                  : "" || tSale.tsType === "Combined"
                                    ? "Combined"
                                    : "" || tSale.tsType === "PS"
                                      ? "Package Selection"
                                      : ""}
                              {/* {tSale.msGrandTotal ? tSale.msGrandTotal : ''} */}
                            </td>
                            <td>
                              {tSale.tsType === "TS"
                                ? tSale.paymentMethod
                                : "-"}
                            </td>
                            <td>{tSale?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.actualRevenue, 0)}</td>
                            <td>{tSale?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0) === 0 ? tSale.relatedTreatmentSelection[0]
                              ?.totalAmount : tSale?.relatedTreatmentSelection?.reduce((acc, val) => acc + val.deferRevenue, 0)}</td>
                            <td>
                              {
                                tSale.relatedTreatmentSelection[0]
                                  ?.totalAmount
                              }
                            </td>
                            <td>
                              {!tSale.relatedTreatmentSelection[0]?.paidAmount
                                ? 0
                                : tSale.relatedTreatmentSelection[0]
                                  ?.paidAmount}
                            </td>
                            <td>
                              {tSale.relatedTreatmentSelection[0]
                                ?.totalAmount ===
                                tSale.relatedTreatmentSelection[0]?.paidAmount
                                ? 0
                                : !tSale.relatedTreatmentSelection[0]
                                  ?.paidAmount
                                  ? 0
                                  : tSale.relatedTreatmentSelection[0]
                                    ?.totalAmount -
                                  tSale.relatedTreatmentSelection[0]
                                    ?.paidAmount}
                            </td>
                            <td>
                              {tSale.createdBy
                                ? tSale.createdBy.givenName
                                : ""}
                            </td>
                            <td>
                              {tSale.relatedCash
                                ? tSale.relatedCash.name
                                : ""}
                            </td>
                            <td></td>
                            <td>
                              {tSale.purchaseType === "Clinic"
                                ? "Asthetic"
                                : tSale.purchaseType === "Surgery"
                                  ? "Surgery"
                                  : "-"}
                            </td>
                            <td>
                              {tSale.purchaseType === "Clinic"
                                ? "0"
                                : "Deposit"}
                            </td>

                            <td className='text-center'>
                              <button
                                class='btn btn-primary btn-sm'
                                onClick={() => showDialog(tSale._id)}
                              >
                                Image
                              </button>
                              <button
                                className='btn btn-primary btn-sm mt-1'
                                onClick={() => showDeferDialog(tSale)}
                              >
                                DEFERR
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    {/* {oriList.map((tSale, i) => (
                      <tbody key={tSale._id}>
                        <tr>
                          <td>{++i}</td>
                          <td>
                            {tSale.createdAt
                              ? tSale.createdAt.split('T')[0]
                              : ''}
                          </td>
                          <td>{tSale.code ? tSale.code : ''}</td>
                          <td>
                            {tSale.relatedPatient
                              ? tSale.relatedPatient.name
                              : ''}
                          </td>
                          <td>
                            {tSale.relatedTreatmentSelection.relatedAppointments
                              ? tSale.relatedTreatmentSelection
                                  .relatedAppointments[0].relatedDoctor.name
                              : ''}
                          </td>
                          <td>
                            {tSale.relatedTreatmentSelection
                              ? tSale.relatedTreatmentSelection.purchaseType
                              : ''}
                          </td>
                          <td>
                            {tSale.paymentMethod ? tSale.paymentMethod : ''}
                          </td>

                          <td>{tSale.amount ? tSale.amount : ''}</td>

                          <td>
                            {tSale.createdBy ? tSale.createdBy.givenName : ''}
                          </td>
                          <td>{tSale.bankType}</td>
                          <td className='text-center'>
                            <button
                              class='btn btn-primary btn-sm'
                              onClick={() => showDialog(tSale._id)}
                            >
                              Image
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))} */}
                  </table>
                </div>

                <div className='col-8 d-flex justify-content-between'>
                  <div style={{ marginRight: "100px", marginLeft: "50px" }}>
                    <p>
                      <b>
                        <u>Bank List</u>
                      </b>
                    </p>
                    {/* {bankNameList.BankList.map((i) => (
                      <p>
                        {i.relatedBank?.name} : {i.totalAmount}{" "}
                      </p>
                    ))} */}
                    {bankType && (
                      <>
                        <p>
                          {bankType == "POS" ? (
                            <p>POS-MMK : {bankTotal}</p>
                          ) : (
                            <p>POS-MMK : 0</p>
                          )}
                        </p>
                        <p>
                          {bankType == "Normal" ? (
                            <p>Normal-MMK : {bankTotal}</p>
                          ) : (
                            <p>Normal-MMK : 0</p>
                          )}
                        </p>
                        <p>
                          {bankType == "Pay" ? (
                            <p>Pay-MMK : {bankTotal}</p>
                          ) : (
                            <p>Pay-MMK : 0</p>
                          )}
                        </p>
                      </>
                    )}
                  </div>

                  {bankType ? (
                    <div className='invisible'>
                      <p>
                        <b>
                          <u>Cash List</u>
                        </b>
                      </p>

                      {cashNameList
                        ? Object.entries(cashNameList).map(([k, v]) => (
                          <p>
                            {k} : {v}{" "}
                          </p>
                        ))
                        : ""}
                    </div>
                  ) : (
                    <div>
                      <p>
                        <b>
                          <u>Cash List</u>
                        </b>
                      </p>

                      {cashNameList
                        ? Object.entries(cashNameList).map(([k, v]) => (
                          <p>
                            {k} : {v}{" "}
                          </p>
                        ))
                        : ""}
                    </div>
                  )}

                  {/* <div>
                    <p>
                      <b>
                        <u>
                          {POSTotal ? 'POS Total' : ''}
                          {normalTotal ? 'Normal Total' : ''}
                          {payTotal ? 'Pay Total' : ''}
                        </u>
                      </b>
                    </p>

                   
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <ImageDialog open={open} close={() => setOpen(false)} value={value} />
          <DeferDialog
            open={openDefer}
            close={() => setOpenDefer(false)}
            value={deferValue}
          />
        </div>

        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{" "}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>

        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  );
}
export default TreatementSale;

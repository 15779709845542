import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { useLocation, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import Member from '../Member/MemberDialog'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import HeaderName from '../../HeaderName'
const Income = () => {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })

  const [origin, setOrigin] = useState(true)
  const [filterList, setFilterList] = useState([])
  const [showFilterList, setShowFilterList] = useState(false)
  const [memLists, setMemLists] = useState([])
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const CreateDialog = () => {
    setOpen(true)
  }
  const handleDelete = event => {
    console.log(event, 'ID')
    api
      .delete('member/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = memLists.filter(item => item._id !== event)
        setMemLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleNameFilter = event => {
    if (event) {
      setFilterList(
        memLists.filter(el =>
          el.name.toLowerCase().includes(event.toLowerCase())
        )
      )
      setShowFilterList(true)
      setOrigin(false)
    } else {
      setShowFilterList(false)

      setOrigin(true)
    }
  }
  useEffect(() => {
    const getMemberLists = async () => {
      try {
        const res = await api.get('members')
        setMemLists(res.data.list)

        // setTreatmentLists(res.data.list)

        console.log(res.data.list, 'dis')
      } catch (err) {
        alert(err.message)
      }
    }
    getMemberLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active'>Member List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-1 col-md-4 ml-4'>
                  <div
                    className='input-group row'
                    style={{ marginBottom: '20px', marginLeft: '60px' }}
                  >
                    <div className='col-md-6'>
                      <input
                        type='search'
                        className='form-control rounded ml-1'
                        style={{ borderRadius: '12px' }}
                        onChange={e => handleNameFilter(e.target.value)}
                        id='search_code'
                        placeholder='Search'
                      />
                    </div>
                    <div className='col-md-6 mt-1'>
                      <button
                        onClick={CreateDialog}
                        className='btn'
                        style={{
                          borderRadius: '7px',
                          backgroundColor: 'black',
                          color: 'white'
                        }}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <h5 className='card-header'>Member List</h5>
                    {/* <div className="card-header">
                      <div className="row justify-content-between">
                        <span className="float-right">
                          <a href="/income_type" className="btn btn-primary">
                            Income Type
                          </a>
                           <button
                              type="button"
                              className="btn btn-primary"
                              onClick={excelExport}>
                              Export Excel
                            </button>
                        </span>
                      </div>
                      <div className="row" id="trial_balance"></div>
                    </div> */}

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {/* <ExcelExport data={incomeLists} ref={_export}>
                        <ExcelExportColumn
                          field=""
                          title="No"
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field="date"
                          title="Date"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field="relatedBankAccount.name"
                          title="Bank Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedCashAccount.name"
                          title="Cash Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedAccounting.name"
                          title="Account"
                          width={150}
                        />
                        <ExcelExportColumn
                          field="remark"
                          title="Remark"
                          width={150}
                        />
                      </ExcelExport> */}
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div
                            className='table-responsive text-black'
                            id='slimtest2'
                          >
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead>
                                <tr>
                                  <th>#</th>

                                  <th>Name</th>
                                  <th>Entitled Discount</th>
                                  <th>Membership Condition</th>
                                  <th>Value</th>
                                  {/* <th>Condition Type</th> */}
                                  {/* <th>Condition Type</th> */}

                                  {/* <th>Condition Value</th> */}
                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>
                              {origin && (
                                <tbody>
                                  {memLists.map((sq, i) => (
                                    <tr>
                                      <td>{++i}</td>

                                      <td>
                                        {sq.relatedDiscount[0]
                                          ? sq.relatedDiscount[0].name
                                          : ''}
                                      </td>
                                      <td>
                                        {sq.relatedDiscount.map(data =>
                                          data.type == 'Amount'
                                            ? data.value
                                            : ''
                                        ) ||
                                          sq.relatedDiscount.map(data =>
                                            data.type == 'FOCItem'
                                              ? data.type
                                              : ''
                                          )}
                                      </td>

                                      <td>
                                        {sq.relatedDiscount[0].conditionAmount
                                          ? 'Amount'
                                          : '' ||
                                            sq.relatedDiscount[0]
                                              .conditionPackageQty
                                          ? 'Package Qty'
                                          : '' ||
                                            sq.relatedDiscount[0]
                                              .conditionPurchaseFreq
                                          ? 'Purchase Freq'
                                          : ''}
                                      </td>
                                      {/* <td>
                                        {sq.relatedFOCID
                                          ? sq.relatedFOCID.name
                                          : ''}
                                      </td> */}

                                      <td>
                                        {sq.relatedDiscount[0]
                                          .conditionAmount ||
                                          sq.relatedDiscount[0]
                                            .conditionPurchaseFreq ||
                                          sq.relatedDiscount[0]
                                            .conditionPackageQty}
                                      </td>

                                      <td className='text-center'>
                                        {/* <div>
                                        <UnitUpdate2 TreatId={TreatId} />
                                      </div> */}
                                        <Link
                                          to={'/sq-Update/' + sq._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e => handleDelete(sq._id)}
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                        &nbsp;
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}

                              {showFilterList && (
                                <tbody>
                                  {filterList.map((sq, i) => (
                                    <tr>
                                      <td>{++i}</td>

                                      <td>{sq ? sq.name : ''}</td>
                                      <td>{sq.type}</td>

                                      <td>{sq.value}</td>
                                      <td>
                                        {sq.relatedFOCID
                                          ? sq.relatedFOCID.name
                                          : ''}
                                      </td>

                                      {/* <td>
                                      {sq.conditionAmount ||
                                        sq.conditionPurchaseFreq ||
                                        sq.conditionPackageQty}
                                    </td> */}

                                      <td className='text-center'>
                                        {/* <div>
                                        <UnitUpdate2 TreatId={TreatId} />
                                      </div> */}
                                        <Link
                                          to={'/sq-Update/' + sq._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e => handleDelete(sq._id)}
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                        &nbsp;
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Member
                open={open}
                close={() => setOpen(false)}
                setMemLists={setMemLists}
                memLists={memLists}
              />
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Income

import React from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'

import Button from '@mui/material/Button'
import { Input, Spacer } from '@nextui-org/react'

import { useEffect, useState } from 'react'
// import CurrencyDialog from '../views/CurrencyDialog'
// import CurrencyUpdate from '../views/CurrencyUpdate'
import { Link, useLocation } from 'react-router-dom'
import ReactShadowScroll from 'react-shadow-scroll'
import useCollapse from 'react-collapsed'
import HeaderName from '../../HeaderName'
// import { motion, useViewportScroll, useTransform } from 'framer-motion'
import AddUser from './AddUserDialog'

import {
  FaCashRegister,
  FaFileMedical,
  FaLongArrowAltRight,
  FaYoutube,
  FaFacebookSquare,
  FaArrowLeft,
  FaAngleDown,
  FaRegEdit,
  FaAngleUp,
  FaList,
  FaRegUser,
  FaRegTrashAlt,
  FaClipboardCheck,
  FaPowerOff
} from 'react-icons/fa'
import apiInstance from '../../../../utils/api'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
function Dashboard() {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const UserId = useLocation().pathname.split('/')[2]

  const [scroll, setScroll] = useState(true)
  const [isExpanded, setExpanded] = useState(false)
  const [isAccData, setAccData] = useState(false)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [gender, setGender] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [position, setPosition] = useState('')
  const [branch, setBranch] = useState('')
  const [branchName, setBranchName] = useState('')

  const [branchLists, setBranchLists] = useState([])

  // const handleBranch = event => {
  //   // console.log(event, 'b')
  //   setBranch(event.split(',')[0])
  //   setBranchName(event.split(',')[1])
  // }

  const [purchaseDetail, setPurchaseDetail] = useState([])

  const [showUpdate, setShowUpdate] = useState(false)
  const [id, setId] = useState('')
  const [userDetail, setUserDetail] = useState([])
  const showDialog = () => setOpen(true)
  // const showDialog = val => {
  //   setId(val)
  //   setShowUpdate(true)
  // }

  const Update = () => {
    const data = {
      id: UserId,
      givenName: name,
      email: email,
      password: password,
      address: address,
      position: position,
      role: role,
      phone: phone,
      gender: gender
      // branch: branch,
      // branchName: branchName
    }

    alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance.put('user', data, config).then(function (response) {
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })
    })
  }

  useEffect(() => {
    const getUserListsID = async () => {
      try {
        const res = await apiInstance.get('user/' + UserId)
        console.log(res.data.data, 'user id list')
        setUserDetail(res.data.data)
        setName(res.data.data.givenName)
        setRole(res.data.data.role)
        setEmail(res.data.data.email)
        setPosition(res.data.data.position)
        setPhone(res.data.data.phone)
        setAddress(res.data.data.address)
        setPassword(res.data.data.password)
        setGender(res.data.data.gender)
        // setBranchName(res.data.data.branchName)
        // setBranch(res.data.data.branch)
      } catch (err) { }
    }

    getUserListsID()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <div className='row'>
          <div className='col-md-3'>
            <SideBar />
          </div>
          <div className='col-md-9'>
            <HeaderName />

            {/* <!-- /.navbar --> */}

            {/* <!-- Main Sidebar Container --> */}

            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className='content-wrapper'>
              {/* <!-- Content Header (Page header) --> */}
              <div className='content-header'>
                <div className='container-fluid'>
                  <div className='row mb-2'>
                    <div className='col-sm-12'>
                      <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                          <Link to='/user'>
                            <i>
                              <FaArrowLeft />
                            </i>
                          </Link>
                        </li>
                        <li
                          className='breadcrumb-item active'
                          style={{ marginTop: '0.1em' }}
                        >
                          Employee Detail & Update
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Main content --> */}
              <section class='content'>
                <div class=''>
                  <div className='row ml-2'>
                    <div className='col-12 mt-1'>
                      <div className='row'>
                        <div className='col-5'>
                          <p className='ml-5 fs-3'>Employee Detail & Update</p>
                        </div>
                      </div>

                      <div className='row '>
                        <div className='ml-5 col-12 mt-3 card mb-2'>
                          <div className='row'>
                            <div className='col-3'>
                              <div className='ml-5'>
                                <img
                                  src={require('../User/user-icon.webp')}
                                  style={{
                                    width: '150px',
                                    marginTop: '30px'
                                  }}
                                  alt=''
                                />
                              </div>
                              <div>
                                <b
                                  className='fs-5'
                                  style={{
                                    marginLeft: '3em'
                                  }}
                                >
                                  {name}
                                </b>
                                <br />
                                {/* Role &nbsp;
                                <FaLongArrowAltRight /> */}
                                &nbsp;
                                <b
                                  className=''
                                  style={{
                                    marginLeft: '5.8em'
                                  }}
                                >
                                  {role}
                                </b>
                              </div>

                              <p className='ml-5 mt-3'>
                                <br />
                                <hr />
                                <br />
                                <div className=''>
                                  <label>Email Address</label>
                                  <div>{email}</div>
                                </div>
                                <br />
                                <div
                                  className='
'
                                >
                                  <label>Phone</label>
                                  <div>{phone}</div>
                                </div>
                                <br />
                                <div className='row d-flex justify-content-center'>
                                  <div className='col-2'>
                                    <FaFacebookSquare />
                                  </div>
                                  <div className='col-2 size-3'>
                                    <FaYoutube />
                                  </div>
                                </div>
                                <br />
                              </p>
                            </div>
                            <div className='offset-2 col-6'>
                              <div className='row'>
                                <div className='col-5'>
                                  <Spacer y={1.5} />

                                  <div class='form-group'>
                                    <label>Name</label>
                                    <input
                                      type='text'
                                      color='primary'
                                      defaultValue={name}
                                      className='form-control border border-info'
                                      onChange={e => setName(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className='offset-2 col-5'>
                                  <Spacer y={1.5} />
                                  <div class='form-group'>
                                    <label>Email</label>
                                    <input
                                      type='email'
                                      color='primary'
                                      defaultValue={email}
                                      className='form-control border border-info'
                                      onChange={e => setEmail(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-5'>
                                  <Spacer y={1.5} />
                                  <div class='form-group'>
                                    <label>Password</label>

                                    <input
                                      type='password'
                                      color='primary'
                                      value={password}
                                      className='form-control border border-info'
                                      onChange={e =>
                                        setPassword(e.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className='offset-2 col-5'>
                                  <Spacer y={1.5} />
                                  <div class='form-group'>
                                    <label>Phone</label>

                                    <input
                                      type='phone'
                                      color='primary'
                                      defaultValue={phone}
                                      className='form-control border border-info'
                                      onChange={e => setPhone(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-5'>
                                  <Spacer y={1.5} />
                                  <div class='form-group'>
                                    <label>Address</label>

                                    <input
                                      type='text'
                                      color='primary'
                                      defaultValue={address}
                                      className='form-control border border-info'
                                      onChange={e => setAddress(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className='offset-2 col-5'>
                                  <Spacer y={1.5} />
                                  <div class='form-group'>
                                    <label>Position</label>

                                    <input
                                      type='text'
                                      color='primary'
                                      defaultValue={position}
                                      className='form-control border border-info'
                                      onChange={e =>
                                        setPosition(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <Spacer y={1.5} />
                              <div class='form-group'>
                                <label>Gender</label>
                                <select
                                  class='custom-select border-info'
                                  name='account_type_id'
                                  id='cat'
                                  onChange={e => setGender(e.target.value)}
                                >
                                  <option value={gender} hidden>
                                    {gender}
                                  </option>
                                  <option value='Male'>Male</option>
                                  <option value='Female'>Female </option>
                                </select>
                              </div>

                              <Spacer y={1.5} />
                              <div class='form-group'>
                                <label>Role</label>
                                <select
                                  class='custom-select border-info'
                                  name='account_type_id'
                                  id='cat'
                                  onChange={e => setRole(e.target.value)}
                                >
                                  <option value={role} hidden>
                                    {role}
                                  </option>
                                  <option value='Doctor'>Doctor</option>
                                  <option value='User'>User</option>
                                  <option value='Admin'>Admin </option>
                                </select>
                              </div>

                              {/* <div class='form-group'>
                                <label>Branch</label>
                                <select
                                  class='custom-select border-info'
                                  name='account_type_id'
                                  id='cat'
                                  onChange={e => handleBranch(e.target.value)}
                                >
                                  <option value={branch} hidden>
                                    {branchName}{' '}
                                  </option>
                                  {branchLists.map(option => (
                                    <option
                                      value={option._id + ',' + option.name}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div> */}

                              <div class='modal-footer'>
                                <Link
                                  to='/user'
                                  class='btn btn-secondary'
                                  data-dismiss='modal'
                                // onClick={props.close}
                                >
                                  Close
                                </Link>
                                <Button
                                  class='btn btn-primary'
                                  onClick={Update}
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* <!-- /.content-wrapper --> */}
            <footer className='main-footer'>
              <strong>
                Copyright &copy; 2017-2020{' '}
                <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
              </strong>
              All rights reserved.
            </footer>

            {/* <!-- Control Sidebar --> */}
            <aside classNameName='control-sidebar control-sidebar-dark'>
              {/* <!-- Control sidebar content goes here --> */}
            </aside>
          </div>
        </div>

        {/* <!-- /.control-sidebar --> */}
      </div>
      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Dashboard

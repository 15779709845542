import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Swal from 'sweetalert2'
import { Checkbox } from "@nextui-org/react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import { RxCross2 } from 'react-icons/rx';


import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'

export default function NotesDetailModal(props) {
  const token = localStorage.getItem('token')
  const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  const animatedComponents = makeAnimated();
  const [relatedBrandList, setRelatedBrandList] = useState([])
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [categoryLists, setCategoryLists] = useState([])
  const [subCategoryLists, setSubCategoryLists] = useState([])
  const [state, setState] = useState('defaultChecked')
  const [edit, setEdit] = useState(false)
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [relatedBrand, setRelatedBrand] = useState('')
  const [accountLists, setAccountLists] = useState('')
  const [fnoteAcc, setFNoteAcc] = useState(null)
  const [fOperator, setfOperator] = useState(null)
  const [fDataTable, setfDataTable] = useState([])
  const [typeValue, setTypeValue] = useState(null)
  const [snoteAcc, setSNoteAcc] = useState(null)
  const [sOperator, setsOperator] = useState(null)
  const [sDataTable, setsDataTable] = useState([])

  const [description, setDescription] = useState('')
  const type = [
    { value: 'income', label: 'Income Satement' },
    { value: 'balance', label: 'Balance' }
  ]
  const operator = [
    { value: 'Minus', label: 'Minus' },
    { value: 'Plus', label: 'Plus' }
  ]

  // const handleCheckBox = async () => {
  //   checkSate === true ? setCheckState(false) : setCheckState(true)
  // }
  const handlesAccountSelect = async (data) => {
    setSNoteAcc(data)
  }

  const handlesOperatorSelect = async (data) => {
    setsOperator(data)
  }

  const handlesAddRow = async (relatedAccount, name, operator) => {
    console.log(relatedAccount, name, operator)
    setsDataTable([...sDataTable, { relatedAccount: relatedAccount, name: name, operator: operator }])
    console.log(sDataTable)
  }

  const removesRow = id => {
    setsDataTable(sDataTable.filter(el => el.relatedAccount != id))
  }


  const handlefAccountSelect = async (data) => {
    setFNoteAcc(data)
    console.log(fnoteAcc, 'fnoteAcc')
  }

  const handleTypeValue = async (data) => {
    setTypeValue(data)
    console.log(typeValue, 'typeValue')
  }

  const handleFOperatorSelect = async (data) => {
    setfOperator(data)
  }

  const handleFAddRow = async (relatedAccount, name, operator) => {
    console.log(relatedAccount, name, operator)
    setfDataTable([...fDataTable, { relatedAccount: relatedAccount, name: name, operator: operator }])
    console.log(fDataTable)
  }

  const removeRow = id => {
    setfDataTable(fDataTable.filter(el => el.relatedAccount != id))
  }

  const handleEdit = async () => {
    setName(props.name)
    setDescription(props.description)
    setfDataTable(props.item)
    setsDataTable(props.secondaryItem)
    edit === false ? setEdit(true) : setEdit(false)
    state === 'defaultChecked' ? setState('') : setState('defaultChecked')
  }

  const updateNotesDetail = () => {

    const data = {
      id: props.id,
      name: name,
      description: description,
      item: fDataTable,
      secondaryItem: sDataTable,
      type: typeValue ? typeValue : props.type
    }
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    api.put('note', data, config).then((response) => {
      Swal.fire({
        title: 'Successful!',
        text: 'You have updated the note',
        icon: 'success',
        // showCancelButton: true,
        cancelButtonText: 'Close'
      })
      window.location.reload()
    })
    props.setOpen(false)
  }

  useEffect(() => {
    const getAccounts = async () => {
      const res = await api.get('accounting-lists')
      const filteredArray = res.data.list.map(item => { return ({ value: item._id, label: item.name }) })
      setAccountLists(filteredArray)

    }
    const getCategory = async () => {
      try {
        const res = await api.get('categories')

        setCategoryLists(res.data.data)
      } catch (err) { }
    }
    const getSubCategory = async () => {
      try {
        const res = await api.get('sub-categories')

        setSubCategoryLists(res.data.data)
      } catch (err) { }
    }

    const getBrandLists = async () => {
      try {
        const res = await api.get('brands')
        setRelatedBrandList(res.data.data)
      } catch (err) {
        alert(err.message)
      }
    }
    getCategory()
    getSubCategory()
    getBrandLists()
    getAccounts()
    // getDetailData()
  }, [])

  return (
    <div>
      <Dialog open={props.open} onClose={props.close} >
        <DialogTitle >
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Note Detail</h4>

            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <DialogContentText></DialogContentText>

          {/* @csrf */}
          <div class='modal-body'>
            <div class='form-group'>
              <label for='name'>Name</label>
              <input
                type='text'
                class='form-control border border-info'
                defaultValue={props.name ? props.name : ''}
                name='name'
                placeholder='eg. Notes 7,8'
                onChange={e => setName(e.target.value)}
                disabled={state}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Description</label>
              <input
                type='text'
                class='form-control border border-info'
                defaultValue={props.description ? props.description : ''}
                name='name'
                placeholder='Description'
                onChange={e => setDescription(e.target.value)}
                disabled={state}
              />
            </div>
            <div class='form-group'>
              <label for='name'>Type</label>
              <select className='form-control' onChange={(e) => setTypeValue(e.target.value)} disabled={state}>
                <option hidden>{props.type ? props.type : `Select Note Type`}</option>
                <option value='income'>Income Statement</option>
                <option value='balance'>Balance Sheet</option>
              </select>
            </div>

            <div class='form-group'>
              {edit ?
                (
                  <>

                    <label for='name'>First Notes</label>
                    <div className='row'>
                      <div className='col-5'>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={accountLists}
                          isSearchable
                          isClearable
                          name='Accounts'
                          placeholder='Accounts'
                          value={fnoteAcc}
                          onChange={handlefAccountSelect}

                        />
                      </div>
                      <div className='col-5'>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={operator}
                          isSearchable
                          isClearable
                          name='Operators'
                          placeholder='Operators'
                          value={fOperator}
                          onChange={handleFOperatorSelect}
                        />
                      </div>
                      <div className='col-2'>
                        <button className='btn btn-primary' onClick={() => handleFAddRow(fnoteAcc.value, fnoteAcc.label, fOperator.value)}>
                          Add
                        </button>
                      </div>
                      {console.log(fDataTable, 'hi there')}
                    </div>
                    <div className='row table-responsive mt-3'>
                      <label className='form-label'>Account Table</label>
                      <table className='table table-hover ml-2'>
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Operator</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fDataTable.map((item, i) => (
                            <tr>
                              <td>{++i}</td>
                              <td>{item.name ? item.name : item.relatedAccount ? item.relatedAccount.name : 'Not Set'}</td>
                              <td>{item.operator ? item.operator : 'Not Set'}</td>
                              <td><RxCross2
                                onClick={() => removeRow(item.relatedAccount)}
                              /></td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </>
                )
                : (
                  <>
                    <label for='name'>First Notes</label>
                    {props.item.map(item => (
                      <div className='input-group'>
                        <div className=''>
                          <input
                            type='text'
                            class='form-control border border-info'
                            name='name'
                            placeholder='Account'
                            value={item.relatedAccount ? item.relatedAccount.name : 'Not Set'}
                            disabled={state}
                          />
                        </div>
                        <input
                          type='text'
                          class='form-control border border-info'
                          name='name'
                          placeholder='Operator'
                          value={item.operator ? item.operator : 'Not Set'}
                          disabled={state}
                        />
                      </div>
                    )
                    )}
                  </>

                )}
            </div>
            <div class='form-group'>
              {edit ?
                (
                  <>
                    <label for='name'>Second Notes</label>
                    <div className='row'>
                      <div className='col-5'>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={accountLists}
                          isSearchable
                          isClearable
                          name='Accounts'
                          placeholder='Accounts'
                          value={snoteAcc}
                          onChange={handlesAccountSelect}
                        />
                      </div>
                      <div className='col-5'>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={operator}
                          isSearchable
                          isClearable
                          name='Operators'
                          placeholder='Operators'
                          value={sOperator}
                          onChange={handlesOperatorSelect}
                        />
                      </div>
                      <div className='col-2'>
                        <button className='btn btn-primary' onClick={() => handlesAddRow(snoteAcc.value, snoteAcc.label, sOperator.value)}>
                          Add
                        </button>
                      </div>
                    </div>
                    <div className='row table-responsive mt-3'>
                      <label className='form-label'>Account Table</label>
                      <table className='table table-hover ml-2'>
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Operator</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sDataTable.map((item, i) => (
                            <tr>
                              <td>{++i}</td>
                              <td>{item.name ? item.name : item.relatedAccount ? item.relatedAccount.name : 'Not Set'}</td>
                              <td>{item.operator ? item.operator : 'Not Set'}</td>
                              <td><RxCross2
                                onClick={() => removesRow(item.relatedAccount)}
                              /></td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </>
                )
                : (
                  <>
                    <label for='name'>Second Notes</label>
                    {props.secondaryItem.map(item => (
                      <div className='input-group'>
                        <div className=''>
                          <input
                            type='text'
                            class='form-control border border-info'
                            name='name'
                            placeholder='Account'
                            value={item.relatedAccount ? item.relatedAccount.name : 'Not Set'}
                            disabled={state}
                          />
                        </div>
                        <input
                          type='text'
                          class='form-control border border-info'
                          name='name'
                          placeholder='Operator'
                          value={item.operator ? item.operator : 'Not Set'}
                          disabled={state}
                        />
                      </div>
                    )
                    )}
                  </>

                )}
            </div>
          </div>
          <div class='modal-footer'>
            <Checkbox isRounded onChange={() => handleEdit()}>Edit</Checkbox>
            <Button
              class='btn btn-secondary'
              data-dismiss='modal'
              onClick={props.close}
            >
              Close
            </Button>
            <Button class='btn btn-primary' disabled={state} onClick={() => updateNotesDetail()}>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

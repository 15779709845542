import * as React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar.js'
import { Link, useLocation } from 'react-router-dom'

import { FaCashRegister, FaFileMedical, FaArrowLeft } from 'react-icons/fa'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import apiInstance from '../../../../utils/api.js'
function AddAsset(props) {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const location = useLocation()
  const Id = location.pathname.split('/')[2]
  const [decAccountingLists, setDecAccountingLists] = useState([])

  const [accountingLists, setAccountingLists] = useState([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [fixAccountID, setFixAccountID] = useState('')
  const [decAccountID, setDecAccountID] = useState('')

  const [existingAsset, setExistingAsset] = useState(false)
  const [initialPrice, setInitialPrice] = useState('')
  const [usedYear, setUsedYear] = useState('')
  const [salvageValue, setSalvageValue] = useState('')
  const [depriciationTotal, setDepriciationTotal] = useState('')
  const [useLife, setUseLife] = useState('')
  const [currentPrice, setCurrentPrice] = useState('')
  const [yearDepriciation, setYearDepriciation] = useState('')
  const [startDate, setStartDate] = useState('')

  const [showYes, setShowYes] = useState(false)
  const [showNo, setShowNo] = useState(false)
  const [origin, setOrigin] = useState(true)
  const [showBank, setShowBank] = useState(false)
  const [showCash, setShowCash] = useState(false)
  const [cashList, setCashList] = useState([])
  const [bankList, setBankList] = useState([])
  const [relatedBankAcc, setRelatedBankAcc] = useState('')
  const [relatedCashAcc, setRelatedCashAcc] = useState('')
  const [bankRadioValue, setBankRadioValue] = useState('')
  const [cashRadioValue, setCashRadioValue] = useState('')
  const [type, setType] = useState('')

  const handleBankRadioChange = val => {
    setBankRadioValue(val)
    setShowBank(true)
    setShowCash(false)
  }

  const handleCashRadioChange = val => {
    setCashRadioValue(val)
    setShowBank(false)
    setShowCash(true)
  }

  const handleYesChange = () => {
    setShowYes(true)
    setShowNo(false)
    setOrigin(false)
  }

  const handleNoChange = () => {
    setShowYes(false)
    setOrigin(false)
    setShowNo(true)
  }

  const handleCalculation = event => {
    if (initialPrice && salvageValue) {
      let sub = initialPrice - salvageValue
      let ans = sub / event
      console.log(ans)
      setYearDepriciation(ans.toFixed(2))
    }

    setUseLife(event)
  }

  const handleYesCalculation = event => {
    if (existingAsset == '1') {
      let multi = event * yearDepriciation
      let subFinal = initialPrice - multi
      setCurrentPrice(subFinal.toFixed(2))
      setDepriciationTotal(multi)
    }

    setUsedYear(event)
  }

  const handleNoCalculation = event => {
    console.log(event)
    if (existingAsset == '2') {
      setCurrentPrice(initialPrice)
      console.log(currentPrice)
    }
    setInitialPrice(event)
  }

  useEffect(() => {
    const getAccountingList = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        setAccountingLists(
          res.data.list.filter(
            el =>
              el.relatedHeader.name === 'P, P & E' &&
              el.relatedType.name === 'Assets'
          )
        )
        setDecAccountingLists(
          res.data.list.filter(
            el =>
              el.relatedHeader.name === 'Accumulated Depreciation' &&
              el.relatedType.name === 'Assets'
          )
        )
        const cash = res.data.list.filter(
          el =>
            el.relatedHeader.name === 'Cash in Hand' &&
            el.relatedType.name === 'Assets'
        )
        setCashList(cash)
        const bank = res.data.list.filter(
          el =>
            el.relatedHeader.name === 'Cash at Bank' &&
            el.relatedType.name === 'Assets'
        )
        setBankList(bank)

        console.log(res.data.list, 'acc list')
      } catch (err) { }
    }

    const getFixAssetData = async () => {
      try {
        const res = await apiInstance.get('fixed-asset/' + Id)

        // console.log(res.data.data, 'fix list')
        const fix = res.data.data[0]
        setName(fix.name)
        setDescription(fix.description)
        setType(fix.type)
        setInitialPrice(fix.initialPrice)
        setSalvageValue(fix.salvageValue)
        setUseLife(fix.useLife)
        setYearDepriciation(fix.yearDepriciation)
        setExistingAsset(fix.existingAsset)
        setDepriciationTotal(fix.depriciationTotal)
        setUsedYear(fix.usedYear)
        setCurrentPrice(fix.currentPrice)
        setStartDate(fix.startDate?.split('T')[0])
        setFixAccountID(fix.fixedAssetAcc)
        setDecAccountID(fix.depriciationAcc)
      } catch (err) { }
    }

    getFixAssetData()
    getAccountingList()
  }, [])

  const FixedAssetCreate = () => {
    const data = {
      id: Id,
      name: name,
      description: description,
      // fixedAssetAcc: fixAccountID,
      // depriciationAcc: decAccountID,
      type: type,
      existingAsset: existingAsset,
      initialPrice: initialPrice,
      salvageValue: salvageValue,
      useLife: useLife,
      yearDepriciation: yearDepriciation,
      // currentPrice: initialPrice,
      startDate: startDate
    }
    if (decAccountID) {
      data.depriciationAcc = decAccountID
    } else {
      data.fixedAssetAcc = fixAccountID
    }
    if (bankRadioValue) {
      data.relatedBank = relatedBankAcc
    }
    if (cashRadioValue) {
      data.relatedCash = relatedCashAcc
    }

    if (existingAsset === '1' || decAccountID) {
      data.usedYear = usedYear
      data.depriciationTotal = depriciationTotal
      data.currentPrice = currentPrice
      data.startDate = startDate
    }

    if (existingAsset === '2' || fixAccountID) {
      data.currentPrice = initialPrice
      data.depriciationTotal = 0

      data.startDate = startDate
    }

    // alert(JSON.stringify(data))
    // console.log(data)
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    // alert(JSON.stringify(data));
    apiInstance
      .put('fixed-asset', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Updated Fixed Asset Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
      .catch(function (err) {
        alert(err.message)
      })
    //     props.setOpen(false);
  }

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/fix_asset'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li
                      className='breadcrumb-item active'
                      style={{ marginTop: '0.15em' }}
                    >
                      Sale Project List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section class='content'>
            <div class='container-fluid'>
              <div className='container'>
                <div className='card'>
                  <div className='card-header bg-info'>
                    <h3
                      className='card-title text-white py-2'
                      id='exampleModalLabel'
                    >
                      Fixed Asset Update
                    </h3>
                  </div>
                  <div className='card-body'>
                    <div className='row offset-md-4'>
                      <div className='col-md-2'>
                        <div className='form-check form-check-inline mt-2 '>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='bank_cash'
                            id='bank'
                            value='1'
                            onClick={handleBankRadioChange}
                          />
                          &nbsp;
                          <label
                            className='form-check-label text-success'
                            for='bank'
                          >
                            Bank
                          </label>
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <div className='form-check form-check-inline mt-2 px-4'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='bank_cash'
                            id='cash'
                            value='2'
                            onClick={handleCashRadioChange}
                          />
                          <label
                            className='form-check-label text-success'
                            for='cash'
                          >
                            Cash
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6'>
                        <div className=''>
                          {showBank && (
                            <div className='form-group mt-1' id='bankkk'>
                              <label className='control-label'>
                                Bank Account
                              </label>

                              <select
                                className='form-control'
                                name='bank_acc'
                                id='bank_acc'
                                onChange={e =>
                                  setRelatedBankAcc(e.target.value)
                                }
                              >
                                <option value=''>Select Bank Account</option>

                                {bankList.map(option => (
                                  <option value={option._id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                          {showCash && (
                            <div className='form-group mt-1' id='cashhh'>
                              <label className='control-label'>
                                Cash Account
                              </label>

                              <select
                                className='form-control'
                                name='cash_acc'
                                id='cash_acc'
                                onChange={e =>
                                  setRelatedCashAcc(e.target.value)
                                }
                              >
                                <option value=''>Select Cash Account</option>

                                {cashList.map(option => (
                                  <option value={option._id}>
                                    {option.name}
                                  </option>
                                ))}

                                {/* @endforeach */}
                              </select>
                            </div>
                          )}

                          <div className='form-group'>
                            <label className='text-secondary'>Name</label>
                            <input
                              type='text'
                              className='form-control'
                              name='asset_name'
                              value={name}
                              placeholder='Enter Asset Name'
                              onChange={e => setName(e.target.value)}
                            />
                          </div>
                          <div className='form-group'>
                            <label className='text-secondary'>
                              Description
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='asset_description'
                              value={description}
                              placeholder='Enter Asset Description'
                              onChange={e => setDescription(e.target.value)}
                            />
                          </div>
                          <div className='form-group'>
                            <label className='text-secondary'>Type</label>
                            <select
                              id='asset_type'
                              name='type'
                              className='form-control'
                              onChange={e => setType(e.target.value)}
                            >
                              <option value={type} hidden>
                                {type}
                              </option>
                              <option value='Computer & IT Equipment'>
                                Computer & IT Equipment
                              </option>
                              <option value='Plant & Machinery'>
                                Plant & Machinery
                              </option>
                              <option value='Furniture & Fitting'>
                                Furniture & Fitting
                              </option>
                              <option value='Office Equipment'>
                                Office Equipment
                              </option>
                              <option value='Motor Vehicle'>
                                Motor Vehicle
                              </option>
                              <option value='Medical Machinery'>
                                Medical Machinery
                              </option>
                              <option value='Medical Equipment'>
                                Medical Equipment
                              </option>
                              <option value='Air Con'>Air Con</option>
                              <option value='Surgery Equipment'>
                                Surgery Equipment
                              </option>
                            </select>
                          </div>
                          <div className='form-group'>
                            <label className='text-secondary'>
                              Purchase Initial Price
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              value={initialPrice}
                              name='purchase_initial_price'
                              id='initial_price'
                              placeholder='Enter Purchase Initial Price'
                              onChange={e =>
                                handleNoCalculation(e.target.value)
                              }
                            />
                          </div>
                          <div className='form-group'>
                            <label className='text-secondary'>
                              Salvage Value
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              name='salvage_value'
                              id='salvage'
                              value={salvageValue}
                              placeholder='Enter Salvage Value'
                              onChange={e => setSalvageValue(e.target.value)}
                            />
                          </div>
                          <div className='form-group'>
                            <label className='text-secondary'>Use Life</label>
                            <input
                              type='text'
                              className='form-control'
                              name='use_life'
                              value={useLife}
                              id='uselife'
                              placeholder='Enter Use Life'
                              onChange={e => handleCalculation(e.target.value)}
                            />
                          </div>
                          <div className='form-group'>
                            <label className='text-secondary'>
                              Year Depriciation
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              value={yearDepriciation}
                              name='year_depriciation'
                              id='year_dep'
                              defaultValue={yearDepriciation}
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label className='control-label'>
                            Fixed Assets Account
                          </label>

                          <select
                            name='acc'
                            id=''
                            className='form-control mt-1'
                            onChange={e => setFixAccountID(e.target.value)}
                          >
                            <option value={fixAccountID} hidden>
                              {fixAccountID ? fixAccountID : 'Choose Account'}
                            </option>

                            {accountingLists.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className='form-group'>
                          <label className='control-label'>
                            Depriciation Account
                          </label>

                          <select
                            name='acc'
                            id=''
                            className='form-control mt-1'
                            onChange={e => setDecAccountID(e.target.value)}
                          >
                            <option value={decAccountID} hidden>
                              {decAccountID ? decAccountID : 'Choose Account'}
                            </option>

                            {decAccountingLists.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>

                        {/* <div className="form-group">
                          <label className="text-secondary">Account Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="account_name"
                            onChange={(e) => setRelatedAccount(e.target.value)}
                          />
                        </div> */}
                        <div className='form-group'>
                          <label className='text-secondary'>
                            Existing Asset
                          </label>
                          <div
                            className='form-check form-check-inline ml-5'
                            style={{ marginTop: '40px' }}
                          >
                            {existingAsset === false ? (
                              <input
                                className='form-check-input'
                                type='radio'
                                disabled
                                name='exist_asset'
                                id='inlineRadio1'
                                value='1'
                                checked={existingAsset === true}
                                onClick={handleYesChange}
                                onChange={e => setExistingAsset(true)}
                              />
                            ) : (
                              <input
                                className='form-check-input'
                                type='radio'
                                name='exist_asset'
                                id='inlineRadio1'
                                value='1'
                                checked={existingAsset === true}
                                onClick={handleYesChange}
                                onChange={e => setExistingAsset(true)}
                              />
                            )}

                            <label
                              className='form-check-label text-secondary'
                              for='inlineRadio1'
                            >
                              Yes
                            </label>
                          </div>
                          <div className='form-check form-check-inline'>
                            {existingAsset === true ? (
                              <input
                                className='form-check-input'
                                disabled
                                type='radio'
                                name='exist_asset'
                                id='inlineRadio2'
                                checked={existingAsset === false}
                                value='2'
                                onChange={e => setExistingAsset(false)}
                                onClick={handleNoChange}
                              />
                            ) : (
                              <input
                                className='form-check-input'
                                type='radio'
                                name='exist_asset'
                                id='inlineRadio2'
                                checked={existingAsset === false}
                                value='2'
                                onChange={e => setExistingAsset(false)}
                                onClick={handleNoChange}
                              />
                            )}
                            <label
                              className='form-check-label text-secondary'
                              for='inlineRadio2'
                            >
                              No
                            </label>
                          </div>
                        </div>
                        {existingAsset === true ? (
                          <div className='mt-3'>
                            <div className='form-group' id='used_year'>
                              <label className='text-secondary'>
                                Used Year
                              </label>

                              <input
                                type='text'
                                className='form-control'
                                name='used_year'
                                id='use_year'
                                value={usedYear}
                                placeholder='Enter Used Year'
                                onChange={e =>
                                  handleYesCalculation(e.target.value)
                                }
                              />
                            </div>
                            <div className='form-group' id='dept_tot'>
                              <label className='text-secondary'>
                                Depriciation Total
                              </label>
                              <input
                                type='text'
                                value={depriciationTotal}
                                className='form-control'
                                name='depriciation_total'
                                id='dep_total'
                                placeholder='Enter Depriciation Total'
                                readonly
                              />
                            </div>
                            <div className='form-group'>
                              <label className='text-secondary'>
                                Current Price
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                name='current_value'
                                id='current_price'
                                value={currentPrice}
                                placeholder='Enter Current Value'
                                defaultValue={currentPrice}
                                readonly
                              />
                            </div>
                            <div className='form-group'>
                              <label className='text-secondary'>
                                Start Date
                              </label>
                              <input
                                type='date'
                                className='form-control'
                                value={startDate}
                                name='start_date'
                                onChange={e => setStartDate(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='mt-3'>
                            <div className='form-group'>
                              <label className='text-secondary'>
                                Current Price
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                name='current_value'
                                id='current_price'
                                placeholder='Enter Current Value'
                                defaultValue={initialPrice}
                                readonly
                              />
                            </div>
                            <div className='form-group'>
                              <label className='text-secondary'>
                                Start Date
                              </label>
                              <input
                                type='date'
                                className='form-control'
                                name='start_date'
                                value={startDate}
                                onChange={e => setStartDate(e.target.value)}
                              />
                            </div>
                          </div>
                        )}
                        {/* {origin && (
                          <div>
                            <div className='form-group'>
                              <label className='text-secondary'>
                                Current Price
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                name='current_value'
                                id='current_price'
                                placeholder='Enter Current Value'
                                defaultValue={initialPrice}
                                readonly
                              />
                            </div>
                            <div className='form-group'>
                              <label className='text-secondary'>
                                Start Date
                              </label>
                              <input
                                type='date'
                                className='form-control'
                                name='start_date'
                                onChange={e => setStartDate(e.target.value)}
                              />
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className='card-footer'>
                    <button
                      onClick={FixedAssetCreate}
                      className='btn btn-primary float-right'
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <footer className='main-footer text-center'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside classNameName='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}
    </div>
  )
}
export default AddAsset

import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowLeft, FaMinus } from 'react-icons/fa'
import Sidebar from '../../SideBar'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import HeaderName from '../../HeaderName'
// import { useSelector } from 'react-redux'
import { MultiSelect } from "react-multi-select-component";
import apiInstance from '../../../../utils/api'
function LabServiceRegister() {


  //Doctor
  const [branch, setBranch] = useState('')
  const [name, setName] = useState('')
  const [special, setSpecial] = useState('')
  const [treatmentUnitMain, setTreatmentUnitMain] = useState('')
  const [commission, setCommission] = useState(0)
  const [schedule, setSchedule] = useState('')
  const [selected, setSelected] = useState([]);
  //TherapiInstancest
  const [Id, setId] = useState('')
  const [tName, setTName] = useState('')
  const [tSpecial, setTSpecial] = useState('')
  const [tTreatmentUnitMain, setTTreatmentUnitMain] = useState('')
  const [tCommission, setTCommission] = useState('')
  const [tSchedule, setTSchedule] = useState('')

  //Nurse

  const [nName, setNName] = useState('')
  const [nSpecial, setNSpecial] = useState('')
  const [nTreatmentUnitMain, setNTreatmentUnitMain] = useState('')
  const [nCommission, setNCommission] = useState('')
  const [nSchedule, setNSchedule] = useState('')

  const [showRelatedDoc, setShowRelatedDoc] = useState(true)
  const [showTherapiInstancest, setShowTherapiInstancest] = useState(false)
  const [showNurse, setShowNurse] = useState(false)

  const [doctorLists, setDoctorLists] = useState([])
  const [therapiInstancestLists, setTherapiInstancestLists] = useState([])
  const [nurseLists, setNurseLists] = useState([])
  const [headTreatmentList, setHeadTreatmentList] = useState([])
  const [treatmentArr, setTreatmentArr] = useState([])
  const [treatmentName, setTreatmentName] = useState('')
  console.log(treatmentArr, 'treat')

  // const url = 'http://backendcherryk.kwintechnologykw11.com:4000/apiInstance'

  const options = headTreatmentList.map((i) => {
    return { label: i.name, value: i._id }
  })
  // console.log(selected.map((e) => {
  //   return {
  //     relatedTreatmentList: e.value,
  //     commissionPercent: parseInt(commission),
  //   }
  // }), 'tre')

  const handleAdd = Id => {
    setId(Id)
    // console.log(headTreatmentList.filter(el => el._id === Id)[0]?.name, 'name')
    setTreatmentName(headTreatmentList.filter(el => el._id === Id)[0]?.name)

  }

  const handleBox = event => {

    let newPackage = {
      relatedTreatmentList: Id,
      commissionPercent: commission ? parseInt(commission) : 0,

    }
    setTreatmentArr([...treatmentArr, newPackage])
    document.getElementById('com').value = 0

  }
  const handleListDelete = (index) => {
    // console.log(treatmentArr.filter((el, ind) => ind !== index), 'ind')
    setTreatmentArr(treatmentArr.filter((el, ind) => ind !== index))
    setCommission(0)
    // console.log(treatmentArr.filter((el, ind) => ind !== index))
  }
  //Doctor create
  const DoctorCreate = event => {
    const data = {
      name: name,
      treatmentUnitMain: treatmentUnitMain,
      commission: treatmentArr,
      schedule: schedule,
      speciality: special
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('doctor', data, config)
      .then(function (response) {
        setDoctorLists([...doctorLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000
        })


      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  //TherapiInstancest create
  const TherapiInstancestCreate = event => {
    const data = {
      name: tName,

      treatmentUnitMain: tTreatmentUnitMain,
      commission: tCommission,
      schedule: tSchedule,
      speciality: tSpecial
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('therapiInstancest', data, config)
      .then(function (response) {
        setTherapiInstancestLists([...therapiInstancestLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000
        })

        // document.getElementById('name').value = ''
        // document.getElementById('com').value = ''
        // document.getElementById('spe').value = ''
        // document.getElementById('unit').value = ''
        // document.getElementById('sche').value = ''
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  //Nurse
  const NurseCreate = event => {
    const data = {
      name: nName,

      treatmentUnitMain: nTreatmentUnitMain,
      commission: nCommission,
      schedule: nSchedule,
      speciality: nSpecial
    }

    // alert(JSON.stringify(data))
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('nurse', data, config)
      .then(function (response) {
        setNurseLists([...nurseLists, response.data.data])
        Swal.fire({
          title: 'Success',
          text: 'successfully Registered!',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000
        })

        // document.getElementById('name').value = ''
        // document.getElementById('com').value = ''
        // document.getElementById('spe').value = ''
        // document.getElementById('unit').value = ''
        // document.getElementById('sche').value = ''
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Error',
          text: 'Something Wrong',
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  useEffect(() => {
    const getHeadTreatment = async () => {

      try {
        const res = await apiInstance.get('treatment-lists')

        // console.log(res.data.list, 'd list one')
        setHeadTreatmentList(res.data.list)
      } catch (err) { }
    }

    getHeadTreatment()
  }, [])
  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />
        <Sidebar />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/doctor'>
                        <i>
                          <FaArrowLeft />
                        </i>
                      </Link>
                    </li>
                    <li className='breadcrumb-item active mt-1'>
                      Doctor / Nurse Aid Register
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className='content'>
            <div className='container-fluid'>
              {/* <!-- Small boxes (Stat box) --> */}
              <div class='card'>
                <div class='card-body p-b-0'>
                  {/* @if($com == null) */}

                  {/* @csrf */}

                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <label>ReferDoctor</label>&nbsp;
                        <input
                          type='radio'
                          id='doccl'
                          name='doccl'
                          onChange={e => {
                            setShowRelatedDoc(true)
                            setShowNurse(false)

                            setShowTherapiInstancest(false)
                          }}
                        />
                      </div>
                      {/* <div className="col-md-4">
                        <label>Clinic</label>&nbsp;
                        <input
                          type="radio"
                          id="doccl"
                          name="doccl"
                          value="Clinic"
                          onChange={(e) => {
                            setSelection(e.target.value);
                            setShowRelatedDoc(false);
                          }}
                        />
                      </div> */}
                      <div className='col-md-4'>
                        <label>Nurse Aid</label>&nbsp;
                        <input
                          type='radio'
                          id='doccl'
                          name='doccl'
                          onClick={e => {
                            setShowRelatedDoc(false)
                            setShowNurse(true)
                            setShowTherapiInstancest(false)
                          }}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>TherapiInstancest</label>&nbsp;
                        <input
                          type='radio'
                          id='doccl'
                          name='doccl'
                          value='Pathologist'
                          onClick={e => {
                            setShowRelatedDoc(false)
                            setShowNurse(false)

                            setShowTherapiInstancest(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {showNurse && (
                    <>
                      <div className='form-group'>
                        <label className='control-label'>Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='company_address'
                          id='name'
                          onChange={e => setNName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Position</label>
                        <input
                          type='text'
                          className='form-control'
                          id='spe'
                          name='company_contact'
                          onChange={e => setNSpecial(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Treatment Type</label>
                        <input
                          type='email'
                          className='form-control'
                          id='unit'
                          name='company_email'
                          onChange={e => setNTreatmentUnitMain(e.target.value)}
                        />
                      </div>
                      {/* <div className="form-group">
                      <div className="row  mt-3">
                        <div className="col-md-4">
                          <label>Amount</label>&nbsp;
                          <input
                            type="radio"
                            id="amoper"
                            name="amoper"
                            value="Amount"
                            onChange={(e) => setValueType(e.target.value)}
                          />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col-md-4">
                          <label>%</label>&nbsp;
                          <input
                            type="radio"
                            id="amoper"
                            name="amoper"
                            value="Percent"
                            onChange={(e) => setValueType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    */}
                      <div className='form-group'>
                        <label className='control-label'>Commission</label>

                        <input
                          type='number'
                          className='form-control'
                          id='com'
                          onChange={e => setNCommission(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Joined Date</label>

                        <input
                          type='date'
                          className='form-control'
                          id='sche'
                          onChange={e => setNSchedule(e.target.value)}
                        />
                      </div>
                      <div></div>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className=' col-md-9'>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={NurseCreate}
                              >
                                Nurse Create
                              </button>
                              &nbsp;
                              <button
                                type='button'
                                className='btn btn-danger'
                                data-dismiss='modal'
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showRelatedDoc && (
                    <>
                      <div className='form-group'>
                        <label className='control-label'>Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='company_address'
                          id='name'
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Position</label>
                        <input
                          type='text'
                          className='form-control'
                          id='spe'
                          name='company_contact'
                          onChange={e => setSpecial(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Treatment Type</label>
                        <input
                          type='email'
                          className='form-control'
                          id='unit'
                          name='company_email'
                          onChange={e => setTreatmentUnitMain(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Joined Date</label>

                        <input
                          type='date'
                          className='form-control'
                          id='sche'
                          onChange={e => setSchedule(e.target.value)}
                        />
                      </div>
                      <div className=' row '>
                        <div className='col-5' style={{ marginTop: '0.8rem' }}>
                          <label>Treatment</label>&nbsp;
                          <select

                            class='custom-select border-info'
                            name='account_type_id'
                            id='flag'
                            onChange={e => {
                              handleAdd(e.target.value)
                            }}
                          >
                            <option hidden>Choose Treatment</option>
                            {headTreatmentList.map(option => (
                              <option value={option._id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-5' style={{ marginTop: '0.5rem' }}>
                          <label>Comission</label>
                          <input
                            type='number'
                            defaultValue={commission}
                            className='form-control'
                            id='com'
                            onChange={e => setCommission(e.target.value)}
                          />
                        </div>
                        <div className='col-1' style={{ marginTop: '2.3rem' }}>
                          <button
                            type='button'
                            className='btn btn-lg btn-primary'
                            onClick={() => handleBox(Id)}
                          >
                            <i class='fa fa-plus'></i>
                          </button>
                        </div>
                      </div>
                      {treatmentArr ? (
                        <div>
                          {treatmentArr.map((regArr, index) => (
                            <div className='row mt-3'>
                              <div className='col-md-5'>
                                <label>Treatment Name</label>
                                <input
                                  type='text'
                                  disabled
                                  defaultValue={treatmentName}
                                  className='form-control'
                                />
                              </div>
                              <div className='col-md-5'>
                                <label>Comission</label>
                                <input
                                  type='text'
                                  disabled
                                  defaultValue={regArr.commissionPercent ? regArr.commissionPercent : 0}
                                  className='form-control'

                                />
                              </div>


                              <div className='col-md-2'>
                                <button
                                  className='btn btn-sm btn-danger rounded-circle opacity-75'
                                  style={{ marginTop: '2.5em' }}
                                  onClick={() => handleListDelete(index)}
                                >
                                  <FaMinus />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}



                      <div></div>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className=' col-md-9'>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={DoctorCreate}
                              >
                                Doctor Create
                              </button>
                              &nbsp;
                              <Link to='/doctor'>
                                <button
                                  type='button'
                                  className='btn btn-danger'
                                  data-dismiss='modal'

                                >

                                  Cancel
                                </button>
                              </Link>

                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showTherapiInstancest && (
                    <>
                      <div className='form-group'>
                        <label className='control-label'>Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='company_address'
                          id='name'
                          onChange={e => setTName(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Position</label>
                        <input
                          type='text'
                          className='form-control'
                          id='phone'
                          name='company_contact'
                          onChange={e => setTSpecial(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Treatment Type</label>
                        <input
                          type='email'
                          className='form-control'
                          id='email'
                          name='company_email'
                          onChange={e => setTTreatmentUnitMain(e.target.value)}
                        />
                      </div>
                      {/* <div className="form-group">
                      <div className="row  mt-3">
                        <div className="col-md-4">
                          <label>Amount</label>&nbsp;
                          <input
                            type="radio"
                            id="amoper"
                            name="amoper"
                            value="Amount"
                            onChange={(e) => setValueType(e.target.value)}
                          />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col-md-4">
                          <label>%</label>&nbsp;
                          <input
                            type="radio"
                            id="amoper"
                            name="amoper"
                            value="Percent"
                            onChange={(e) => setValueType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    */}
                      <div className='form-group'>
                        <label className='control-label'>Commission</label>

                        <input
                          type='number'
                          className='form-control'
                          onChange={e => setTCommission(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label'>Joined Date</label>

                        <input
                          type='date'
                          className='form-control'
                          onChange={e => setTSchedule(e.target.value)}
                        />
                      </div>
                      <div></div>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='row'>
                            <div className=' col-md-9'>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={TherapiInstancestCreate}
                              >
                                TherapiInstancest Create
                              </button>
                              &nbsp;
                              <button
                                type='button'
                                className='btn btn-danger'
                                data-dismiss='modal'
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* <!-- /.row (main row) --> */}
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
          {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <footer className='main-footer'>
          <strong>
            Copyright &copy; 2017-2020{' '}
            <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
          </strong>
          All rights reserved.
        </footer>
        {/* <!-- Control Sidebar --> */}
        <aside className='control-sidebar control-sidebar-dark'>
          {/* <!-- Control sidebar content goes here --> */}
        </aside>
        {/* <!-- /.control-sidebar --> */}
      </div >
      {/* <!-- ./wrapper --> */}
    </div >
  )
}
export default LabServiceRegister

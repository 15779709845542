import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'
import apiInstance from '../../utils/api'
const AddSupplierDialog = props => {
  // const token = localStorage.getItem('token')
  // const apiInstanceInstance = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseapiInstanceInstance: apiInstanceInstance,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [isShow, setIsShow] = useState(true)
  const [suppliers, setSuppliers] = useState([])
  const [email, setEmail] = useState('')

  const save = () => {
    const data = {
      phone: phone,
      name: name,
      address: address,
      email: email
    }
    apiInstance
      .post('supplier', data)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        props.setSuppliers([...props.suppliers, response.data.data])
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    props.close()
  }

  return (
    <div>
      {isShow && (
        <Dialog open={props.open} onClose={props.close}>
          <DialogTitle className='text-center'>
            <b>Create New Suppllier</b>
          </DialogTitle>
          <DialogContent>
            <div className='row form-group'>
              <div className='col-12 mt-2'>
                <label htmlFor=''>Name:</label>
                <input
                  type='text'
                  className='form-control'
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className='col-12 mt-2'>
                <label htmlFor=''>Phone:</label>
                <input
                  type='number'
                  className='form-control'
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
              <div className='col-12 mt-2'>
                <label htmlFor=''>Email:</label>
                <input
                  type='email'
                  className='form-control'
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className='col-12 mt-2'>
                <label htmlFor=''>Address:</label>
                <textarea
                  className='form-control'
                  onChange={e => setAddress(e.target.value)}
                ></textarea>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button onClick={save}>Create</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default AddSupplierDialog

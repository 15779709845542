import React, { useState } from 'react'
import axios from 'axios'
import SideBar from '../../SideBar'
import { useEffect } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { useLocation, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import UnitUpdate2 from './UnitUpdate2.jsx'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import Treatement from '../Treatment/Treatment'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../../utils/api.js'
const Income = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const scroll = {
    height: '550px',
    overflowY: 'scroll'
  }
  const [treatmentLists, setTreatmentLists] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [showOri, setShowOri] = useState(true)
  const [searchList, setSearchList] = useState([])

  const TreatId = useLocation().pathname.split('/')[2]

  // const _export = React.useRef(null);
  // const excelExport = () => {
  //   if (_export.current !== null) {
  //     console.log(_export.current.props.data);
  //     _export.current.props.data.map(function (element, index) {
  //       element.date = element.date.split("T")[0];
  //     });
  //     _export.current.save();
  //   }
  // };

  const handleDelete = event => {
    console.log(event, 'id')

    apiInstance
      .delete('treatment/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = treatmentLists.filter(item => item._id !== event)

        setTreatmentLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const search = val => {
    if (val) {
      setSearchList(
        treatmentLists.filter(
          el =>
            el.name.toLowerCase().includes(val.toLowerCase()) ||
            el.treatmentCode.toLowerCase().includes(val.toLowerCase())
        )
      )
      setShowSearch(true)
      setShowOri(false)
    } else {
      setShowSearch(false)
      setShowOri(true)
    }
  }

  useEffect(() => {
    const getTreatmentLists = async () => {
      try {
        const res = await apiInstance.get(
          'treatments'
          //'http://localhost:9000/apiInstance/treatments'
        )
        setTreatmentLists(
          res.data.list.filter(
            el =>
              (el.treatmentName == null ? '' : el.treatmentName._id) === TreatId
          )
        )

        // setTreatmentLists(res.data.list)

        console.log(
          res.data.list.filter(
            el =>
              (el.treatmentName == null ? '' : el.treatmentName._id) === TreatId
          ),
          'dat'
        )
      } catch (err) {
        alert(err.message)
      }
    }
    getTreatmentLists()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/treatC'>Back</Link>
                    </li>
                    <li className='breadcrumb-item active'>
                      Treatment Unit List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row d-flex justify-content-between '>
                <div className='col-md-6'>
                  {/* <h4>Medicine Item List</h4> */}
                </div>
                <div className='offset-1 col-md-4 ml-4'>
                  <div
                    className='input-group row ml-4'
                    style={{ marginBottom: '20px' }}
                  >
                    <div className='col-md-6'>
                      <input
                        type='search'
                        className='form-control rounded ml-1'
                        style={{ borderRadius: '12px' }}
                        id='search_code'
                        onChange={e => search(e.target.value)}
                        placeholder='Search'
                      />
                    </div>
                    <div className='col-md-6 mt-1'>
                      <Link
                        to={'/TCL/' + TreatId}
                        className='btn  btn-primary'
                        style={{ borderRadius: '7px' }}
                      >
                        Create Unit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    {/* <div className="card-header">
                      <div className="row justify-content-between">
                        <span className="float-right">
                          <a href="/income_type" className="btn btn-primary">
                            Income Type
                          </a>
                           <button
                              type="button"
                              className="btn btn-primary"
                              onClick={excelExport}>
                              Export Excel
                            </button>
                        </span>
                      </div>
                      <div className="row" id="trial_balance"></div>
                    </div> */}

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {/* <ExcelExport data={incomeLists} ref={_export}>
                        <ExcelExportColumn
                          field=""
                          title="No"
                          locked={true}
                          width={30}
                        />
                        <ExcelExportColumn
                          field="date"
                          title="Date"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={200}
                        />

                        <ExcelExportColumn
                          field="relatedBankAccount.name"
                          title="Bank Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedCashAccount.name"
                          title="Cash Account"
                          headerCellOptions={{
                            textAlign: "center",
                          }}
                          width={250}
                        />

                        <ExcelExportColumn
                          field="relatedAccounting.name"
                          title="Account"
                          width={150}
                        />
                        <ExcelExportColumn
                          field="remark"
                          title="Remark"
                          width={150}
                        />
                      </ExcelExport> */}
                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div
                            className='table-responsive '
                            id='slimtest2'
                            style={scroll}
                          >
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead className='sticky-top'>
                                <tr>
                                  <th>#</th>
                                  <th>Code</th>
                                  <th>Name</th>
                                  <th>Times</th>
                                  {/* <th>Doctor</th> */}
                                  <th>Total Price</th>
                                  <th>Selling Price</th>
                                  <th>Description</th>
                                  {/* <th>Check</th> */}
                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>

                              {showOri && (
                                <tbody>
                                  {treatmentLists.map((treatment, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {treatment.treatmentCode
                                          ? treatment.treatmentCode
                                          : ''}
                                      </td>
                                      <td>{treatment.name}</td>
                                      <td>{treatment.treatmentTimes}</td>
                                      {/* <td>
                                      {treatment.relatedDoctor
                                        ? treatment.relatedDoctor.name
                                        : ''}
                                    </td> */}
                                      <td>{treatment.estimateTotalPrice?.toLocaleString()}</td>
                                      <td>{treatment.sellingPrice?.toLocaleString()}</td>

                                      <td>
                                        {treatment.description
                                          ? treatment.description
                                          : ''}
                                      </td>
                                      {/* <td className='text-center'>
                                      <Link to={'/detail/' + treatment._id}>
                                        <p className='btn btn-sm btn-info rounded'>
                                          Detail
                                        </p>
                                      </Link>
                                    </td> */}
                                      <td className='text-center'>
                                        {/* <div>
                                        <UnitUpdate2 TreatId={TreatId} />
                                      </div> */}
                                        <Link
                                          to={`/unitUpdate/${treatment._id}/${TreatId}`}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(treatment._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                              {showSearch && (
                                <tbody>
                                  {searchList.map((treatment, i) => (
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {treatment.treatmentCode
                                          ? treatment.treatmentCode
                                          : ''}
                                      </td>
                                      <td>{treatment.name}</td>
                                      <td>{treatment.treatmentTimes}</td>
                                      {/* <td>
                                      {treatment.relatedDoctor
                                        ? treatment.relatedDoctor.name
                                        : ''}
                                    </td> */}
                                      <td>{treatment.estimateTotalPrice?.toLocaleString()}</td>
                                      <td>{treatment.sellingPrice?.toLocaleString()}</td>

                                      <td>
                                        {treatment.description
                                          ? treatment.description
                                          : ''}
                                      </td>
                                      {/* <td className='text-center'>
                                      <Link to={'/detail/' + treatment._id}>
                                        <p className='btn btn-sm btn-info rounded'>
                                          Detail
                                        </p>
                                      </Link>
                                    </td> */}
                                      <td className='text-center'>
                                        {/* <div>
                                        <UnitUpdate2 TreatId={TreatId} />
                                      </div> */}
                                        <Link
                                          to={`/unitUpdate/${treatment._id}/${TreatId}`}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(treatment._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Treatement
                open={open}
                close={() => setOpen(false)}
                setTreatmentLists={setTreatmentLists}
                treatmentLists={treatmentLists}
              /> */}
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer' style={{ marginTop: '50px;' }}>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Income

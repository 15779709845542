import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from '../SideBar'
import { useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import {
  FaCashRegister,
  FaFileMedical,
  FaRegEdit,
  FaRegTrashAlt
} from 'react-icons/fa'

import HeaderName from '../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../utils/api'

function Category () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/api/'
  // const api = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [category, setCategory] = useState([])
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [showUpdate, setShowUpdate] = useState(false)
  const [updateDataList, setUpdateDataList] = useState([])
  const [upCode, setUpCode] = useState('')
  const [upName, setUpName] = useState('')
  const [upDesc, setUpDesc] = useState('')
  const [Id, setId] = useState('')

  const handleDelete = event => {
    apiInstance
      .delete('category/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = category.filter(item => item._id !== event)
        setCategory(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleUpdate = event => {
    console.log(event, 'event')
    const getCatUpdate = async () => {
      try {
        const res = await apiInstance.get('category/' + event)
        console.log(res.data.data[0].code)
        setUpCode(res.data.data[0].code)
        setUpName(res.data.data[0].name)
        setUpDesc(res.data.data[0].description)
      } catch (err) {}
    }
    getCatUpdate()
    setShowUpdate(true)
    setId(event)
  }

  const CategoryUpdate = () => {
    const data = {
      id: Id,
      code: upCode,
      name: upName,
      description: upDesc
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('category', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })

        setCategory(
          category.map(category => {
            if (category._id === response.data.data._id) {
              return response.data.data
            } else {
              return category
            }
          })
        )
  
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    document.getElementById('desc').value = ''
    document.getElementById('name').value = ''
    document.getElementById('code').value = ''
    document.getElementById('submit').value = ''
  }

  const CategoryCreate = () => {
    const data = {
      code: code,
      name: name,
      description: description
    }

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('category', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })

        window.location.reload()
      })
      .catch(function (err) {
        alert(err.message)
      })
    document.getElementById('desc').value = ''
    document.getElementById('name').value = ''
    document.getElementById('code').value = ''
    document.getElementById('submit').value = ''
  }
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await apiInstance.get('categories')

        setCategory(res.data.data)
        
      } catch (err) {}
    }

    //   const getCategoryUp = async () => {
    //   try {
    //     const res = await apiInstance.get('category/'+Id)

    //     // setCategory(res.data.data)
    //     console.log(res.data.list,'hi')
    //   } catch (err) {}
    // }
    // getCategoryUp()
    getCategory()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>
                      Category List
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Cost Center List</h3> */}
                    </div>
                    <div className='card-body'>
                      <table id='example1' className='table'>
                        <thead className='bg-info'>
                          <tr>
                            <th>No</th>
                            <th>Code</th>
                            <th>Category Name</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {category.map((cat, i) => (
                          <tbody className=''>
                            <tr>
                              <td>{++i}</td>
                              <td>{cat.code}</td>
                              <td>{cat.name}</td>
                              <td>{cat.description}</td>
                              <td className=''>
                                <button
                                  className='btn btn-sm btn-warning'
                                  onClick={e => handleUpdate(cat._id)}
                                >
                                  <FaRegEdit />
                                </button>
                                &nbsp; &nbsp;
                                <button
                                  className='btn btn-sm btn-danger'
                                  onClick={e => handleDelete(cat._id)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                {showUpdate ? (
                  <div className='col-md-3'>
                    <div className='card px-3 py-3'>
                      <h5 className='card-header mb-4 fw-5 text-secondary'>
                        Update Category
                      </h5>

                      <div class='form-group'>
                        <label for='code' className='text-secondary'>
                          Code
                        </label>
                        <input
                          type='text'
                          id='code'
                          class='form-control border-info'
                          name='code'
                          defaultValue={upCode}
                          //   ref={(el) => (this.name = el)}
                          onChange={e => setUpCode(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Name
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='name'
                          id='name'
                          defaultValue={upName}
                          //   ref={(el) => (this.name = el)}
                          onChange={e => setUpName(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Description
                        </label>
                        <textarea
                          className='form-control'
                          id='desc'
                          defaultValue={upDesc}
                          //   ref={(el) => (this.description = el)}
                          onChange={e => setUpDesc(e.target.value)}
                        ></textarea>
                      </div>
                      <button
                        className='btn form-control text-center fw-5'
                        id='submit'
                           style={{backgroundColor:'#0b9490',color:'white'}}
                        onClick={CategoryUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='col-md-3'>
                    <div className='card px-3 py-3'>
                      <h5 className='card-header mb-4 fw-5 text-secondary'>
                        Create Category
                      </h5>

                      <div class='form-group'>
                        <label for='code' className='text-secondary'>
                          Code
                        </label>
                        <input
                          type='text'
                          id='code'
                          class='form-control border-info'
                          name='code'
                          //   ref={(el) => (this.name = el)}
                          onChange={e => setCode(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Name
                        </label>
                        <input
                          type='text'
                          class='form-control border-info'
                          name='name'
                          id='name'
                          //   ref={(el) => (this.name = el)}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div class='form-group'>
                        <label for='name' className='text-secondary'>
                          Description
                        </label>
                        <textarea
                          className='form-control'
                          id='desc'
                          //   ref={(el) => (this.description = el)}
                          onChange={e => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <button
                          className='btn form-control text-center fw-5'
                          style={{backgroundColor:'#0b9490',color:'white'}}
                        id='submit'
                        onClick={CategoryCreate}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer mt-5'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      {/* <aside classNameName="control-sidebar control-sidebar-dark">
       
      </aside> */}
      {/* <!-- /.control-sidebar --> */}

      {/* <!-- ./wrapper --> */}
    </div>
  )
}
export default Category

import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import apiInstance from '../../utils/api'
export default function BankInfoDialog(props) {
  const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [exchangeRate, setExchangeRate] = useState('')

  const [lastUpdate, setLastUpdate] = useState('')

  const CurrencyCreate = () => {
    const data = {
      name: name,
      code: code,
      exchangeRate: exchangeRate,
      lastUpdate: Date.now()
    }
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    const res = apiInstance.post('currency', data, config).then(function (response) {
      Swal.fire({
        title: 'Successful!',
        text: 'You Created Income Data!',
        icon: 'success',
        // showCancelButton: true,

        cancelButtonText: 'Close'
      })

      props.setCurrencyLists([...props.currencyLists, response.data.data])
    })
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle>
          {' '}
          <div className='modal-header bg-info'>
            <h4 className='modal-title'>Add New Currency</h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={props.close}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <form action='' method='post'>
            {/* @csrf */}
            <div class='modal-body'>
              <div class='form-group'>
                <label for='name'>Code</label>
                <input
                  type='text'
                  class='form-control border border-info'
                  name='code'
                  onChange={e => setCode(e.target.value)}
                />
              </div>
              <div class='form-group'>
                <label for='name'>Name</label>
                <input
                  type='text'
                  class='form-control border border-info'
                  name='name'
                  placeholder='eg. mg mg'
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div class='form-group'>
                <label for='name'>Exchange Rate</label>
                <input
                  type='text'
                  class='form-control border border-info'
                  name='rate'
                  onChange={e => setExchangeRate(e.target.value)}
                />
              </div>
              <div class='form-group'>
                <label for='name'>Last Update</label>
                <input
                  type='date'
                  class='form-control border border-info'
                  name='last'
                  onChange={e => setLastUpdate(e.target.value)}
                />
              </div>
            </div>
            <div class='modal-footer'>
              <Button
                class='btn btn-secondary'
                data-dismiss='modal'
                onClick={props.close}
              >
                Close
              </Button>
              <Button class='btn btn-primary' onClick={CurrencyCreate}>
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// phyo
//maymyat

import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SideBar from '../SideBar'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import HeaderName from '../HeaderName'
import { useSelector } from 'react-redux'
import apiInstance from '../../../utils/api'
function SubCategory () {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'
  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [open, setOpen] = useState(false)
  const [categoryLists, setCategoryLists] = useState([])
  const [subCategoryLists, setSubCategoryLists] = useState([])
  const [brandLists, setBrandLists] = useState([])

  const [code, setCode] = useState('')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [showUpdate, setShowUpdate] = useState(false)

  const [upCode, setUpCode] = useState('')
  const [upName, setUpName] = useState('')
  const [upDesc, setUpDesc] = useState('')
  const [relatedCat, setRelatedCat] = useState('')
  const [relatedCatID, setRelatedCatID] = useState('')
  const [reSubCat, setReSubCat] = useState('')
  const [reSubCatID, setReSubCatID] = useState('')
  const [Id, setId] = useState('')
  // const [categoryLists, setCategoryLists] = useState([]);

  const handleDelete = event => {
    apiInstance
      .delete('brand/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = brandLists.filter(item => item._id !== event)
        setBrandLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const handleUpdate = event => {
    console.log(event, 'event')
    const getBrandUpdate = async () => {
      try {
        const res = await apiInstance.get('brand/' + event)
        console.log(res.data.data)

        setUpCode(res.data.data[0].code)
        console.log(res.data.data[0].code)

        setUpName(res.data.data[0].name)
        setUpDesc(res.data.data[0].description)
        console.log(res.data.data[0].description)

        console.log(res.data.data[0].name)
      } catch (err) {}
    }
    getBrandUpdate()
    setShowUpdate(true)
    setId(event)
  }

  const BrandUpdate = () => {
    const data = {
      id: Id,
      code: upCode,
      name: upName,
      description: upDesc
    }

    // alert(JSON.stringify(data))

    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .put('brand', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        window.location.reload()
        //  setSubCategoryLists([...subCategoryLists, response.data.data]);
        setBrandLists(
          brandLists.map(category => {
            if (category._id === response.data.data._id) {
              return response.data.data
            } else {
              return category
            }
          })
        )
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    document.getElementById('updesc').value = ''
    document.getElementById('upname').value = ''
    document.getElementById('upcode').value = ''
  }

  const BrandCreate = () => {
    const data = {
      code: code,
      name: name,
      // category: category,
      // subCategory: subCategory,
      description: description
    }
    // alert(JSON.stringify(data))
    console.log(data)
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    apiInstance
      .post('brand', data, config)
      .then(function (response) {
        Swal.fire({
          title: 'Successful!',
          text: 'You Created Income Data!',
          icon: 'success',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
        setBrandLists([...brandLists, response.data.data])
      })
      .catch(function (err) {
        Swal.fire({
          title: 'Something Wrong!',
          text: 'Try again, Please.',
          icon: 'warning',
          // showCancelButton: true,

          cancelButtonText: 'Close'
        })
      })
    document.getElementById('desc').value = ''
    document.getElementById('name').value = ''
    document.getElementById('code').value = ''
  }
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await apiInstance.get('categories')

        setCategoryLists(res.data.data)
      } catch (err) {}
    }
    const getSubCategory = async () => {
      try {
        const res = await apiInstance.get('sub-categories')

        setSubCategoryLists(res.data.data)
      } catch (err) {}
    }
    const getBrand = async () => {
      try {
        const res = await apiInstance.get('brands')

        setBrandLists(res.data.data)
      } catch (err) {}
    }

    getCategory()
    getSubCategory()
    getBrand()
  }, [])
  return (
    <div classNameName='App'>
      {/* <!-- end preloader --> */}
      {/* @include('sweet::alert') */}

      <div className='wrapper'>
        {/* <!-- Navbar --> */}
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}

        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'>
                <div className='col-sm-12'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item active fs-5'>Brand List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-9'>
                  <div className='card'>
                    <div className='card-header'>
                      {/* <h3 className="card-title">Cost Center List</h3> */}
                    </div>
                    <div className='card-body'>
                      <table id='example1' className='table'>
                        <thead className=' bg-info'>
                          <tr>
                            <th>No</th>
                            <th>Code</th>
                            <th>Name</th>
                            {/* <th>Category</th>
                            <th>Sub Category</th> */}
                            <th>Description</th>
                            <th className='text-center'>Action</th>
                          </tr>
                        </thead>

                        {brandLists.map((brand, i) => (
                          <tbody className=''>
                            <tr>
                              <td>{++i}</td>
                              <td>{brand.code}</td>
                              <td>{brand.name}</td>
                              {/* <td>
                                {brand.relatedCategory
                                  ? brand.relatedCategory.name
                                  : ""}
                              </td>
                              <td>
                                {brand.subCategory
                                  ? brand.subCategory.name
                                  : ""}
                              </td> */}
                              <td>{brand.description}</td>
                              <td className='text-center'>
                                <button
                                  className='btn btn-sm btn-warning'
                                  onClick={e => handleUpdate(brand._id)}
                                >
                                  <FaRegEdit />
                                </button>
                                &nbsp;
                                <button
                                  className='btn btn-sm btn-danger'
                                  onClick={e => handleDelete(brand._id)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                {showUpdate ? (
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='card-header mb-4 fw-5 text-secondary py-3'>
                        Update Brand
                      </h5>
                      <div className='px-3 py-3'>
                        <div class='form-group'>
                          <label for='code' className='text-secondary'>
                            Code
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='code'
                            id='upcode'
                            value={upCode}
                            onChange={e => setUpCode(e.target.value)}
                          />
                        </div>
                        {/* <div class="form-group">
                          <label for="name">Category</label>
                          <select
                            class="custom-select border-info"
                            name="account_type_id"
                            id="cat"
                            onChange={(e) => setRelatedCat(e.target.value)}>
                            <option value={relatedCatID}>{relatedCat}</option>
                            {categoryLists.map((option) => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="name">Sub Category</label>
                          <select
                            class="custom-select border-info"
                            id="subCat"
                            name="account_type_id"
                            onChange={(e) => setReSubCat(e.target.value)}>
                            <option value={reSubCatID}>{reSubCat}</option>
                            {subCategoryLists.map((option) => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div> */}
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Name
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='balance'
                            id='upname'
                            value={upName}
                            onChange={e => setUpName(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Description
                          </label>
                          <textarea
                            className='form-control'
                            id='updesc'
                            value={upDesc}
                            onChange={e => setUpDesc(e.target.value)}
                          ></textarea>
                        </div>
                        <button
                          className='btn form-control text-center fw-5'
                             style={{backgroundColor:'#0b9490',color:'white'}}
                          onClick={BrandUpdate}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='card-header mb-4 fw-5 text-secondary py-3'>
                        Create Brand
                      </h5>
                      <div className='px-3 py-3'>
                        <div class='form-group'>
                          <label for='code' className='text-secondary'>
                            Code
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='code'
                            id='code'
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setCode(e.target.value)}
                          />
                        </div>
                        {/* <div class="form-group">
                          <label for="name">Category</label>
                          <select
                            class="custom-select border-info"
                            name="account_type_id"
                            id="cat"
                            onChange={(e) => setCategory(e.target.value)}>
                            <option>Choose Category</option>
                            {categoryLists.map((option) => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="name">Sub Category</label>
                          <select
                            class="custom-select border-info"
                            id="subCat"
                            name="account_type_id"
                            onChange={(e) => setSubCategory(e.target.value)}>
                            <option>Choose Sub Category</option>
                            {subCategoryLists.map((option) => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                          </select>
                        </div> */}
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Name
                          </label>
                          <input
                            type='text'
                            class='form-control border-info'
                            name='balance'
                            id='name'
                            //   ref={(el) => (this.name = el)}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='name' className='text-secondary'>
                            Description
                          </label>
                          <textarea
                            className='form-control'
                            id='desc'
                            // value={upDesc}
                            //   ref={(el) => (this.description = el)}
                            onChange={e => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                        <button
                            className='btn form-control text-center fw-5'
                               style={{backgroundColor:'#0b9490',color:'white'}}
                          onClick={BrandCreate}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*<!-- /.container-fluid --> */}
          </section>
        </div>
      </div>

      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer mt-5'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  )
}
export default SubCategory

import React, { useState } from 'react'
// import IncomeDialog from '../views/IncomeDialog'
import axios from 'axios'
import { useEffect } from 'react'

import Swal from 'sweetalert2'
import { Button } from '@mantine/core'

import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'

import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Link } from 'react-router-dom'

import useCollapse from 'react-collapsed'
import HeaderName from '../../HeaderName'
import { useSelector } from 'react-redux'
import SideBar from '../../SideBar.js'
import apiInstance from '../../../../utils/api.js'
const Income = () => {
  // const token = localStorage.getItem('token')
  // const url = 'http://clinicdenovobackend.kwintechnologies.com:3000/apiInstance/'

  // const apiInstance = axios.create({
  //   baseURL: url,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  // })

  const [fromAcc, setFromAcc] = useState('')
  const [fromType, setFromType] = useState('')
  const [fromAmount, setFromAmount] = useState('')
  const [toAcc, setToAcc] = useState('')
  const [toType, setToType] = useState('')
  const [toAmount, setToAmount] = useState('')
  const [date, setDate] = useState('')
  const [accList, setAccList] = useState([])
  const timeElapsed = Date.now()
  const today = new Date(timeElapsed)
  const today_date = today.toISOString().split('T')[0]
  console.log(today_date)
  const [hide, setHide] = useState(true)

  const [isExpanded, setExpanded] = useState(false)
  const [isAccData, setAccData] = useState(false)
  const [relatedLists, setRelatedLists] = useState([])
  const [isShow, setIsShow] = useState(false)

  const [fromDate, setFromDate] = useState(today_date)

  const [toDate, setToDate] = useState(today_date)

  const [bankTotal, setBankTotal] = useState(0)
  const [cashTotal, setCashTotal] = useState(0)

  const [bankIncomeList, setBankIncomeList] = useState([])
  const [cashIncomeList, setCashIncomeList] = useState([])
  const [bankNameList, setBankNameList] = useState([])
  const [cashNameList, setCashNameList] = useState([])
  const [bankList, setBankList] = useState([])
  const [cashList, setCashList] = useState([])
  const [type, setType] = useState('')
  const [showFilter, setShowFilter] = useState('')

  const [show, setShow] = useState('bankTable')

  const [searchList, setSearchList] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [acc, setAcc] = useState('')
  const [showBankAcc, setShowBankAcc] = useState(true)
  const [showCashAcc, setShowCashAcc] = useState(false)

  const handleDelete = event => {
    apiInstance
      .delete('income/' + event)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully Deleted!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        const result = incomeLists.filter(item => item._id !== event)
        setIncomeLists(result)
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'CANCEL'
        })
      })
  }

  const [incomeLists, setIncomeLists] = useState([])
  const [open, setOpen] = useState(false)

  const showDialog = () => setOpen(true)
  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.date = element.date ? element.date.split('T')[0] : ''
      })
      _export.current.save()
    }
  }
  const excelExport1 = () => {
    if (_export.current !== null) {
      console.log(_export.current.props.data)
      _export.current.props.data.map(function (element, index) {
        element.date = element.date ? element.date.split('T')[0] : ''
      })
      _export.current.save()
    }
  }
  const handleTransaction = name => { }

  const handleType = name => {
    setType(name)
    if (name == 'Bank') {
      setBankList(
        accList.filter(
          el =>
            el.relatedHeader.name == 'Cash at Bank' &&
            el.relatedType.name == 'Assets'
        )
      )
      setShowBankAcc(true)
      setShowCashAcc(false)
    } else {
      setCashList(
        accList.filter(
          el =>
            el.relatedHeader.name == 'Cash in Hand' &&
            el.relatedType.name == 'Assets'
        )
      )
      setShowBankAcc(false)
      setShowCashAcc(true)
    }
  }

  const handleRelatedShow = async id => {
    // const res = await apiInstance.get('transactions/related/' + id)

    // console.log(res.data.data, 'rel list')
    // setFromAcc(res.data.data[0].relatedTransaction?.relatedAccounting?.name)
    // setFromAmount(res.data.data[0].relatedTransaction?.amount)
    // setFromType(res.data.data[0].relatedTransaction?.type)
    // // console.log(res.data.data[0].type, 'type')
    // setToAcc(res.data.data[0].relatedBank?.name)
    // setToAmount(res.data.data[0]?.amount)
    // setToType(res.data.data[0]?.type)
    // setDate(res.data.data[0] ? res.data.data[0].date : '')

    // if (isShow) {
    //   document.getElementById('toggle' + id).removeAttribute('hidden')
    // } else {
    //   document.getElementById('toggle' + id).setAttribute('hidden', 'hidden')
    // }
    // setIsShow(!isShow)
    Swal.fire({
      title: 'Wait',
      text: 'Not yet!',
      icon: 'warning',
      confirmButtonText: 'OK'
    })
  }

  const handleFilterDate = async () => {
    console.log(acc, 'acc')

    console.log(type, 'type')
    setShowFilter(type)
    const nDate = new Date(toDate)
    const endDate = nDate.setDate(nDate.getDate() + 1)
    try {
      Swal.fire({
        title: 'Loading',
        text: 'Please wait...',
        allowOutsideClick: false
      })
      await apiInstance
        .get(`transactions/report`, {
          params: { start: fromDate, end: endDate, type: type, account: acc }
        })
        .then(res => {
          // console.log(res.data, 'fil list')
          //setTransactionLists(res.data.transaction)
          // console.log(res.data.data, 'date fil')
          setBankTotal(res.data.total)
          setCashTotal(res.data.total)
          setBankIncomeList(res.data.data.filter(el => !el.relatedAccounting))
          console.log(
            res.data.data.filter(el => !el.relatedAccounting),
            'b list'
          )

          setCashIncomeList(res.data.data)
          console.log(res.data.data, 'list')

          setBankNameList(res.data.names)
          setCashNameList(res.data.names)
          //console.log(res.data.transaction)
        })
        .catch(error => {
          console.log('error', error)
        })
      Swal.close()

      // Process the response data
      //console.log(data);
    } catch (error) {
      Swal.close()
      console.error('Error occurred while fetching data.', error)
    }
  }
  const search = val => {
    console.log(val, 'e val')
    if (val) {
      setSearchList(
        incomeLists.filter(
          el =>
            (el.relatedAccounting ? el.relatedAccounting.name === val : '') ||
            el.relatedBranch.name === val ||
            (el.relatedBankAccount
              ? el.relatedBankAccount.name == val
              : el.relatedCashAccount.name == val)
        )
      )
      setShowSearch(true)
      setHide(false)
    } else {
      setShowSearch(false)
      setHide(true)
    }
  }

  useEffect(() => {
    const getBankLists = async () => {
      try {
        const res = await apiInstance.get('accounting-lists')
        console.log(res.data.list, 'bank')

        setAccList(res.data.list)

        console.log(
          res.data.list.filter(el => el.relatedBank),
          'bank'
        )

        // const bank = res.data.list.filter(
        //   el =>
        //     el.relatedHeader.name == 'Cash At Bank' &&
        //     el.relatedType.name === 'Assets'
        // )
      } catch (err) { }
    }

    getBankLists()
    handleFilterDate()
  }, [])

  return (
    <div classNameName='App'>
      <div className='wrapper'>
        <HeaderName />

        {/* <!-- /.navbar --> */}

        {/* <!-- Main Sidebar Container --> */}
        <SideBar />

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className='content-wrapper'>
          {/* <!-- Content Header (Page header) --> */}
          <div className='content-header'>
            <div className='container-fluid'>
              <div className='row mb-2'></div>
            </div>
          </div>

          {/* <!-- Main content --> */}

          <section className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='text-center'>
                  <h4>Bank / Cash Transaction Report</h4>
                </div>

                <div className='col-md-12 mt-5'>
                  <div className='row'>
                    <div className='form-group col-md-2'>
                      <label>From</label>
                      <input
                        type='date'
                        name='from'
                        id='from'
                        className='form-control'
                        onChange={e => setFromDate(e.target.value)}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <label>To</label>
                      <input
                        type='date'
                        name='to'
                        id='to'
                        className='form-control'
                        onChange={e => setToDate(e.target.value)}
                      />
                    </div>
                    <div className='col-md-2' style={{ marginTop: '0.4em' }}>
                      <div className='form-group'>
                        <label>Type</label>

                        <select
                          name='currency'
                          id=''
                          className='form-control '
                          // onchange='convert(this.value)'
                          // placeholder='Filter By Branches'
                          onChange={e => handleType(e.target.value)}
                        >
                          <option hidden>Select</option>

                          <option value='Bank'>Bank</option>
                          <option value='Cash'>Cash</option>

                          {/* {branchLists.map(option => (
                            <option value={option._id}>{option.name}</option>
                          ))} */}
                        </select>
                      </div>
                    </div>

                    <div className='col-md-2' style={{ marginTop: '0.4em' }}>
                      <div className='form-group'>
                        <label>Account</label>

                        <select
                          name='currency'
                          id=''
                          className='form-control '
                          // onchange='convert(this.value)'
                          // placeholder='Filter By Branches'
                          onChange={e => setAcc(e.target.value)}
                        >
                          <option hidden>Select</option>
                          {showBankAcc &&
                            bankList.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}{' '}
                          {showCashAcc &&
                            cashList.map(option => (
                              <option value={option._id}>{option.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className='form-group col-md-1'>
                      <button
                        className='btn btn-sm btn-primary form-control'
                        style={{ marginTop: '32px' }}
                        onClick={() => handleFilterDate()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div
                      className='col-3 input-group'
                      style={{ marginTop: '28px' }}
                    >
                      {/* <input
                        type='search'
                        className='form-control rounded'
                        id='search_code'
                        placeholder='Serach'
                        onChange={e => search(e.target.value)}
                      /> */}
                      {/* <button
                        type='button'
                        className='btn btn-outline-primary'
                        style={{ height: '0.97cm', marginTop: '0.2cm' }}
                      >
                        search
                      </button> */}
                    </div>
                    <div
                      className='offset-3 col-md-5'
                      style={{ marginTop: '2.6em' }}
                    >
                      {/* <div className='form-group'>
                        <select
                          name='currency'
                          id=''
                          className='form-control '
                          onChange={e => handleTransaction(e.target.value)}
                        >
                          <option hidden>Transactions ...</option>

                          <option value='Income'>Income</option>
                          <option value='Expense'>Expense</option>
                          <option value='Medicine'>Medicine</option>
                          <option value='Treatment'>Treatment</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex text-center'>
                          {/* <div className='' style={{ marginRight: '5px' }}>
                            <Button
                              color='cyan'
                              radius='sm'
                              size='sm'
                              onClick={() => setShow('bankTable')}
                            >
                              Bank (Name) Total:{bankTotal}
                            </Button>
                          </div>

                          <div className=''>
                            <Button
                              color='cyan'
                              radius='sm'
                              size='sm'
                              onClick={() => setShow('cashTable')}
                            >
                              Cash (Name) Total:{cashTotal}
                            </Button>
                          </div> */}
                        </div>

                        <span className='float-right'>
                          {/* <button
                            type='button'
                            className='btn btn-sm btn-dark'
                            onClick={showDialog}
                          >
                            <i className='fas fa-plus'></i> Add
                          </button> */}
                          &nbsp;
                          {/* <a href="/income_type" className="btn btn-primary">
                            Income Type
                          </a> */}
                          {show == 'bankTable' ? (
                            <button
                              type='button'
                              className='btn btn-sm btn-success'
                              onClick={excelExport}
                            >
                              Export
                            </button>
                          ) : (
                            <button
                              type='button'
                              className='btn btn-sm btn-success'
                              onClick={excelExport1}
                            >
                              Export
                            </button>
                          )}
                        </span>
                      </div>
                      <div className='row' id='trial_balance'></div>
                    </div>

                    <div className='card-body'>
                      {/* Export data in Excel */}
                      {show == 'bankTable' ? (
                        <ExcelExport data={bankIncomeList} ref={_export}>
                          <ExcelExportColumn
                            field=''
                            title='No'
                            locked={true}
                            width={30}
                          />
                          <ExcelExportColumn
                            field='date'
                            title='Date'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={200}
                          />

                          <ExcelExportColumn
                            field='relatedBank.name'
                            title='Bank Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='amount'
                            title='Amount'
                            width={150}
                          />
                        </ExcelExport>
                      ) : (
                        <ExcelExport data={cashIncomeList} ref={_export}>
                          <ExcelExportColumn
                            field=''
                            title='No'
                            locked={true}
                            width={30}
                          />
                          <ExcelExportColumn
                            field='date'
                            title='Date'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={200}
                          />

                          <ExcelExportColumn
                            field='relatedCash.name'
                            title='Cash Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='amount'
                            title='Amount'
                            width={150}
                          />
                        </ExcelExport>
                      )}

                      {/* {showBranch && (
                        <ExcelExport data={relatedBranch} ref={_export}>
                          <ExcelExportColumn
                            field=''
                            title='No'
                            locked={true}
                            width={30}
                          />
                          <ExcelExportColumn
                            field='date'
                            title='Date'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={200}
                          />

                          <ExcelExportColumn
                            field='relatedBankAccount.name'
                            title='Bank Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedCashAccount.name'
                            title='Cash Account'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={250}
                          />

                          <ExcelExportColumn
                            field='relatedAccounting.name'
                            title='Account'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalAmount'
                            title='Balance'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />
                          <ExcelExportColumn
                            field='finalCurrency'
                            title='Currency'
                            headerCellOptions={{
                              textAlign: 'center'
                            }}
                            width={150}
                          />

                          <ExcelExportColumn
                            field='relatedBranch.name'
                            title='Branch'
                            width={150}
                          />
                          <ExcelExportColumn
                            field='remark'
                            title='Remark'
                            width={150}
                          />
                        </ExcelExport>
                      )} */}

                      {/* Export Data in Excel end */}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div
                            className='table-responsive text-black'
                            id='slimtest2'
                          >
                            <table
                              className='table table-hover'
                              id='filter_date'
                            >
                              <thead className='ok'>
                                <tr>
                                  <th>#</th>
                                  <th className=''>Date</th>
                                  {showFilter == 'Bank' ? (
                                    <th className=''>Bank Account</th>
                                  ) : showFilter == 'Cash' ? (
                                    <th className=''> Cash Account</th>
                                  ) : (
                                    <th className=''> Account</th>
                                  )}

                                  <th className=''>Amount</th>

                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>
                              <tbody className=''>
                                {show === 'bankTable'
                                  ? bankIncomeList.map((incomeList, i) => (
                                    <>
                                      <tr>
                                        <td>{++i}</td>
                                        <td>
                                          {incomeList.date
                                            ? incomeList.date.split('T')[0]
                                            : ''}
                                        </td>
                                        <td>
                                          {incomeList.relatedBank
                                            ? incomeList.relatedBank.name
                                            : ''}
                                        </td>
                                        {/* <td>
                                            {incomeList.relatedAccounting.name}
                                          </td> */}
                                        <td>{incomeList.amount}</td>
                                        <td className='text-center'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-primary ml-2'
                                            onClick={() =>
                                              handleRelatedShow(
                                                incomeList.relatedTransaction
                                                  .relatedAccounting?._id
                                              )
                                            }
                                          >
                                            Detail
                                          </button>
                                        </td>

                                        {/* <td>
                                            <Link
                                              to={
                                                '/income-update/' +
                                                incomeList._id
                                              }
                                              className='btn btn-sm btn-warning'
                                            >
                                              <FaRegEdit />
                                            </Link>
                                            &nbsp;
                                            <button
                                              className='btn btn-sm btn-danger'
                                              onClick={e =>
                                                handleDelete(incomeList._id)
                                              }
                                            >
                                              <FaRegTrashAlt />
                                            </button>
                                            &nbsp;
                                          </td> */}
                                      </tr>
                                      <tr
                                        className='bg-light'
                                        id={
                                          'toggle' +
                                          incomeList.relatedTransaction
                                            .relatedAccounting?._id
                                        }
                                        hidden
                                      >
                                        <td colspan='10'>
                                          <div style={{ marginLeft: '15em' }}>
                                            <div class='row'>
                                              <div className='col-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  No
                                                </label>
                                              </div>
                                              <div class='col-md-3'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Account
                                                </label>
                                              </div>
                                              <div class='col-md-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Type
                                                </label>
                                              </div>
                                              <div class='col-md-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Date
                                                </label>
                                              </div>
                                              <div class='col-md-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Amount
                                                </label>
                                              </div>
                                            </div>

                                            <div class='row'>
                                              <div className='col-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  1
                                                </label>
                                              </div>

                                              <div class='col-md-3'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {fromAcc}(From)
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                {fromType}
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {date.split('T')[0]}
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {fromAmount}
                                                </div>
                                              </div>
                                            </div>
                                            <div class='row'>
                                              <div className='col-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  2
                                                </label>
                                              </div>

                                              <div class='col-md-3'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {toAcc}(To)
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                {toType}
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {date.split('T')[0]}
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {toAmount}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                  : cashIncomeList.map((incomeList, i) => (
                                    <>
                                      <tr>
                                        <td>{++i}</td>
                                        <td>
                                          {incomeList.date
                                            ? incomeList.date.split('T')[0]
                                            : ''}
                                        </td>
                                        <td>
                                          {incomeList.relatedCash &&
                                            incomeList.relatedBank
                                            ? incomeList.relatedCash.name
                                            : ''}
                                        </td>
                                        <td>
                                          {incomeList.relatedAccounting.name}
                                        </td>
                                        <td>
                                          {incomeList.finalAmount
                                            ? incomeList.finalAmount.toLocaleString(
                                              undefined,
                                              {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                              }
                                            )
                                            : ''}
                                        </td>
                                        <td>{incomeList.finalCurrency}</td>

                                        <td>{incomeList.remark}</td>
                                        <td className='text-center'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-primary ml-2'
                                            onClick={() =>
                                              handleRelatedShow(
                                                incomeList.relatedTransaction
                                                  .relatedAccounting?._id
                                              )
                                            }
                                          >
                                            Detail
                                          </button>
                                        </td>

                                        {/* <td>
                                            <Link
                                              to={
                                                '/bank-update/' + incomeList._id
                                              }
                                              className='btn btn-sm btn-warning'
                                            >
                                              <FaRegEdit />
                                            </Link>
                                            &nbsp;
                                            <button
                                              className='btn btn-sm btn-danger'
                                              onClick={e =>
                                                handleDelete(incomeList._id)
                                              }
                                            >
                                              <FaRegTrashAlt />
                                            </button>
                                            &nbsp;
                                          </td> */}
                                      </tr>
                                      <tr
                                        className='bg-light'
                                        id={
                                          'toggle' +
                                          incomeList.relatedTransaction
                                            .relatedAccounting?._id
                                        }
                                        hidden
                                      >
                                        <td colspan='10'>
                                          <div style={{ marginLeft: '15em' }}>
                                            <div class='row'>
                                              <div className='col-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  No
                                                </label>
                                              </div>
                                              <div class='col-md-3'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Account
                                                </label>
                                              </div>
                                              <div class='col-md-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Type
                                                </label>
                                              </div>
                                              <div class='col-md-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Date
                                                </label>
                                              </div>
                                              <div class='col-md-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  Amount
                                                </label>
                                              </div>
                                            </div>

                                            <div class='row'>
                                              <div className='col-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  1
                                                </label>
                                              </div>

                                              <div class='col-md-3'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {fromAcc}(From)
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                {fromType}
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {date.split('T')[0]}
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {fromAmount}
                                                </div>
                                              </div>
                                            </div>
                                            <div class='row'>
                                              <div className='col-2'>
                                                <label
                                                  style={{ fontSize: '15px' }}
                                                  class='text-dark'
                                                >
                                                  2
                                                </label>
                                              </div>

                                              <div class='col-md-3'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {toAcc}(To)
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                {toType}
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {date.split('T')[0]}
                                                </div>
                                              </div>
                                              <div class='col-md-2'>
                                                <div
                                                  style={{
                                                    fontSize: '15px'
                                                  }}
                                                >
                                                  {toAmount}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                              {/* {showBranch &&
                                relatedBranch.map((incomeList, i) => (
                                  <tbody className=''>
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {incomeList.date
                                          ? incomeList.date.split('T')[0]
                                          : ''}
                                      </td>
                                      <td>
                                        {incomeList.relatedBankAccount
                                          ? incomeList.relatedBankAccount.name
                                          : incomeList.relatedCashAccount.name}
                                      </td>
                                      <td>
                                        {incomeList.relatedAccounting.name}
                                      </td>
                                      <td>
                                        {incomeList.finalAmount
                                          ? incomeList.finalAmount.toLocaleString(
                                              undefined,
                                              {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                              }
                                            )
                                          : ''}
                                      </td>
                                      <td>{incomeList.finalCurrency}</td>
                                      <td>{incomeList.relatedBranch.name}</td>

                                      <td>{incomeList.remark}</td>
                                      <td className='text-center'>
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-primary ml-2'
                                          onClick={() =>
                                            handleRelatedShow(
                                              incomeList._id,
                                              incomeList.relatedAccounting._id
                                            )
                                          }
                                        >
                                          Transaction
                                        </button>
                                      </td>

                                      <td>
                                        <Link
                                          to={'/bank-update/' + incomeList._id}
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(incomeList._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                        &nbsp;
                                      </td>
                                    </tr>

                                    

                              {hide &&
                                incomeLists.map((incomeList, i) => (
                                  <tbody className=''>
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {incomeList.date
                                          ? incomeList.date.split('T')[0]
                                          : ''}
                                      </td>
                                      <td>
                                        {incomeList.relatedBankAccount
                                          ? incomeList.relatedBankAccount.name
                                          : incomeList.relatedCashAccount.name}
                                      </td>
                                      <td>
                                        {incomeList.relatedAccounting.name}
                                      </td>
                                      <td>
                                        {incomeList.finalAmount
                                          ? incomeList.finalAmount.toLocaleString(
                                              undefined,
                                              {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                              }
                                            )
                                          : ''}
                                      </td>
                                      <td>{incomeList.finalCurrency}</td>
                                      <td>{incomeList.relatedBranch.name}</td>

                                      <td>{incomeList.remark}</td>
                                      <td className='text-center'>
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-primary ml-2'
                                          onClick={() =>
                                            handleRelatedShow(
                                              incomeList._id,
                                              incomeList.relatedAccounting._id
                                            )
                                          }
                                        >
                                          Transaction
                                        </button>
                                      </td>

                                      <td>
                                        <Link
                                          to={
                                            '/income-update/' + incomeList._id
                                          }
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(incomeList._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                        &nbsp;
                                      </td>
                                    </tr>

                                    <tr
                                      className='bg-light'
                                      id={'toggle' + incomeList._id}
                                      hidden
                                    >
                                      <td colspan='12'>
                                        <div>
                                          <div class='row'>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                No
                                              </label>
                                            </div>
                                            <div class='col-md-3'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Account
                                              </label>
                                            </div>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Type
                                              </label>
                                            </div>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Date
                                              </label>
                                            </div>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Amount
                                              </label>
                                            </div>
                                          </div>

                                          {relatedLists
                                            ? relatedLists.map((reList, i) => (
                                                <div class='row'>
                                                  <div class='col-md-2'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {++i}
                                                    </div>
                                                  </div>
                                                  <div class='col-md-3'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {
                                                        reList.relatedAccounting
                                                          .name
                                                      }
                                                    </div>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    {reList.type}
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {
                                                        reList.date.split(
                                                          'T'
                                                        )[0]
                                                      }
                                                    </div>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {reList.amount}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                            : ''}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>

                                ))}
                              {showSearch &&
                                searchList.map((incomeList, i) => (
                                  <tbody className=''>
                                    <tr>
                                      <td>{++i}</td>
                                      <td>
                                        {incomeList.date
                                          ? incomeList.date.split('T')[0]
                                          : ''}
                                      </td>
                                      <td>
                                        {incomeList.relatedBankAccount
                                          ? incomeList.relatedBankAccount.name
                                          : incomeList.relatedCashAccount.name}
                                      </td>
                                      <td>
                                        {incomeList.relatedAccounting.name}
                                      </td>
                                      <td>
                                        {incomeList.finalAmount
                                          ? incomeList.finalAmount.toLocaleString(
                                              undefined,
                                              {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                              }
                                            )
                                          : ''}
                                      </td>
                                      <td>{incomeList.finalCurrency}</td>
                                      <td>{incomeList.relatedBranch.name}</td>

                                      <td>{incomeList.remark}</td>
                                      <td className='text-center'>
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-primary ml-2'
                                          onClick={() =>
                                            handleRelatedShow(
                                              incomeList._id,
                                              incomeList.relatedAccounting._id
                                            )
                                          }
                                        >
                                          Transaction
                                        </button>
                                      </td>

                                      <td>
                                        <Link
                                          to={
                                            '/income-update/' + incomeList._id
                                          }
                                          className='btn btn-sm btn-warning'
                                        >
                                          <FaRegEdit />
                                        </Link>
                                        &nbsp;
                                        <button
                                          className='btn btn-sm btn-danger'
                                          onClick={e =>
                                            handleDelete(incomeList._id)
                                          }
                                        >
                                          <FaRegTrashAlt />
                                        </button>
                                        &nbsp;
                                      </td>
                                    </tr>

                                    <tr
                                      className='bg-light'
                                      id={'toggle' + incomeList._id}
                                      hidden
                                    >
                                      <td colspan='12'>
                                        <div>
                                          <div class='row'>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                No
                                              </label>
                                            </div>
                                            <div class='col-md-3'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Account
                                              </label>
                                            </div>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Type
                                              </label>
                                            </div>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Date
                                              </label>
                                            </div>
                                            <div class='col-md-2'>
                                              <label
                                                style={{ fontSize: '15px' }}
                                                class='text-dark'
                                              >
                                                Amount
                                              </label>
                                            </div>
                                          </div>

                                          {relatedLists
                                            ? relatedLists.map((reList, i) => (
                                                <div class='row'>
                                                  <div class='col-md-2'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {++i}
                                                    </div>
                                                  </div>
                                                  <div class='col-md-3'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {
                                                        reList.relatedAccounting
                                                          .name
                                                      }
                                                    </div>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    {reList.type}
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {
                                                        reList.date.split(
                                                          'T'
                                                        )[0]
                                                      }
                                                    </div>
                                                  </div>
                                                  <div class='col-md-2'>
                                                    <div
                                                      style={{
                                                        fontSize: '15px'
                                                      }}
                                                    >
                                                      {reList.amount}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                            : ''}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}

                                ))} */}
                            </table>
                          </div>

                          <div className='d-flex '>
                            {showFilter == 'Bank' && (
                              <div style={{ marginRight: '100px' }}>
                                <p>
                                  <b>
                                    <u>Bank List</u>
                                  </b>
                                </p>
                                {Object.entries(bankNameList).map(([k, v]) => (
                                  <p>
                                    {k} : {v}{' '}
                                  </p>
                                ))}
                              </div>
                            )}

                            {showFilter == 'Cash' && (
                              <div>
                                <p>
                                  <b>
                                    <u>Cash List</u>
                                  </b>
                                </p>
                                {Object.entries(cashNameList).map(([k, v]) => (
                                  <p>
                                    {k} : {v}{' '}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <IncomeDialog
                open={open}
                close={() => setOpen(false)}
                setIncomeLists={setIncomeLists}
                incomeLists={incomeLists}
              /> */}
            </div>
          </section>
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
      <footer className='main-footer'>
        <strong>
          Copyright &copy; 2017-2020{' '}
          <a href='http://www.kwintechnologies.com'>K-win Technology</a>.
        </strong>
        All rights reserved.
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className='control-sidebar control-sidebar-dark'>
        {/* <!-- Control sidebar content goes here --> */}
      </aside>

      {/* <!-- ./wrapper --> */}
    </div>
  )
}

export default Income
